import {
  WagtailBlockProps,
  Colors,
  LAYOUT_TEMPLATE
} from '../../services/api/types'
import * as S from './Divider.styled'

export interface DividerProps {
  colour: Colors
  size: string
  template?: LAYOUT_TEMPLATE
}

export interface DividerAPIProps extends WagtailBlockProps {
  value: DividerProps
}

export const Divider = (props: DividerProps) => (
  <S.DividerContainer {...props} />
)

Divider.ApiBlockToProps = (data: DividerAPIProps) => {
  const { value } = data
  if (value) {
    let props: DividerProps = {
      colour: value.colour,
      size: value.size
    }
    return props
  }
}
