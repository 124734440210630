import * as S from './BlocksTable.styles'
import * as G from '../../styles/global.styles'
import { Colors, WagtailBlockProps } from '../../services/api/types'
import { BlockTitle } from '../base'

interface Block {
  text: string
  isFull?: boolean
}
export interface BlocksTableProps {
  title: string
  subTitle: string
  blocks: Block[]
  background_colour?: Colors
}

export interface BlocksTableAPIProps extends WagtailBlockProps {
  value: {
    headline: string
    text: string
    table_items: Block[]
    background_colour: {
      background_colour?: Colors
    }
  }
}

export const BlocksTable = (props: BlocksTableProps) => {
  const { blocks, title, subTitle, background_colour } = props
  return (
    <G.Section bgColor={background_colour}>
      <G.Container contentSize='lg'>
        <G.CenteredContainer>
          {title && (
            <BlockTitle textColor='blue-dark' underlineColor='orange'>
              {title}
            </BlockTitle>
          )}
          {subTitle && (
            <G.Text color='blue' fontStyle='italic'>
              {subTitle}
            </G.Text>
          )}
        </G.CenteredContainer>
        <S.BlocksWrapper>
          {blocks &&
            blocks.map((block, i) => (
              <S.Block
                key={i}
                color={block.isFull ? 'orange' : i % 2 == 1 ? 'blue' : 'white'}
                isFull={block.isFull}
              >
                <S.BlockNumber>
                  <G.Text
                    color={block.isFull ? 'white' : 'orange'}
                    size='6xl'
                    fontWeight='bold'
                  >
                    {i + 1}
                  </G.Text>
                </S.BlockNumber>
                <S.TextWrapper>
                  {block.isFull ? (
                    <S.Text>{block.text}</S.Text>
                  ) : (
                    <G.Text size={block.isFull ? '4xl' : 'md'}>
                      {block.text}
                    </G.Text>
                  )}
                </S.TextWrapper>
              </S.Block>
            ))}
        </S.BlocksWrapper>
      </G.Container>
    </G.Section>
  )
}

BlocksTable.ApiBlockToProps = (data: BlocksTableAPIProps) => {
  const { value } = data
  if (data.value) {
    let props: BlocksTableProps = {
      title: value.headline,
      subTitle: value.text,
      blocks: value.table_items,
      background_colour: value.background_colour.background_colour
    }
    props.blocks[props.blocks.length - 1].isFull = props.blocks.length % 3 == 1
    return props
  }
}

export default BlocksTable
