import styled, { css } from 'styled-components'
import { PAGE_TEMPLATE } from '../../services/api/constants'
import { DividerProps } from './Divider'
import tw from 'twin.macro'

const DivHeight = (height: string) => {
  let h = ''

  switch (height) {
    case 'small':
      h = '1rem'
      break

    case 'medium':
      h = '2.5rem'
      break

    case 'large':
      h = '5rem'
      break

    default:
      h = '1rem'
      break
  }

  return h
}

export const DividerContainer = styled.div<DividerProps>`
  width: 100%;
  ${({ size }) =>
    size &&
    css`
      min-height: ${DivHeight(size)};
    `}
  ${({ colour, template }) =>
    colour
      ? `background-color: ${colour} ;`
      : template === PAGE_TEMPLATE.YOU_FITNESS
      ? tw`bg-black__deep`
      : tw`bg-white`}
`
