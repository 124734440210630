import WagtailBlocksSection, { WagtailBlock } from '../src/components/wagtail/components'
import { NextSeo, NextSeoProps } from 'next-seo'
import Head from 'next/head'
import {
  GymPageProps,
  WagtailBlockProps,
  MenuBlockItem,
  LinkAPI,
  PageContext,
  ButtonProps,
  GymLocation,
  LocationCardProps,
  LAYOUT_TEMPLATE
} from '../src/services/api/types'
import { MenuBlocksProps } from '../src/components/menu/Menu'
import SubMenu from '../src/components/menu/SubMenu'
import { NextPage } from 'next'
import { gymPageSubMenuItemBuild, apiCall } from '../src/services/api/helpers'
import { useRef, useState, useContext, useEffect } from 'react'
import { StructureData } from '../src/services/api/SEOHelper'
import {
  generalItemsPerPage,
  PAGE_TEMPLATE,
  YOU_FITNESS_TEMPLATE
} from '../src/services/api/constants'
import { getAllGyms, getFormCMSContent, getGymsFromSite } from '../src/services/api/cms'
import { SiteContext } from '../src/services/context/SiteContext'
import parse from 'html-react-parser'
import { PromoBannerAPIProps } from '../src/components/main-section/MainSection'
import { redirects } from '../src/services/api'
import ReactGA from 'react-ga';
import Modal from '../src/components/modal/Modal'
import { isNullOrEmpty } from '../src/services/utils'

interface InitDataProps {
  menuItems?: MenuBlockItem[]
  locationButtons?: ButtonProps[]
}
const Gym: NextPage<{
  pageData: GymPageProps
  initialData?: InitDataProps
  locationQuery?: string | string[]
  locationMapPoints?: Array<GymLocation>
}> = (props: {
  pageData: GymPageProps
  initialData?: InitDataProps
  locationQuery?: string | string[]
  locationMapPoints?: Array<GymLocation>
}) => {
    const { pageData, initialData, locationQuery, locationMapPoints } = props
    const [mapPins, setMapPins] = useState(locationMapPoints)
    const { otherSites } = useContext(SiteContext)
    const [facebookPixelScript, setFacebookPixelScript] = useState('')
    const [googleAdwordsScript, setGoogleAdwordsScript] = useState('')
    const [modalForm, setModalForm] = useState<WagtailBlockProps[]>([])
    const ownEnquireRef = useRef<any>()

    useEffect(() => {
      if (pageData && pageData.meta && pageData.meta.facebook_tracking_code) {
        setFacebookPixelScript(pageData.meta.facebook_tracking_code)
      }
    }, [pageData?.meta?.facebook_tracking_code])

    useEffect(() => {
      if (
        pageData &&
        pageData.meta &&
        pageData.meta.google_adwords_tracking_code
      ) {
        setGoogleAdwordsScript(pageData.meta.google_adwords_tracking_code)
      }
    }, [pageData?.meta?.google_adwords_tracking_code])

    useEffect(() => {
      let otherGyms: Array<GymLocation> = []

      !locationQuery &&
        otherSites &&
        mapPins?.length == locationMapPoints?.length &&
        otherSites.map(async site => {
          const data = await getGymsFromSite(
            {
              per_page: 2000,
              fields: 'address_suburb,address_state,geo_point,full_url'
            },
            site
          )
          data.results &&
            data.results.map((gym: any) => {
              otherGyms.push({
                geometry: gym.geo_point,
                pageURL: gym.full_url,
                title: gym.title,
                suburb: gym.address_suburb,
                state: gym.address_state_code
              })
            })

          let nextUrl: string | null = data.next
          while (nextUrl) {
            const nextData = await apiCall(nextUrl)
            nextUrl = nextData.next
            nextData.results &&
              nextData.results.map((gym: any) => {
                otherGyms.push({
                  geometry: gym.geo_point,
                  pageURL: gym.full_url,
                  title: gym.title,
                  suburb: gym.address_suburb,
                  state: gym.address_state_code
                })
              })
          }
          setMapPins([...(locationMapPoints || []), ...(otherGyms || [])])
        })
    }, [])

    useEffect(() => {
      ReactGA.event({
        category: 'Forms',
        action: 'Gym landing page',
        nonInteraction: true
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'gym_landing_page',
      });
      const [formBlock] = pageData.flexible_content?.filter(
        (block: any) => block.type === 'form' && block.value.pop_up_form === true
      ) || []
    
      if (formBlock) {
        const { value: formConfig } = formBlock
        setModalForm([formBlock])
    
        const timer = setTimeout(() => {
          ownEnquireRef?.current?.setShow(true)
        }, formConfig.pop_up_time_delay ? formConfig.pop_up_time_delay * 1000 : 5000)
    
        return () => {
          clearTimeout(timer)
        }
      }
    }, [])

    const scrollToRef = (ref: any) =>
      window.scrollTo(0, ref && ref.current ? ref.current.offsetTop - 116 : 0)

    const menuClick = (item: MenuBlockItem) => {
      switch (item.label) {
        default: {
          if (item.blockRef && item.blockRef.current) {
            scrollToRef(item.blockRef)
          } else {
            window.open(item.url, item.target)
          }
          return
        }
      }
    }

    let menus: MenuBlocksProps = {
      //default menu join link (open in new page)
      menuItems: initialData?.menuItems || [],
      menuClick: menuClick,
      pageName: 'gyms',
      popup_form: pageData.popup_form,
      joinUrl: pageData.join_url || ''
    }

    menus.menuItems.forEach(item => {
      if (item.blockName && !item.blockRef) {
        item.blockRef = useRef(null)
      }
      return item
    })

    // rebuild block data for some specific blocks
    let blocksData: WagtailBlockProps[] = []
    if (pageData.flexible_content) {
      let gymDesc: { description: string; promo_banners: PromoBannerAPIProps[] }
      pageData.flexible_content.map((block: any) => {
        if (block.type == 'gym_description') {
          gymDesc = {
            description: block.value.description,
            promo_banners: block.value.promo_banners
          }
        }
      })
      blocksData = pageData.flexible_content.map((block: WagtailBlockProps) => {
        switch (block.type) {
          case 'page_submenu': {
            block.value = menus
            return block
          }
          case 'gym_servicing_suburbs': {
            block.value = {
              ...block.value,
              name: pageData.title
            }
            return block
          }
          case 'gym_location': {
            block.value = {
              ...block.value,
              name: pageData.title,
              address: pageData.address_street || '',
              suburb: `${pageData.address_suburb} ${pageData.address_state_code}`,
              postcode: pageData.address_postcode || '',
              phone: pageData.phone_number || block.value.phone || '',
              email: pageData.email || block.value.email || '',
              geo: pageData.geo_point?.coordinates,
              CTAButtons: initialData?.locationButtons,
              mapPins: mapPins || [],
              template: pageData.meta.layout_template,
              description: gymDesc.description,
              promo_banners: gymDesc.promo_banners,
              show_franchisee_email: pageData.show_franchisee_email,
              franchisee_email: pageData.franchisee_email
            }
            return block
          }
          case 'gym_features': {
            block.value = {
              ...block.value,
              template: pageData.meta.layout_template
            }
            return block
          }
          case 'CTAPanels': {
            block.value = {
              ...block.value,
              gym_id: pageData.id,
              gym_title: pageData.title,
              template: pageData.meta.layout_template
            }
            return block
          }
          case 'Hero': {
            block.value = {
              ...block.value,
              is_gym: true,
              name: pageData?.title,
              address: pageData?.address_street ? pageData?.address_street : null,
              template: pageData.meta.layout_template,
              sub_address: `${pageData?.address_suburb ? `${pageData?.address_suburb} ` : ''
                }${pageData?.address_state_code
                  ? `${pageData?.address_state_code} `
                  : ''
                }${pageData?.address_postcode ? `${pageData?.address_postcode}` : ''
                }`
            }
            return block
          }
          case 'gym_image_gallery': {
            block.value = [...block.value]
            block.template = pageData.meta.layout_template as LAYOUT_TEMPLATE
            return block
          }
          default:
            block.value = {
              ...block.value,
              template: pageData.meta.layout_template
            }
            return block
        }
      })
    }

    // make list of blocks components which use to render and check linked with menu
    const SEO_Obj = Object.assign(
      StructureData.ExerciseGym(pageData),
      pageData.meta.structured_data
    )

    const checkMetaDescription = (metaType?: string) => {
      switch (metaType) {
        case 'gyms.GymOpportunity':
          if (pageData.meta.meta_description === '') {
            return `Local Gym in ${pageData.address_suburb ||
              ''}, ${pageData.address_postcode ||
              ''}, ${pageData.address_state_code ||
              ''} with 24/7 Access. No Lock In Contracts, Low Prices and over 200 Locations. Great class options.`
          } else return pageData.meta.meta_description
        case 'gyms.Gym':
          if (pageData.meta.meta_description === '') {
            return `Local 24/7 Gym in ${pageData.address_suburb ||
              ''}, ${pageData.address_postcode ||
              ''}, ${pageData.address_state_code ||
              ''}. No Lock In Contracts, Low Prices and over 200 Locations Australia-wide. Great class options.`
          } else return pageData.meta.meta_description
        default:
          return pageData.meta.meta_description
      }
    }

    const checkMetaTitle = () => {
      if (pageData.meta.meta_title === '') {
        return `Plus Fitness ${pageData.title || ''}`
      } else return pageData.meta.meta_title
    }

    const SEO: NextSeoProps = {
      description: checkMetaDescription(pageData.meta.type),
      title: checkMetaTitle(),
      openGraph: {
        title: pageData.meta.og_title ? pageData.meta.og_title : checkMetaTitle(),
        description: pageData.meta.og_description
          ? pageData.meta.og_description
          : checkMetaDescription(pageData.meta.type),
        images: [
          {
            url: (pageData?.meta.layout_template === YOU_FITNESS_TEMPLATE
              ? '/static/plus_new.png'
              : '/static/plus_old.png')
          }
        ]
      }
    }

    const array_move = (arr: WagtailBlockProps[], from: number, to: number) => {
      let numberOfDeletedElm = 1

      const elm = arr.splice(from, numberOfDeletedElm)[0]

      numberOfDeletedElm = 0

      arr.splice(to, numberOfDeletedElm, elm)
      return arr
    }

    const handleBlockWithoutHero = (blockWithouthHero: WagtailBlockProps[]) => {
      if (pageData?.meta.layout_template !== 'original') {
        let gymDescIndex = blockWithouthHero.findIndex(
          val => val.type == 'gym_description'
        )
        let gymLocationIndex = blockWithouthHero.findIndex(
          val => val.type == 'gym_location'
        )

        const newBlockWithoutHero = array_move(
          blockWithouthHero,
          gymDescIndex,
          gymDescIndex < gymLocationIndex
            ? gymLocationIndex
            : gymLocationIndex + 1
        )
        return newBlockWithoutHero
      }

      return blockWithouthHero
    }

    const blockHeroData = blocksData.filter(block => block.type === 'Hero')
    let blockWithouthHero = blocksData.filter(block => block.type != 'Hero')
    blockWithouthHero = handleBlockWithoutHero(blockWithouthHero)

    const template = (pageData?.meta.layout_template === YOU_FITNESS_TEMPLATE
      ? PAGE_TEMPLATE.YOU_FITNESS
      : PAGE_TEMPLATE.ORIGINAL) as LAYOUT_TEMPLATE

    return (
      <>
        <Head>
          {parse(facebookPixelScript)}
          {parse(googleAdwordsScript)}
        </Head>
        {SEO_Obj && (
          <script
            type='application/ld+json'
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(SEO_Obj)
            }}
          />
        )}
        {SEO && <NextSeo {...SEO} />}
        <h1 className='hidden'>{`Plus Fitness ${pageData.title}`}</h1>
        {blockHeroData &&
          blockHeroData.map((block: WagtailBlockProps, key: number) => {
            return (
              <div className='w-full' key={key}>
                <WagtailBlock
                  template={template}
                  type={block.type}
                  data={block}
                />
              </div>
            )
          })}
        {pageData?.join_url && template !== PAGE_TEMPLATE.YOU_FITNESS && (
          <SubMenu
            joinUrl={pageData?.join_url}
            gymId={pageData.id}
            popup_form={pageData?.popup_form}
          />
        )}
        {blockWithouthHero &&
          blockWithouthHero
          .filter(x => !x.value?.pop_up_form)
          .map((block: WagtailBlockProps, key: number) => {
            let blockrender = (
              <div className='w-full'>
                <WagtailBlock
                  type={block.type}
                  template={template}
                  key={key}
                  data={block}
                />
              </div>
            )
            menus.menuItems = menus.menuItems.map(mn => {
              if (mn.blockName == block.type) {
                blockrender = (
                  <div className='w-full' ref={mn.blockRef}>
                    <WagtailBlock
                      type={block.type}
                      template={template}
                      key={key}
                      data={block}
                    />
                  </div>
                )
              }
              return mn
            })

            return blockrender
          })}

        <Modal
          className='generic'
          showCloseIcon={true}
          ref={ownEnquireRef}
          template={template}
        >
          {modalForm && modalForm.length && (
            <WagtailBlocksSection template={template} blocks={modalForm} />
          )}
        </Modal>
      </>
    )
  }
Gym.getInitialProps = async (ctx: PageContext) => {
  let props: {
    pageData: GymPageProps
    initialData?: InitDataProps
    locationQuery?: string | string[]
    locationMapPoints?: Array<GymLocation>
  } = {
    pageData: ctx.pageData
  }

  const { q } = ctx.query

  const slug = ctx.pageData.meta.slug
  const redirectsResponse = await redirects.getRedirects(`/gyms/${slug}`, ctx)
  if (
    redirectsResponse &&
    redirectsResponse.results &&
    redirectsResponse.results.length > 0 &&
    ctx.res
  ) {
    const redirect = redirectsResponse.results[0]
    ctx.res.writeHead(redirect.is_permanent ? 301 : 302, {
      Location: redirect.link
    })
    ctx.res.end()
  }

  const gymResults = await getAllGyms(
    {
      ordering: 'title',
      per_page: generalItemsPerPage,
      q: typeof q === 'string' ? q : ''
    },
    ctx
  )

  props.locationQuery = q

  const { pageData } = ctx
  const menuItems: MenuBlockItem[] = []
  // check and build menu base on returned block from CMS
  pageData.flexible_content?.map(block => {
    if (block.type) {
      let menu = gymPageSubMenuItemBuild(block.type)
      if (menu && !menuItems.find(s => s.blockName == block.type)) {
        menuItems.push(menu)
      }
    }
  })
  let locationButtons: ButtonProps[] = []
  const sub = pageData.flexible_content?.find(s => s.type == 'page_submenu')
  if (sub) {
    sub.value?.link?.forEach((link: LinkAPI) => {
      locationButtons.push({
        label:
          link.link_text.toLowerCase() == 'join' ? 'JOIN NOW' : link.link_text,
        href:
          link.link.external_link ||
          link.link.page_link ||
          link.link.document_link,
        target: link.link.link_target,
        color: locationButtons.length % 2 == 0 ? 'blue' : 'orange'
      })
      menuItems.push({
        label: link.link_text,
        url:
          link.link.external_link ||
          link.link.page_link ||
          link.link.document_link,
        target: link.link.link_target
      })
    })
  }
  if (props.pageData.join_url) {
    menuItems.push({
      label: 'Join Now',
      url: props.pageData.join_url,
      target: '_blank'
    })
  }
  props.initialData = {
    menuItems: menuItems,
    locationButtons: locationButtons
  }
  props.locationMapPoints = gymResults?.results?.map(
    (gym: LocationCardProps) => {
      return {
        geometry: gym.geo_point,
        pageURL: gym.full_url,
        title: gym.title,
        suburb: gym.address_suburb,
        state: gym.address_state_code
      }
    }
  )

  const forms = props.pageData.flexible_content?.filter(
    ctn => ctn.type === 'form'
  )
  if (forms && !isNullOrEmpty(forms)) {
    for (let i = 0; i < forms.length; i++) {
      const formData = await getFormCMSContent(ctx, {
        slug: forms[i].value.slug
      })
      forms[i].value = {
        ...forms[i].value,
        ...formData
      }
    }
  }

  return props
}
export default Gym
