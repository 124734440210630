import React, { ReactElement, useState } from 'react'
import { CSSProperties } from 'react'
import RegisterYourInterestForm from './RegisterYourInterestForm'
import * as S from './RegisterYourInterestModal.styled'
import { FormFieldProps } from '../../services/api/types'

export interface RegisterYourInterestModalProps {
  button?: ReactElement
  buttonStyle?: CSSProperties
  downloadFileOnSuccess?: boolean
  fileUrl?: string
  form?: {
    id: number
    slug: string
    fields: FormFieldProps[]
    submit_button_text: string
    title: string
    preamble: string
  }
  preferredLocation?: {
    state: string
    suburb: string
  }
  timeOut?: boolean
}

const RegisterYourInterestModal: React.FC<RegisterYourInterestModalProps> = ({
  button,
  buttonStyle,
  downloadFileOnSuccess,
  preferredLocation,
  fileUrl,
  form
}) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const toggleModal = () => {
    setOpen(!isOpen)
  }

  return (
    <>
      <S.ButtonWrap onClick={toggleModal} style={buttonStyle}>
        {button}
      </S.ButtonWrap>
      {isOpen && (
        <S.OverlayModal isOpen={isOpen}>
          {form && (
            <RegisterYourInterestForm
              id={form.id}
              slug={form.slug}
              submitText={form.submit_button_text}
              fields={form.fields}
              title={form.title || 'Register your interest'}
              preamble={form.preamble || ''}
              onClose={toggleModal}
              downloadFileOnSuccess={downloadFileOnSuccess}
              preferredLocation={preferredLocation}
              fileUrl={fileUrl}
            />
          )}
        </S.OverlayModal>
      )}
    </>
  )
}

export default RegisterYourInterestModal
