import { Hero, HeroProps } from './Hero'
import { HeroFormBlock, HeroEnquireFormWrapper } from './Hero.styles'
import { OwnerEnquireFormProps } from '../forms/OwnerEnquireForm'
import { Section } from '../../styles/global.styles'
import { WagtailBlockProps, FormFieldProps } from '../../services/api/types'
import { defaultHeroSetting } from '../../services/mock'
import dynamic from 'next/dynamic'

const OwnerEnquireForm = dynamic(() => import('../forms/OwnerEnquireForm'), {
  ssr: false
})

export interface HeroWithFormAPIProps extends WagtailBlockProps {
  value: {
    image: string
    mobile_image: string
    title: string
    subtitle?: string
    fields?: Array<FormFieldProps>
    pre_form_text?: string
    form: {
      id: number
      title: string
      slug: string
      submit_button_text: string
      fields?: Array<FormFieldProps>
    }
  }
}

interface IHeroFormProp extends OwnerEnquireFormProps {
  isShowFormModal?: boolean
}

export interface HeroWithFormProps {
  heroProps: HeroProps
  heroFormProp: IHeroFormProp
  hideForm?: boolean
}

export const HeroWithForm = (props: HeroWithFormProps) => {
  const { heroProps, heroFormProp, hideForm } = props
  if (heroFormProp.slug.includes('career-apply')) {
    heroProps.isCareer = true
  }
  return (
    <Section position='relative' id={heroFormProp.slug} className='h-auto'>
      <Hero {...heroProps} />
      {!hideForm && !heroFormProp.isShowFormModal && (
        <HeroFormBlock>
          <HeroEnquireFormWrapper>
            <OwnerEnquireForm {...heroFormProp} isHero={true} />
          </HeroEnquireFormWrapper>
        </HeroFormBlock>
      )}
    </Section>
  )
}

HeroWithForm.ApiBlockToProps = (data: HeroWithFormAPIProps) => {
  // return default props for test
  const apiData = data.value

  if (apiData.fields) apiData.form.fields = apiData.fields

  let props: HeroWithFormProps = {
    heroFormProp: {
      ...apiData.form,
      formHeader: apiData.pre_form_text,
      submitText: apiData.form.submit_button_text
    },
    heroProps: defaultHeroSetting
  }
  if (data.value) {
    delete defaultHeroSetting.slides[0].bgImgURL
    props.heroProps = {
      slides: [
        {
          ...defaultHeroSetting.slides[0],
          bgImgURL: apiData.image,
          bgImgMobileURL: apiData.mobile_image,
          texts: [
            {
              text: apiData.title,
              color: 'white',
              fontWeight: 'bold',
              size: '6xl',
              textCase: 'upper'
            }
          ]
        }
      ]
    }

    if (apiData.subtitle && props.heroProps.slides[0]) {
      props.heroProps.slides[0].texts?.push({
        text: apiData.subtitle,
        color: 'white',
        fontWeight: 'bold',
        fontStyle: 'italic',
        size: '4xl',
        spaceTop: 0.5
      })
    }
  }

  return props
}
