import React from 'react'
import {
  ButtonProps,
  WagtailBlockProps,
  ButtonAPIProps
} from '../../../services/api/types'
import bgOverlay from '../../../static/imgs/bg-overlay.png'
import * as S from './AngledBanner.styled'
import * as G from '../../../styles/global.styles'

export interface AngledBannerProps {
  image: string
  overlay_colour: string
  logo?: string
  title?: string
  text: string
  buttons?: ButtonProps[]
}

export interface AngledBannerAPIProps extends WagtailBlockProps {
  value: {
    image: string
    overlay_colour: string
    logo?: string
    title?: string
    text: string
    call_to_action?: ButtonAPIProps[]
  }
}
export const AngledBanner = (props: AngledBannerProps) => {
  const { image, overlay_colour, logo, title, text, buttons } = props

  return (
    <S.AngledBannerWrapper src={image}>
      <S.Overlay img={bgOverlay} />
      <G.FlexContainer position='relative' contentSize='xl'>
        <S.AngledContent>
          <S.AngledBG color={overlay_colour} />
          <S.AngledLogo src={logo} />
          {title && <S.AngledTitle>{title}</S.AngledTitle>}
          <S.AngledText dangerouslySetInnerHTML={{ __html: text }} />
          {buttons && buttons.length > 0 && (
            <S.ButtonWrapper>
              {buttons.map((btn, idx) =>
                btn.color === 'primary' ? (
                  <G.BlueButtonLink key={idx} {...btn}>
                    {btn.label}
                  </G.BlueButtonLink>
                ) : (
                  <G.WhiteButton key={idx} {...btn}>
                    {btn.label}
                  </G.WhiteButton>
                )
              )}
            </S.ButtonWrapper>
          )}
        </S.AngledContent>
      </G.FlexContainer>
    </S.AngledBannerWrapper>
  )
}

AngledBanner.ApiBlockToProps = (data: AngledBannerAPIProps) => {
  const { value } = data
  if (data.value) {
    let props: AngledBannerProps = {
      image: value.image,
      overlay_colour: value.overlay_colour,
      logo: value.logo,
      title: value.title,
      text: value.text,
      buttons: value.call_to_action?.map(btn => {
        return {
          label: btn.button_text,
          href:
            btn.button_link.external_link ||
            btn.button_link.page_link ||
            btn.button_link.document_link ||
            btn.button_link.anchor_link,
          color: btn.button_colour,
          target: btn.button_link.link_target
        }
      })
    }
    return props
  }
}

export default AngledBanner
