import styled from 'styled-components'
import tw from 'twin.macro'

export const AvatarWrapper = styled.div`
  ${tw`mb-2`}
`

export const AvatarInner = styled.a<{ avatarImgURL?: string }>`
  ${tw`relative block rounded-full`}
  background: url(${props => props.avatarImgURL}) 0 0/cover no-repeat;
  width: 170px;
  height: 170px;
  top: 0.5rem;
  left: 0.5rem;
`

export const AvatarFrame = styled.img`
  ${tw`absolute`}
  height: 180px;
  z-index: 1;
`

export const InformationSection = styled.div`
  ${tw`relative max-w-full md:w-full font-display md:pl-8 md:max-w-screen-md`}
`

export const PersonName = styled.h4`
  ${tw`text-xl text-orange font-bold uppercase`}
`

export const PersonBios = styled.h6`
  ${tw`text-base text-blue__dark font-bold`}
`

export const PersonIntro = styled.div<{ collapsed?: boolean }>`
  ${tw`text-grey__medium mt-4 md:max-w-lg xl:max-w-full`}



  & p:last-child {
    ${props => props.collapsed && tw`truncate`}
  }
`

export const ReadButton = styled.a`
  ${tw`text-grey__medium mt-2 text-sm cursor-pointer`}

  &::before {
    content: "\\[";
  }

  &::after {
    content: "\\]";
  }
`

