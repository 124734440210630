import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { PAGE_TEMPLATE } from '../../services/api/constants'
import { LAYOUT_TEMPLATE } from '../../services/api/types'

export const ShareIconList = styled.ul`
  ${tw`flex items-center justify-around md:justify-center lg:w-1/2`}

  & > span {
    ${tw`text-grey__medium font-medium text-lg`}
  }
`

export const ShareIconItem = styled.li`
  ${tw`md:mx-1 cursor-pointer rounded-full flex items-center`}
`

export const ShareBlockSection = styled.div<{ template?: LAYOUT_TEMPLATE }>`
  ${tw`relative w-full font-display py-4 bg-grey__light border-t-2 border-b-2 lg:flex lg:justify-center`}

  ${({ template }) =>
    template === PAGE_TEMPLATE.YOU_FITNESS &&
    css`
      ${tw`bg-transparent inline-flex w-auto py-0 border-none`}

      ${ShareIconItem} {
        ${tw`mx-0`}

        div {
          width: auto !important;
          height: auto !important;
        }
      }

      ${ShareIconList} {
        ${tw`my-0`}

        > span {
          ${tw`text-sm mr-0 whitespace-no-wrap`}
          color: #909090;
        }
      }

      svg {
        width: 34px;
        height: 34px;

        ${tw`text-blue__deep ml-5`}
      }
    `}
`
