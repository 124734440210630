import * as S from './ShareBlock.styled'
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  LinkedinShareButton
} from 'react-share'
import { IconEnums } from '../../base'

export interface ShareBlockProps {
  shareObj: SocialMedia
}

export interface SocialMedia {
  image?: string
  shareUrl?: string
  description: string
  name: string
}

export interface ShareObjectProps {
  url: string
  title?: string
  quote?: string
  hashtag?: string
  imageUrl?: string
  image?: string
  subject?: string
}

export const ShareBlockYouFitness = (props: ShareBlockProps) => {
  const { shareObj } = props
  let image = ''
  image = shareObj?.image || ''

  let shareObject: ShareObjectProps = {
    url: shareObj?.shareUrl || '',
    title: shareObj?.name || '',
    quote: shareObj?.description || '',
    hashtag: '#plusfitness',
    imageUrl: image,
    image: image
  }

  return (
    <S.ShareBlockSection>
      <S.ShareIconList>
        <span className='title'>Share on</span>
        <S.ShareIconItem>
          <FacebookShareButton className='link' {...shareObject}>
            <S.Icon name={IconEnums['icon-fb']} />
          </FacebookShareButton>
        </S.ShareIconItem>
        <S.ShareIconItem>
          <TwitterShareButton className='link twitter' {...shareObject}>
            <S.Icon name={IconEnums['icon-twitter']} />
          </TwitterShareButton>
        </S.ShareIconItem>
        <S.ShareIconItem>
          <PinterestShareButton className='link' {...shareObject} media={image}>
            <S.Icon name={IconEnums['icon-pinterest']} />
          </PinterestShareButton>
        </S.ShareIconItem>
        <S.ShareIconItem>
          <LinkedinShareButton className='link' {...shareObject}>
            <S.Icon name={IconEnums['icon-linkedin']} />
          </LinkedinShareButton>
        </S.ShareIconItem>
      </S.ShareIconList>
    </S.ShareBlockSection>
  )
}

export default ShareBlockYouFitness
