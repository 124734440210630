import { useRef, useEffect } from 'react'
import {
  WagtailPageProps,
  PageContext,
  LocationCardProps,
  SelectOption,
  GymLocation,
  GeoPoint,
  SearchOptions
} from '../src/services/api/types'
import { getAllOpportunities } from '../src/services/api/cms'
import {
  generalItemsPerPage,
  defaultMapCenter,
  defaultMapZoom
} from '../src/services/api/constants'
import { isNullOrEmpty, maxBounds } from '../src/services/utils'
import { locationSearchFormProps } from '../src/services/mock'
import { LocationTab } from '../src/components/tabs'
import { GymLocationResults } from '../src/components/cards'
import * as G from '../src/styles/global.styles'
import WagtailBlocksSection from '../src/components/wagtail/components'
import locationMarker from '../src/static/imgs/location-marker.png'
import { NextPage } from 'next'
import dynamic from 'next/dynamic'

const LocationMap = dynamic(
  () => import('../src/components/maps/LocationMap'),
  { ssr: false }
)
const LocationSearchForm = dynamic(
  () => import('../src/components/search/LocationSearchForm'),
  { ssr: false }
)

interface GymOpportunityIndexPageProps extends WagtailPageProps {
  search_title?: string
  state_options?: []
}

const GymOpportunityIndex: NextPage<{
  pageData: GymOpportunityIndexPageProps
  locationQuery?: string | string[]
  initialData?: any
  map_center_point?: GeoPoint
  locationMapPoints?: Array<GymLocation>
}> = (props: {
  pageData: GymOpportunityIndexPageProps
  locationQuery?: string | string[]
  initialData?: any
  map_center_point?: GeoPoint
  locationMapPoints?: Array<GymLocation>
}) => {
  const {
    pageData,
    initialData,
    map_center_point,
    locationQuery,
    locationMapPoints
  } = props

  const mapRef = useRef<any>()
  const resultRef = useRef<any>()

  useEffect(() => {

    if (!isNullOrEmpty(locationQuery)) {
      doSearch({ q: locationQuery?.toString() })
    }
  }, [locationQuery])

  const onGymsFilter = async (filter: SelectOption) => {
    doSearch({ franchise_status: filter.value })
  }

  const doSearch = async (query: SearchOptions) => {
    const gymResults = await getAllOpportunities({
      ...query,
      ordering: query.ordering
        ? query.ordering
        : query.address_state_code
        ? 'title'
        : '',
      per_page: generalItemsPerPage
    })
    relocateMapPosition(gymResults)
  }

  const relocateMapPosition = (data: any) => {
    const { results } = data
    // Set search results to component
    resultRef?.current?.setGyms(results)
    const { map } = mapRef?.current?.state

    if (!isNullOrEmpty(results)) {
      let bounds: Array<Array<number>> = []
      results.map((res: any) => {
        return bounds.push(res.geo_point.coordinates)
      })

      map.fitBounds(maxBounds(bounds))
    } else {
      map.flyTo({
        center: [135, -20],
        zoom: [3],
        essential: true
      })
    }
  }

  const changeState = async (stateCode: string) => {
    resultRef?.current?.setTitle(
      `OPPORTUNITIES IN ${stateCode !== '' ? stateCode : 'your area'}`
    )
    doSearch({
      q: '',
      address_state_code: stateCode
    })
  }

  return (
    <>
      <LocationMap
        pinIcon={locationMarker}
        centerCoord={map_center_point?.coordinates || defaultMapCenter}
        mapZoom={defaultMapZoom}
        mapData={locationMapPoints}
        refElm={mapRef}
        isIndex
      />
      <G.Section bgColor='grey-light'>
        <LocationSearchForm
          searchLabel={pageData.search_title}
          {...locationSearchFormProps}
        />
      </G.Section>
      <LocationTab
        tabClick={changeState}
        tabItems={pageData.state_options}
        selectedTab=''
      />
      {pageData && pageData.flexible_content && (
        <WagtailBlocksSection blocks={pageData.flexible_content} />
      )}

      {initialData && (
        <GymLocationResults
          onFilter={onGymsFilter}
          ref={resultRef}
          gyms={initialData?.results}
          title={`OPPORTUNITIES IN YOUR AREA`}
        />
      )}
    </>
  )
}

GymOpportunityIndex.getInitialProps = async (ctx: PageContext) => {
  let props: {
    pageData: GymOpportunityIndexPageProps
    locationQuery?: string | string[]
    initialData?: any
    map_center_point?: GeoPoint
    locationMapPoints?: Array<GymLocation>
  } = {
    pageData: ctx.pageData
  }

  const query = ctx.query
  const gymResults = await getAllOpportunities(
    {
      ordering: 'title',
      per_page: generalItemsPerPage
    },
    ctx
  )

  props.locationQuery = query.q
  props.initialData = gymResults

  props.locationMapPoints = gymResults?.results?.map(
    (gym: LocationCardProps) => {
      return {
        geometry: gym.geo_point,
        pageURL: gym.full_url,
        title: gym.title,
        suburb: gym.address_suburb,
        state: gym.address_state
      }
    }
  )

  return props
}

export default GymOpportunityIndex
