import styled from 'styled-components'
import tw from 'twin.macro'

export const Author = styled.div`
  ${tw`flex flex-col md:flex-row items-center border border-white rounded md:rounded-full p-5 mx-auto mt-12`}
  max-width: 815px;
`

export const AuthorAvatar = styled.img`
  width: 150px;
  height: 150px;
  ${tw`rounded-full object-cover mb-4 md:mb-0`}
`

export const AuthorInfo = styled.div`
  ${tw`md:ml-8`}
`

export const AuthorName = styled.h4`
  ${tw`text-white font-youfitnessHeading uppercase mb-2.5 leading-sm`}
`

export const AuthorIntro = styled.div`
  ${tw`text-white text-sm leading-relaxed`}
`
