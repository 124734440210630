import { HeroYouFitness, HeroProps } from './Hero'
import { HeroFormBlock } from './Hero.styles'
import { OwnerEnquireFormProps } from '../../forms/OwnerEnquireForm'
import { Section } from '../../../styles/global.styles'
import {
  WagtailBlockProps,
  FormFieldProps,
  LAYOUT_TEMPLATE
} from '../../../services/api/types'
import { defaultHeroSetting } from '../../../services/mock'
import dynamic from 'next/dynamic'
import { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import useWindowSize from '../../../hooks/useWindowSize'

const OwnerEnquireForm = dynamic(() => import('../../forms/OwnerEnquireForm'), {
  ssr: false
})

export interface HeroWithFormAPIProps extends WagtailBlockProps {
  template?: LAYOUT_TEMPLATE
  value: {
    image: string
    mobile_image: string
    title: string
    subtitle?: string
    fields?: Array<FormFieldProps>
    pre_form_text?: string
    form: {
      id: number
      title: string
      slug: string
      submit_button_text: string
      fields?: Array<FormFieldProps>
    }
  }
}

interface IHeroFormProp extends OwnerEnquireFormProps {
  isShowFormModal?: boolean
}

export interface HeroWithFormProps {
  heroProps: HeroProps
  heroFormProp: IHeroFormProp
  hideForm?: boolean
  template?: LAYOUT_TEMPLATE
}

export const HeroWithForm = (props: HeroWithFormProps) => {
  const { heroProps, heroFormProp, hideForm, template } = props
  const [heroHeight, setHeroHeight] = useState<number | null>(null)
  const formRef = useRef<HTMLDivElement>(null)
  const windowSize = useWindowSize()

  const onSuccess = () => {
    window.scrollTo({
      top: formRef.current?.offsetTop,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    setTimeout(() => {
      formRef.current?.clientHeight &&
        setHeroHeight(formRef.current.clientHeight)
    }, 10)
  }, [windowSize.width])

  return (
    <Section position='relative' className='h-auto hero-form'>
      <HeroYouFitness
        {...heroProps}
        isHeroForm
        style={{
          minHeight: heroHeight
            ? heroHeight
            : heroFormProp.isShowFormModal
            ? '578px'
            : 'auto'
        }}
      />
      {!hideForm && !heroFormProp.isShowFormModal && (
        <HeroFormBlock ref={formRef}>
          <OwnerEnquireForm
            {...heroFormProp}
            isHero={true}
            template={template}
            onSuccess={onSuccess}
          />
        </HeroFormBlock>
      )}
    </Section>
  )
}

HeroWithForm.ApiBlockToProps = (data: HeroWithFormAPIProps) => {
  // return default props for test
  const apiData = data.value

  if (apiData.fields) apiData.form.fields = apiData.fields

  let props: HeroWithFormProps = {
    heroFormProp: {
      ...apiData.form,
      formHeader: apiData.pre_form_text,
      submitText: apiData.form.submit_button_text
    },
    heroProps: defaultHeroSetting
  }
  if (data.value) {
    delete defaultHeroSetting.slides[0].bgImgURL
    props.heroProps = {
      slides: [
        {
          ...defaultHeroSetting.slides[0],
          bgImgURL: apiData.image,
          bgImgMobileURL: apiData.mobile_image,
          texts: [
            {
              text: apiData.title,
              color: 'white',
              fontWeight: 'bold',
              size: '5xl',
              textCase: 'upper'
            }
          ]
        }
      ]
    }

    if (apiData.subtitle && props.heroProps.slides[0]) {
      props.heroProps.slides[0].texts?.push({
        text: apiData.subtitle,
        color: 'white',
        fontWeight: 'bold',
        fontStyle: 'italic',
        size: '2xl',
        spaceTop: 0.5
      })
    }
  }

  return props
}

export default HeroWithForm
