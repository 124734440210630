import * as C from './PTrainers.styled'
import * as G from '../../../styles/global.styles'
import React, { useState } from 'react'
import {
  PersonalTrainer,
  PersonalTrainersAPIProps,
  TrainerAPIProps
} from '../PersonalTrainers'
import { Colors } from '../../../services/api/types'
import { YOU_FITNESS } from '../../../services/api/constants'
import { Container, Section } from '../../../styles/grid.styles'
import PopupOwnerAndPT from '../../blocks/YouFitnessTemplate/PopupOwnerAndPT'

export interface PTrainersNewGymTemplateProps {
  title?: string
  subtext?: string
  trainers: PersonalTrainer[]
  background_colour?: Colors
}

export const PTrainersNewGymTemplate = (
  props: PTrainersNewGymTemplateProps
) => {
  const [popupData, setPopupData] = useState<PersonalTrainer | null>()

  const openPopup = (trainer: PersonalTrainer) => {
    setPopupData(trainer)
  }

  const closePopup = () => {
    setPopupData(null)
  }

  const { trainers, title, subtext } = props

  return (
    <Section>
      {popupData && (
        <PopupOwnerAndPT closePopup={closePopup} popupData={popupData} />
      )}
      <Container>
        {title && <G.GymCommonTitle>{title}</G.GymCommonTitle>}
        {subtext && <G.GymCommonDesc>{subtext}</G.GymCommonDesc>}
        <C.TrainerWrap>
          {trainers &&
            !!trainers.length &&
            trainers.map((trainer, idx) => (
              <C.TrainerBlock key={idx}>
                <C.TrainerImage onClick={() => openPopup(trainer)}>
                  <G.DefaultImg
                    objectFit={'cover'}
                    src={trainer.avatar}
                    alt={trainer.fullname}
                  />
                </C.TrainerImage>
                <C.TrainerName onClick={() => openPopup(trainer)}>
                  {trainer.fullname}
                </C.TrainerName>
                <C.ReadMoreNewLayout onClick={() => openPopup(trainer)}>
                  {YOU_FITNESS.readMoreBtn}
                </C.ReadMoreNewLayout>
              </C.TrainerBlock>
            ))}
        </C.TrainerWrap>
      </Container>
    </Section>
  )
}

PTrainersNewGymTemplate.ApiBlockToProps = (
  data: PersonalTrainersAPIProps
): PTrainersNewGymTemplateProps => {
  const { value } = data
  return {
    title: value.headline,
    subtext: value.text,
    trainers: value.personal_trainers.map((pt: TrainerAPIProps) => {
      return {
        avatar: pt.image,
        fullname: pt.full_name,
        phone: pt.phone,
        description: pt.description,
        email: pt.email,
        instagram_url: pt.instagram_url
      }
    }),
    background_colour: value.background_colour.background_colour
  }
}

export default PTrainersNewGymTemplate
