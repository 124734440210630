import { useContext, useState } from 'react'
import { Col, Container, Row } from '../../styles/grid.styles'
import { Heading } from './Franchise.styled'
import RegisterYourInterestModal from './RegisterYourInterestModal'
import { WagtailBlockProps } from '../../services/api/types'
import { useEffect } from 'react'
import routes from '../../routes'
import * as S from './LocationSuggestions.styled'
import { SUBURBS_GROUP } from '../../services/api/constants'
import { SiteContext } from '../../services/context/SiteContext'

export type State = {
  title: string
  regional_suburbs: string[]
  metro_suburbs: string[]
  other_suburbs: string[]
  [key: string]: string | string[]
}

export type SUBURBS_GROUP_TYPE =
  | 'regional_suburbs'
  | 'metro_suburbs'
  | 'other_suburbs'
  | ''

export interface LocationSuggestionsProps extends WagtailBlockProps {
  value: {
    title: string
    footer_text: string
    states: State[]
  }
}

const LocationSuggestions = (props: LocationSuggestionsProps) => {
  const { title, states, footer_text } = props.value
  const [selectedState, setSelectedState] = useState<State | undefined>(
    states.length > 0 ? states[0] : undefined
  )
  const [selectedRegionsGroup, setSelectedRegionsGroup] = useState<
    SUBURBS_GROUP_TYPE
  >('')

  const { formBrochure, siteInfor } = useContext(SiteContext)

  states.forEach(state => {
    state.regional_suburbs = state.regional_suburbs.sort()
  })
  const statesData = states.sort((a, b) => a.title.localeCompare(b.title))

  const onStateChange = (state: State) => {
    const url = `${routes.Router.asPath.split('?')[0]}?state=${state.title}`
    routes.Router.pushRoute(url, { shallow: true })
    setSelectedState(state)
  }

  useEffect(() => {
    const { state } = routes?.Router?.query
    if (state) {
      const selectedState = states.find(
        it => it.title.toLowerCase() === state.toString().toLowerCase()
      )
      selectedState && setSelectedState(selectedState)
    }
  }, [routes, states])

  useEffect(() => {
    if (selectedState) {
      if (selectedState.metro_suburbs.length > 0) {
        setSelectedRegionsGroup('metro_suburbs')
      } else if (selectedState.regional_suburbs.length > 0) {
        setSelectedRegionsGroup('regional_suburbs')
      } else if (selectedState.other_suburbs.length > 0) {
        setSelectedRegionsGroup('other_suburbs')
      }
    }
  }, [selectedState])

  return (
    <S.Location>
      <Container size='lg'>
        <S.Head>
          <Heading>{title}</Heading>
          {siteInfor?.isDownloadable ? (
            <S.Link
              href={
                formBrochure && formBrochure.linkUrl ? formBrochure.linkUrl : ''
              }
              target={'_blank'}
              download
            >
              {'Suggest Location'}
            </S.Link>
          ) : (
            <RegisterYourInterestModal
              form={formBrochure}
              downloadFileOnSuccess={true}
              fileUrl={
                formBrochure && formBrochure.linkUrl ? formBrochure.linkUrl : ''
              }
              button={<S.SuggestLocation>Suggest Location</S.SuggestLocation>}
            />
          )}
        </S.Head>
        <S.States>
          {statesData.map((state, key: number) => (
            <S.State
              key={key}
              active={selectedState?.title === state.title}
              onClick={() => onStateChange(state)}
            >
              {state.title}
            </S.State>
          ))}
        </S.States>

        {statesData.map((state, idx: number) =>
          selectedState?.title === state.title ? (
            <S.Suburbs active>
              <S.SuburbsGroup>
                {Object.keys(SUBURBS_GROUP).map(key => {
                  return (
                    selectedState[key].length > 0 && (
                      <S.SuburbsGroupItem
                        active={selectedRegionsGroup === key}
                        key={key}
                        onClick={() =>
                          setSelectedRegionsGroup(key as SUBURBS_GROUP_TYPE)
                        }
                      >
                        {SUBURBS_GROUP[key]}
                      </S.SuburbsGroupItem>
                    )
                  )
                })}
              </S.SuburbsGroup>

              <Row gutter={10}>
                {selectedRegionsGroup &&
                  selectedState &&
                  selectedState[selectedRegionsGroup]?.length > 0 &&
                  selectedState[selectedRegionsGroup].map(
                    (suburb: string, key: number) => (
                      <Col md={6} lg={4} key={`${idx}-${key}`}>
                        {siteInfor?.isDownloadable ? (
                          <S.LocationItemLink
                            href={
                              formBrochure && formBrochure.linkUrl
                                ? formBrochure.linkUrl
                                : ''
                            }
                            target={'_blank'}
                            download
                          >
                            {suburb}
                          </S.LocationItemLink>
                        ) : (
                          <RegisterYourInterestModal
                            form={formBrochure}
                            button={<S.LocationItem>{suburb}</S.LocationItem>}
                            buttonStyle={{ width: '100%' }}
                            preferredLocation={{ state: title, suburb: suburb }}
                            downloadFileOnSuccess={true}
                            fileUrl={
                              formBrochure && formBrochure.linkUrl
                                ? formBrochure.linkUrl
                                : ''
                            }
                          />
                        )}
                      </Col>
                    )
                  )}
              </Row>
            </S.Suburbs>
          ) : null
        )}
        {footer_text && <S.Note>{footer_text}</S.Note>}
      </Container>
    </S.Location>
  )
}

export default LocationSuggestions
