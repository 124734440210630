import { LAYOUT_TEMPLATE, WagtailBlockProps } from '../../services/api/types'
import React from 'react'
import Blocks from './blocks'
import { getTemplate } from '../../services/api/helpers'
import BlocksYouFitness from './blocksYouFitness'

interface BlockProps {
  type: string
  data: any
  template?: LAYOUT_TEMPLATE
}
export const WagtailBlock = (props: BlockProps) => {
  const { type, data, template } = props
  let Component: any = null
  if (template) {
    Component = BlocksYouFitness(getTemplate(type, template))
      ? BlocksYouFitness(getTemplate(type, template))
      : Blocks(type)
      ? Blocks(type)
      : null
  } else {
    Component = Blocks(type) ? Blocks(type) : null
  }
  let componentProps = data
  if (Component && Component.ApiBlockToProps) {
    componentProps = Component.ApiBlockToProps(data)
  }

  return Component ? (
    <Component {...componentProps} template={template} />
  ) : null
}

const WagtailBlocksSection = (props: {
  template?: LAYOUT_TEMPLATE
  blocks: WagtailBlockProps[]
}) => {
  const { blocks, template } = props
  return (
    <>
      {blocks && blocks.length
        ? blocks.map((block, key) => {
            {
              return (
                block.type &&
                block.value && (
                  <WagtailBlock
                    type={block.type}
                    template={template}
                    key={key}
                    data={block}
                  />
                )
              )
            }
          })
        : null}
    </>
  )
}

export default WagtailBlocksSection
