import { Colors, WagtailBlockProps } from '../../services/api/types'
import * as G from '../../styles/global.styles'
import * as S from './IconSection.styles'
import { BlockTitle } from '../base'
import { LazyImage } from '../lazy-image'

export interface IconProps {
  icon: string
  label: string
  mdSize: 'w-1/3' | 'w-1/4'
}

export interface IconSectionProps {
  title: string
  bgColor?: Colors
  icons: IconProps[]
}

export interface IconSectionAPIProps extends WagtailBlockProps {
  value: {
    title: string
    background_colour: {
      background_colour?: Colors
    }
    icon: IconProps[]
  }
}

export const Icon = (props: IconProps) => {
  const { icon, label, mdSize } = props
  return (
    <S.Group mdSize={mdSize}>
      <S.ImgWrap>
        <LazyImage src={`/static/icon-section/${icon}.svg`} height='100' />
      </S.ImgWrap>

      <S.GText color='white'>
        {label}
      </S.GText>
    </S.Group>
  )
}

export const IconSection = (props: IconSectionProps) => {
  const { title, bgColor, icons } = props
  return (
    <G.Section spacing={8} bgColor={bgColor}>
      <G.CenteredContainer contentSize='lg'>
        {title && <BlockTitle textColor='white'>{title}</BlockTitle>}
        <S.IconsWrapper>
          {icons && icons.map((icon, idx) => <Icon key={idx} {...icon} />)}
        </S.IconsWrapper>
      </G.CenteredContainer>
    </G.Section>
  )
}

IconSection.ApiBlockToProps = (data: IconSectionAPIProps) => {
  const apiData = data.value

  let props: IconSectionProps = {
    icons: [],
    title: '',
    bgColor: undefined
  }

  if (apiData) {
    const { icon, title, background_colour } = apiData
    props.icons = icon
    props.title = title
    props.bgColor = background_colour.background_colour
    const mdSize = props.icons.length > 6 ? 'w-1/4' : 'w-1/3'
    props.icons.forEach(i => (i.mdSize = mdSize))
  }

  return props
}
