import { useEffect, useState } from 'react'
import { WagtailBlockProps } from '../../services/api/types'
import { Col, Container, Row, Section } from '../../styles/grid.styles'
import { Heading } from './Franchise.styled'
import ModalSlider from './ModalSlider'
import * as S from './Media.styled'
import { isMobile } from 'react-device-detect'
import { VideoPlayer } from '../blocks/YouFitnessTemplate/VideoBlock'
import React from 'react'

export interface MediaProps extends WagtailBlockProps {
  value: {
    heading: string
    media_items: {
      image?: string
      video?: string
    }[]
  }
}

const Media = (props: MediaProps) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [hasWindow, setHasWindow] = useState(false);

  const { heading, media_items } = props.value

  const toggleModal = () => {
    setOpen(!isOpen)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true);
    }
  }, []);

  return (
    <>
      <Section>
        <Container size='lg'>
          <Heading center={media_items.length === 1}>{heading}</Heading>
          <Row gutter={12}>
            {isMobile ? (
              <Col md={6} style={{ margin: media_items.length === 1 ? '0 auto' : undefined }}>
                {media_items.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.video ? (
                      hasWindow && (
                        <S.VideoContainer>
                          <VideoPlayer
                            videoURL={item.video}
                            thumbnailURL={item.image}
                            autoPlay={false}
                          />
                        </S.VideoContainer>
                      )
                    ) : (
                      <S.Media
                        image={item.image}
                        onClick={() => {
                          toggleModal();
                          setSelectedIndex(index);
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Col>
            ) : (
              <>
                {media_items[0] && (
                  <Col md={6} style={{ margin: media_items.length === 1 ? '0 auto' : undefined }}>
                    <S.Media
                      image={media_items[0].image}
                      onClick={() => {
                        toggleModal();
                        setSelectedIndex(0);
                      }}
                    >
                      {media_items[0].video && (
                        <S.PlayButton src='/static/imgs/icon-play.png' />
                      )}
                    </S.Media>
                  </Col>
                )}
  
                {media_items.length > 1 && (
                  <Col md={6}>
                    <Row gutter={8}>
                      {media_items.slice(1, 5).map((item, index) => (
                        <Col md={6} key={index}>
                          <S.Media
                            image={item.image}
                            isLastItem={index === 3 && media_items.length > 5}
                            onClick={() => {
                              toggleModal();
                              setSelectedIndex(index + 1);
                            }}
                          >
                            {index === 3 && media_items.length > 5 ? (
                              <S.Count>+{media_items.length - 4}</S.Count>
                            ) : item.video ? (
                              <S.PlayButton src='/static/imgs/icon-play.png' />
                            ) : null}
                          </S.Media>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Container>
      </Section>
      <ModalSlider
        isOpen={isOpen}
        toggleModal={toggleModal}
        initIndex={selectedIndex}
        media={media_items}
      />
    </>
  )
}

export default Media
