import * as S from './ResponsiveImage.styles'
import { WagtailBlockProps } from '../../services/api/types'

export interface ResponsiveImageProps {
  image?: string
  mobile_image?: string
  alt_text?: string
}

export interface ResponsiveImageAPIProps extends WagtailBlockProps {
  value: {
    image?: string
    mobile_image?: string
    alt_text?: string
  }
}

export const ResponsiveImage = (props: ResponsiveImageProps) => {
  const { image, mobile_image, alt_text } = props

  return image || mobile_image ? (
    <>
      {image && (
        <S.IMG
          key='main-image'
          src={image}
          alt={alt_text || ''}
          hasMobile={mobile_image ? true : false}
        />
      )}
      {mobile_image && (
        <S.IMG
          key='mobile-image'
          src={mobile_image}
          alt={alt_text || ''}
          isMobile={true}
        />
      )}
    </>
  ) : null
}

ResponsiveImage.ApiBlockToProps = (
  data: ResponsiveImageAPIProps
): ResponsiveImageProps => {
  const { value } = data
  return {
    image: value.image,
    mobile_image: value.mobile_image,
    alt_text: value.alt_text
  }
}
