import styled from 'styled-components'
import tw from 'twin.macro'

export const GalleryWrapper = styled.div`
  ${tw`flex flex-wrap -mx-2`}
`

export const GalleryItem = styled.div<{ itemsPerRow: number }>`
  ${tw`w-full px-2 pb-4`}
  ${({ itemsPerRow }) => {
    switch (itemsPerRow) {
      case 1:
        return tw`lg:w-full`
      case 2:
        return tw`md:w-1/2`
      case 3:
        return tw`md:w-1/2 lg:w-1/3`
      default:
        return null
    }
  }}
`
