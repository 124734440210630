import styled from 'styled-components'
import tw from 'twin.macro'

export const ImgWrapper = styled.div`
  ${tw`mr-4`}

  img {
    ${tw`h-8`}
  }
`

export const ImgMaxFull = styled.img`
  ${tw`max-w-full`}
`

export const Text = styled.span`
  ${tw`py-4 uppercase text-2xl leading-8`}
`
export const Span = styled.span`
  ${tw`text-blue__deep`}
`

export const Subtext = styled.div`
  ${tw`mb-10 px-2 lg:px-8 text-center text-white__deeper text-sm`}
`

export const ButtonsList = styled.div`
  ${tw`w-full flex flex-wrap content-center justify-center items-stretch`}
`
export const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom:30px;
  padding-top:20px;
`
export const CountdownBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
`
export const CountdownNumber = styled.span`
  font-size: 36px;
  border: 1px solid white;
  border-radius:8px;
  padding:0px 10px 0px 10px;
  font-weight: bold;
`
export const CountdownLabel = styled.span`
  margin-top:5px;
  font-size: 15px;
`
export const ButtonIcon = styled.a`
  ${tw`mb-4 md:mb-0 w-full md:w-auto text-white flex justify-start items-center text-lg bg-blue__deep hover:bg-blue__dark2 transition duration-300 px-8 py-4 md:mx-3 leading-tight`}

  @media (max-width: 767px) {
    ${tw`justify-center`};
    min-height: 77px;
  }
`

import { SVGIcon } from '../../base/icon/svgIcon'
import { fontSize, size } from '../../../styles/theme'

export const IconLeft = styled(SVGIcon)`
  ${tw`hover:text-orange text-white absolute`}
  left: -25%;
  top: 120%;
  transform: translate(-50%, -50%);

  @media (max-width: 768px) {
    left: -15%;
    width: ${size(31)};
    height: ${size(40)};
    transform: rotate(-15deg);
    top: 80%;

    svg {
      width: ${size(101)};
      height: ${size(110)};
    }
  }

  @media (max-width: 414px) {
    left: -4%;
    svg {
      width: ${size(80)};
      height: ${size(88)};
    }
  }
`

export const IconRight = styled(SVGIcon)`
  ${tw`hover:text-orange text-white absolute`}
  right: -32%;
  top: 110%;
  transform: translate(50%, -50%);

  @media (max-width: 768px) {
    right: 0;
    width: ${size(31)};
    height: ${size(40)};
    transform: rotate(50deg);

    svg {
      width: ${size(101)};
      height: ${size(110)};
    }
  }

  @media (max-width: 768px) {
    right: 4vw;
    top: 50%;
  }

  @media (max-width: 414px) {
    top: 80%;
    svg {
      width: ${size(80)};
      height: ${size(88)};
    }
  }
`

export const PrimaryText = styled.div`
  ${tw`uppercase text-sm mb-4 text-white__deep text-center`}
`

export const SecondaryText = styled.div`
  ${tw`text-white__deep text-sm text-center mb-7`}
`
export const SubtextWrapper = styled.div`
  ${tw`mb-8 px-2 lg:px-8 text-center`}
`

export const GymComp3BtnJoin = styled.div`
  ${tw`w-full mx-auto relative`}
  max-width: 300px;
  margin-bottom: ${size(25)};

  @media (max-width: 768px) {
    max-width: ${size(327)};
  }

  @media (max-width: 480px) {
    padding: 0 20px;
  }

  a {
    ${tw`w-full ml-0 justify-center text-2xl bg-blue__deep text-white px-8 py-5 font-medium transition-colors duration-300 flex items-center`}
    line-height: 1.5;
    text-transform: unset;

    figure {
      margin-bottom: 0;
      margin-right: ${size(10)};
      width: ${size(14)};
      height: ${size(14)};
    }

    &:hover {
      ${tw`bg-blue__dark2`}
    }
  }
`

export const GymComp3BtnWhite = styled.div`
  ${tw`w-full mx-auto flex justify-center items-center`}
  
  button, a {
    ${tw`flex justify-center items-center`}
    
    @media (max-width: 768px) {
      ${tw`text-sm mb-4 w-full`}
    }
  }
  
  @media (max-width: 768px) {
    ${tw`flex-col`}
    max-width: ${size(155)};
  }
`

export const LinkWrapperNewLayout = styled.a`
  ${tw`bg-white text-black text-blue__deep transition-colors duration-300 flex items-center`}
  line-height: 2.5;
  padding: 0.35rem 2rem;
  font-weight: 500;
  ${fontSize(14)}

  span {
    ${fontSize(14)}
    text-overflow:ellipsis;
    white-space: nowrap;
    padding: 0;
    padding: 0.35rem 0;
  }

  figure {
    margin-bottom: 0;
    margin-right: ${size(10)};
    width: ${size(14)};
    height: ${size(14)};
  }

  &:hover {
    background-color: #cccccc;
  }
`

export const LinkWrapper = styled.a`
  ${tw`w-full flex flex-col content-center justify-center items-center text-center`}
`

