import * as S from './GetStarted.styled'
import React, { useContext } from 'react'
import { Container, Row, Col } from '../../styles/grid.styles'
import { getPanelIconSet } from '../../services/api/helpers'
import { LazyImage } from '../lazy-image'
import { Heading } from './Franchise.styled'
import RegisterYourInterestModal from './RegisterYourInterestModal'
import { SiteContext } from '../../services/context/SiteContext'
import { WagtailBlockProps } from '../../services/api/types'

export interface GetStartedProps extends WagtailBlockProps {
  value: {
    button_one_icon?: {
      icon: string
    }
    button_one_link: {
      link_target?: string
      external_link?: string
      page_link?: string
      document_link?: string
      anchor_link?: string
    }
    button_one_text: string
    button_two_icon?: {
      icon: string
    }
    button_two_link: {
      link_target?: string
      external_link?: string
      page_link?: string
      document_link?: string
      anchor_link?: string
    }
    button_two_text: string
    title: string
    content: string
  }
}

export const GetStarted = (props: GetStartedProps) => {
  const appContext = useContext(SiteContext)

  const { formBrochure } = useContext(SiteContext)

  const {
    button_one_icon,
    button_one_link,
    button_one_text,
    button_two_icon,
    button_two_link,
    button_two_text,
    title,
    content
  } = props.value

  let buttons = []
  if ((button_one_icon || button_one_text) && button_one_link) {
    buttons.push({
      imgURL: getPanelIconSet(button_one_icon?.icon)?.icon,
      text: button_one_text || '',
      link:
        button_one_link.page_link ||
        button_one_link.document_link ||
        button_one_link.external_link ||
        button_one_link.anchor_link ||
        undefined,
      linkTarget: button_one_link.link_target || '',
      enquireLink: button_one_link.page_link || undefined
    })
  }

  if ((button_two_text || button_two_icon?.icon) && button_two_link) {
    buttons.push({
      imgURL: getPanelIconSet(button_two_icon?.icon)?.icon,
      text: button_two_text || '',
      link:
        button_two_link.page_link ||
        button_two_link.document_link ||
        button_two_link.external_link ||
        button_two_link.anchor_link ||
        undefined,
      linkTarget: button_two_link.link_target || '',
      enquireLink: button_two_link.page_link || undefined
    })
  }

  return (
    <S.GetStarted>
      <Container size='lg'>
        <Heading>{title}</Heading>
        <Row gutter={15} itemsCenter>
          <Col xl={6}>
            <S.Description dangerouslySetInnerHTML={{ __html: content }} />
          </Col>
          <Col xl={6}>
            <S.ButtonsList>
              {buttons?.length > 0 &&
                buttons.map((button, idx) =>
                  idx === 0 && appContext.siteInfor?.isDownloadable ? (
                    <S.ButtonIconLink
                      key={idx}
                      href={button.link}
                      download
                      target='_blank'
                    >
                      <S.ImgWrapper>
                        <LazyImage src={button.imgURL} alt={button.text} />
                      </S.ImgWrapper>
                      <div dangerouslySetInnerHTML={{ __html: button.text }} />
                    </S.ButtonIconLink>
                  ) : (
                    <RegisterYourInterestModal
                      button={
                        <S.ButtonIcon key={idx}>
                          <S.ImgWrapper>
                            <LazyImage src={button.imgURL} alt={button.text} />
                          </S.ImgWrapper>
                          <div
                            dangerouslySetInnerHTML={{ __html: button.text }}
                          />
                        </S.ButtonIcon>
                      }
                      fileUrl={idx === 0 ? button.link : undefined}
                      downloadFileOnSuccess={idx === 0 ? true : false}
                      form={formBrochure}
                    />
                  )
                )}
            </S.ButtonsList>
          </Col>
        </Row>
      </Container>
    </S.GetStarted>
  )
}

export default GetStarted
