import React, { useEffect, useState } from 'react'
import * as G from '../../styles/global.styles'
import { WagtailBlockProps } from '../../services/api/types'
import * as S from './ImageGallery.styles'
import useWindowSize from '../../hooks/useWindowSize'

export interface ImageGalleryItemProps {
  image: string
  caption?: string
}

export interface ImageGalleryProps {
  imgUrls: string[]
}

export interface ImageGalleryAPIProps extends WagtailBlockProps {
  value: ImageGalleryItemProps[]
}

export const ImageGallery = (props: ImageGalleryProps) => {
  const { imgUrls } = props

  const windowSize = useWindowSize()

  const oneThird: number = 33.3333
  const fullWidth: number = 100
  const breakpoint = 768 // medium tailwind breakpoint
  const [slideWidth, setSlideWidth] = useState(oneThird)

  const sliderSetting = {
    swipeable: true,
    showArrows: false,
    infiniteLoop: true,
    interval: 3000,
    transitionTime: 700,
    autoPlay: true,
    emulateTouch: true,
    showIndicators: true,
    showStatus: false,
    centerMode: true,
    centerSlidePercentage: slideWidth,
    showThumbs: false
  }

  useEffect(() => {
    const { width } = windowSize
    if (width && width >= breakpoint && slideWidth === fullWidth) {
      setSlideWidth(oneThird)
    } else if (width && width < breakpoint && slideWidth === oneThird) {
      setSlideWidth(fullWidth)
    }
  }, [windowSize])

  return (
    <G.Section>
      <G.FullWidthWrapper>
        <S.Carousel {...sliderSetting}>
          {imgUrls &&
            imgUrls.map((img, idx) => <G.FullWidthImg key={idx} src={img} />)}
        </S.Carousel>
      </G.FullWidthWrapper>
    </G.Section>
  )
}

ImageGallery.ApiBlockToProps = (
  data: ImageGalleryAPIProps
): ImageGalleryProps => {
  const { value } = data
  return {
    imgUrls: value.map(item => {
      return item.image
    })
  }
}

export default ImageGallery
