import * as G from '../../../styles/global.styles'
import { Container, Section } from '../../../styles/grid.styles'
import { BlockTitle } from '../../base'
import { featureItemProps, GymFeaturesAPIProps } from '../GymFeatures'

export interface GymFeaturesNewTemplateProps {
  title: string
  text: string
  features: featureItemProps[]
  newIcons: string[]
}

export const GymFeaturesNewTemplate = (props: GymFeaturesNewTemplateProps) => {
  const { text, title, features, newIcons } = props

  return (
    <Section>
      <Container>
        {title && <BlockTitle isYouFitness>{title}</BlockTitle>}
        <G.GymCommonDescHuge>{text}</G.GymCommonDescHuge>
        <G.GymCommonNewLogo>
          {features &&
            features.map((item: featureItemProps, i: number) => {
              return (
                <G.GymCommonNewComp key={i}>
                  <G.GymCommonNewLogoImg>
                    <G.DefaultImg src={newIcons[i]} alt={item.name} />
                  </G.GymCommonNewLogoImg>
                  <G.GymCommonNewLogoInfo>
                    <p>{item.name}</p>
                    {item.items.map((data, index) => {
                      return (
                        <span key={index}>
                          {index == item.items.length - 1 ? data : data + ', '}
                        </span>
                      )
                    })}
                  </G.GymCommonNewLogoInfo>
                </G.GymCommonNewComp>
              )
            })}
        </G.GymCommonNewLogo>
      </Container>
    </Section>
  )
}

GymFeaturesNewTemplate.ApiBlockToProps = (data: GymFeaturesAPIProps) => {
  //return default props for test, will implement data from API later
  if (data.value) {
    let props: GymFeaturesNewTemplateProps = {
      text: data.value.subtext,
      title: data.value.headline,
      features: Object.keys(data.value.features).map(key => {
        return {
          name: key,
          items: data.value.features[key]
        }
      }),
      newIcons: data.value.features_icons_new_brand
    }
    return props
  }
}

export default GymFeaturesNewTemplate
