import * as S from '../SocialLinks.styles'
import * as C from './SocialLinks.styled'
import { IconEnums } from '../../base'
import { useContext } from 'react'
import { SiteContext } from '../../../services/context/SiteContext'
import { SocialLinksAPIProps } from '../SocialLinks'
import { Container, Section } from '../../../styles/grid.styles'
import { isIOS13, isIPad13, isIPhone13, isIPod13 } from 'react-device-detect'

export interface SocialLinksNewGymTemplateProps {
  twitterUrl?: string
  facebookUrl?: string
  googlePlusUrl?: string
  pinterestUrl?: string
  youtubeUrl?: string
  instagramUrl?: string
  threadsUrl?: string
}

export const SocialLinksNewGymTemplate = (
  props: SocialLinksNewGymTemplateProps
) => {
  const { facebookUrl, instagramUrl, youtubeUrl, threadsUrl } = props
  const { siteSettings } = useContext(SiteContext)

  const openFacebookFanPage = () => {
    if (!facebookUrl) {
      return
    }

    if (isIPhone13 || isIPad13 || isIOS13 || isIPod13) {
      const fanPageId = extractStringFromURL(facebookUrl)
      if (fanPageId) {
        window.location.href = "fb://profile/" + fanPageId
      }
      
      setTimeout(() => {
        window.location.href = facebookUrl
      }, 500)
    } else {
      window.location.href = facebookUrl;
    }
  }

  const extractStringFromURL = (url: string): string | null => {
    const regex = /-(\d+)\//
    const match = url.match(regex)
  
    if (match && match[1]) {
      return match[1]
    } else {
      return null
    }
  }

  return (
    <Section>
      <Container>
        <C.GymBtnSocials>
          {facebookUrl && (
            <C.GymBtnSocialsLink onClick={openFacebookFanPage}>
              <S.Icon name={IconEnums['icon-fb']} />
            </C.GymBtnSocialsLink>
          )}

          {threadsUrl && (
              <C.GymBtnSocialsLink href={threadsUrl}>
                <S.Icon name={IconEnums['threads-filled']} />
              </C.GymBtnSocialsLink>
          )}

          {instagramUrl && (
            <C.GymBtnSocialsLink href={instagramUrl}>
              <S.Icon name={IconEnums['icon-instagram']} />
            </C.GymBtnSocialsLink>
          )}
          {youtubeUrl && (
            <C.GymBtnSocialsLink href={youtubeUrl}>
              <S.Icon name={IconEnums['icon-youtube']} />
            </C.GymBtnSocialsLink>
          )}
          {siteSettings && siteSettings.app_landing && (
            <C.GymBtnSocialsLink href={siteSettings.app_landing}>
              <S.Icon name={IconEnums['icon-store']} />
            </C.GymBtnSocialsLink>
          )}
        </C.GymBtnSocials>
      </Container>
    </Section>
  )
}

SocialLinksNewGymTemplate.ApiBlockToProps = (data: SocialLinksAPIProps) => {
  const { value } = data
  if (data.value) {
    let props: SocialLinksNewGymTemplateProps = {
      facebookUrl: value.facebook,
      twitterUrl: value.twitter,
      pinterestUrl: value.pinterest,
      instagramUrl: value.instagram,
      youtubeUrl: value.youtube,
      threadsUrl: value.threads,
    }
    return props
  }
}

export default SocialLinksNewGymTemplate
