import styled from 'styled-components'
import tw from 'twin.macro'

export const BlockQuoteSection = styled.section`
  ${tw`w-full bg-grey__light py-6`}
`

export const BlockQuoteContainer = styled.div`
  ${tw`container mx-auto px-2`}
`
