import styled from 'styled-components'
import tw from 'twin.macro'

export const Container = styled.div`
  ${tw`w-full xl:pl-48 flex flex-wrap items-stretch`}
`

export const ContentWrapper = styled.div<{ size: 1 | 2 | 3 | 4 }>`
  min-height: 45vh;
  ${tw`w-full flex flex-wrap items-stretch`}
  ${({ size }) => {
    switch (size) {
      case 1:
        return tw`lg:w-1/4`
      case 2:
        return tw`lg:w-1/2`
      case 3:
        return tw`lg:w-3/4`
      case 4:
        return tw`lg:w-full`
    }
  }}
`
export const InfoWrapper = styled.div`
  ${tw`w-full p-4`}
`

export const TextPrefix = styled.div`
  line-height: 3.25rem;
  width: auto;
  max-width: 100%;
  ${tw`text-blue__dark font-bold uppercase text-2xl mr-2`}
  @media (min-width: 541px) {
    max-width: 35%;
  }
`

export const TextName = styled.div`
  line-height: 1.25;
  max-width: 100%;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${tw`text-blue__dark font-extrabold uppercase text-4xl pr-2`}
  @media (min-width: 541px) {
    max-width: 65%;
  }
`

export const ButtonActionWrapper = styled.div`
  ${tw`w-full flex flex-col`}
  @media (min-width: 415px) {
    ${tw`flex-row items-center`}
  }
`

export const ButtonAction = styled.div<{ isFirst?: boolean }>`
  ${tw`w-full mb-2`}
  @media (min-width: 415px) {
    max-width: 250px;
  }
  ${({ isFirst }) => {
    if (isFirst) return tw`mr-2`
  }}
`

export const OpenTimesWrapper = styled.div`
  ${tw`w-full`}
`

export const TextBlock = styled.div<{ isHover?: boolean }>`
  ${tw`w-full mb-4`}

  ${({ isHover }) => {
    if (isHover) {
      return `
            a {
              &:hover {
                  color: #ee6c16;
              }
            }
            `
    }
  }}
`

export const TextBlockInline = styled.h2`
  ${tw`w-full mb-2 flex flex-col`}
  @media (min-width: 541px) {
    ${tw`flex-row`}
  }
`

export const TextWrapperInline = styled.div`
  ${tw`w-full mb-2 flex`}
`

export const WeekDayText = styled.p`
  ${tw`w-1/3 xl:w-1/4 text-base text-grey__dark font-bold mb-0`}
`

export const WeekDayTime = styled.p`
  ${tw`w-2/3 xl:w-3/4 text-base text-grey__dark mb-0`}
`

export const ButtonsGroup = styled.div`
  ${tw`w-full flex flex-wrap justify-center content-center`}
`

export const ButtonWrapper = styled.span`
  ${tw`ml-2 my-2`}
`

export const AddressWrapper = styled.address`
  ${tw`not-italic`}
`
export const IframeWrapper = styled.iframe`
  height: 10rem;
  @media (min-width: 768px) {
    height: 20rem;
  }
  @media (min-width: 1024px) {
    height: 26rem;
  }
  ${tw`w-full`}
`
