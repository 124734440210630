import styled from 'styled-components'
import tw from 'twin.macro'
import {DEVICES} from "../../../services/variables";

export const ImageAndTextWrap = styled.div`
  ${tw`mt-12 mb-12`}
  
  @media ${DEVICES.xs} {
    .image-and-text-container {
      ${tw`flex flex-col gap-6`}
    }
  }
`

export const Image = styled.img`
  ${tw``}
`

export const TextWrap = styled.div`
  ${tw`flex flex-col justify-center h-full`}
`

export const Headline = styled.h3`
  ${tw`w-full font-youfitnessHeading sm:text-xl md:text-3xl font-bold uppercase text-white mb-4 text-blue__deep`}

  @media ${DEVICES.xs} {
    ${tw`text-2.5xl mb-2 mt-2`}
  }
`

export const Description = styled.div`
  ${tw`text-white`}
`

export const ImageWrap = styled.div`
  ${tw``}

  > img {
    height: 320px;
  }

  @media ${DEVICES.xs} {
    > img {
      height: auto;
    }
  }
`