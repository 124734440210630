import * as S from './Pagination.styles'
import { SVGIcon, IconEnums } from '../base/icon/svgIcon'
import Pag from 'react-js-pagination'
import { LAYOUT_TEMPLATE } from '../../services/api/types'
import { PAGE_TEMPLATE } from '../../services/api/constants'

export interface PaginationProps {
  activePage?: number
  itemsCountPerPage: number
  totalItemsCount: number
  onChange?: Function
  scrollTo?: number
  template?: LAYOUT_TEMPLATE
}

export const Pagination = (props: PaginationProps) => {
  const {
    activePage,
    itemsCountPerPage,
    totalItemsCount,
    onChange,
    scrollTo,
    template
  } = props

  const prevArrow = () => <SVGIcon name={IconEnums.prev} />
  const nextArrow = () => <SVGIcon name={IconEnums.next} />
  const scroll =
    scrollTo && scrollTo >= 0
      ? () => {
          window.scrollTo({
            top: scrollTo,
            left: 0,
            behavior: 'smooth'
          })
        }
      : null
  const onPageChange = (e: number) => {
    onChange && onChange(e)
    if (scroll) scroll()
  }

  return (
    <S.Container>
      <div
        className={
          template === PAGE_TEMPLATE.YOU_FITNESS ? 'you-fitness-pag' : ''
        }
      >
        <Pag
          activePage={activePage || 1}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={totalItemsCount}
          onChange={onPageChange}
          prevPageText={prevArrow()}
          nextPageText={nextArrow()}
          innerClass=''
          itemClassPrev='prev'
          itemClassNext='next'
          activeClass='bca'
          activeLinkClass='is-active'
          linkClass='item'
        />
      </div>
    </S.Container>
  )
}

export default Pagination
