import styled from 'styled-components'
import * as G from '../../styles/global.styles'
import tw from 'twin.macro'

export const OwnerBase = styled.div`
  ${tw`relative w-full flex flex-col`}
`

export const OwnerSection = styled(OwnerBase)`
  ${tw`lg:flex-row pb-4 text-center`}
`

const calculateWidth = (count: number) => {
  if (count === 1) {
    return tw`items-center py-2 w-full`;
  } else if (count === 2) {
    return tw`items-center py-2 w-1/2`;
  } else if (count === 3) {
    return tw`items-center py-2 w-1/3`;
  } else {
    return tw`items-center py-2`;
  }
};


export const OwnerBlock = styled(OwnerBase)<{ count: number }>`
  ${(props) => calculateWidth(props.count)};
`

export const Name = styled(G.Text)`
  ${tw`mb-4`}
`

export const PopupWrapper = styled.div`
  ${tw`fixed w-full h-full overflow-auto z-50 top-0 left-0 md:flex items-center justify-center content-center`}
  @media (max-width: 1024px) {
    ${tw`p-8`}
  }

  @media (max-width: 767px) {
    ${tw`px-4`}
  }
`

export const PopupContainer = styled.div`
  ${tw`w-full bg-white w-full lg:w-3/4 xl:w-2/3 py-8 px-12 relative mx-auto`}

  @media (max-width: 1024px) {
    ${tw`px-4 py-4`}
  }

  @media (max-width: 767px) {
    ${tw`px-2 pt-10`}
  }
`

export const Close = styled.a`
  ${tw`absolute top-0 right-0 pr-8 text-4xl font-bold text-grey__medium hover:text-blue cursor-pointer`}
  @media (max-width: 767px) {
    ${tw`pr-2`}
  }
`

export const PopupContent = styled.div`
  ${tw`w-full flex flex-row -m-6`}

  @media (max-width: 1024px) {
    ${tw`m-0`}
  }

  @media (max-width: 767px) {
    ${tw`flex-col`}
  }
`

export const PopupAvatarWrap = styled.div<{ content?: string }>`
  ${tw`max-w-sm flex-initial w-1/4 p-6`}
  ${({ content }) =>
    content &&
    content.length > 1000 &&
    `@media screen and (min-width: 1973px) {
      height: 370px;
    }`}
  @media (max-width: 1024px) {
    ${tw`p-4`}
  }

  @media (max-width: 767px) {
    ${tw`p-2 w-full max-w-none`}
  }
`

export const PopupAvatar = styled.img<{ content?: string }>`
  ${({ content }) =>
    content && content.length > 1000
      ? tw`w-full h-full object-cover`
      : tw`w-full h-full object-contain object-top`}
`

export const PopupInfo = styled.div<{ content?: string }>`
  ${tw`w-3/4 p-6`}

  ${({ content }) =>
    content && content.length > 1000
      ? `padding-right: 0;`
      : ``}

  @media (max-width: 1024px) {
    ${tw`p-4`}
  }

  @media (max-width: 767px) {
    ${tw`p-2 w-full`}
  }
`

export const PopupInfoContent = styled.div<{
  content?: string
  isHorizontal?: boolean
}>`
  ${({ content, isHorizontal }) =>
    content && content.length > 1000
      ? `min-height: 272px;
  max-height: ${isHorizontal ? '250px' : '301px'};
  overflow-y: auto;
  padding-right: 1.5rem;
  `
      : ``}

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
  }
`

export const PhoneWrapper = styled.div`
  ${tw`py-4`}
`

export const PhoneIconWrapper = styled.span`
  ${tw`mr-2`}
`

export const TitleWrapper = styled.div`
  ${tw`py-4 w-full text-center`}
`
