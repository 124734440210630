import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import * as G from '../../../styles/global.styles'
import { colors } from '../../../styles/theme'

export const BannerTitle = styled(G.TextH2)`
  ${tw`mb-1 text-blue__deep font-youfitnessHeading text-xs uppercase font-normal lg:font-bold`}
`

export const Content = styled.h3`
  ${tw`text-black text-3xl font-youfitnessHeading leading-tiny px-4 font-bold uppercase mb-6 leading-10 lg:leading-none`}
`

export const ButtonWrapper = styled.div`
  a {
    ${tw`normal-case text-sm not-italic py-2.5 px-8`}

    @media (max-width: 1023px) {
      ${tw`bg-transparent border border-white hover:bg-white hover:text-blue__deep`}
    }
  }
`

export const Banner = styled.div<{ bgImage?: string }>`
  ${tw`bg-black__deep flex relative overflow-hidden bg-cover bg-no-repeat`}

  ${props =>
    props.bgImage &&
    css`
      background-image: url(${props.bgImage});
    `}

  @media (max-width: 1023px) {
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
    }
  }
`

export const BannerImage = styled.div<{ bgImage?: string }>`
  flex: 0 0 55%;
  img {
    ${tw`h-full object-cover opacity-0 h-0`}
  }

  ${props =>
    props.bgImage &&
    css`
      ${tw`bg-cover`}
      background-image: url(${props.bgImage});
    `}

  @media (max-width: 1023px) {
    ${tw`hidden`}
  }
`

export const BannerInfo = styled.div<{ triangleHeight?: number }>`
  ${tw`bg-white__deep text-center py-7 lg:py-12.5 flex-1 relative px-4 lg:pr-5 lg:pl-0 flex items-center flex-col justify-center`}

  @media (max-width: 1023px) {
    ${tw`w-full bg-transparent`}

    ${Content},
    ${BannerTitle} {
      ${tw`text-white`}
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    right: 100%;
    top: -2px;
    border-style: solid;
    border-width: ${({ triangleHeight }) => triangleHeight}px 120px 0px 0px;
    border-color: transparent ${colors.white__deep} transparent transparent;
    z-index: 5;
  }
`

export const Section = styled.div`
  ${tw`bg-black__deep pb-20 w-full`}
`
