import tw from 'twin.macro'
import styled from 'styled-components'

export const Article = styled.div`
  ${tw`w-full pt-6 md:pt-5`}
`

export const ArticleImage = styled.a`
  ${tw`relative h-0 block`};
  padding-top: 56.33%;

  img {
    ${tw`absolute w-full h-full`};
    top: 0;
    left: 0;
  }
`

export const ArticleTitle = styled.h4`
  ${tw`bg-white__deep font-youfitnessHeading overflow-hidden text-base uppercase text-center px-4 py-3`}
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height:90px;
`

export const ArticleLink = styled.a`
  ${tw`text-white text-center block cursor-pointer text-sm`}
`
