import * as G from '../../styles/global.styles'
import * as S from './KeyMessage.styles'
import Head from 'next/head'
import {
  ButtonProps,
  WagtailBlockProps,
  ButtonAPIProps,
  Colors
} from '../../services/api/types'

export interface KeyMessageBlock {
  Title: string
  LargeText?: string
  Msg?: string
  ImageURL: string
  buttons: ButtonProps[]
}

export interface KeyMessageProps {
  blocks: [KeyMessageBlock, KeyMessageBlock]
  centralButtons: ButtonProps[]
  overlay?: string
  background_colour?: Colors
}

export interface KeyMessageAPIProps extends WagtailBlockProps {
  value: {
    overlay: string
    left_panel: {
      background: {
        image: string
        mobile_image: string
      }
      headline: string
      large_text: string
      subtext: string
      button: ButtonAPIProps[]
    }
    right_panel: {
      background: {
        image: string
        mobile_image: string
      }
      headline: string
      large_text: string
      subtext: string
      button: ButtonAPIProps[]
    }
    centered_call_to_action_button: ButtonAPIProps[]
    background_colour: {
      background_colour?: Colors
    }
  }
}

export const KeyMessage = (props: KeyMessageProps) => {
  const { blocks, centralButtons, overlay, background_colour } = props

  return (
    <>
      <Head>
        {blocks.length > 0 && blocks[0].ImageURL && (
          <link rel='preload' href={blocks[0].ImageURL} as='image' />
        )}
      </Head>
      <S.Section overlay={overlay} bgColor={background_colour}>
        <G.Container contentSize='xl'>
          <S.ContentWrapper>
            <S.Blocks>
              {blocks.map((block, i) => {
                const count = i % 2
                return (
                  <S.Block
                    key={i}
                    className={`bg-opacity-75 ${
                      count == 0 ? `bg-orange` : `bg-blue`
                    }`}
                    bgImage={block.ImageURL}
                    isCentralBlock={centralButtons && centralButtons.length > 0}
                  >
                    <S.Content isRight={count == 1}>
                      {block.Title && (
                        <G.Text
                          size='xl'
                          color={count == 0 ? 'blue' : 'orange'}
                          fontWeight='bold'
                        >
                          {block.Title}
                        </G.Text>
                      )}
                      {block.LargeText && (
                        <S.LargeText
                          size='4xl'
                          color='white'
                          fontWeight='bold'
                          textCase='upper'
                        >
                          {block.LargeText}
                        </S.LargeText>
                      )}
                      {block.Msg && (
                        <S.MsgText color={count == 0 ? 'blue' : 'orange'}>
                          {block.Msg}
                        </S.MsgText>
                      )}

                      {block.buttons && block.buttons.length > 0 && (
                        <S.ButtonWrapper>
                          {block.buttons.map((btn, idx) => (
                            <S.Button key={idx} {...btn}>
                              {btn.label}
                            </S.Button>
                          ))}
                        </S.ButtonWrapper>
                      )}
                    </S.Content>
                  </S.Block>
                )
              })}
            </S.Blocks>
            {centralButtons && centralButtons.length > 0 && (
              <S.CentralButtonWrapper>
                {centralButtons.map((btn, idx) => (
                  <S.Button key={idx} {...btn}>
                    {btn.label}
                  </S.Button>
                ))}
              </S.CentralButtonWrapper>
            )}
          </S.ContentWrapper>
        </G.Container>
      </S.Section>
    </>
  )
}

KeyMessage.ApiBlockToProps = (data: KeyMessageAPIProps) => {
  const { value } = data
  const props: KeyMessageProps = {
    blocks: [
      {
        Title: value.left_panel.headline,
        LargeText: value.left_panel.large_text,
        Msg: value.left_panel.subtext,
        ImageURL: value.left_panel.background.image,
        buttons: value.left_panel.button.map(btn => {
          return {
            label: btn.button_text,
            href:
              btn.button_link.external_link ||
              btn.button_link.page_link ||
              btn.button_link.document_link ||
              btn.button_link.anchor_link,
            color: btn.button_colour,
            target: btn.button_link.link_target
          }
        })
      },
      {
        Title: value.right_panel.headline,
        LargeText: value.right_panel.large_text,
        Msg: value.right_panel.subtext,
        ImageURL: value.right_panel.background.image,
        buttons: value.right_panel.button.map(btn => {
          return {
            label: btn.button_text,
            href:
              btn.button_link.external_link ||
              btn.button_link.page_link ||
              btn.button_link.document_link ||
              btn.button_link.anchor_link,
            color: btn.button_colour,
            target: btn.button_link.link_target
          }
        })
      }
    ],
    centralButtons: value.centered_call_to_action_button.map(btn => {
      return {
        label: btn.button_text,
        href:
          btn.button_link.external_link ||
          btn.button_link.page_link ||
          btn.button_link.document_link ||
          btn.button_link.anchor_link,
        color: btn.button_colour,
        target: btn.button_link.link_target,
        onClick: btn.onClick
      }
    }),
    overlay: value.overlay,
    background_colour: value.background_colour.background_colour
  }
  return props
}

export default KeyMessage
