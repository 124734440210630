import * as S from './BlockQuote.styles'
import * as G from '../../styles/global.styles'

export interface BlockQuoteProps {
  quote: string
}

export const BlockQuote = (props: BlockQuoteProps) => {
  const { quote } = props
  return (
    <S.BlockQuoteSection>
      <S.BlockQuoteContainer>
        <G.Text
          size='xl'
          color='grey-medium'
          lineHeight='relaxed'
          fontWeight='thin'
        >
          {quote}
        </G.Text>
      </S.BlockQuoteContainer>
    </S.BlockQuoteSection>
  )
}
