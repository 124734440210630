import * as G from '../../../styles/global.styles'
import * as S from './ContentBlock.styled'
import {
  WagtailBlockProps,
  ButtonAPIProps,
  ButtonProps,
  FirstContentBlock,
  LAYOUT_TEMPLATE
} from '../../../services/api/types'
import { BlockTitle, IconEnums } from '../../base'
import { getPanelIconSet } from '../../../services/api/helpers'
import Modal from '../../modal/Modal'
import { GymSearchCard } from '../../gym-search-card'
import { Section, Container } from '../../../styles/grid.styles'
import { PAGE_TEMPLATE } from '../../../services/api/constants'
import React, {useState} from "react";

export interface ContentBlockAPIProps extends WagtailBlockProps {
  value: {
    headline: string
    featured_text: string
    standard_text: string
    cta_buttons: {
      cta_button: ButtonAPIProps[]
    }
    isHomePage?: boolean
    hide_you_plus_fitness?: boolean

  }
  id: string
}

export interface ContentBlockProps extends FirstContentBlock {
  headerText: string
  contentPrimayText?: string
  contentSecondaryText?: string
  buttons?: ButtonProps[]
  isHomePage?: boolean
  hide_you_plus_fitness?: boolean
  
}

export const ContentBlockNewLayout = (props: ContentBlockProps) => {
  const {
    headerText,
    contentPrimayText,
    contentSecondaryText,
    buttons,
    isHomePage
  } = props

  const [suburbStep, setSuburbStep] = useState(true)

  const handleSuburbStep = () => {
    setSuburbStep(!suburbStep)
  }

  return (
    <Section>
      <Container>
        <G.GymCommonWrap>
          <BlockTitle isYouFitness>{headerText}</BlockTitle>
          {contentPrimayText && (
            <S.PrimaryText
              dangerouslySetInnerHTML={{ __html: contentPrimayText }}
            />
          )}
          {contentSecondaryText && (
            <S.SecondaryText
              dangerouslySetInnerHTML={{ __html: contentSecondaryText }}
            />
          )}
          {isHomePage
            ? buttons &&
              buttons.length > 0 &&
              buttons.map((btn, idx) => {
                return idx === 0 ? (
                  <S.GymComp3BtnJoin key={idx}>
                    <S.IconLeft name={IconEnums['icon-letdoit']} />
                    {btn.joinNowPopup ? (
                        <Modal
                            buttonText={btn.label}
                            showCloseIcon={!!suburbStep}
                            cancelText={!suburbStep ? 'Cancel' : ''}
                            isHeroButton={true}
                            template={PAGE_TEMPLATE.YOU_FITNESS as LAYOUT_TEMPLATE}
                        >
                          <GymSearchCard
                              template={PAGE_TEMPLATE.YOU_FITNESS as LAYOUT_TEMPLATE}
                              suburbStep={suburbStep}
                              handleSuburbStep={handleSuburbStep}
                          />
                        </Modal>
                    ) : (
                        <a href={btn.href} target={btn.target}>
                          {btn.label}
                        </a>
                    )}
                    <S.IconRight name={IconEnums['icon-ready']} />
                  </S.GymComp3BtnJoin>
                ) : (
                  <S.GymComp3BtnWhite key={idx}>
                    {btn.joinNowPopup ? (
                        <Modal
                            buttonText={btn.label}
                            buttonColor={btn.color}
                            buttonSize={'sm'}
                            showCloseIcon={!!suburbStep}
                            cancelText={!suburbStep ? 'Cancel' : ''}
                            isHeroButton={true}
                            template={PAGE_TEMPLATE.YOU_FITNESS as LAYOUT_TEMPLATE}
                            iconImageUrl={btn.iconImgUrl}
                        >
                          <GymSearchCard
                              template={PAGE_TEMPLATE.YOU_FITNESS as LAYOUT_TEMPLATE}
                              suburbStep={suburbStep}
                              handleSuburbStep={handleSuburbStep}
                          />
                        </Modal>
                        ) : (
                        <S.LinkWrapperNewLayout href={btn.href} target={btn.target}>
                          <G.Icon name={IconEnums['icon-email']} />
                          {btn.label}
                        </S.LinkWrapperNewLayout>
                    )}
                  </S.GymComp3BtnWhite>
                )
              })
            : buttons &&
              buttons.length > 0 && (
                <S.ButtonsList>
                  {buttons.map((btn, idx) => (
                    <G.BorderButton
                      key={idx}
                      href={btn.href}
                      target={btn.target}
                    >
                      {btn.iconImgUrl && (
                        <img src={btn.iconImgUrl} alt={btn.label} />
                      )}
                      {btn.label}
                    </G.BorderButton>
                  ))}
                </S.ButtonsList>
              )}
        </G.GymCommonWrap>
      </Container>
    </Section>
  )
}

ContentBlockNewLayout.ApiBlockToProps = (
  data: ContentBlockAPIProps
): ContentBlockProps => {
  const apiData = data.value

  const { cta_buttons } = apiData
  let buttons = new Array<ButtonProps>()
  if (cta_buttons?.cta_button) {
    cta_buttons.cta_button.forEach(btn => {
      let ctaButton: ButtonProps = {
        label: btn.button_text,
        color: btn.button_colour,
        href:
          btn.button_link.document_link ||
          btn.button_link.external_link ||
          btn.button_link.page_link ||
          btn.button_link.anchor_link ||
          undefined,
        target: btn.button_link.link_target,
        iconImgUrl: btn.button_icon?.icon
          ? getPanelIconSet(btn.button_icon.icon)?.icon
          : undefined,
        joinNowPopup: btn.join_now_popup
      }

      buttons.push(ctaButton)
    })
  }

  return {
    headerText: apiData.headline,
    contentPrimayText: apiData.featured_text,
    contentSecondaryText: apiData.standard_text,
    buttons: buttons,
    isFirstBlock: data.isFirstBlock,
    isHomePage: data.value.isHomePage,
    hide_you_plus_fitness: data.value.hide_you_plus_fitness

  }
}

export default ContentBlockNewLayout
