import { LocationTabWrapper, LocationTabItem } from './LocationTab.styled'
import { useState, useEffect } from 'react'
import { LAYOUT_TEMPLATE } from '../../services/api/types'

export interface LocationTabProps {
  tabItems?: Array<string>
  selectedTab?: string
  tabClick?: Function
  template?: LAYOUT_TEMPLATE
}

export const LocationTab = (props: LocationTabProps) => {
  const { tabItems, tabClick, selectedTab, template } = props
  const [selectTab, setSelectTab] = useState(selectedTab)

  useEffect(() => {
    setSelectTab(selectTab)
  }, [selectTab])

  const onTabClick = (tabName: string) => {
    tabClick && tabClick(tabName)
    setSelectTab(tabName)
  }

  return (
    <LocationTabWrapper template={template}>
      <LocationTabItem
        dangerouslySetInnerHTML={{ __html: 'ALL' }}
        className={selectTab === '' ? 'active' : ''}
        onClick={() => {
          onTabClick('')
        }}
      />
      {tabItems &&
        tabItems.length > 0 &&
        !(tabItems.length === 1 && tabItems[0] === '') &&
        tabItems.map((tab: string, idx) => {
          return (
            <LocationTabItem
              key={idx}
              className={tab === selectTab ? 'active' : ''}
              onClick={() => {
                onTabClick(tab)
              }}
            >
              {tab}
            </LocationTabItem>
          )
        })}
    </LocationTabWrapper>
  )
}

export default LocationTab
