import { ShareBlock, ShareBlockProps } from '../blocks'
import * as S from './BlogHero.styled'

export interface BlogFooterProps extends ShareBlockProps {
  info: string
}

const BlogFooter = ({ info, ...props }: BlogFooterProps) => {
  return (
    <S.GroupInfo style={{ paddingBottom: 20 }}>
      <S.Info dangerouslySetInnerHTML={{ __html: info }} />
      <ShareBlock template='you_fitness' {...props} />
    </S.GroupInfo>
  )
}

export default BlogFooter
