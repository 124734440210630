import { Col, Container, Row, Section } from '../../styles/grid.styles'
import RegisterYourInterestModal from './RegisterYourInterestModal'
import { useContext, useEffect, useState } from 'react'
import { SiteContext } from '../../services/context/SiteContext'
import { WagtailBlockProps, FormsProps } from '../../services/api/types'
import * as S from './Brochure.styled'
import { getForm } from '../../services/api/cms'

export interface BrochureProps extends WagtailBlockProps {
  value: {
    title: string
    image: string
    content: string
    button_text: string
    form: FormsProps
    button_link: {
      link_target?: string
      external_link?: string
      page_link?: string
      document_link?: string
      anchor_link?: string
    }
  }
}
const getFormContent = async (ctx: any = null, params: any) => {
  const formRes = await getForm(ctx, params)
  if (formRes && formRes.results && formRes.results.length > 0) {
    const formData = formRes.results[0]
    delete formData.title
    return {
      ...formData
    }
  }

  return {}
}
const Brochure = (props: BrochureProps) => {
  const appContext = useContext(SiteContext)
  const { setFormBrochure } = useContext(SiteContext)
  const { title, image, content, button_text, button_link, form } = props.value
  const link = {
    url:
      button_link.page_link ||
      button_link.document_link ||
      button_link.external_link ||
      button_link.anchor_link ||
      undefined,
    target: button_link.link_target
  }
  const [formFields, setFormFields] = useState<any>()

  useEffect(() => {
    getFormFields()
  }, [])

  const getFormFields = async () => {
    const form_fields = await getFormContent(null, {
      slug: form && form.slug ? form.slug : ''
    })
    form_fields.linkUrl =
      button_link.page_link ||
      button_link.document_link ||
      button_link.external_link ||
      button_link.anchor_link ||
      undefined
    form_fields.linkTarget = button_link.link_target
    form_fields.title = form.title
    form_fields.preamble = form.preamble
    setFormFields(form_fields)
    setFormBrochure && setFormBrochure(form_fields)
  }

  return (
    <Section>
      <Container size='lg'>
        <Row itemsCenter gutter={10}>
          {image && (
            <Col md={6}>
              <S.Image src={image} alt={title} />
            </Col>
          )}
          <Col md={6}>
            <S.Info>
              {title && <S.Title>{title}</S.Title>}
              <S.Content dangerouslySetInnerHTML={{ __html: content }} />
              {appContext.siteInfor?.isDownloadable ? (
                <S.Link href={link.url} download>
                  {button_text}
                </S.Link>
              ) : (
                <RegisterYourInterestModal
                  button={<S.Button>{button_text}</S.Button>}
                  downloadFileOnSuccess={true}
                  form={formFields}
                  fileUrl={link.url}
                />
              )}
            </S.Info>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}
export default Brochure
