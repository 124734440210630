import * as G from '../../styles/global.styles'

export interface FullColumnImageProps {
  imageUrl: string
}

export const FullColumnImage = (props: FullColumnImageProps) => {
  const { imageUrl } = props
  return (
    <G.FullWidthWrapper>
      <G.FullWidthImg src={imageUrl} />
    </G.FullWidthWrapper>
  )
}
