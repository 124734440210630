import WagtailBlocksSection from '../src/components/wagtail/components'
import { WagtailPageProps } from '../src/services/api/types'
import { NextPage } from 'next'
import { newsletterProps, PAGE_TEMPLATE } from '../src/services/api/constants'
import { Newsletter } from '../src/components/newsletter'
import { NewsletterNewLayout } from '../src/components/newsletter/YouFitnessTemplate'

const HomePage: NextPage<{
  pageData: WagtailPageProps
}> = (props: { pageData: WagtailPageProps }) => {
  let { pageData } = props
  pageData.flexible_content?.forEach(it => (it.value.isHomePage = true, it.value.hide_you_plus_fitness = pageData.hide_you_plus_fitness))

  return (
    <>
      <WagtailBlocksSection
        blocks={pageData.flexible_content || []}
        template={pageData.template}
      />

      {pageData.template == PAGE_TEMPLATE.YOU_FITNESS ? (
        <NewsletterNewLayout {...newsletterProps} />
      ) : (
        <Newsletter {...newsletterProps} />
      )}
    </>
  )
}

export default HomePage
