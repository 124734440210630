import { WagtailBlockProps } from '../../services/api/types'
import { BlockTitle } from '../base'
import ResponsiveEmbededVideo from '../base/ResponsiveEmbededVideo'
import { BlockQuote } from '../blockquote'
import { FullColumnImage } from '../full-column-image'
import { GalleryRow } from '../gallery-row'
import { StandardContent } from '../standard-content'
import * as G from '../../styles/global.styles'

export interface BlogArticleProps {
  blocks?: WagtailBlockProps[]
  template?: String
}

const BlogArticle = ({ blocks, template }: BlogArticleProps) => {
  return (
    <>
      {blocks && blocks?.length > 0
        ? blocks?.map((block, idx) => {
            switch (block.type) {
              case 'image_gallery':
                return (
                  <G.Section spacing={4} key={idx}>
                    <GalleryRow
                      key={idx}
                      imgUrls={block.value.map((img: any) => img.image)}
                      itemsPerRow={
                        block.value.length % 3 === 0
                          ? 3
                          : block.value.length % 3
                      }
                    />
                  </G.Section>
                )
              case 'featured_image':
                return (
                  <G.Section spacing={4} key={idx}>
                    <FullColumnImage imageUrl={block.value} />
                  </G.Section>
                )
              case 'drop_quote':
                return (
                  <G.Section spacing={4} key={idx}>
                    <BlockQuote quote={block.value} />
                  </G.Section>
                )
              case 'subtitle':
                return <BlockTitle key={idx}>{block.value}</BlockTitle>
              case 'video_embed':
                return (
                  <G.Section spacing={4} key={idx}>
                    <ResponsiveEmbededVideo url={block.value.video_url} />
                  </G.Section>
                )
              case 'paragraph':
              default:
                return (
                  <G.Section spacing={4} key={idx}>
                    <StandardContent
                      key={idx}
                      template={template}
                      paragraphs={[{ type: 'text', text: block.value }]}
                    />
                  </G.Section>
                )
            }
          })
        : null}
    </>
  )
}

export default BlogArticle
