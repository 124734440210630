import { useState } from 'react'
import { Button, TextInput } from '../base'
import {
  FranchiseeSearchWrapper,
  FranchiseeSearchGroup
} from './SearchForm.styled'
import * as G from '../../styles/global.styles'

export interface FranchiseeSearchFormProps {
  searchPlaceHolder: string
  searchButtonText: string
  searchButtonClick?: Function
  setSearchText?: Function
}

export const FranchiseeSearchForm = (props: FranchiseeSearchFormProps) => {
  const {
    searchButtonText,
    searchPlaceHolder,
    searchButtonClick,
    setSearchText
  } = props

  const [searchTerm, setSearchTerm] = useState('')

  const onSearch = (e?: any) => {
    e.preventDefault()
    searchButtonClick && searchButtonClick(searchTerm)
    setSearchText && setSearchText(searchTerm)
  }

  const textInputChanged = (newValue: any) => {
    setSearchTerm(newValue.target.value)
  }

  return (
    <FranchiseeSearchWrapper>
      <G.FormWrapper onSubmit={onSearch}>
        <FranchiseeSearchGroup>
          <TextInput
            color='black'
            className='mr-2 lg:mr-0'
            onChange={textInputChanged}
            placeholder={searchPlaceHolder}
          />
          <Button type='submit'>{searchButtonText}</Button>
        </FranchiseeSearchGroup>
      </G.FormWrapper>
    </FranchiseeSearchWrapper>
  )
}

export default FranchiseeSearchForm
