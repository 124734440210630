import * as G from '../../styles/global.styles'
import * as S from './GymSuburbs.styles'
import { WagtailBlockProps } from '../../services/api/types'

export interface GymSuburbsProps {
  name: string
  suburbs?: string
}

export interface GymSuburbsAPIProps extends WagtailBlockProps {
  value: {
    suburbs?: string
    name: string
  }
}

export const GymSuburbs = (props: GymSuburbsProps) => {
  const { name, suburbs } = props

  return name && suburbs ? (
    <G.Section spacing={12}>
      <G.FullWidthWrapper>
        <G.Container contentSize='lg'>
          <G.Card>
            <G.CardHeader separator>
              Plus Fitness {name} Servicing Suburbs
            </G.CardHeader>
            <G.CardBody>
              <S.SuburbText>
                Plus Fitness {name} services the fitness needs of all members in
                the suburbs of {suburbs}. Let us help you achieve your fitness
                goals.
              </S.SuburbText>
            </G.CardBody>
          </G.Card>
        </G.Container>
      </G.FullWidthWrapper>
    </G.Section>
  ) : null
}

GymSuburbs.ApiBlockToProps = (data: GymSuburbsAPIProps) => {
  const { value } = data
  if (value) {
    let props: GymSuburbsProps = {
      name: value.name,
      suburbs: value.suburbs
    }
    return props
  }
}

export default GymSuburbs
