import WagtailBlocksSection from '../src/components/wagtail/components'
import { PAGE_TEMPLATE } from '../src/services/api/constants'
import { WagtailPageProps } from '../src/services/api/types'
import * as G from '../src/styles/global.styles'

const ClassPage = (props: { pageData: WagtailPageProps }) => {
  const { pageData } = props
  const isYouFitness = pageData.template === PAGE_TEMPLATE.YOU_FITNESS

  return isYouFitness ? (
    <main style={{ paddingTop: '5rem' }} className='bg-black__deep'>
      {pageData && pageData.flexible_content && (
        <WagtailBlocksSection
          blocks={pageData.flexible_content}
          template={pageData.template}
        />
      )}
    </main>
  ) : (
    <G.Container contentSize='lg' className='mb-20'>
      {pageData && pageData.flexible_content && (
        <WagtailBlocksSection
          blocks={pageData.flexible_content}
          template={pageData.template}
        />
      )}
    </G.Container>
  )
}

export default ClassPage
