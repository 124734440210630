import * as S from './TrainingOptions.styles'
import * as G from '../../styles/global.styles'
import { WagtailBlockProps } from '../../services/api/types'
import { BlockTitle } from '../base'

export interface TrainingOption {
  title?: string
  imgUrl: string
  description?: string
}

export interface TrainingOptionsProps {
  title: string
  subtext: string
  blocks: TrainingOption[]
}

export interface TrainingOptionsAPIProps extends WagtailBlockProps {
  value: {
    title: string
    subtitle: string
    classes: [
      {
        logo: string
        class_name: string
        description: string
      }
    ]
  }
}

export const TrainingBlock = (props: TrainingOption) => {
  const { imgUrl, title, description } = props
  return (
    <S.Block>
      <S.ImgWrapper>
        <S.IMG src={imgUrl} alt={title} />
      </S.ImgWrapper>
      {title && (
        <S.TitleWrapper>
          <G.Text fontWeight='bold' color='white' size='2xl'>
            {title}
          </G.Text>
        </S.TitleWrapper>
      )}
      {description && <G.Text color='white'>{description}</G.Text>}
    </S.Block>
  )
}

export const TrainingOptions = (props: TrainingOptionsProps) => {
  const { title, subtext, blocks } = props
  return (
    <G.Section bgColor='blue-dark' spacing={16}>
      <G.CenteredContainer contentSize='xl'>
        {title && (
          <BlockTitle textColor='orange' underlineColor='blue'>
            {title}
          </BlockTitle>
        )}
        {subtext && (
          <S.TitleWrapper>
            <G.Text size='xl' color='white' fontStyle='italic'>
              {subtext}
            </G.Text>
          </S.TitleWrapper>
        )}
        <S.ContentWrapper>
          {blocks &&
            blocks.map((block, idx) => <TrainingBlock key={idx} {...block} />)}
        </S.ContentWrapper>
      </G.CenteredContainer>
    </G.Section>
  )
}

TrainingOptions.ApiBlockToProps = (
  data: TrainingOptionsAPIProps
): TrainingOptionsProps => {
  const { value } = data
  return {
    title: value.title,
    subtext: value.subtitle,
    blocks: value.classes.map(item => {
      return {
        imgUrl: item.logo,
        title: item.class_name,
        description: item.description
      }
    })
  }
}
