import styled from 'styled-components'
import tw from 'twin.macro'
import { size } from '../../../styles/theme'

export const Person = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`

export const Avatar = styled.div`
  ${tw`relative block cursor-pointer`}
  transition: all 0.3s ease;
`
export const PersonContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const VideoPlayerContainer = styled.div<{ avatarImgURL?: string }>`
  ${tw`w-full`}
  background: url(${props => props.avatarImgURL}) center top / cover no-repeat;
  height: 435px;
  max-height: 435px;
  
  > div {
    height: 100%;
  }

  .react-player {
    height: 100% !important;
  }
`

export const PersonInfo = styled.div`
  ${tw`relative text-center font-display`}
  width: 100%;
`

export const PersonName = styled.h4`
  ${tw`bg-white text-center text-sm font-bold uppercase py-1`}
  padding: 0.5rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ReadButton = styled.a`
  ${tw`bg-blue__deep py-2.5 font-medium px-8 text-sm text-white transition-colors duration-300 inline-flex items-center uppercase font-bold`}
  line-height: 1.5;
  font-size: 1.375rem;

  figure {
    width: ${size(14)};
    height: ${size(14)};
    ${tw`text-sm mr-2.5 mb-0`}
  }

  &:hover {
    ${tw`bg-blue__dark2`}
  }
`

export const PlayButton = styled.img`
  ${tw`absolute z-30 object-contain`}
  width: 76px;
  margin: 0 auto;
  text-align: center;
  height: 76px;
  top: 40%;
  left: 0;
  right: 0;
  transform: translateY(-50%);

  :hover {
    cursor: pointer;
  }
`
