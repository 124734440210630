import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import WagtailBlocksSection from '../src/components/wagtail/components'
import {
  WagtailPageProps,
  ClassIndexPageProps,
  PageContext
} from '../src/services/api/types'
import { NextPage } from 'next'
import { cms } from '../src/services/api'
import { Classes, ClassProps } from '../src/components/classes'
import ClassesYouFitness from '../src/components/classes/YouFitnessTemplate/Classes'
import * as G from '../src/styles/global.styles'
import {
  generalItemsPerPage,
  PAGE_TEMPLATE
} from '../src/services/api/constants'

interface QueryParamsProps {
  type: string
  category?: number
  limit: number
  offset?: number
  fields: string
}

interface ClassIndexAPIItemProps {
  id: number
  meta: {
    type: string
    detail_url: string
    html_url: string
    slug: string
    first_published_at: string
  }
  title: string
  preamble: string
  image: {
    url: string
    width: number
    height: number
  }
}

const ClassIndex: NextPage<{
  pageData: ClassIndexPageProps
  initialData?: any
}> = (props: { pageData: ClassIndexPageProps; initialData?: any }) => {
  const {
    pageData: {
      categories,
      title,
      flexible_content,
      footer_flexible_content,
      template
    },
    initialData
  } = props

  const parseClassData = (classData: any) => {
    const newClasseData =
      classData && classData.items
        ? classData.items.map(
            (item: ClassIndexAPIItemProps): ClassProps => {
              return {
                imgUrl: item.image && item.image.url,
                description: item.preamble,
                title: item.title,
                link: item.meta.html_url
              }
            }
          )
        : []
    return newClasseData
  }

  const parseCategoryData = (
    categoryData?: { name: string; slug: string; id: number }[]
  ) => {
    if (categoryData && categoryData.length > 0) {
      return categoryData.map(category => {
        return {
          label: category.name,
          value: category.id.toString()
        }
      })
    }
    return []
  }

  const [classItems, setClassItems] = useState(parseClassData(initialData))
  const [totalCount, setTotalCount] = useState(initialData?.meta?.total_count)
  const [category, setCategory] = useState(1)
  let queryParams: QueryParamsProps = {
    type: `cms.ClassPage`,
    fields: `image,preamble`,
    limit: generalItemsPerPage,
    offset: classItems.length || 0
  }

  const onFilterByCategoryChange = async (selectedFilterBy: any) => {
    await setCategory(selectedFilterBy.value)
    setClassItems([])
  }

  const loadFunc = async () => {
    const newParams = {
      ...queryParams,
      category: category
    }
    const res = await cms.getClassData(newParams)
    if (res && res.meta && res.meta.total_count) {
      await setTotalCount(res.meta.total_count)
    }
    setClassItems(classItems.concat(parseClassData(res)))
  }

  const isYouFitness = template === PAGE_TEMPLATE.YOU_FITNESS

  let classCategories = parseCategoryData(categories)
  classCategories.unshift({ label: '-------', value: '' })

  return (
    <>
      <div className={`${isYouFitness ? 'bg-black__deep' : ''} w-full`}>
        {flexible_content && (
          <WagtailBlocksSection blocks={flexible_content} template={template} />
        )}
        <InfiniteScroll
          pageStart={0}
          loadMore={loadFunc}
          hasMore={classItems.length < totalCount}
          loader={
            <G.SpinnerWrap isYouFitness={isYouFitness}>
              <G.Spinner />
            </G.SpinnerWrap>
          }
        >
          {isYouFitness ? (
            <ClassesYouFitness
              cards={classItems}
              title={title}
              categories={classCategories}
              onFilterChange={onFilterByCategoryChange}
            />
          ) : (
            <Classes
              cards={classItems}
              title={title}
              categories={classCategories}
              onFilterChange={onFilterByCategoryChange}
            />
          )}
        </InfiniteScroll>
        {footer_flexible_content && (
          <WagtailBlocksSection
            blocks={footer_flexible_content}
            template={template}
          />
        )}
      </div>
    </>
  )
}

ClassIndex.getInitialProps = async (ctx: PageContext) => {
  let props: { pageData: WagtailPageProps; initialData?: any } = {
    pageData: ctx.pageData
  }

  const data = await cms.getClassData(
    {
      type: `cms.ClassPage`,
      fields: `image,preamble`,
      limit: generalItemsPerPage
    },
    ctx
  )

  props.initialData = data
  return props
}

export default ClassIndex
