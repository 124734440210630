import { useEffect, useState } from 'react'
import { WagtailBlockProps } from '../../services/api/types'
import { Col, Container, Row } from '../../styles/grid.styles'
import { IconEnums, SVGIcon } from '../base'
import { Heading } from './Franchise.styled'
import * as S from './GymOwners.styled'

export interface Trainer {
  name: string
  description?: string
  image: string
  viewMore: () => void
}

const Trainer = ({ name, image, viewMore }: Trainer) => {
  return (
    <S.Trainer>
      <S.TrainerImage src={image} alt={name} />
      <S.TrainerName>{name}</S.TrainerName>
      <S.TrainerButton onClick={viewMore}>Read more</S.TrainerButton>
    </S.Trainer>
  )
}

export interface TrainerDetailsProps {
  trainer: Trainer
  onClose: () => void
}

const TrainerDetails = ({ trainer, onClose }: TrainerDetailsProps) => {
  const [isOpen, setOpen] = useState<boolean>(false)

  const toggleModal = () => {
    setOpen(!isOpen)
    onClose && onClose()
  }

  useEffect(() => {
    trainer && setOpen(true)
  }, [trainer])

  return (
    <S.TrainerDetails
      isOpen={isOpen}
      onBackgroundClick={toggleModal}
      onEscapeKeydown={toggleModal}
    >
      <S.CloseWrapper onClick={onClose}>
        <SVGIcon name={IconEnums.close} />
      </S.CloseWrapper>
      <Heading>{trainer.name}</Heading>
      <S.TrainerInfo>
        <S.TrainerAvatar>
          <img src={trainer.image} alt={trainer.name} />
        </S.TrainerAvatar>
        <S.TrainerDescription
          dangerouslySetInnerHTML={{ __html: trainer?.description || '' }}
        />
      </S.TrainerInfo>
      <S.ButtonWrap>
        <S.Button onClick={toggleModal}>Close</S.Button>
      </S.ButtonWrap>
    </S.TrainerDetails>
  )
}

export interface GymOwnersProps extends WagtailBlockProps {
  value: {
    headline: string
    gym_owners: {
      name: string
      image: string
      text: string
    }[]
  }
}

const GymOwners = (props: GymOwnersProps) => {
  const { headline, gym_owners } = props.value
  const trainers = gym_owners.map(it => {
    return {
      name: it.name,
      image: it.image,
      description: it.text
    } as Trainer
  })

  const [selectedTrainer, setSelectedTrainer] = useState<Trainer | null>()

  return (
    <>
      {selectedTrainer && (
        <TrainerDetails
          trainer={selectedTrainer}
          onClose={() => setSelectedTrainer(null)}
        />
      )}
      <S.GymOwners>
        <Container size='lg'>
          <Heading>{headline}</Heading>
          <Row gutter={30} tabletGutter={15}>
            {trainers.length > 0 &&
              trainers.map((trainer, key: number) => (
                <Col md={4} lg={key === 1 ? 6 : 3} key={key}>
                  <Trainer
                    image={trainer.image}
                    name={trainer.name}
                    viewMore={() => setSelectedTrainer(trainer)}
                  />
                </Col>
              ))}
          </Row>
        </Container>
      </S.GymOwners>
    </>
  )
}

export default GymOwners
