import { SocialCardProps, SocialIconCardProps } from './cards'
import * as S from './WhatsHot.styles'
import * as G from '../../../styles/global.styles'
import { BlockTitle, IconEnums } from '../../base'
import {
  Link,
  WagtailBlockProps,
  ButtonAPIProps
} from '../../../services/api/types'
import React from 'react'
import { Section, Container, Row, Col } from '../../../styles/grid.styles'
import { ArticleCard } from './cards'

export interface BlockProps {
  type: 'twitter' | 'article' | 'instagram' | 'social-icons'
  block: SocialCardProps
}

interface BlockRow {
  blocks: [BlockProps, BlockProps, BlockProps]
}

export interface WhatsHotProps {
  title?: string
  blocksRow?: BlockRow[]
  action: Link
}

export interface BlogAPIProps {
  title: string
  author: {
    name: string
    image: string
    biography: string
  }
  preamble: string
  date: string
  header_image?: string
  full_url: string
}
export interface WhatsHotAPIProps extends WagtailBlockProps {
  value: {
    cta_button: ButtonAPIProps
    latest_posts: BlogAPIProps[]
    social_media: {
      twitter: string
      facebook: string
      linkedin: string
      instagram: string
      youtube: string
      app: string
    }
  }
}

export const WhatsHotNewLayout = (props: WhatsHotProps) => {
  const {
    title,
    blocksRow,
    action: { label, url, target }
  } = props

  return (
    <Section>
      <Container fullWidthMobile>
        {title && <BlockTitle isYouFitness>{title}</BlockTitle>}
        <Row gutter={20} mobileGutter={0}>
          {blocksRow &&
            !!blocksRow.length &&
            blocksRow.map(data => {
              return (
                data &&
                data.blocks &&
                !!data.blocks.length &&
                data.blocks.map((value, idx) => {
                  return (
                    value.type == 'article' && (
                      <Col md={6} key={idx}>
                        <ArticleCard {...value.block} />
                      </Col>
                    )
                  )
                })
              )
            })}
        </Row>
        <S.ButtonWrap>
          <G.BorderButton href={url} target={target}>
            {label}
          </G.BorderButton>
        </S.ButtonWrap>
        <S.Socials>
          {blocksRow &&
            !!blocksRow.length &&
            blocksRow.map(
              data =>
                data &&
                data.blocks &&
                !!data.blocks.length &&
                data.blocks.map(
                  value =>
                    value.type === 'social-icons' &&
                    value.block.socials?.map((data: any, idx) => (
                      <S.SocialsLink href={data.url} key={idx}>
                        <S.Icon name={data.icon} />
                      </S.SocialsLink>
                    ))
                )
            )}
        </S.Socials>
      </Container>
    </Section>
  )
}

WhatsHotNewLayout.ApiBlockToProps = (data: WhatsHotAPIProps) => {
  const { value } = data
  const props: WhatsHotProps = {
    title: `What's hot`,
    action: {
      label: value.cta_button.button_text || 'Go to the blog',
      target: value.cta_button.button_link?.link_target,
      url:
        value.cta_button.button_link?.page_link ||
        value.cta_button.button_link?.external_link ||
        '#'
    },
    blocksRow: []
  }
  let blocks: BlockProps[] = []
  if (value.latest_posts && value.latest_posts.length > 0) {
    let i = 0
    for (i = 0; i < 2; i++) {
      if (value.latest_posts[i]) {
        let block: BlockProps = {
          type: 'article',
          block: {
            title: value.latest_posts[i].title,
            content: value.latest_posts[i].preamble,
            action: {
              label: 'Read article',
              url: value.latest_posts[i].full_url
            },
            imgURL: value.latest_posts[i].header_image
          }
        }
        blocks.push(block)
      }
    }
  }
  if (value.social_media) {
    let socialBlock: SocialIconCardProps = {
      socials: []
    }
    if (!!value.social_media.facebook) {
      socialBlock.socials?.push({
        icon: IconEnums['icon-fb'],
        url: value.social_media.facebook
      })
    }
    if (!!value.social_media.twitter) {
      socialBlock.socials?.push({
        icon: IconEnums['twitter-simple'],
        url: value.social_media.twitter
      })
    }
    if (!!value.social_media.instagram) {
      socialBlock.socials?.push({
        icon: IconEnums['icon-instagram'],
        url: value.social_media.instagram
      })
    }
    if (!!value.social_media.youtube) {
      socialBlock.socials?.push({
        icon: IconEnums['icon-youtube'],
        url: value.social_media.youtube
      })
    }
    if (!!value.social_media.app) {
      socialBlock.socials?.push({
        icon: IconEnums['icon-store'],
        url: value.social_media.app
      })
    }
    blocks.push({
      type: 'social-icons',
      block: socialBlock
    })
  }
  props.blocksRow = [{ blocks: [blocks[0], blocks[1], blocks[2]] }]
  return props
}

export default WhatsHotNewLayout
