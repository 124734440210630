import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { size } from '../../styles/theme'

export const VideoWrapper = styled.div<{
  thumbURL?: string
  isShowTwoVideo?: boolean
  isHomePage?: boolean
}>`
  ${tw`relative w-full lg:flex`}

  ${({ isShowTwoVideo }) =>
    isShowTwoVideo ? '' : tw`flex-wrap self-center`}

  & > img {
    width: 100%;
  }

  @media (min-width: 1026px) {
    ${tw`bg-blue`}
    ${props =>
      props.thumbURL &&
      css`
        background: url(${props.thumbURL}) center/cover no-repeat;
      `}
  }
`

export const PlayerWrapper = styled.div<{
  thumbURL?: string
  isShowTwoVideo?: boolean
}>`
  ${tw`relative bg-blue flex flex-col mb-1 justify-center items-center lg:w-1/2 lg:mb-0 lg:border lg:border-white`}
  min-height: 20rem;
  ${props =>
    !props.isShowTwoVideo &&
    css`
      @media (min-width: 1024px) {
        min-height: 36rem;
      }
    `}
  ${props => !props.isShowTwoVideo && tw`lg:w-full lg:border-none`}

  ${props =>
    props.thumbURL &&
    css`
      background: url(${props.thumbURL}) center/cover no-repeat;
    `}
`

export const PlayerWrapperNewLayout = styled.div<{
  thumbURL?: string
  isShowTwoVideo?: boolean
  playing?: boolean
}>`
  ${tw`relative flex flex-col justify-center items-center w-full`}
  height: ${size(268)};
  
   @media (max-width: 768px) {
        height: ${size(226)};
        margin: 0;
      }

  ${props =>
    !props.isShowTwoVideo &&
    css`
      // @media (max-width: 480px) {
      //   height: ${size(226)};
      // }
    `}
  ${props => !props.isShowTwoVideo && tw`lg:w-full lg:border-none`}

  ${props =>
    props.thumbURL &&
    css`
      background: url(${props.thumbURL}) center/cover no-repeat;
    `}
`

export const VideoTitle = styled.h2`
  text-shadow: 2px 0px 6px #0b0c4d;
  ${tw`text-white font-bold text-center text-2xl mb-4`}
`

export const VideoDescription = styled.p`
  ${tw`text-white font-medium text-center text-base uppercase max-w-sm`}
`

export const PlayButtonWrap = styled.a`
  ${tw`m-4 text-center relative`}

  @media (max-width: 480px) {
    ${tw`absolute`}
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const PlayButtonWrapNewLayout = styled.a`
  ${tw`m-0 text-center relative`}

  @media (max-width: 480px) {
    ${tw`absolute`}
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    width: 70px;
    height: 70px;
  }
`

export const ReactPlayerWrapper = styled.div<{ active?: boolean }>`
  ${tw`absolute top-0 w-full h-full hidden`}

  ${props =>
    props.active &&
    css`
      ${tw`block`}
    `}

  .react-player {
    ${tw`absolute top-0 left-0 w-full h-full`}
    width: 100% !important;
  }
`

export const VideoThumb = styled.img`
  ${tw`hidden lg:block`}
`

export const GymVideoWrap = styled.div`
  ${tw`flex w-full`}

  @media (max-width: 768px) {
    ${tw`flex-col`}
  }
`

export const GymVideoBlock = styled.div`
  width: 50%;
  ${tw`flex justify-end flex-col`}  
  margin: 0 12px;
  
  @media (max-width: 768px) {
    ${tw`w-full`}  
    margin: 0;
    margin-bottom: ${size(60)};
    
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`
