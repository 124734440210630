import React, { useEffect, useRef, useState } from 'react'
import {
  ImageLink,
  WagtailBlockProps,
  Colors,
  LAYOUT_TEMPLATE
} from '../../services/api/types'
import * as G from '../../styles/global.styles'
import * as S from './MainSection.styles'
import { BlockTitle } from '../base'
import {
  maximumParagraphInShortContent,
  PAGE_TEMPLATE
} from '../../services/api/constants'
import ReactDOM from 'react-dom'

export interface MainSectionProps {
  title?: string
  content?: string
  images?: ImageLink[]
  contentAlign?: 'left' | 'center' | 'right'
  background_colour?: Colors
  template?: LAYOUT_TEMPLATE
  viewMoreLink?: boolean
}

export interface PromoBannerAPIProps {
  image: string
  banner_link: {
    link_target: string
    external_link: string
    page_link: string
    document_link: string
  }
  image_alt_text?: string
  title?: string
  title_large_text?: string
  banner_link_text?: string
}
export interface MainSectionAPIProps extends WagtailBlockProps {
  value: {
    description?: string
    promo_banners?: PromoBannerAPIProps[]
    title?: string
    content?: string
    headline?: string
    content_alignment?: {
      content_alignment: 'left' | 'center' | 'right'
    }
    background_colour: {
      background_colour?: Colors
    }
    view_more_link?: boolean
  }
}

const MainSection = (props: MainSectionProps) => {
  const {
    content,
    images,
    title,
    contentAlign,
    background_colour,
    template,
    viewMoreLink
  } = props

  const contentRef = useRef(null)

  const [expanded, setExpanded] = useState(false)
  const [isLessText, setIsLessText] = useState(true)

  const handleClickToggle = () => {
    if (expanded) {
      if (contentRef && contentRef.current) {
        const node = ReactDOM.findDOMNode(contentRef.current) as Element
        node.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
    setExpanded(!expanded)
  }

  useEffect(() => {
    setIsLessText(false)
    //Check if the content is short by count the paragraph in it
    if (content && content?.length < maximumParagraphInShortContent) {
      setIsLessText(true)
    }
  }, [content])

  return (
    <G.Section
      spacing={12}
      bgColor={
        template === PAGE_TEMPLATE.YOU_FITNESS
          ? 'black-deep'
          : background_colour
      }
    >
      <S.Container>
        {title && (
          <S.TitleWrapper>
            <BlockTitle
              textTransform='uppercase'
              textColor={
                template === PAGE_TEMPLATE.YOU_FITNESS ? 'white' : undefined
              }
            >
              {title}
            </BlockTitle>
          </S.TitleWrapper>
        )}
        <S.ContentWrapper
          isHaveImages={images && images.length > 0}
          alignContent={contentAlign}
        >
          {content && (
            <S.ContentContainer
              style={{
                color:
                  template === PAGE_TEMPLATE.YOU_FITNESS ? 'white' : undefined
              }}
              dangerouslySetInnerHTML={{
                __html:
                  !viewMoreLink || isLessText || expanded
                    ? content
                    : `${content.slice(0, maximumParagraphInShortContent)}...`
              }}
              ref={contentRef}
            />
          )}
          {!isLessText && viewMoreLink && (
            <S.ReadMoreText onClick={() => handleClickToggle()}>
              {expanded ? '<< View less' : 'View more >>'}
            </S.ReadMoreText>
          )}
        </S.ContentWrapper>
        {images && (
          <S.ImagesWrapper>
            {images.map((img, idx) =>
              img.url ? (
                <S.Link href={img.url} key={idx} target='_blank'>
                  <S.IMG src={img.imageUrl} alt={img.imageAlt || ''} />
                </S.Link>
              ) : (
                <S.IMG src={img.imageUrl} alt={img.imageAlt || ''} />
              )
            )}
          </S.ImagesWrapper>
        )}
      </S.Container>
    </G.Section>
  )
}
MainSection.ApiBlockToProps = (data: MainSectionAPIProps): MainSectionProps => {
  const { value, type } = data
  switch (type) {
    case 'title_and_richtext':
      return {
        content: value.content,
        title: value.headline,
        contentAlign: value.content_alignment?.content_alignment
      }

    default:
      return {
        content: value.description,
        title: value.title,
        images:
          value.promo_banners &&
          value.promo_banners.map(item => {
            return {
              imageUrl: item.image,
              url:
                item.banner_link.page_link ||
                item.banner_link.external_link ||
                item.banner_link.document_link ||
                '',
              imageAlt: item.image_alt_text,
              imageUrlTarget: item.banner_link.link_target,
              title: item.title,
              title_large_text: item.title_large_text,
              banner_link_text: item.banner_link_text
            }
          }),
        background_colour: value.background_colour.background_colour
      }
  }
}
export default MainSection
