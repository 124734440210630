import React, { CSSProperties } from 'react'
import { Link, WagtailBlockProps } from '../../services/api/types'
import { ButtonLink } from '../base'
import { LazyImage } from '../lazy-image'
import { useInView } from 'react-intersection-observer'
import * as S from './Banner.styled'
import * as G from '../../styles/global.styles'

export interface BannerProps {
  imageURL: string
  title: string
  content: string
  action: Link
  styles?: CSSProperties
}

export interface BannerAPIProps extends WagtailBlockProps {
  value: {
    image: {
      image: string
    }
    title: string
    title_large_text: string
    banner_link_text: string
    banner_link: {
      link_target: string
      external_link: string
      page_link: string
      document_link: string
    }
  }
}
export const Banner = (props: BannerProps) => {
  const {
    imageURL,
    title,
    content,
    styles,
    action: { url, label }
  } = props
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  })
  return (
    <G.Section style={styles}>
      <G.FlexContainer position='relative' contentSize='lg'>
        <S.DesktopView>
          <LazyImage src={imageURL} alt={title} width='100%' />
          <S.ContentWrapper className='hover:bg-orange hover:bg-opacity-75'>
            <S.BannerTitle color='white' size='4xl' fontWeight='bold' textCase='upper'>
              {title}
            </S.BannerTitle>
            <S.Content>{content}</S.Content>
            <ButtonLink href={url} color='blue'>
              {label}
            </ButtonLink>
          </S.ContentWrapper>
        </S.DesktopView>
        <S.MobileView
          ref={ref}
          style={{
            backgroundImage: inView ? `url('${imageURL}')` : ''
          }}
        >
          <S.MobileContainer className='bg-opacity-75 bg-orange'>
            <S.BannerTitle color='white' size='4xl' fontWeight='bold' textCase='upper'>
              {title}
            </S.BannerTitle>
            <S.Content>{content}</S.Content>
            <S.ButtonWrapper>
              <ButtonLink href={url} color='blue'>
                {label}
              </ButtonLink>
            </S.ButtonWrapper>
          </S.MobileContainer>
        </S.MobileView>
      </G.FlexContainer>
    </G.Section>
  )
}

Banner.ApiBlockToProps = (data: BannerAPIProps) => {
  //return default props for test, will implement data from API later
  const { value } = data
  if (data.value) {
    let props: BannerProps = {
      imageURL: value.image.image,
      title: value.title,
      content: value.title_large_text,
      action: {
        label: value.banner_link_text,
        url:
          value.banner_link.page_link ||
          value.banner_link.document_link ||
          value.banner_link.external_link ||
          '#'
      }
    }
    return props
  }
}

export default Banner
