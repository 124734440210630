import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import * as G from '../../styles/global.styles'

export const TitleWrapper = styled(G.Container)`
  ${tw`flex justify-center content-center flex-col items-center py-8`}
`
export const ContentWrapper = styled.div<{
  contentAlign?: 'left' | 'center' | 'right'
}>`
  ${tw`w-full flex flex-wrap`}
  ${({ contentAlign }) => {
    switch (contentAlign) {
      case 'center':
        return tw`text-center justify-center content-center`
      case 'left':
        return tw`text-left`
      case 'right':
        return tw`text-right`
    }
  }}
`
export const IMG = styled.img<{
  hasMobile?: boolean
  isMobile?: boolean
}>`
  ${tw`w-full`}

  ${({ hasMobile }) =>
    hasMobile &&
    css`
      display: none !important;
      @media (min-width: 1024px) {
        display: block !important;
      }
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: none !important;
      @media (max-width: 1023px) {
        display: block !important;
      }
    `}
`
export const Link = styled.a`
  ${tw`max-w-full`}
`
