import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { ButtonLink } from '../base'
import * as G from '../../styles/global.styles'
import { Colors } from '../../services/api/types'

export interface DownloadPDFProps {
  color?: Colors
  url: string
  text?: string
}

const DownloadPDF = ({ color, url, text }: DownloadPDFProps) => {
  return (
    <G.CenteredContainer>
      <ButtonLink color={color || 'orange'} href={url}>
        <FontAwesomeIcon icon={faDownload} />
        &nbsp;&nbsp;{text || 'DOWNLOAD THE PDF'}
      </ButtonLink>
      <G.Space />
    </G.CenteredContainer>
  )
}

export default DownloadPDF
