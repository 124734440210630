import styled from 'styled-components'
import tw from 'twin.macro'

export const Container = styled.div`
  ${tw`container mx-auto`}
`

export const NewsletterWrapper = styled.div`
  ${tw`w-full flex flex-wrap self-center bg-blue p-4 lg:flex-no-wrap lg:flex-row`}
`

export const NewsletterContent = styled.div<{ full?: boolean }>`
  ${tw`flex-col flex-grow-0`}
  ${props => (props.full ? tw`lg:w-full` : tw`lg:w-1/2`)}
`

export const NewsletterTitle = styled.h2<{ center?: boolean }>`
  ${tw`mb-4 text-white text-4xl font-bold font-display`}
  ${props => props.center && tw`flex justify-center`}
`

export const NewsletterMessage = styled.p<{ center?: boolean }>`
  ${tw`mb-4 text-left text-white`}
  ${props => props.center && tw`flex justify-center`}
`

export const Form = styled.form`
${tw`w-full lg:w-1/2`}
`

export const ErrorMessageText = styled.span`
  ${tw`text-white`}
`

export const SubscribeFormWrap = styled.div`
  ${tw`justify-center flex-grow`}

  & > input, & > button {
    ${tw`w-full my-2`}
  }

  & > input {
    ${tw`bg-blue border-white`}
  }
`


