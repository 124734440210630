import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { Section } from '../../styles/grid.styles'

export const GetStarted = styled(Section)`
  ${tw`pt-20`}
`

export const Description = styled.div`
  ${tw`text-white text-lg md:text-1.5xl mb-8 lg:mb-0`}
`

export const ButtonsList = styled.div`
  ${tw`w-full flex flex-wrap content-center xl:justify-end`}

  > div:last-child {
    ${tw`md:ml-5`};
  }

  @media (max-width: 767px) {
    > div {
      ${tw`w-full`};
    }
  }
`

const buttonStyle = css`
  ${tw`mb-4 md:mb-5 lg:mb-0 w-full md:w-auto uppercase font-extrabold font-youfitnessHeading text-2xl text-white flex justify-start items-center bg-blue__deep hover:bg-blue__dark2 transition duration-300 px-8 py-4 leading-none`}
  min-height: 78px;

  @media (max-width: 767px) {
    ${tw`justify-center`};
  }
`

export const ButtonIcon = styled.div`
  ${buttonStyle}
`

export const ButtonIconLink = styled.a`
  ${buttonStyle}
`

export const ImgWrapper = styled.div`
  ${tw`mr-4`}

  img {
    ${tw`h-8`}
  }
`
