import styled from 'styled-components'
import tw from 'twin.macro'
import * as G from '../../../styles/global.styles'

export const AngledBannerWrapper = styled.div<{ src: string }>`
  position: relative;
  ${tw`w-full md:px-10 md:py-16 lg:px-16 lg:py-32 bg-cover bg-no-repeat bg-top flex items-center justify-center content-center`}
  ${({ src }) => {
    return `background-image: url('${src}');`
  }}
`

export const Overlay = styled.div<{
  img?: string
}>`
  ${tw`w-full h-full absolute`}

  ${({ img }) => {
    return `
    background: url(${img});
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 1500px) {
      background-size: contain;
      background-repeat: repeat;
    }
    `
  }}
`

export const AngledBG = styled.div<{ color: string }>`
  mix-blend-mode: multiply;
  z-index: 0;
  ${tw`transform w-full h-full md:-skew-y-3 absolute`}
  background-color: rgba(0, 0, 0, 0.8);
`

export const AngledContent = styled.div`
  ${tw`w-full h-full p-4 p-10 md:p-16 lg:p-24 flex flex-col items-center justify-center content-center relative`}
`

export const AngledLogo = styled.img`
  width: 180px;
  height: auto;
  ${tw`relative z-10 mb-5 md:mb-8`}
`

export const AngledTitle = styled.h2`
  ${tw`w-full text-white leading-tiny md:leading-none text-center font-youfitnessHeading text-2xl md:text-5xl flex justify-center uppercase font-bold mb-2.5 md:mb-4 relative`}
  letter-spacing: 5px;
`

export const AngledText = styled.div`
  ${tw`text-sm text-white text-center relative z-10 uppercase mb-5 md:mb-10`}

  p {
    ${tw`mb-0`}
  }
`

export const ButtonWrapper = styled.div`
  ${tw`py-0 relative z-10 text-center`}

  a {
    ${tw`mx-2`}
  }
`

export const Button = styled(G.ButtonLink)`
  ${tw`not-italic capitalize px-8 text-sm`}
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  line-height: 1.5;
`


