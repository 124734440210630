import React from 'react'
import { useInView } from 'react-intersection-observer'
import { LazyImage } from '../../lazy-image'
import * as S from './Advertisement.styled'
import { WagtailBlockProps, ButtonProps } from '../../../services/api/types'
import phoneFrame from '../../../static/imgs/phone-frame.png'
import logoPlus from '../../../static/imgs/logo_plus.png'
import { Container } from '../../../styles/grid.styles'

export interface AdvertisementProps {
  bgImageURL: string
  imageURL: string
  title: string
  bigTitle: string
  text: string
  button?: ButtonProps
  second_button?: ButtonProps
}

export interface AdvertisementAPIProps extends WagtailBlockProps {
  value: {
    image: string
    background_image: string
    overlay_colour: string
    title: string
    title_large_text: string
    subtext: string
    banner_link_text: string
    banner_link: {
      link_target: string
      external_link: string
      page_link: string
      document_link: string
    },
    second_banner_link_text: string,
    second_banner_link: {
      link_target: string
      external_link: string
      page_link: string
      document_link: string
    }
  }
}

export const AdvertisementNewLayout = (props: AdvertisementProps) => {
  const { title, bigTitle, text, button, bgImageURL, imageURL, second_button } = props
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  })

  return (
    <S.AdvertisementWrap ref={ref} bgImage={inView ? bgImageURL : ''}>
      <Container>
        <S.Advertisement>
          <S.ImageWrapper>
            <LazyImage
              src={phoneFrame}
              alt={title}
              width='100%'
              className={'frame'}
            />
            <LazyImage
              src={imageURL}
              alt={title}
              width={'100$'}
              className={'main-img'}
            />
            <LazyImage
              src={logoPlus}
              alt={title}
              width={'100$'}
              className={'logo-plus'}
            />
          </S.ImageWrapper>
          <S.Details>
            <S.Title>{title}</S.Title>
            <S.BigTitle>{bigTitle}</S.BigTitle>
            <S.Content>{text}</S.Content>
            <S.ButtonGroup>
            {button && (
              <S.Button href={button.href} target={button.target}>
                {button.label}
              </S.Button>
            )}
            {second_button && second_button.label && (
              <S.Button href={second_button.href} target={second_button.target}>
                {second_button.label}
              </S.Button>
            )}
            </S.ButtonGroup>
          </S.Details>
        </S.Advertisement>
      </Container>
    </S.AdvertisementWrap>
  )
}

AdvertisementNewLayout.ApiBlockToProps = (data: AdvertisementAPIProps) => {
  const { value } = data
  if (value) {
    let props: AdvertisementProps = {
      bgImageURL: value.background_image,
      imageURL: value.image,
      title: value.title,
      bigTitle: value.title_large_text,
      text: value.subtext,
      button: value.banner_link && {
        label: value.banner_link_text,
        href:
          value.banner_link.external_link ||
          value.banner_link.page_link ||
          value.banner_link.document_link ||
          '#',
        color: 'blue',
        target: value.banner_link.link_target
      },
      second_button: value.second_banner_link && {
        label: value.second_banner_link_text,
        href:
          value.second_banner_link.external_link ||
          value.second_banner_link.page_link ||
          value.second_banner_link.document_link ||
          '#',
        color: 'blue',
        target: value.second_banner_link.link_target
      }
    }
    return props
  }
}

export default AdvertisementNewLayout
