import { useState, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faTimes,
  faChevronDown,
  faChevronUp
} from '@fortawesome/free-solid-svg-icons'
import * as S from './Menu.styled'
import { NewsSearchForm } from '../../search'
import {
  WagtailBlockProps,
  MenuBlockItem,
  LAYOUT_TEMPLATE
} from '../../../services/api/types'
import { MenuContext } from '../../../services/context/MenuContext'
import { useContext } from 'react'
import { PAGE_TEMPLATE } from '../../../services/api/constants'

export interface MenuItemAPIProps {
  id: number
  name: string
  slug: string
  posts_in_category: number
}

export interface MenuAPIProps extends WagtailBlockProps {
  value: {
    categories: MenuItemAPIProps[]
    isSearch?: boolean
    menuClick?: Function
    onSearchAction?: Function
  }
}

export interface MenuBlocksProps {
  menuItems: MenuBlockItem[]
  bgCorlor?: 'blue' | 'grey-light'
  isSearch?: boolean
  hideNav?: boolean
  menuClick?: Function
  onSearchAction?: Function
  hideMenu?: boolean
  pageName?: string
  joinUrl?: string
  popup_form?: boolean
}

export const Menu = (props: MenuBlocksProps) => {
  const {
    menuItems,
    isSearch,
    menuClick,
    onSearchAction,
    hideMenu,
    pageName,
    joinUrl
  } = props
  const [displaySearch, setDisplaySearch] = useState(false)
  const [currentActiveItem, setCurrentActiveItem] = useState(0)
  const [isFixedPosition, setIsFixedPosition] = useState(false)
  const [mobileShowed, setMobileShowed] = useState(false)
  const searchFormRef: any = useRef()
  const menuRef = useRef<HTMLElement>(null)
  const { navigationData } = useContext(MenuContext)
  const onItemClick = (item: MenuBlockItem) => {
    menuClick && menuClick(item)
    setCurrentActiveItem(item.id || 0)
  }

  const resetFilterCategoryAndSearch = (searchInput: string) => {
    setCurrentActiveItem(0)
    onSearchAction && onSearchAction(searchInput)
  }

  const showSearchBar = () => {
    setDisplaySearch(!displaySearch)
  }

  useEffect(() => {
    const offsetTop = menuRef.current && menuRef.current.offsetTop
    const navHeight = menuRef.current
      ? document.getElementById('nav')?.offsetHeight || 80
      : 80
    const handleScroll = () => {
      if (offsetTop && window.scrollY > offsetTop - navHeight - 1) {
        setIsFixedPosition(true)
      } else {
        setIsFixedPosition(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [menuRef])

  return (
    <>
      {isFixedPosition && <S.ArticleListFixedGlobalStyle />}
      <S.MenuWrapper
        isFixedPosition={isFixedPosition}
        hideNav={navigationData && navigationData.hidden}
        hideMenu={hideMenu}
        ref={menuRef}
      >
        {isSearch && (
          <S.MenuSearchWrapper fixedPosition={isFixedPosition}>
            <CSSTransition
              in={displaySearch}
              timeout={400}
              classNames='search-form'
              unmountOnExit
            >
              <S.AdditionalMenuStyled displaySearch={displaySearch}>
                <NewsSearchForm
                  displaySearch={displaySearch}
                  onSearchClick={resetFilterCategoryAndSearch}
                  ref={searchFormRef}
                  template={PAGE_TEMPLATE.YOU_FITNESS as LAYOUT_TEMPLATE}
                />
              </S.AdditionalMenuStyled>
            </CSSTransition>
          </S.MenuSearchWrapper>
        )}
        <S.MenuItems mobileShowed={mobileShowed}>
          {menuItems &&
            menuItems.map((item: MenuBlockItem, idx: number) => {
              return (
                <S.MenuItem
                  key={item.id || idx}
                  isActive={currentActiveItem === item.id}
                  onClick={() => onItemClick(item)}
                >
                  {item.label}
                </S.MenuItem>
              )
            })}
          {isSearch && (
            <S.MenuItem isSearchIcon onClick={showSearchBar}>
              {!displaySearch ? (
                <FontAwesomeIcon size='lg' icon={faSearch} />
              ) : (
                <FontAwesomeIcon size='lg' icon={faTimes} />
              )}
            </S.MenuItem>
          )}
        </S.MenuItems>
        <S.ButtonWrapper>
          <S.ButtonStyled
            color='blue-deep'
            onClick={() =>
              pageName && joinUrl && pageName === 'gyms'
                ? null
                : setMobileShowed(!mobileShowed)
            }
          >
            {mobileShowed ? (
              <>
                HIDE
                <FontAwesomeIcon icon={faChevronUp} className='ml-3' />
              </>
            ) : pageName && joinUrl && pageName === 'gyms' ? (
              <a href={joinUrl} target='_blank'>
                JOIN NOW
              </a>
            ) : (
              <>
                GO TO
                <FontAwesomeIcon icon={faChevronDown} className='ml-3' />
              </>
            )}
          </S.ButtonStyled>
          {isSearch && (
            <S.ButtonStyled color='blue-deep' onClick={showSearchBar}>
              <FontAwesomeIcon icon={faSearch} />
            </S.ButtonStyled>
          )}
        </S.ButtonWrapper>
      </S.MenuWrapper>
    </>
  )
}

Menu.ApiBlockToProps = (data: MenuAPIProps) => {
  const { value } = data
  return value
}

export default Menu
