import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const Heading = styled.h2<{ center?: boolean }>`
  ${tw`text-white font-youfitnessHeading leading-tiny text-2.5xl md:text-5xl mb-6 uppercase`}

  ${props =>
    props.center &&
    css`
      ${tw`text-center`}
    `}
`

export const Main = styled.main`
  padding-top: 80px;

  @media (max-width: 767px) {
    padding-top: 69px;
  }
`
