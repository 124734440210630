import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { PAGE_TEMPLATE } from '../../services/api/constants'
import { LAYOUT_TEMPLATE } from '../../services/api/types'

export const LocationTabItem = styled.a`
  ${tw`text-white p-2 text-center uppercase font-display hover:bg-orange active:bg-orange cursor-pointer`}
  min-width: 4rem;

  @media (max-width: 640px) {
    min-width: 2rem;
  }
`

export const LocationTabWrapper = styled.div<{ template?: LAYOUT_TEMPLATE }>`
  ${tw`relative w-full flex bg-blue justify-center flex-wrap`}

  &.active {
    ${tw`bg-orange`}
  }

  ${props =>
    props.template === PAGE_TEMPLATE.YOU_FITNESS &&
    css`
      ${tw`bg-blue__deep`}

      ${LocationTabItem} {
        ${tw`hover:bg-white active:bg-white active:text-blue__deep hover:text-blue__deep`}

        &.active {
          ${tw`text-blue__deep bg-white`}
        }
      }
    `}
`
