import styled from 'styled-components'
import tw from 'twin.macro'

export const Container = styled.div`
  ${tw`container mx-auto flex justify-end py-8 lg:py-12`}
`

export const ContentWrapper = styled.div`
  ${tw`w-full lg:w-2/3 flex flex-wrap px-4`}
`
export const Content = styled.div`
  ${tw`w-full lg:w-1/2 p-2 lg:p-4`}
`

export const LogosWrapper = styled.div`
  ${tw`w-full flex content-center justify-center items-center mt-8 lg:mt-8`}
`
export const Logo = styled.a`
  ${tw`p-2`}
`

export const LogoImg = styled.img`
  max-height: 110px;
`

export const ImgWrapper = styled.div`
  max-width: 350px;
  ${tw`hidden lg:flex lg:w-1/3 flex-col items-end relative`};
`

export const Img = styled.img`
  max-width: 100%;
  ${tw`flex-1 max-h-full  absolute left-0 bottom-0`}
`
