import styled from 'styled-components'
import tw from 'twin.macro'
import { SVGIcon } from '../../base'

export const ShareBlockSection = styled.div`
  ${tw`relative w-full font-display py-4 lg:flex lg:justify-center bg-black__deep`}
`

export const Icon = styled(SVGIcon)`
  ${tw`hover:text-orange text-white relative`}
`

export const ShareIconList = styled.ul`
  ${tw`flex items-center justify-around justify-center lg:w-1/2`}

  & > span {
    ${tw`font-medium text-lg`}
    color: #909090;
  }

  .title {
    margin-right: 10px;
  }
`

export const ShareIconItem = styled.li`
  ${tw`cursor-pointer `}
  margin: 0 10px;

  .link {
    ${tw`text-white bg-blue__deep relative rounded-full transition-colors duration-300 flex items-center justify-center hover:bg-blue__dark2`}
    width: 44px;
    height: 44px;
  }
`
