import * as G from '../../styles/global.styles'
import * as S from './ContentBlock.styled'
import {
  ButtonWithIconProps,
  WagtailBlockProps,
  ButtonAPIProps,
  ButtonProps,
  FirstContentBlock
} from '../../services/api/types'
import { ButtonLink } from '../base'
import { ButtonWithIcon } from '../base/ButtonWithIcon'
import { getPanelIconSet } from '../../services/api/helpers'
import Modal from '../modal/Modal'
import { GymSearchCard } from '../gym-search-card'

export interface ContentBlockAPIProps extends WagtailBlockProps {
  value: {
    headline: string
    featured_text: string
    standard_text: string
    cta_buttons: {
      cta_button: ButtonAPIProps[]
    }
  }
  id: string
}

export interface ContentBlockProps extends FirstContentBlock {
  headerText: string
  contentPrimayText?: string
  contentSecondaryText?: string
  iconButtons?: ButtonWithIconProps[]
  buttons?: ButtonProps[]
}

export const ContentBlock = (props: ContentBlockProps) => {
  const {
    headerText,
    contentPrimayText,
    contentSecondaryText,
    iconButtons,
    buttons,
    isFirstBlock
  } = props

  return (
    <G.Section spacing={16}>
      <G.Container contentSize='lg'>
        <G.Card>
          {isFirstBlock ? (
            <G.CardHeaderH1>{headerText}</G.CardHeaderH1>
          ) : (
            <G.CardHeader separator>{headerText}</G.CardHeader>
          )}
          <G.CardBody>
            {contentPrimayText && (
              <S.ContentBlockText
                fontBold
                color='orange'
                dangerouslySetInnerHTML={{ __html: contentPrimayText }}
              />
            )}
            {contentSecondaryText && (
              <S.ContentBlockText
                color='black'
                dangerouslySetInnerHTML={{ __html: contentSecondaryText }}
              />
            )}
          </G.CardBody>
          <G.CardFooter>
            {buttons &&
              buttons.map((btn, idx) => {
                if (btn.label === 'Join Now') {
                  return (
                    <Modal
                      buttonText={btn.label}
                      cancelText='Cancel'
                      isHeroButton={true}
                    >
                      <GymSearchCard />
                    </Modal>
                  )
                } else {
                  return (
                    <S.ButtonWrapper key={idx}>
                      <ButtonLink {...btn}>{btn.label}</ButtonLink>
                    </S.ButtonWrapper>
                  )
                }
              })}
            {iconButtons &&
              iconButtons.map((btn, idx) => (
                <ButtonWithIcon key={idx} {...btn} />
              ))}
          </G.CardFooter>
        </G.Card>
      </G.Container>
    </G.Section>
  )
}

ContentBlock.ApiBlockToProps = (
  data: ContentBlockAPIProps
): ContentBlockProps => {
  const apiData = data.value

  const { cta_buttons } = apiData
  let buttonWithIcons = new Array<ButtonWithIconProps>()
  let buttons = new Array<ButtonProps>()
  if (cta_buttons?.cta_button) {
    cta_buttons.cta_button.forEach(btn => {
      let ctaButton: ButtonProps = {
        label: btn.button_text,
        color: btn.button_colour,
        href:
          btn.button_link.document_link ||
          btn.button_link.external_link ||
          btn.button_link.page_link ||
          btn.button_link.anchor_link ||
          undefined,
        target: btn.button_link.link_target
      }

      if (btn.button_icon?.icon) {
        const iconSet = getPanelIconSet(btn.button_icon.icon)
        ctaButton
        buttonWithIcons.push({
          ...ctaButton,
          iconImgUrl: iconSet?.icon
        })
      } else {
        buttons.push(ctaButton)
      }
    })
  }

  return {
    headerText: apiData.headline,
    contentPrimayText: apiData.featured_text,
    contentSecondaryText: apiData.standard_text,
    iconButtons: buttonWithIcons,
    buttons: buttons,
    isFirstBlock: data.isFirstBlock
  }
}

export default ContentBlock
