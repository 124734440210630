import { useEffect, useState } from 'react'
import * as S from './GymOwnerBlock.styled'
import * as G from '../../styles/global.styles'
import { PersonBlock, PersonBlockProps } from './PersonBlock'
import { HeaderWithTitle } from '../header/HeaderWithTitle'
import circleFrame from '../../static/imgs/circle_grey.png'
import { Colors, GymOwnerBlockAPIProps } from '../../services/api/types'

export interface GymOwnerBlockProps {
  owners?: Array<PersonBlockProps>
  headline?: string
  subtext?: string
  background_colour?: Colors
}

export const GymOwnerBlock = (props: GymOwnerBlockProps) => {
  const [popupData, setPopupData] = useState<PersonBlockProps | null>()
  const { owners, headline, subtext, background_colour } = props
  const [isMobile, setIsMobile] = useState(false)
  const [isHorizontal, setIsHorizontal] = useState(false)

  useEffect(() => {
    checkScreen(window.innerHeight < window.innerWidth, 'init')

    // Listen for orientation changes
    window.addEventListener(
      'orientationchange',
      function() {
        // Announce the new orientation number
        checkScreen(!!window.orientation, 'orientationchange')
      },
      false
    )
    return () => {
      window.removeEventListener(
        'orientationchange',
        function() {
          // Announce the new orientation number
          checkScreen(!!window.orientation, 'orientationchange')
        },
        false
      )
    }
  }, [])

  const checkScreen = (hasHorizontal: boolean, type: string) => {
    const width =
      type == 'init'
        ? window.innerWidth
        : hasHorizontal
        ? window.innerWidth
        : window.innerHeight

    if (hasHorizontal) {
      setIsMobile(false)
      setIsHorizontal(type == 'init' ? false : true)
    } else if (width < 600) {
      setIsMobile(true)
      setIsHorizontal(false)
    } else {
      setIsMobile(false)
      setIsHorizontal(false)
    }
  }

  const openPopup = (trainer: PersonBlockProps) => {
    setPopupData(trainer)
  }

  const closePopup = () => {
    setPopupData(null)
  }

  useEffect(() => {
    document.body.style.overflow = !!popupData ? 'hidden' : ''
  }, [popupData])

  return (
    <G.Section bgColor={background_colour}>
      {popupData && (
        <S.PopupWrapper className='bg-black bg-opacity-75'>
          <S.PopupContainer>
            <S.Close aria-label='Close' onClick={closePopup}>
              ×
            </S.Close>
            <S.PopupContent>
              <S.PopupAvatarWrap content={popupData.intro}>
                <S.PopupAvatar
                  content={popupData.intro}
                  id='avatar'
                  src={popupData.avatarURL}
                  alt={popupData.name}
                />
              </S.PopupAvatarWrap>
              <S.PopupInfo>
                <S.Name color='orange' textCase='upper' fontWeight='bold'>
                  {popupData.name}
                </S.Name>
                {isMobile ? (
                  <G.Text
                    color='grey-medium'
                    dangerouslySetInnerHTML={{ __html: popupData.intro }}
                  />
                ) : (
                  <S.PopupInfoContent
                    isHorizontal={isHorizontal}
                    content={popupData.intro}
                  >
                    <G.Text
                      color='grey-medium'
                      dangerouslySetInnerHTML={{ __html: popupData.intro }}
                    />
                  </S.PopupInfoContent>
                )}
              </S.PopupInfo>
            </S.PopupContent>
          </S.PopupContainer>
        </S.PopupWrapper>
      )}
      <G.Container contentSize='lg'>
        <HeaderWithTitle
          headerText={`${headline}`}
          titleText={subtext}
          background_colour={background_colour}
        />
        <S.OwnerSection>
          {owners &&
            owners.map((own, idx) => {
              return (
                <S.OwnerBlock count={owners.length} key={idx}>
                  <PersonBlock
                    {...own}
                    avatarFrame={circleFrame}
                    viewMore={() => openPopup(own)}
                  />
                </S.OwnerBlock>
              )
            })}
        </S.OwnerSection>
      </G.Container>
    </G.Section>
  )
}

GymOwnerBlock.ApiBlockToProps = (
  data: GymOwnerBlockAPIProps
): GymOwnerBlockProps => {
  const apiData = data.value
  const { gym_owners } = apiData
  const ownerData = gym_owners?.map(owner => {
    const owerInfo = owner.text?.split('.')
    const owerExert = owerInfo.shift()
    return {
      avatarURL: owner.image,
      name: owner.name,
      bios: owner.bold_text,
      intro: owner.text,
      excert: `${owerExert}.`
    }
  })

  return {
    ...apiData,
    owners: ownerData,
    background_colour: apiData.background_colour.background_colour
  }
}

export default GymOwnerBlock
