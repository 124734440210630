import { WagtailBlockProps } from '../../services/api/types'
import { Col, Container, Row } from '../../styles/grid.styles'
import { Heading } from './Franchise.styled'
import * as S from './OurValues.styled'

export interface OurValuesProps extends WagtailBlockProps {
  value: {
    title: string
    content: string
    value_icons: {
      image: string
      heading: string
      sub_heading: string
    }[]
  }
}

const OurValues = (props: OurValuesProps) => {
  const { title, content, value_icons } = props.value

  return (
    <S.OurValues>
      <Container size='lg'>
        <Row gutter={30}>
          <Col lg={5}>
            <Heading>{title}</Heading>
            {content && (
              <S.Content dangerouslySetInnerHTML={{ __html: content }} />
            )}
          </Col>
          <Col lg={7}>
            <S.IconList>
              <Row gutter={30}>
                {value_icons.map((it, key) => (
                  <Col md={6} key={key}>
                    <S.Icon>
                      <S.IconImg src={it.image} alt={it.heading} />
                      <S.IconTitle>{it.heading}</S.IconTitle>
                      <S.IconSubTitle>{it.sub_heading}</S.IconSubTitle>
                    </S.Icon>
                  </Col>
                ))}
              </Row>
            </S.IconList>
          </Col>
        </Row>
      </Container>
    </S.OurValues>
  )
}

export default OurValues
