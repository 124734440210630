import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const Content = styled.div<{ featured?: boolean }>`
  ${tw`text-center text-sm mb-4 text-white__deep`}
  line-height: 1.65;

  ${props =>
    props.featured &&
    css`
      ${tw`uppercase`}
    `}
`
