import * as C from './ClubLocation.styled'
import * as G from '../../../styles/global.styles'
import address_bg from '../../../static/imgs/address-bg.jpeg'
import { IconEnums } from '../../base'
import { GymLocation, LAYOUT_TEMPLATE, Link } from '../../../services/api/types'
import { useContext, useEffect, useRef, useState } from 'react'
import ReactGA from 'react-ga';
import { SiteContext } from '../../../services/context/SiteContext'
import dynamic from 'next/dynamic'
import { YOU_FITNESS } from '../../../services/api/constants'
import {
  GoogleMapDirectionLink,
  GoogleMapPointLink
} from '../../../services/api/helpers'
import { ClubLocationAPIProps } from '../ClubLocation'
import { Container, Section } from '../../../styles/grid.styles'
import { Modal } from '../../modal'
import VirtualImg from '../../../static/imgs/icon-vtour-blue-big.png'

const LocationMap = dynamic(() => import('../../maps/LocationMap'), {
  ssr: false
})

export interface ClubLocationProps {
  prefix?: string
  name: string
  address: string
  suburb: string
  postcode: string
  phone?: string
  email?: string
  geo?: [number, number]
  directionAction?: Link
  distanceAction?: Link
  virtualTourAction?: Link
  mapPins?: Array<GymLocation>
  template?: LAYOUT_TEMPLATE
  description: string
  openTimes: {
    title: string
    times: {
      day: string
      days?: string
      times: string
    }[]
  }[]
  franchisee_email?: string
  show_franchisee_email?: boolean
}

export const ClubLocation = (props: ClubLocationProps) => {
  const {
    prefix,
    name,
    address,
    suburb,
    postcode,
    phone,
    email,
    geo,
    directionAction,
    distanceAction,
    mapPins,
    template,
    description,
    openTimes,
    virtualTourAction,
    franchisee_email,
    show_franchisee_email
  } = props

  const [isReadMore, setReadMore] = useState(true)
  const [textHeight, setTextHeight] = useState(902)
  const [isDesTouching, setDesTouching] = useState(false)
  const [isShowReadMoreBtn, setShowReadMoreBtn] = useState(false)
  const [textDesc, setTextDesc] = useState('')
  const { siteSettingPlus, siteSettings } = useContext(SiteContext)
  const clubMapRef = useRef<any>()

  const list_franchisee_email = franchisee_email
    ? franchisee_email?.split(',')
    : []

  useEffect(() => {
    setTextDesc(description)
  }, [])

  useEffect(() => {
    var word =
      siteSettingPlus &&
      siteSettingPlus.labels &&
      !!siteSettingPlus?.labels.length
        ? siteSettingPlus.labels
        : []
    var element = document.getElementById('wrap-flip')
    var wordIndex = 1

    var resetAnimation = function() {
      element && element.classList.remove('flip')
    }

    setInterval(function() {
      switch (wordIndex) {
        case 0:
          if (element) {
            element.classList.add('flip')
            element.textContent = word[wordIndex]
          }
          wordIndex = 1
          setTimeout(resetAnimation, 1000)
          break

        case 1:
          if (element) {
            element.classList.add('flip')
            element.textContent = word[wordIndex]
          }
          wordIndex = 2
          setTimeout(resetAnimation, 1000)
          break

        case 2:
          if (element) {
            element.classList.add('flip')
            element.textContent = word[wordIndex]
          }
          wordIndex = 3
          setTimeout(resetAnimation, 1000)
          break

        case 3:
          if (element) {
            element.classList.add('flip')
            element.textContent = word[wordIndex]
          }
          wordIndex = 0
          setTimeout(resetAnimation, 1000)
          break
      }
    }, 2000)
  }, [])
  
  const handlePhoneLinkClick = () => {
    ReactGA.event({
      category: 'Club location',
      action: 'Click to call',
      label: phone,
      nonInteraction: true
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click_to_call',
    });
  };

  useEffect(() => {
    if (textDesc) {
      const expandBox = document.getElementById('expandBox')
      if (expandBox) {
        expandBox.style.height = 'auto'
        const newHeight = expandBox.offsetHeight
        expandBox.style.height = ''
        setTextHeight(newHeight)
        if (newHeight < 393) {
          setReadMore(false)
          setShowReadMoreBtn(false)
        }
      }
    }
  }, [textDesc])

  return (
    <Section>
      <Container>
        <C.GymInformation src={address_bg}>
          <div className='left'>
            <div className={'wrap'}>
              <p className='title'>
                {prefix && `${prefix} `}
                {name}
              </p>
              <a
                className='address'
                href={distanceAction?.url}
                target={distanceAction?.target}
              >
                {address} <br /> <span>{suburb}</span> {postcode}
              </a>
              <div
                className={`phone-email ${
                  !!list_franchisee_email.length && show_franchisee_email
                    ? 'franchisee'
                    : ''
                }`}
              >
                <a href={`tel:${phone}`} className='phone' onClick={handlePhoneLinkClick}>
                  P: <span>{phone}</span>
                </a>
                <a href={`mailto:${email}`} className='email'>
                  E: <span>{email}</span>
                </a>
                {!!list_franchisee_email?.length &&
                  show_franchisee_email &&
                  list_franchisee_email.map(email => (
                    <a href={`mailto:${email}`} className='franchisee'>
                      Franchisee: <span>{email}</span>
                    </a>
                  ))}
              </div>

              {openTimes &&
                openTimes.map(
                  (ot, idx) =>
                    ot.title &&
                    ot.times.length > 0 && (
                      <C.OpenTime key={idx}>
                        <C.OpenTimeTitle>{ot.title}</C.OpenTimeTitle>
                        {ot.times &&
                          ot.times.map((t, i) => (
                            <C.OpenTimeLine key={i}>
                              <C.WeekDayText>{t.days || t.day}</C.WeekDayText>
                              <C.WeekDayTime>{t.times}</C.WeekDayTime>
                            </C.OpenTimeLine>
                          ))}
                      </C.OpenTime>
                    )
                )}

              <div className='buttons'>
                {distanceAction && (
                  <G.WhiteButton
                    className={'button-direction'}
                    href={distanceAction.url}
                    target={distanceAction.target}
                  >
                    <C.btnIcon>
                      <C.Icon name={IconEnums['icon-direction']} />
                    </C.btnIcon>
                    {distanceAction.label}
                  </G.WhiteButton>
                )}
                {directionAction && directionAction.url && (
                  <G.WhiteButton
                    className={'button-map'}
                    href={directionAction.url}
                    target={directionAction.target}
                  >
                    <C.btnIcon>
                      <C.Icon name={IconEnums['icon-map']} />
                    </C.btnIcon>
                    {directionAction.label}
                  </G.WhiteButton>
                )}
                {virtualTourAction && virtualTourAction.url && (
                  <C.ButtonAction className={'button-virtual'}>
                    <Modal
                      buttonText={virtualTourAction.label}
                      cancelText='Close'
                      iconImageUrl={VirtualImg}
                    >
                      <C.IframeWrapper src={virtualTourAction.url} />
                    </Modal>
                  </C.ButtonAction>
                )}
              </div>
            </div>
            <C.GymNewLayoutMap>
              <LocationMap
                mapZoom={[14]}
                centerCoord={geo}
                mapData={mapPins}
                refElm={clubMapRef}
                googleMapDirection={null}
                template={template}
              ></LocationMap>
            </C.GymNewLayoutMap>
          </div>
          <div className='right'>
            {siteSettings && siteSettings?.show_you_fitness_banner && (
              <C.GymInFoTitle id='p-title'>
              <div className={'group'}>
                YOU
                <C.Icon name={IconEnums['icon-plus']} />
                FITNESS
              </div>
              <div className={'group'}>
                <span>=</span>
                <C.WrapFlip>
                  <p id={'wrap-flip'}></p>
                </C.WrapFlip>
              </div>
            </C.GymInFoTitle>
            )}
            <C.GymInFoDescWrap
              onTouchStart={() => {
                setDesTouching(true)
              }}
              onTouchCancel={() => {
                setTimeout(() => {
                  setDesTouching(false)
                }, 500)
              }}
              onTouchEnd={() => {
                setTimeout(() => {
                  setDesTouching(false)
                }, 500)
              }}
              isTouching={isDesTouching}
            >
              <C.GymInFoDesc
                id={'expandBox'}
                height={textHeight}
                isReadMore={isReadMore}
                dangerouslySetInnerHTML={{ __html: textDesc }}
              />
              <C.GymInFoDescShadow isReadMore={isReadMore} />
            </C.GymInFoDescWrap>

            {!isShowReadMoreBtn && (
              <C.GymInFoButton onClick={() => setReadMore(!isReadMore)}>
                <C.Icon
                  name={
                    isReadMore
                      ? IconEnums['icon-plus-circle']
                      : IconEnums['icon-minus']
                  }
                />
                {isReadMore ? YOU_FITNESS.readMoreBtn : YOU_FITNESS.readLessBtn}
              </C.GymInFoButton>
            )}
          </div>
        </C.GymInformation>
      </Container>
    </Section>
  )
}

ClubLocation.ApiBlockToProps = (
  data: ClubLocationAPIProps
): ClubLocationProps => {
  const { value } = data

  const cleanOpenTimes = (
    opentimes: {
      day: string
      times: string
    }[]
  ) => {
    const newData: {
      day: string
      days?: string
      times: string
    }[] = []
    let startIndex = 0
    opentimes.forEach(it => {
      if (newData.length > 0 && it.times === newData[startIndex].times) {
        newData[newData.length - 1] = {
          days: `${newData[startIndex].day} - ${it.day}`,
          times: it.times,
          day: newData[startIndex].day
        }
      } else {
        newData.push(it)
        startIndex = newData.length - 1
      }
    })

    return newData
  }

  const staffedHours = cleanOpenTimes(value.staffed_hours)
  const crecheHours = cleanOpenTimes(value.creche_hours)

  return {
    prefix: value.pre_text,
    name: value.name,
    address: value.address,
    suburb: value.suburb,
    postcode: value.postcode,
    phone: value.phone,
    email: value.email,
    geo: value.geo,
    directionAction: {
      url: GoogleMapPointLink(value.geo),
      label: 'Larger Map',
      target: '_blank'
    },
    distanceAction: {
      url: GoogleMapDirectionLink(value.geo),
      label: 'Get directions',
      target: '_blank'
    },
    mapPins: value.mapPins,
    template: value.template as LAYOUT_TEMPLATE,
    description: value.description,
    virtualTourAction: { url: value.virtual_tour_url, label: 'Virtual tour' },
    openTimes: [
      {
        title: 'STAFFED HOURS',
        times: staffedHours
      },
      {
        title: 'CRECHE HOURS',
        times: crecheHours
      }
    ],
    franchisee_email: value.franchisee_email,
    show_franchisee_email: value.show_franchisee_email
  }
}

export default ClubLocation
