import * as G from '../../styles/global.styles'
import { WagtailBlockProps } from '../../services/api/types'

export interface Paragraph {
  type: 'text' | 'title' | 'space'
  text?: string
}

export interface PageContentProps {
  paragraphs?: Paragraph[]
  template?: String
}

export const StandardContent = (props: PageContentProps) => {
  const { paragraphs, template } = props
  return (
    <G.Container>
      {paragraphs &&
        paragraphs.map((para, idx) => {
          switch (para.type) {
            case 'text':
              return (
                <>
                {template == "YouFitness" ? (
                  <G.FitnessTextWrap>
                    <G.Text
                    key={idx}
                    dangerouslySetInnerHTML={{ __html: para.text || '' }}
                  />
                </G.FitnessTextWrap>
                ): (
                  <G.Text
                  key={idx}
                  dangerouslySetInnerHTML={{ __html: para.text || '' }}
                />
                )}
                </>
              )
            case 'title':
              return (
                <G.Text key={idx} size='lg' fontWeight='semibold'>
                  {para.text}
                </G.Text>
              )
            case 'space':
              return <G.Space key={idx} height={2} />
          }
        })}
    </G.Container>
  )
}

StandardContent.ApiBlockToProps = (data: WagtailBlockProps) => {
  //return default props for test, will implement data from API later
  if (data.value) {
    const apiData = data.value
    const props: PageContentProps = {
      paragraphs: [
        {
          type: 'text',
          text: apiData
        }
      ]
    }

    return props
  }
}

export default StandardContent
