import styled from 'styled-components'
import tw from 'twin.macro'

export const ContactFormWrapper = styled.div`
  ${tw`relative flex flex-col w-full font-display bg-grey__light`}

  @media(min-width:1024px){
    max-width: 350px;
  }
`

