import styled from 'styled-components'
import tw from 'twin.macro'
import { BlueButton, BlueButtonLink } from '../../styles/global.styles'
import { Heading } from './Franchise.styled'

export const Brochure = styled.section`
  ${tw`flex `}
`

export const Image = styled.img`
  width: 87.5%;
  ${tw`mb-10 md:mb-0`}

  @media (max-width: 767px) {
    ${tw`w-full`};
  }
`

export const Info = styled.div`
  ${tw`text-white`}
`

export const Title = styled(Heading)`
  ${tw`mb-6`}
`

export const Content = styled.div`
  ${tw`text-lg mb-10`}
`

export const Link = styled(BlueButtonLink)`
  ${tw`uppercase font-semibold font-youfitnessHeading text-1.5xl py-1.5`}
`

export const Button = styled(BlueButton)`
  ${tw`uppercase font-semibold font-youfitnessHeading text-1.5xl py-1.5`}
`
