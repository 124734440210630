import * as G from '../../../styles/global.styles'
import { WagtailBlockProps } from '../../../services/api/types'
import { Container, Section } from '../../../styles/grid.styles'
import { BlockTitle } from '../../base'

export interface GymSuburbsProps {
  name: string
  suburbs?: string
}

export interface GymSuburbsAPIProps extends WagtailBlockProps {
  value: {
    suburbs?: string
    name: string
  }
}

export const GymSuburbs = (props: GymSuburbsProps) => {
  const { name, suburbs } = props

  return name && suburbs ? (
    <Section>
      <Container>
        <BlockTitle isYouFitness>
          Plus Fitness {name} Servicing Suburbs
        </BlockTitle>
        <G.Text color='white' className='text-center' size='sm'>
          Plus Fitness {name} services the fitness needs of all members in the
          suburbs of {suburbs}. Let us help you achieve your fitness goals.
        </G.Text>
      </Container>
    </Section>
  ) : null
}

GymSuburbs.ApiBlockToProps = (data: GymSuburbsAPIProps) => {
  const { value } = data
  if (value) {
    let props: GymSuburbsProps = {
      name: value.name,
      suburbs: value.suburbs
    }
    return props
  }
}

export default GymSuburbs
