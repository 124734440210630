import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const ContentWrapper = styled.div<{
  contentAlign?: 'left' | 'center' | 'right'
}>`
  ${tw`w-full`}
  ${({ contentAlign }) => {
    switch (contentAlign) {
      case 'center':
        return css`
          ${tw`text-center`}
          ${ImgWrap} {
            ${tw`w-full`}
          }
        `
  
      case 'left':
        return css`
          ${tw`text-left`}
          ${ImgWrap} {
            ${tw`md:w-1/2`}
          }
        `

      case 'right':
        return css`
          ${tw`text-right`}
          ${ImgWrap} {
            ${tw`md:w-1/2`}
          }
        `
    }
  }}
`

export const ImgWrap = styled.div`
  ${tw`max-w-full inline-block`}
`

export const IMG = styled.img`
  ${tw`w-full`}
`
export const Link = styled.a``
