import styled from 'styled-components'
import tw from 'twin.macro'

export const FAQItems = styled.div`
  ${tw`w-full`}
`
export const Narrow = styled.span`
  ${tw`flex-initial text-blue`}
`

export const TitleWrapper = styled.div`
  ${tw`w-full flex flex-row items-center justify-between cursor-pointer`}
`

export const Title = styled.h2`
  ${tw`flex-1 text-blue__Congress font-normal text-2xl`}
`

export const FAQContent = styled.div`
  ${tw`w-full`}
  .react-css-collapse-transition {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`
