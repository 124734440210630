import { useState } from 'react'
import * as G from '../../styles/global.styles'
import * as S from './FranchiseBenefits.styles'
import {
  FranchiseBenefitCard,
  FranchiseBenefitCardProps
} from './FranchiseBenefitCard'
import FranchiseeSearchForm from '../search/FranchiseeSearchForm'

export interface FranchiseBenefitsProps {
  franchise: FranchiseBenefitCardProps[]
  title?: string
  handleSearch?: Function
}

export const FranchiseBenefits = (props: FranchiseBenefitsProps) => {
  const { franchise, title, handleSearch } = props
  const [searchText, setSearchText] = useState('')
  
  return (
    <G.Section bgColor='grey-light' spacing={12}>
      <G.Container>
        <S.TitleWrapper>
          <G.Text size='4xl' color='blue-dark' fontWeight='bold'>
            {title}
          </G.Text>
          {handleSearch && (
            <FranchiseeSearchForm
              searchButtonText='Search'
              searchPlaceHolder='Search'
              searchButtonClick={handleSearch}
              setSearchText={setSearchText}
            />
          )}
        </S.TitleWrapper>
        <S.FranchiseWrapper>
          {franchise &&
            franchise.map((f, i) => (
              <S.FranchiseItem key={i}>
                <FranchiseBenefitCard {...f} />
              </S.FranchiseItem>
            ))}
          {(!franchise || (franchise && franchise.length === 0)) && (
            <S.FranchiseEmpty>
              We couldn’t find a match for "{searchText}". Please try another
              search!
            </S.FranchiseEmpty>
          )}
        </S.FranchiseWrapper>
      </G.Container>
    </G.Section>
  )
}
