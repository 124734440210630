import React, { useState } from 'react'
import Collapse from 'react-css-collapse'
import * as S from './FAQ.styles'
import * as G from '../../styles/global.styles'
import { SVGIcon, IconEnums } from '../base/icon/svgIcon'
import { WagtailBlockProps } from '../../services/api/types'
import { BlockTitle } from '../base'

export interface FAQProps {
  title: string
  content: string
  showed?: boolean
}

export interface FAQsProps {
  title: string
  faqs: FAQProps[]
}

export interface FAQsAPIProps extends WagtailBlockProps {
  value: {
    title: string
    faqs: [
      {
        question: string
        answer: string
        start_open?: boolean
      }
    ]
  }
}

export const FAQ = (props: FAQProps) => {
  const [expanded, setExpanded] = useState(false)
  const { title, content } = props
  return (
    <G.FullWidthWrapper>
      <S.TitleWrapper
        onClick={() => {
          setExpanded(!expanded)
        }}
      >
        <S.Title>{title}</S.Title>
        <S.Narrow className={`${expanded && `rotate-180 transform`}`}>
          <SVGIcon name={IconEnums['narrow-down']} />
        </S.Narrow>
      </S.TitleWrapper>
      <S.FAQContent>
        <Collapse isOpen={expanded}>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </Collapse>
      </S.FAQContent>
    </G.FullWidthWrapper>
  )
}

export const FAQs = (props: FAQsProps) => {
  const { title, faqs } = props
  return (
    <G.Section>
      <G.Container contentSize='lg'>
        <G.CenteredContainer>
          {title && <BlockTitle>{title}</BlockTitle>}
        </G.CenteredContainer>
        <S.FAQItems>
          {faqs && faqs.map((item, idx) => <FAQ key={idx} {...item} />)}
        </S.FAQItems>
      </G.Container>
    </G.Section>
  )
}

FAQs.ApiBlockToProps = (data: FAQsAPIProps): FAQsProps => {
  const { value } = data
  return {
    title: value.title,

    faqs: value.faqs.map(item => {
      return {
        title: item.question,
        content: item.answer,
        showed: item.start_open
      }
    })
  }
}
