import * as S from './PersonBlock.styled'
import { PersonalTrainer } from '../../personal-trainers/PersonalTrainers'
import { VideoPlayer } from './VideoBlock'
import { isMobile } from 'react-device-detect'
import { useEffect, useState } from 'react'

export const PersonBlock = (props: PersonalTrainer) => {
  const {
    avatar,
    fullname,
    viewMore,
    toggleModal,
    setSelectedIndex,
    video
  } = props

  const [hasWindow, setHasWindow] = useState(false);
  
  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true);
    }
  }, []);
  
  return (
    <S.Person>
      <S.PersonContent>
        <S.VideoPlayerContainer avatarImgURL={avatar}>
          {isMobile && video ? (
            hasWindow &&
            <VideoPlayer
              videoURL={video}
              thumbnailURL={avatar}
              autoPlay={false}
            />
          ) : (
            video ? (
              <S.PlayButton
                src='/static/imgs/icon-play.png'
                onClick={
                  video && setSelectedIndex && toggleModal
                    ? () => {
                      toggleModal();
                      setSelectedIndex();
                    }
                    : viewMore
                }
              />
            ) : null
          )}
        </S.VideoPlayerContainer>
        <S.PersonInfo>
          <S.PersonName>{fullname}</S.PersonName>
          <S.ReadButton onClick={viewMore}>Read more</S.ReadButton>
        </S.PersonInfo>
      </S.PersonContent>
    </S.Person>
  )
}

export default PersonBlock
