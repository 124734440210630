import styled from 'styled-components'
import tw from 'twin.macro'

export const ContentBlockText = styled.p<{ fontBold?: boolean }>`
  ${tw`mb-8 font-body`}
  ${props => (!!!props.color || props.color == 'orange') && tw`text-orange`}
  ${props => props.color == 'blue' && tw`text-blue`}
  ${props => props.color == 'white' && tw`text-white`}
  ${props => props.color == 'black' && tw`text-black`}
  ${props => props.fontBold && tw`font-bold`}
`

export const ButtonWrapper = styled.div`
  ${tw`first:mr-0 lg:mr-4 flex items-center`}

  & > a {
    ${tw`p-4 inline-block font-body py-2`}
    min-width: 150px;
  }
`
