import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const FAQItems = styled.div`
  ${tw`w-full`}
`

export const Narrow = styled.span`
  ${tw`text-blue__deep`}
`

export const TitleWrapper = styled.div`
  ${tw`w-full flex items-center justify-between cursor-pointer`}
`

export const Title = styled.h3`
  ${tw`text-white mb-0 text-xl md:text-2xl`}
`

export const FAQContent = styled.div`
  ${tw`w-full text-white text-sm`}

  .react-css-collapse-transition {
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    
    > div {
      ${tw`pt-4`}
    }
  }
`

export const FAQ = styled.div<{ active?: boolean }>`
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);

  &:last-child {
    border-bottom: none;
  }

  ${tw`p-4`}

  ${({ active }) =>
    active &&
    css`
      ${tw`bg-black`}
    `}
`
