import tw from 'twin.macro'
import styled from 'styled-components'
import { Carousel } from 'react-responsive-carousel'
import prevImg from '../../static/imgs/icon-arrow-prev.png'
import nextImg from '../../static/imgs/icon-arrow-next.png'
import * as G from '../../styles/global.styles'

export const StyledSlider = styled(Carousel)`
  &{
    margin: 0 auto;
    ${tw`mx-auto`}
    
  .carousel .slider-wrapper{
    .slide{
      background:none;
    }
  }
  .carousel.carousel-slider .control-arrow{
    
    /* Large (lg) */
    @media (min-width: 1024px) {
      
    }
    /* Extra Large (xl) */
    @media (min-width: 1280px) {
      top:50%;
    }
    
    ${tw`m-0 p-0 z-20`}
    width: 40px;
    height: 40px;
    :hover{
      background:none;
    }
    :before{
      width: 40px;
      height: 40px;
      content: ' ';
      ${tw`bg-cover bg-no-repeat bg-center border-0 z-10 block m-0`}
    }
    &.control-prev {
      :before {
        background-image: url('${prevImg}');
      }
    }
    &.control-next {
      :before {
        background-image: url('${nextImg}');
      }
    }
  }
  
}
`

export const SliderButton = styled.button``

export const Container = styled(G.Container)`
  ${tw`flex justify-center content-center flex-col items-center py-8`}
`

export const Title = styled.div`
  ${tw`text-4xl uppercase font-bold text-blue__dark text-center px-4`}
`

export const SliderWrapper = styled.div`
  ${tw`w-full px-2 lg:px-2 xl:px-0 mt-8`}
`
export const ReviewItem = styled.div`
  ${tw`lg:px-24`}
`
