import * as S from './ImageAndText.styled'
import React from "react";
import {WagtailBlockProps} from "../../services/api/types";
import {LazyImage} from "../lazy-image";
import {Col, Container, Row} from "../../styles/grid.styles";


export interface ImageAndTextAPIProps extends WagtailBlockProps {
    value: {
        items: ImageAndTextProps[]
    }
}

export interface ImageAndTextProps {
    headline: string
    description?: string
    alignment?: 'left' | 'right'
    image: string
}

export interface ItemsProps {
    items: ImageAndTextProps[]
}

export const ImageAndText = (prop: ItemsProps) => {

    const {items = []} = prop;

    const renderImage = (image: string, headline: string) => (
        <S.ImageWrap>
            <LazyImage src={image} alt={headline}/>
        </S.ImageWrap>
    )

    const renderText = (headline:string, description?: string) => (
        <S.TextWrap>
            <S.Headline>{headline}</S.Headline>
            <S.Description>{description}</S.Description>
        </S.TextWrap>
    )

    return (
        <S.ImageAndTextWrap>
            <Container className='image-and-text-container'>
                {items.map((item, index) => (
                    <Row key={index} gutter={20}>
                        <Col xs={12} sm={6} xsOrder={1} smOrder={item.alignment === 'left' ? 1 : 2}>
                            {renderImage(item.image, item.headline)}
                        </Col>
                        <Col xs={12} sm={6} xsOrder={2} smOrder={item.alignment === 'left' ? 2 : 1}>
                            {renderText(item.headline, item.description)}
                        </Col>
                    </Row>
                ))}
            </Container>
        </S.ImageAndTextWrap>
    )
}

ImageAndText.ApiBlockToProps = (data: ImageAndTextAPIProps) => {
    if (data?.value) {
        return data.value
    }
}
export default ImageAndText
