import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const Media = styled.div<{
  image?: string
  isLastItem?: boolean
  isMainItem?: boolean
}>`
  ${tw`relative bg-cover bg-no-repeat mb-4 cursor-pointer`}
  height: 0;
  padding-top: 58.246%;

  ${props =>
    props.isMainItem &&
    css`
      padding-top: 59.386%;
    `}

  ${props =>
    props.image &&
    css`
      background-image: url(${props.image});
    `}

  ${props =>
    props.isLastItem &&
    css`
      &:after {
        ${tw`absolute top-0 left-0 w-full h-full`}
        content: '';
        background: rgba(0, 0, 0, 0.88);
      }
    `}
`

export const Count = styled.span`
  ${tw`absolute z-40 text-white font-youfitnessHeading text-6xl font-semibold`}
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const PlayButton = styled.img`
  ${tw`absolute z-40`}
  width: 76px;
  height: 76px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const VideoContainer = styled.div`
  ${tw`w-full`}
  > div {
    background-color: transparent;
  }

  .react-player {
    height: 100% !important;
  }
`
