import tw from 'twin.macro'
import styled from 'styled-components'
import Modal from 'styled-react-modal'

export const SliderModal = Modal.styled`
  width: calc(100% - 5rem);
  height: calc(100% - 5rem);
  ${tw`relative`}

  @media (max-width: 991px) {
    width: calc(100% - 3rem);
    height: 50vh;
  }

  @media (orientation: landscape) {
    height: 80vh;
  }
`

export const SliderWrap = styled.div`
  ${tw`relative`}
`

export const Close = styled.div`
  ${tw`relative w-10 h-10 bg-black text-white text-2xl text-center absolute z-40 rounded-full cursor-pointer`}
  right: -1.25rem;
  top: -1.25rem;
`

export const CarouselItem = styled.div`
  height: calc(100vh - 5rem);

  ${tw`bg-black`}

  > img {
    ${tw`h-full w-auto!`}
  }

  @media (max-width: 991px) {
    height: 50vh;
  
    > img {
      ${tw`object-cover`}
    }
  }

  @media (orientation: landscape) {
    height: 80vh;
  }

  > div {
    ${tw`h-full pt-0 bg-black`}
  }
`
