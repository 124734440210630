import WagtailBlocksSection from '../src/components/wagtail/components'
import { CorporatePartnerPageProps } from '../src/services/api/types'
import { NextPage } from 'next'
import { FranchiseeBenefitDetail } from '../src/components/franchisee-benefit-detail'

const CorporatePartnerPage: NextPage<{
  pageData: CorporatePartnerPageProps
}> = (props: { pageData: CorporatePartnerPageProps }) => {
  const {
    pageData: { flexible_content, logo, contact_details, title, details, notes }
  } = props

  return (
    <>
      <FranchiseeBenefitDetail
        image={logo}
        title={title}
        subText={details}
        content={`${notes} ${contact_details}`}
        action={{ label: 'FIND OUT MORE', url: '', target: '_blank' }}
      />
      <WagtailBlocksSection blocks={flexible_content || []} />
    </>
  )
}

export default CorporatePartnerPage
