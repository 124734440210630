import styled from 'styled-components'
import tw from 'twin.macro'
import * as G from '../../styles/global.styles'

export const OpeningBannerWrapper = styled(G.Container)`
  ${tw`flex justify-center content-center text-center flex-col items-center mb-6`}
`

export const OpeningBannerTitle = styled.div<{
  color?: string
}>`
  line-height: 3rem;
  ${tw`text-5xl text-blue italic uppercase font-extrabold`}
  ${({ color }) => {
    switch (color) {
      case 'blue':
        return tw`text-blue`
      case 'orange':
        return tw`text-orange`
    }
  }}
	text-shadow: 0px 0px 5px #fff, 
               0px 0px 4px #fff, 
			   0px 0px 70px #fff,
			   0px 0px 20px #8c8c8c;
`
export const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom:30px;
  padding-top:20px;
`
export const CountdownBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
`
export const CountdownNumber = styled.span`
  font-size: 36px;
  padding:0px 10px 0px 10px;
  font-weight: bold;
`
export const CountdownLabel = styled.span`
  margin-top:5px;
  font-size: 15px;
  color: blue;
`

export const ButtonsList = styled.div`
  ${tw`w-full flex flex-wrap content-center justify-center items-stretch`}
`
export const ButtonIcon = styled.a`
  ${tw`mb-4 md:mb-0 w-full md:w-auto flex justify-start items-center text-lg bg-blue__deep hover:bg-blue__dark2 transition duration-300 px-8 py-4 md:mx-3 leading-tight`}
  color:white;
  text-shadow:none;
  @media (max-width: 767px) {
    ${tw`justify-center`};
    min-height: 77px;
  }
`

export const OpeningSoonButtonWrapper = styled.div`
  ${tw`flex justify-center mt-8`}
`
