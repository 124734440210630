import { PAGE_TEMPLATE } from '../../services/api/constants'
import YouFitnessFooter from './YouFitnessTemplate/Footer'
import Footer from './Footer'

const FooterTemplate = (name?: string) => {
  switch (name) {
    case PAGE_TEMPLATE.YOU_FITNESS:
      return YouFitnessFooter

    default:
      return Footer
  }
}

export default FooterTemplate
