import styled from 'styled-components'
import tw from 'twin.macro'

export const IconsWrapper = styled.div`
  ${tw`w-full flex flex-wrap items-baseline`}
`

export const Group = styled.div<{
  mdSize: 'w-1/3' | 'w-1/4'
}>`
  margin-top: 3rem;
  ${tw`w-full w-1/2 flex flex-col items-stretch justify-center`}
  ${({ mdSize }) => {
    return mdSize && mdSize !== 'w-1/3' ? tw`md:w-1/4` : tw`md:w-1/3`
  }}
`

export const ImgWrap = styled.div`
  margin-bottom: 1rem;

  img {
    height: 100px;
  }
`

export const GText = styled.span`
  ${tw`text-sm text-white`}
  max-width: 240px;
  margin: 0 auto;
  padding: 0 1.25rem;
`
