import { useRef, useState, useContext, useEffect } from 'react'
import {
  WagtailPageProps,
  PageContext,
  LocationCardProps,
  SelectOption,
  GymLocation,
  SearchOptions,
  GeoPoint
} from '../src/services/api/types'
import { getAllGyms, getGymsFromSite } from '../src/services/api/cms'
import {
  generalItemsPerPage,
  gymLocationMapZoom,
  defaultMapCenter,
  FindAGymListTitle
} from '../src/services/api/constants'
import { isNullOrEmpty, maxBounds } from '../src/services/utils'
import { locationSearchFormProps } from '../src/services/mock'
import { LocationTab } from '../src/components/tabs'
import { GymLocationResults } from '../src/components/cards'
import * as G from '../src/styles/global.styles'
import WagtailBlocksSection from '../src/components/wagtail/components'
import locationMarker from '../src/static/imgs/location-marker.png'
import { NextPage } from 'next'
import dynamic from 'next/dynamic'
import { SiteContext } from '../src/services/context/SiteContext'
import { apiCall } from '../src/services/api/helpers'

const LocationMap = dynamic(
  () => import('../src/components/maps/LocationMap'),
  { ssr: false }
)
const LocationSearchForm = dynamic(
  () => import('../src/components/search/LocationSearchForm'),
  { ssr: false }
)

interface GymIndexPageProps extends WagtailPageProps {
  search_title?: string
  state_options?: []
  map_center_point?: GeoPoint
}

interface SearchProps {
  q: string
  franchise_status: string
  address_state_code: string
}

const GymIndexPage: NextPage<{
  pageData: GymIndexPageProps
  locationQuery?: string | string[]
  initialData?: any
  franchiseStatus?: string | string[]
  map_center_point?: GeoPoint
  locationMapPoints?: Array<GymLocation>
}> = (props: {
  pageData: GymIndexPageProps
  locationQuery?: string | string[]
  initialData?: any
  franchiseStatus?: string | string[]
  map_center_point?: GeoPoint
  locationMapPoints?: Array<GymLocation>
}) => {
  const {
    pageData,
    initialData,
    map_center_point,
    locationQuery,
    franchiseStatus,
    locationMapPoints
  } = props

  const mapRef = useRef<any>()
  const resultRef = useRef<any>()
  const { otherSites } = useContext(SiteContext)
  const [mapPins, setMapPins] = useState(locationMapPoints)
  const [stateData, seStateData] = useState([])

  //Manual get state if state_options is not return from server
  const getStateData = () => {
    let stateOption: any = []
    locationMapPoints &&
      locationMapPoints.length > 0 &&
      locationMapPoints.map(item => {
        if (item.state) {
          stateOption.push(item.state)
        }
      })
    const filteredArr = stateOption.filter(function(item: any, index: number) {
      if (stateOption.indexOf(item) == index) return item
    })
    seStateData(filteredArr)
  }

  useEffect(() => {
    getStateData()
  }, [locationMapPoints])
  // Get the map pins for the rest of the locations

  useEffect(() => {
    let otherGyms: Array<GymLocation> = []

    !locationQuery &&
      otherSites &&
      mapPins?.length == locationMapPoints?.length &&
      otherSites.map(async site => {
        const data = await getGymsFromSite(
          {
            per_page: 2000,
            fields: 'address_suburb,address_state,geo_point,full_url'
          },
          site
        )
        data.results &&
          data.results.map((gym: any) => {
            otherGyms.push({
              geometry: gym.geo_point,
              pageURL: gym.full_url,
              title: gym.title,
              suburb: gym.address_suburb,
              state: gym.address_state_code
            })
          })

        let nextUrl: string | null = data.next
        while (nextUrl) {
          const nextData = await apiCall(nextUrl)
          nextUrl = nextData.next
          nextData.results &&
            nextData.results.map((gym: any) => {
              otherGyms.push({
                geometry: gym.geo_point,
                pageURL: gym.full_url,
                title: gym.title,
                suburb: gym.address_suburb,
                state: gym.address_state_code
              })
            })
        }
        setMapPins([...(locationMapPoints || []), ...(otherGyms || [])])
      })
  }, [])

  const [search, setSearch] = useState<SearchProps>({
    q: locationQuery?.toString() || '',
    franchise_status: franchiseStatus?.toString() || '',
    address_state_code: ''
  })
  const [loading, setLoading] = useState<boolean>(false)

  const onQueryChange = async (query: string) => {
    setSearch({
      ...search,
      q: query
    })
    doSearch({
      ...search,
      q: query
    })
  }
  const onGymsFilter = async (filter: SelectOption) => {
    setSearch({
      ...search,
      franchise_status: filter.value
    })
    doSearch({
      ...search,
      franchise_status: filter.value
    })
  }

  const doSearch = async (query: SearchOptions) => {
    setLoading(true)
    const gymResults = await getAllGyms({
      ...query,
      ordering: query.ordering
        ? query.ordering
        : query.address_state_code || search.address_state_code || !query.q
        ? 'title'
        : '',
      per_page: generalItemsPerPage
    })
    relocateMapPosition(gymResults, query)
    setLoading(false)
  }

  const relocateMapPosition = (data: any, query: SearchOptions) => {
    const { results } = data
    if (!mapRef.current) return false

    // Set search results to component
    resultRef?.current?.setGyms(results)

    const { map } = mapRef?.current?.state

    const centerPoint = results?.find((it: any) =>
      it.title.toLowerCase().includes(query.q?.toLowerCase())
    )

    if (!isNullOrEmpty(results) && results.length > 1 && !centerPoint) {
      let bounds: Array<Array<number>> = []
      results.map((res: any) => {
        return bounds.push(res.geo_point.coordinates)
      })

      map.fitBounds(maxBounds(bounds), { padding: 20 })
    } else if (
      (!isNullOrEmpty(results) && results.length === 1) ||
      centerPoint
    ) {
      map.flyTo({
        center:
          centerPoint?.geo_point?.coordinates ||
          results[0].geo_point.coordinates,
        zoom: [17],
        essential: true
      })
    } else {
      map.flyTo({
        center: [135, -20],
        zoom: [3],
        essential: true
      })
    }
  }

  const centerOnQuery = (pins: any) => {
    if (!mapRef.current) return false
    const { map } = mapRef?.current?.state

    let bounds: Array<Array<number>> = []
    pins?.length &&
      pins.map((res: any) => {
        return bounds.push(res.geometry.coordinates)
      })

    map.fitBounds(maxBounds(bounds), { padding: 100 })
  }

  const changeState = async (stateCode: string) => {
    resultRef?.current?.setTitle(
      `GYMS IN ${stateCode !== '' ? stateCode : 'your area'}`
    )
    setSearch({
      ...search,
      q: '',
      address_state_code: stateCode
    })
    doSearch({
      ...search,
      q: '',
      address_state_code: stateCode
    })
  }

  useEffect(() => {
    if (locationQuery && locationQuery !== '') {
      onQueryChange(locationQuery?.toString())
      resultRef?.current?.setSearchText(locationQuery?.toString())
    }
  }, [locationQuery])

  const template = pageData.template

  return (
    <G.CardBody className='w-full' template={template}>
      <G.FullWidthWrapper>
        <LocationSearchForm
          searchLabel={pageData.search_title}
          searchQuery={search?.q}
          onQueryChange={(q: string) => onQueryChange(q)}
          template={template}
          {...locationSearchFormProps}
        />
        <LocationMap
          pinIcon={locationMarker}
          centerCoord={map_center_point?.coordinates || defaultMapCenter}
          mapZoom={gymLocationMapZoom}
          mapData={mapPins}
          refElm={mapRef}
          onLoad={() => locationQuery && centerOnQuery(mapPins)}
          locationQuery={locationQuery?.toString()}
          template={template}
          isIndex
        />
      </G.FullWidthWrapper>
      <LocationTab
        tabClick={changeState}
        template={template}
        tabItems={
          pageData.state_options && pageData.state_options.length > 0
            ? pageData.state_options
            : stateData
        }
        selectedTab=''
      />

      {initialData && (
        <GymLocationResults
          onFilter={onGymsFilter}
          loading={loading}
          ref={resultRef}
          gyms={initialData?.results}
          title={FindAGymListTitle}
          template={template}
        />
      )}
      {pageData && pageData.flexible_content && (
        <WagtailBlocksSection
          blocks={pageData.flexible_content}
          template={template}
        />
      )}
    </G.CardBody>
  )
}

GymIndexPage.getInitialProps = async (ctx: PageContext) => {
  let props: {
    pageData: GymIndexPageProps
    locationQuery?: string | string[]
    initialData?: any
    franchiseStatus?: string | string[]
    mapApiKey?: string
    map_center_point?: GeoPoint
    locationMapPoints?: Array<GymLocation>
  } = {
    pageData: ctx.pageData
  }

  const { q, franchise_status } = ctx.query

  const gymResults = await getAllGyms(
    {
      ordering: 'title',
      per_page: generalItemsPerPage,
      q: typeof q === 'string' ? q : ''
    },
    ctx
  )

  props.map_center_point = props.pageData.map_center_point
  props.locationQuery = q
  props.franchiseStatus = franchise_status
  props.initialData = gymResults
  props.pageData = ctx.pageData

  props.locationMapPoints = gymResults?.results?.map(
    (gym: LocationCardProps) => {
      return {
        geometry: gym.geo_point,
        pageURL: gym.full_url,
        title: gym.title,
        suburb: gym.address_suburb,
        state: gym.address_state_code
      }
    }
  )

  return props
}

export default GymIndexPage
