import { ReviewCard, ReviewCardProps } from './ReviewCard'
import * as S from './CustomerReviews.styled'
import * as G from '../../styles/global.styles'
import { WagtailBlockProps } from '../../services/api/types'
import { BlockTitle } from '../base'

export interface CustomerReviewProps {
  title: string
  reviews: ReviewCardProps[]
}

export interface ReviewAPIProps {
  author: string
  date: string
  rating: number
  content: string
}
export interface CustomerReviewAPIProps extends WagtailBlockProps {
  value: {
    reviews: ReviewAPIProps[]
  }
}
export const CustomerReviews = (props: CustomerReviewProps) => {
  const { title, reviews } = props
  const sliderSetting = {
    swipeable: true,
    showArrows: true,
    infiniteLoop: true,
    autoPlay: true,
    emulateTouch: true,
    showIndicators: false,
    showStatus: false,
    showThumbs: false
  }
  return (
    <G.Section spacing={12}>
      <S.Container contentSize='lg'>
        {title && <BlockTitle textTransform='uppercase'>{title}</BlockTitle>}
        <S.SliderWrapper>
          <S.StyledSlider {...sliderSetting}>
            {reviews &&
              reviews.map((rev, i) => (
                <S.ReviewItem key={i}>
                  <ReviewCard {...rev} />
                </S.ReviewItem>
              ))}
          </S.StyledSlider>
        </S.SliderWrapper>
      </S.Container>
    </G.Section>
  )
}
CustomerReviews.ApiBlockToProps = (data: CustomerReviewAPIProps) => {
  //return default props for test, will implement data from API later

  const props: CustomerReviewProps = {
    title: 'Customer Reviews',
    reviews: data.value.reviews.map(rv => {
      return {
        customerName: rv.author,
        date: rv.date,
        stars: rv.rating,
        review: rv.content
      }
    })
  }
  return props
}
export default CustomerReviews
