import styled from 'styled-components'
import tw from 'twin.macro'
import * as G from '../../styles/global.styles'

export const Container = styled.div`
  ${tw`container mx-auto flex flex-wrap`}
`

export const ContentWrapper = styled.div<{
  isHaveImages?: boolean
  alignContent?: 'left' | 'center' | 'right'
}>`
  * > ol {
    list-style-type: decimal;
    & > li {
      list-style-position: inside;
      margin-left: 1rem;
    }
  }
  ${tw`w-full px-4`}
  ${({ isHaveImages }) => isHaveImages && tw`md:w-2/3`}
  ${({ alignContent }) => {
    switch (alignContent) {
      case 'center':
        return tw`text-center`
      case 'left':
        return tw`text-left`
      case 'right':
        return tw`text-right`
    }
  }}
  ul {
    ${tw`list-disc pl-8`}
  }
`

export const ImagesWrapper = styled.div`
  ${tw`w-full md:w-1/3`}
`

export const IMG = styled.img`
  ${tw`w-full md:w-auto px-4`}
`

export const Link = styled.a`
  ${tw`pb-4 px-4 cursor-pointer`}
`

export const TitleWrapper = styled(G.Container)`
  ${tw`flex justify-center content-center text-center flex-col items-center`}
`

export const ContentContainer = styled.div`
  ${tw`w-full mx-auto px-2 xl:px-0 sm:max-w-screen-lg h-auto overflow-visible relative`}
  img, svg, video, canvas, audio, iframe, embed, object {
    display: inline-block;
  }
`

export const ReadMoreText = styled.div`
  ${tw`w-full pt-6 pb-2 mx-auto px-2 xl:px-0 sm:max-w-screen-lg font-medium cursor-pointer text-orange hover:underline`}
`
