import styled from 'styled-components'
import tw from 'twin.macro'
import { LazyImage } from '../lazy-image'

export const IconsWrapper = styled.div`
  ${tw`w-full flex flex-wrap items-baseline justify-center`}
`

export const ImageListContainer = styled.div`
  ${tw`flex -mx-4`}
`

export const ImageContainer = styled.div`
  ${tw``}
`

export const ImageLink = styled.a`
  ${tw``}
`

export const Description = styled.div`
  ${tw`text-white text-left`}
`

export const Image = styled(LazyImage)`
  ${tw`h-full object-cover items-center justify-center`}
  width: 151px;
`