import * as G from '../../styles/global.styles'
import * as S from './FranchiseeBenefitDetail.styles'
import { ButtonLink } from '../base'
import { Link } from '../../services/api/types'

export interface FranchiseeBenefitDetailProps {
  title: string
  image?: string
  subText?: string
  content?: string
  action?: Link
}

export const FranchiseeBenefitDetail = (
  props: FranchiseeBenefitDetailProps
) => {
  const { title, image, subText, content, action } = props
  return (
    <G.Section>
      <G.Container contentSize='lg'>
        <S.Img src={image} alt={title} />
        <S.TextWrapper>
          <G.Text color='blue-dark' size='4xl' fontWeight='bold'>
            {title}
          </G.Text>
        </S.TextWrapper>
        {subText && (
          <S.TextWrapper>
            <G.Text color='blue' size='2xl'>
              {subText}
            </G.Text>
          </S.TextWrapper>
        )}
      </G.Container>
      {content && (
        <G.Container
          contentSize='lg'
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
      <S.ContentFooter contentSize='lg'>
        {action && (
          <ButtonLink target={action.target} href={action.url}>
            {action.label}
          </ButtonLink>
        )}
      </S.ContentFooter>
    </G.Section>
  )
}

export default FranchiseeBenefitDetail
