import styled from 'styled-components'
import tw from 'twin.macro'

export const GymBtnSocials = styled.div`
  ${tw`flex justify-center items-center w-full`}
`

export const GymBtnSocialsLink = styled.a`
  ${tw`mx-2.5 text-white bg-blue__deep relative rounded-full transition-colors duration-300 flex items-center justify-center hover:bg-blue__dark2`}
  width: 75px;
  height: 75px;

  span {
    margin-right: 0;
  }

  svg {
    height: 37px;
    width: 28px;
    ${tw`hover:text-white`}
  }

  :hover {
    cursor: pointer;
  }
`
