import React, { useState } from 'react'
import { WagtailBlockProps } from '../../services/api/types'
import * as G from '../../styles/global.styles'
import { BlockTitle } from '../base'
import * as S from './GymFeatures.styles'

export interface FeaturesProps {
  [name: string]: string[]
}
export interface GymFeaturesProps {
  background_colour: string
  title: string
  text: string
  features: featureItemProps[]
  icons: string[]
}

export interface GymFeaturesAPIProps extends WagtailBlockProps {
  value: {
    headline: string
    subtext: string
    background_colour: {
      background_colour: string
    }
    features: FeaturesProps
    features_icons: string[]
    features_icons_new_brand: string[]
  }
}

export interface featureItemProps {
  name: string
  items: string[]
}

export const GymFeatures = (props: GymFeaturesProps) => {
  const { text, title, features, icons } = props
  const [activeTabName, setActiveTabName] = useState('')

  return (
    <G.Section bgColor='blue-dark' spacing={8}>
      <G.Container>
        {title && (
          <S.TitleWrapper>
            <BlockTitle textColor='orange' underlineColor='blue'>
              {title}
            </BlockTitle>
          </S.TitleWrapper>
        )}
        <S.TextWrapper>
          <G.TextPara color='white' textCase='upper'>
            {text}
          </G.TextPara>
        </S.TextWrapper>
        <S.FeatureWrapper>
          <S.FeatureItemsWrapper>
            {features &&
              features.map((item: featureItemProps, i: number) => {
                return (
                  <S.FeatureItemWrapper>
                    <S.FeatureItem
                      key={item.name}
                      onClick={() => {
                        if (item.name === activeTabName) {
                          setActiveTabName('')
                        } else {
                          setActiveTabName(item.name)
                        }
                      }}
                      isActive={activeTabName === item.name}
                    >
                      <S.ImgIcon src={icons[i]} />
                      <S.FeatureItemText>{item.name}</S.FeatureItemText>
                    </S.FeatureItem>
                    <S.FeatureContentWrapper
                      active={activeTabName == item.name}
                    >
                      <S.FeatureContentList>
                        {item.items.map(item => {
                          return (
                            <S.FeatureContentItem>{item}</S.FeatureContentItem>
                          )
                        })}
                      </S.FeatureContentList>
                    </S.FeatureContentWrapper>
                  </S.FeatureItemWrapper>
                )
              })}
          </S.FeatureItemsWrapper>
          <S.FeatureWraper>
            {features &&
              features.map((item: featureItemProps) => {
                return (
                  <S.FeatureItemHide>
                    <S.FeatureContentList>
                      {item.items.map(item => {
                        return (
                          <S.FeatureContentItem>{item}</S.FeatureContentItem>
                        )
                      })}
                    </S.FeatureContentList>
                  </S.FeatureItemHide>
                )
              })}
          </S.FeatureWraper>
        </S.FeatureWrapper>
      </G.Container>
    </G.Section>
  )
}
GymFeatures.ApiBlockToProps = (data: GymFeaturesAPIProps) => {
  //return default props for test, will implement data from API later
  if (data.value) {
    let props: GymFeaturesProps = {
      background_colour: data.value.background_colour?.background_colour,
      text: data.value.subtext,
      title: data.value.headline,
      features: Object.keys(data.value.features).map(key => {
        return {
          name: key,
          items: data.value.features[key]
        }
      }),
      icons: data.value.features_icons
    }
    return props
  }
}

export default GymFeatures
