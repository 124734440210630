import { Section } from '../../styles/grid.styles'
import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const Location = styled(Section)`
  ${tw`pt-20`}
  background: linear-gradient(270deg, #2533d5 0%, rgba(0, 0, 0, 0) 31.15%),
    linear-gradient(90deg, #2533d5 0%, rgba(0, 0, 0, 0) 32.44%);
  ${tw`bg-black__deep`}
`

export const Head = styled.div`
  ${tw`flex-col md:flex-row flex items-center justify-between mb-10`}

  h2 {
    ${tw`mb-5 md:mb-0 mr-5`}
  }
`

export const SuggestLocation = styled.button`
  ${tw`bg-white font-extrabold text-1.5xl rounded uppercase leading-tiny px-8 py-2.5 font-youfitnessHeading whitespace-no-wrap`}
`

export const Link = styled.a`
  ${tw`bg-white font-extrabold text-1.5xl rounded uppercase leading-tiny px-8 py-2.5 font-youfitnessHeading whitespace-no-wrap`}
`

export const States = styled.div`
  ${tw`text-center mb-12`}
`
export const State = styled.div<{ active?: boolean }>`
  ${tw`inline-block cursor-pointer text-center text-white font-extrabold text-1.5xl px-8 font-youfitnessHeading py-4 leading-none`}

  ${props =>
    props.active &&
    css`
      ${tw`bg-white rounded text-black`}
    `}
`

export const SuburbsGroup = styled.div`
  ${tw`text-center mb-12`}
`

export const SuburbsGroupItem = styled.div<{ active?: boolean }>`
  ${tw`inline-block cursor-pointer text-center text-white font-extrabold text-1.5xl px-8 font-youfitnessHeading py-4 leading-none`}

  ${props =>
    props.active &&
    css`
      ${tw`bg-white rounded text-black`}
    `}
`

export const Suburbs = styled.div<{ active?: boolean }>`
  ${tw`hidden`}

  ${props =>
    props.active &&
    css`
      ${tw`block`}
    `}
`

export const Note = styled.p`
  ${tw`text-white italic text-base mb-0`}
`

export const LocationItem = styled.div`
  ${tw`border-white relative border cursor-pointer transition duration-300 hover:bg-blue__deep hover:border-blue__deep text-white uppercase text-xl font-bold font-youfitnessHeading p-5 leading-none mb-5`}

  &:after {
    ${tw`absolute bg-no-repeat right-0`}
    top: 50%;
    background-image: url(/static/svgs/icon-angle-right.svg);
    background-size: 5px;
    width: 1.25rem;
    height: 0.625rem;
    content: ' ';
    transform: translateY(-50%);
  }
`

export const LocationItemLink = styled.a`
  ${tw`border-white relative w-full block border cursor-pointer transition duration-300 hover:bg-blue__deep hover:border-blue__deep text-white uppercase text-xl font-bold font-youfitnessHeading p-5 leading-none mb-5`}

  &:after {
    ${tw`absolute bg-no-repeat right-0`}
    top: 50%;
    background-image: url(/static/svgs/icon-angle-right.svg);
    background-size: 5px;
    width: 1.25rem;
    height: 0.625rem;
    content: ' ';
    transform: translateY(-50%);
  }
`
