import { Hero, HeroProps } from '../src/components/hero'
import { Newsletter } from '../src/components/newsletter'
import {
  BlogPostPageProps,
  BlogPostAPIItemProps,
  PageContext,
  AuthorProps
} from '../src/services/api/types'
import { getAuthorById, getPosts } from '../src/services/api/cms'
import { ArticleList } from '../src/components/whats-hot'
import ArticleListYouFitness from '../src/components/whats-hot/YouFitnessTemplate/ArticleList'
import NewsletterYouFitness from '../src/components/newsletter/YouFitnessTemplate/Newsletter'
import {
  ShareBlock,
  AuthorBlock,
  PersonBlockProps,
  ShareBlockProps
} from '../src/components/blocks'
import ShareBlockYouFitness from '../src/components/blocks/YouFitnessTemplate/ShareBlock'
import moment from 'moment'
import * as G from '../src/styles/global.styles'
import { newsletterProps } from '../src/services/mock'
import { NextPage } from 'next'
import { StructureData } from '../src/services/api/SEOHelper'
import { NextSeo, NextSeoProps } from 'next-seo'
import { PAGE_TEMPLATE } from '../src/services/api/constants'
import BlogHero from '../src/components/blog/BlogHero'
import BlogArticle from '../src/components/blog/BlogArticle'
import { Container } from '../src/styles/grid.styles'
import DownloadPDF from '../src/components/blog/DownloadPDF'
import BlogAuthor from '../src/components/blog/BlogAuthor'
import BlogFooter from '../src/components/blog/BlogFotter'

const BlogPost: NextPage<{
  pageData: BlogPostPageProps
  initialData?: any
}> = (props: { pageData: BlogPostPageProps; initialData?: any }) => {
  const {
    pageData,
    pageData: {
      title,
      article,
      categories,
      author,
      date,
      header_image,
      document,
      meta
    },
    initialData
  } = props

  const publishedDate: string = pageData?.meta?.first_published_at || date || ''
  const info = `${moment(publishedDate).format('MMMM Do, YYYY')}
  ${
    categories && categories.length > 0 && categories[0].category
      ? ` in <span>${categories[0]?.category || ''}</span>`
      : ''
  }
  ${author && author.title ? `by ${author.title || ''}` : ''}`
  const blogHeader: HeroProps = {
    slides: [
      {
        bgColor: 'orange',
        bgImgURL: header_image?.url,
        contentPosition: 'left',
        overlay: true,
        contentWidth: '2/3',
        isHeaderGap: true
      }
    ]
  }
  const SEO_Obj = Object.assign(
    StructureData.NewsArticle(pageData),
    meta.structured_data || {}
  )

  const SEO: NextSeoProps = {
    description:
      meta.meta_description === ''
        ? `Click to view the Plus Fitness blog article with the title - ${pageData.title ||
            ''}`
        : meta.meta_description,
    openGraph: {
      images: meta.og_img
        ? [{ url: meta.og_img }]
        : header_image && header_image.url
        ? [{ url: header_image.url }]
        : []
    }
  }

  const shareProps: ShareBlockProps = {
    shareObj: {
      image: header_image?.url,
      shareUrl: meta.path,
      description: title,
      name: title
    }
  }

  const isYouFitness = pageData.template === PAGE_TEMPLATE.YOU_FITNESS

  const blogHero = {
    heading: title,
    image: header_image?.url,
    category: categories && categories.length > 0 ? categories[0].category : '',
    author: author && author.title,
    date: moment(publishedDate).format('MMMM Do, YYYY') || ''
  }

  return isYouFitness ? (
    <main className='bg-black__deep'>
      {SEO_Obj && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(SEO_Obj)
          }}
        />
      )}
      {SEO && <NextSeo {...SEO} />}
      <BlogHero {...blogHero} {...shareProps} />
      <Container className='text-white'>
        <BlogArticle blocks={article} template="YouFitness" />
        {document && (
          <DownloadPDF color='blue-deep' url={document.meta.download_url} />
        )}
        <BlogFooter {...shareProps} info={info} />
      </Container>
      {initialData.author && <BlogAuthor {...initialData.author} />}
      {initialData.relatedPosts && (
        <ArticleListYouFitness
          listTitle='YOU MIGHT BE INTERESTED BY...'
          pagingPerPage={0}
          articles={
            initialData.relatedPosts.length > 2
              ? [initialData.relatedPosts[0], initialData.relatedPosts[1]]
              : initialData.relatedPosts
          }
          totalItemsCount={initialData.relatedPosts.length}
          activePage={1}
          showSortDropdown={false}
          itemsPerRow={2}
          className='pb-16'
        />
      )}
      <NewsletterYouFitness {...newsletterProps} />
    </main>
  ) : (
    <G.FullWidthWrapper className={'bg-grey__light'}>
      {SEO_Obj && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(SEO_Obj)
          }}
        />
      )}
      {SEO && <NextSeo {...SEO} />}
      <Hero {...blogHeader} />

      <G.Container contentSize='lg'>
        <G.TextH1 size='4xl' className={`text-center pt-8 mb-6`}>
          {title}
        </G.TextH1>
        <BlogArticle blocks={article} template="original" />
        {document && (
          <DownloadPDF color='orange' url={document.meta.download_url} />
        )}
      </G.Container>
      <G.FullWidthWrapper className='flex flex-col items-center bg-grey__light'>
        {isYouFitness ? (
          <ShareBlockYouFitness {...shareProps} />
        ) : (
          <ShareBlock {...shareProps} />
        )}
        {initialData.author && <AuthorBlock {...initialData.author} />}
      </G.FullWidthWrapper>
      {initialData.relatedPosts && (
        <ArticleList
          listTitle='YOU MIGHT BE INTERESTED BY...'
          pagingPerPage={0}
          articles={initialData.relatedPosts}
          totalItemsCount={initialData.relatedPosts.length}
          activePage={1}
          showSortDropdown={false}
        />
      )}
      <Newsletter {...newsletterProps} />
    </G.FullWidthWrapper>
  )
}

BlogPost.getInitialProps = async (ctx: PageContext) => {
  const props: {
    pageData: BlogPostPageProps
    initialData?: any
  } = {
    pageData: ctx.pageData
  }

  const { author, categories } = props.pageData
  const firstCat = categories && categories[0]
  let relatedPosts
  // Get data from CMS

  let authorData: PersonBlockProps | undefined = undefined
  if (author) {
    const authorFull: AuthorProps = await getAuthorById(author?.id, ctx)
    if (authorFull) {
      authorData = {
        avatarURL: authorFull.image?.url,
        name: authorFull.name,
        bios: authorFull.headline,
        intro: authorFull.biography,
        excert: authorFull.title
      }
    }
    const relatedPostData = await getPosts(
      {
        type: `blog.BlogPost`,
        blog_categories: firstCat?.category_id ? firstCat?.category_id : null,
        offset: 0,
        limit: 3,
        fields: `author,card_image`
      },
      ctx
    )

    if (relatedPostData && relatedPostData.items) {
      relatedPosts = relatedPostData.items.map((post: BlogPostAPIItemProps) => {
        return {
          title: `In ${firstCat?.category} by ${post.author?.title}`,
          content: post.title,
          action: { label: 'Read article', url: post.meta.html_url },
          imgURL: post.card_image?.url,
          isReskew: false
        }
      })
    }
  }

  props.initialData = {
    relatedPosts,
    author: authorData
  }

  return props
}

export default BlogPost
