import styled from 'styled-components'
import tw from 'twin.macro'
import { BlueButton } from '../../styles/global.styles'
import { Section } from '../../styles/grid.styles'
import Modal from 'styled-react-modal'

export const GymOwners = styled(Section)`
  ${tw`pt-20`}
`

export const Trainer = styled.div`
  ${tw`text-center pt-7`}

  @media (max-width: 767px) {
    ${tw`mx-auto`}
    width: 260px;
  }
`

export const TrainerImage = styled.img`
  ${tw`w-full object-cover object-top`}
  height: 435px;

  @media (max-width: 1024px) {
    height: 290px;
  }
`

export const TrainerName = styled.div`
  ${tw`font-youfitnessHeading bg-white__deep font-bold text-center uppercase p-2.5 text-xl mb-5`}
`

export const TrainerButton = styled(BlueButton)`
  ${tw`uppercase font-youfitnessHeading font-extrabold text-1.5xl leading-tiny`}
`

export const TrainerDetails = Modal.styled`
  width: calc(100% - 8.75rem);
  max-width: 810px;
  ${tw`bg-black__deep fixed max-h-screen overflow-auto p-16`}

  @media (max-width: 767px) {
    ${tw`p-7 w-full`}

    h2 {
      ${tw`mt-4`}
    }
  }
`

export const TrainerInfo = styled.div`
  ${tw`flex-col md:flex-row flex pt-2`}
`

export const TrainerAvatar = styled.div`
  width: 116px;
  flex: 0 0 116px;
  ${tw`mr-10 mb-5 md:mb-0`}

  img {
    with: 120px;
    height: 180px;
    ${tw`object-cover`}
  }
`

export const TrainerDescription = styled.div`
  ${tw`text-white overflow-auto`}
  max-height: 420px;
`

export const CloseWrapper = styled.span`
  ${tw`absolute p-5 top-0 right-0 cursor-pointer`}

  svg {
    ${tw`text-white w-5 h-5`}
  }
`

export const Button = styled(BlueButton)`
  ${tw`font-youfitnessHeading leading-tiny uppercase font-extrabold text-1.5xl justify-center`}
  min-width: 224px;
`

export const ButtonWrap = styled.div`
  ${tw`text-center pt-6`}
`
