import * as S from './WhyOwn.styles'
import * as G from '../../styles/global.styles'
import { Colors, WagtailBlockProps } from '../../services/api/types'
import { HeaderWithTitle } from '../header'

interface BrandLogo {
  logoImgURL: string
  link: string
}
export interface WhyOwnProps {
  bgImgURL: string
  title: string
  text: string
  heading: string
  logos: BrandLogo[]
  background_colour?: Colors
}

interface LogoAPIProps {
  image: string
  logo_link: {
    link_target?: string
    external_link?: string
    page_link?: string
    document_link?: string
  }
}
export interface WhyOwnAPIProps extends WagtailBlockProps {
  value: {
    image: string
    headline: string
    featured_text: string
    text: string
    logos: LogoAPIProps[]
    background_colour: {
      background_colour?: Colors
    }
  }
}

export const WhyOwn = (props: WhyOwnProps) => {
  const { heading, title, text, logos, bgImgURL, background_colour } = props
  return (
    <G.Section position='relative' bgColor={background_colour}>
      <HeaderWithTitle
        headerText={heading}
        background_colour={background_colour}
      />
      <S.Container>
        <S.ImgWrapper>
          <S.Img src={bgImgURL} alt={heading} />
        </S.ImgWrapper>
        <S.ContentWrapper>
          <G.Text
            size='2xl'
            fontWeight='semibold'
            color='orange'
            lineHeight='loose'
          >
            {title}
          </G.Text>
          <G.Text dangerouslySetInnerHTML={{ __html: text }} />
          <S.LogosWrapper>
            {logos &&
              logos.map((logo, i) => (
                <S.Logo key={i}>
                  <S.LogoImg src={logo.logoImgURL} />
                </S.Logo>
              ))}
          </S.LogosWrapper>
        </S.ContentWrapper>
      </S.Container>
    </G.Section>
  )
}
WhyOwn.ApiBlockToProps = (data: WhyOwnAPIProps) => {
  //return default props for test, will implement data from API later
  if (data.value) {
    const apiData = data.value
    let props: WhyOwnProps = {
      bgImgURL: apiData.image,
      heading: apiData.headline,
      title: apiData.featured_text,
      text: apiData.text,
      background_colour: apiData.background_colour.background_colour,
      logos: apiData.logos.map(lg => {
        return {
          logoImgURL: lg.image,
          link:
            lg.logo_link.document_link ||
            lg.logo_link.external_link ||
            lg.logo_link.page_link ||
            '#'
        }
      })
    }
    return props
  }
}
