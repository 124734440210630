import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import * as G from '../../styles/global.styles'
import { TextProps } from '../../services/api/types'

export const Section = styled(G.Section)<{ overlay?: string }>`
  ${tw`z-20`}
  ${({ overlay }) => {
    switch (overlay) {
      case 'top':
        return css`
          margin-top: -2.75rem;

          /* Medium (md) */
          @media (min-width: 768px) {
            margin-top: -3.25rem;
          }

          /* Large (lg) */
          @media (min-width: 1024px) {
            margin-top: -4.125rem;
          }
        `
      case 'bottom':
        return css`
          margin-bottom: 1.75rem;
          /* Medium (md) */
          @media (min-width: 768px) {
            margin-bottom: 0.5rem;
          }

          /* Extra Large (xl) */
          @media (min-width: 1280px) {
            margin-bottom: 0.25rem;
          }
        `
      default:
        return null
    }
  }}
`
export const ContentWrapper = styled.div`
  ${tw`w-full flex flex-col text-center mt-8 transform -skew-y-3`}
`
export const Blocks = styled.div<{ isCentralBlock?: boolean }>`
  ${tw`w-full flex flex-wrap text-center items-stretch`}
`

export const Block = styled.div<{ bgImage?: string; isCentralBlock?: boolean }>`
  ${tw`w-full lg:w-1/2 px-8 lg:px-16 py-16 md:px-24`}
  ${({ bgImage }) =>
    bgImage && `background:url('${bgImage}') center/cover no-repeat;`}

  ${({ isCentralBlock }) => isCentralBlock && tw`pb-12 lg:pb-40`}

  & {
    :last-child {
      ${({ isCentralBlock }) => isCentralBlock && tw`pb-40`}
    }
`
export const ButtonWrapper = styled.div`
  ${tw`py-8`}
`

export const Content = styled.div<{ isRight?: boolean }>`
  ${tw`transform skew-y-3 flex flex-col justify-between h-full`}
  ${({ isRight }) => (isRight ? tw`lg:pr-24` : tw`lg:pl-24`)}
  ${({ isRight }) =>
    isRight &&
    `/* Large (lg) */
  @media (min-width: 1024px) {
    margin-top: 2rem;
  }`}
  
`

export const Button = styled(G.ButtonLink)`
  ${tw`px-8 lg:px-12 lg:py-4`}
`

export const CentralButtonWrapper = styled.div`
  margin-top: -6rem;
  ${tw`transform skew-y-3`}
`

export const LargeText = styled(G.TextH2)`
  ${tw`mb-0`}
  ${({ size }) => {
    switch (size) {
      case '6xl':
        return tw`leading-8`
      case '4xl':
        return tw`leading-8`
      case '2xl':
        return tw`leading-6`
    }
  }}
`

export const MsgText = styled.p<TextProps>`
  ${tw`text-xl font-bold mb-0`}
  ${({ color }) => {
    switch (color) {
      case 'black':
        return tw`text-black`
      case 'white':
        return tw`text-white`
      case 'blue':
        return tw`text-blue`
      case 'blue-dark':
        return tw`text-blue__dark`
      case 'blue-grey':
        return tw`text-blue__grey`
      case 'blue-dodger':
        return tw`text-blue__dodger`
      case 'orange':
        return tw`text-orange`
      case 'orange-dark':
        return tw`text-orange__dark`
      case 'green':
        return tw`text-green`
      case 'grey':
        return tw`text-grey`
      case 'grey-light':
        return tw`text-grey__light`
      case 'grey-medium':
        return tw`text-grey__medium`
      case 'grey-dark':
        return tw`text-grey__dark`
      case 'silver':
        return tw`text-silver`
      default:
        return null
    }
  }}
`
