import { WagtailBlockProps } from '../../services/api/types'
import React from 'react'
import * as G from '../../styles/global.styles'
import * as S from './SocialLinks.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faGooglePlusG,
  faInstagramSquare,
  faPinterestP,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'

import { isIOS13, isIPad13, isIPhone13, isIPod13 } from 'react-device-detect'
import {IconEnums} from "../base";

export interface SocialLinksProps {
  twitterUrl?: string
  facebookUrl?: string
  googlePlusUrl?: string
  pinterestUrl?: string
  youtubeUrl?: string
  instagramUrl?: string
  threadsUrl?: string
}

export interface SocialLinksAPIProps extends WagtailBlockProps {
  value: {
    twitter?: string
    facebook?: string
    youtube?: string
    pinterest?: string
    instagram?: string
    threads?: string
  }
}

export const SocialLinks = (props: SocialLinksProps) => {
  const {
    twitterUrl,
    facebookUrl,
    googlePlusUrl,
    pinterestUrl,
    instagramUrl,
    threadsUrl
  } = props

  const openFacebookFanPage = () => {
    if (!facebookUrl) {
      return
    }

    if (isIPhone13 || isIPad13 || isIOS13 || isIPod13) {
      const fanPageId = extractStringFromURL(facebookUrl)
      if (fanPageId) {
        window.location.href = "fb://profile/" + fanPageId
      }
      
      setTimeout(() => {
        window.location.href = facebookUrl
      }, 500)
    } else {
      window.location.href = facebookUrl;
    }
  }

  const extractStringFromURL = (url: string): string | null => {
    const regex = /-(\d+)\//
    const match = url.match(regex)
  
    if (match && match[1]) {
      return match[1]
    } else {
      return null
    }
  }



  return (
    <G.Section>
      <G.Container>
        <S.ContenWrapper>
          {facebookUrl && (
            <S.IconWrapper onClick={openFacebookFanPage} color='facebook'>
              <FontAwesomeIcon icon={faFacebookF} />
            </S.IconWrapper>
          )}
          {threadsUrl && (
              <S.IconWrapper target='_blank' href={threadsUrl} color='threads'>
                <S.Icon name={IconEnums['icon-threads']} />
              </S.IconWrapper>
          )}
          {twitterUrl && (
            <S.IconWrapper target='_blank' href={twitterUrl} color='twitter'>
              <FontAwesomeIcon icon={faTwitter} />
            </S.IconWrapper>
          )}
          {instagramUrl && (
            <S.IconWrapper
              target='_blank'
              href={instagramUrl}
              color='instagram'
            >
              <FontAwesomeIcon icon={faInstagramSquare} />
            </S.IconWrapper>
          )}
          {pinterestUrl && (
            <S.IconWrapper
              target='_blank'
              href={pinterestUrl}
              color='pinterest'
            >
              <FontAwesomeIcon icon={faPinterestP} />
            </S.IconWrapper>
          )}
          {googlePlusUrl && (
            <S.IconWrapper
              target='_blank'
              href={googlePlusUrl}
              color='google-plus'
            >
              <FontAwesomeIcon icon={faGooglePlusG} />
            </S.IconWrapper>
          )}
        </S.ContenWrapper>
      </G.Container>
    </G.Section>
  )
}
SocialLinks.ApiBlockToProps = (data: SocialLinksAPIProps) => {
  const { value } = data
  if (data.value) {
    let props: SocialLinksProps = {
      facebookUrl: value.facebook,
      twitterUrl: value.twitter,
      pinterestUrl: value.pinterest,
      instagramUrl: value.instagram,
      youtubeUrl: value.youtube,
      threadsUrl: value.threads,
    }
    return props
  }
}

export default SocialLinks
