import * as S from './ImageLink.styled'
import { WagtailBlockProps } from '../../../services/api/types'
import { BlockTitle } from '../../base'
import { Container, Section } from '../../../styles/grid.styles'

export interface ImagelinkProps {
  title?: string
  imgUrl: string
  link?: string
  target?: string
  align?: 'left' | 'center' | 'right'
}

export interface ImagelinkAPIProps extends WagtailBlockProps {
  value: {
    headline: string
    image: string
    image_link?: {
      link_target?: string
      external_link?: string
      page_link?: string
      document_link?: string
    }
    content_alignment?: {
      content_alignment: 'left' | 'center' | 'right'
    }
  }
}

export const ImageLink = (props: ImagelinkProps) => {
  const { imgUrl, link, target, title, align } = props
  return (
    <Section>
      {title && <BlockTitle isYouFitness>{title}</BlockTitle>}
      <Container>
        <S.ContentWrapper contentAlign={align}>
          {link ? (
            <S.Link href={link} target={target}>
              <S.ImgWrap>
                <S.IMG src={imgUrl} alt={title} />
              </S.ImgWrap>
            </S.Link>
          ) : (
            <S.ImgWrap>
              <S.IMG src={imgUrl} alt={title} />
            </S.ImgWrap>
          )}
        </S.ContentWrapper>
      </Container>
    </Section>
  )
}

ImageLink.ApiBlockToProps = (data: ImagelinkAPIProps): ImagelinkProps => {
  const { value } = data
  return {
    imgUrl: value.image,
    link:
      value.image_link?.external_link ||
      value.image_link?.page_link ||
      value.image_link?.document_link,
    target: value.image_link?.link_target,
    align: value.content_alignment?.content_alignment,
    title: value.headline
  }
}
