import styled from 'styled-components'
import tw from 'twin.macro'

export const Media = styled.div``

export const ImageWrap = styled.div`
  ${tw`relative mb-4`};
  height: 166px;
`

export const Image = styled.img`
  ${tw`absolute w-full h-full object-cover`};
  top: 0;
  left: 0;
`
