import React from 'react'
import Blocks from './blocks'
import { NextSeoProps } from 'next-seo'
import { OpenGraph, Twitter } from 'next-seo/lib/types'
import { isNullOrEmpty } from '../../services/utils'

export const componentFromBlockType = (blockType: string) => {
  // Takes the block "type" value from the API and returns the component
  return Blocks(blockType) != undefined && Blocks(blockType)
    ? Blocks(blockType)
    : React.Component
}

export const WagtailMetaToNextSEO = (pageData: any, meta: any) => {
  // Converts the "meta" from the API into an NextSEO object
  let seo: NextSeoProps = {}
  if (!meta) {
    return seo
  }
  seo.title =
    meta.meta_title && meta.meta_title != '' ? meta.meta_title : pageData.title

  if (meta.description || meta.meta_description) {
    seo.description = meta.description || meta.meta_description
  }

  if (meta.canonical) {
    seo.canonical = meta.canonical
  } else if (meta.path) {
    seo.canonical = `${meta.path}`
  }

  let openGraph: OpenGraph = {}
  openGraph.url = !isNullOrEmpty(meta.og_url) ? meta.og_url : seo.canonical
  openGraph.type = !isNullOrEmpty(meta.og_type) ? meta.og_type : ''
  openGraph.title = !isNullOrEmpty(meta.og_title) ? meta.og_title : seo.title
  openGraph.description = !isNullOrEmpty(meta.og_description) ? meta.og_description : seo.description
  openGraph.images = meta.og_img ? [meta.og_img] : []
  seo.openGraph = openGraph

  if (meta.twitter_card || meta.twitter_site || meta.twitter_creator) {
    let twitter: Twitter = {}
    twitter.cardType = meta.twitter_card ? meta.twitter_card : null
    twitter.site = meta.twitter_site ? meta.twitter_site : null
    twitter.handle = meta.twitter_creator ? meta.twitter_creator : null
    seo.twitter = twitter
  }

  return seo
}
