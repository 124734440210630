import { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import {
  AvatarWrapper,
  AvatarFrame,
  AvatarInner,
  InformationSection,
  PersonName,
  PersonBios,
  PersonIntro,
  ReadButton
} from './PersonBlock.styled'

export interface PersonBlockProps {
  avatarURL?: string
  avatarFrame?: string
  name?: string
  bios?: string
  excert: string
  intro: string
  viewMore?: Function
}

export const PersonBlock = (props: PersonBlockProps) => {
  const { avatarURL, avatarFrame, name, bios, excert, intro, viewMore } = props
  const [introCollapsed, setIntroCollapsed] = useState(true)
  const [readBtnText, setReadBtnText] = useState('read more')
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  })
  const expandDesc = () => {
    setIntroCollapsed(!introCollapsed)

    if (!introCollapsed) {
      setReadBtnText('read more')
    } else {
      setReadBtnText('close')
    }
  }

  return (
    <>
      <AvatarWrapper>
        <AvatarFrame src={avatarFrame} alt={name} />
        <AvatarInner ref={ref} avatarImgURL={inView ? avatarURL : ''} />
      </AvatarWrapper>
      <InformationSection>
        <PersonName>{name}</PersonName>
        <PersonBios>{bios}</PersonBios>
        <PersonIntro collapsed={introCollapsed}>
          <p dangerouslySetInnerHTML={{ __html: excert }} />
          <p dangerouslySetInnerHTML={{ __html: intro }} />
        </PersonIntro>
        <ReadButton onClick={viewMore ? () => viewMore() : expandDesc}>
          {readBtnText}
        </ReadButton>
      </InformationSection>
    </>
  )
}

export default PersonBlock
