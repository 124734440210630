import * as S from './GalleryRow.styles'
import { FullWidthWrapper, FullWidthImg } from '../../styles/global.styles'

export interface GalleryRowProps {
  imgUrls: string[]
  itemsPerRow: number
}

export const GalleryRow = (props: GalleryRowProps) => {
  const { imgUrls, itemsPerRow } = props
  return (
    <FullWidthWrapper>
      <S.GalleryWrapper>
        {imgUrls &&
          imgUrls.map((imgUrl, idx) => (
            <S.GalleryItem key={idx} itemsPerRow={itemsPerRow}>
              <FullWidthImg src={imgUrl} />
            </S.GalleryItem>
          ))}
      </S.GalleryWrapper>
    </FullWidthWrapper>
  )
}
