import styled from 'styled-components'
import tw from 'twin.macro'

export const ContentWrapper = styled.div`
  ${tw`w-full flex flex-wrap content-center justify-center items-stretch`}
`

export const Block = styled.div`
  ${tw`w-full lg:w-1/3 flex flex-col content-center p-6 text-center items-start`}
`
export const ImgWrapper = styled.div`
  ${tw`text-center mb-4 mx-auto`}
`
export const IMG = styled.img`
  ${tw`h-auto w-auto mx-auto`}
`

export const TitleWrapper = styled.div`
  ${tw`py-4 w-full text-center`}
`
