import styled from 'styled-components'

const ResponsiveCoontainer = styled.div`
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
export const ResponsiveEmbededVideo = (props: { url: string }) => {
  const { url } = props
  return (
    <ResponsiveCoontainer>
      <iframe width='480' height='270' src={url} />
    </ResponsiveCoontainer>
  )
}
export default ResponsiveEmbededVideo
