import { VideoPlayer } from '../blocks/YouFitnessTemplate/VideoBlock'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import * as S from './ModalSlider.styled'
import { useState, useEffect } from 'react'

export interface ModalSliderProps {
  isOpen: boolean
  toggleModal: () => void
  initIndex: number
  media: {
    image?: string
    video?: string
  }[]
}

const ModalSlider: React.FC<ModalSliderProps> = ({
  isOpen,
  toggleModal,
  initIndex,
  media
}) => {
  const [selectedSlide, setSelectedSlide] = useState<number>(-1)

  useEffect(() => {
    setSelectedSlide(initIndex)
  }, [initIndex])

  return (
    <S.SliderModal isOpen={isOpen}>
      <S.Close onClick={toggleModal}>&times;</S.Close>
      <S.SliderWrap>
        <Carousel
          showStatus={false}
          infiniteLoop={false}
          selectedItem={selectedSlide}
          showThumbs={false}
          onChange={idx => setSelectedSlide(idx)}
        >
          {media.map((it: any, key: number) =>
            it.video ? (
              <S.CarouselItem key={key}>
                <VideoPlayer
                  videoURL={it.video}
                  thumbnailURL={it.image}
                  autoPlay={selectedSlide === key}
                />
              </S.CarouselItem>
            ) : (
              <S.CarouselItem key={key}>
                <img src={it.image} alt='' />
              </S.CarouselItem>
            )
          )}
        </Carousel>
      </S.SliderWrap>
    </S.SliderModal>
  )
}

export default ModalSlider
