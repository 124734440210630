import styled from 'styled-components'
import tw from 'twin.macro'

export const TitleWrapper = styled.div`
  ${tw`w-full lg:px-8 flex flex-col items-start justify-start lg:flex-row lg:items-center lg:justify-between`}
`

export const FranchiseWrapper = styled.div`
  ${tw`w-full flex flex-wrap items-stretch justify-center`}
`

export const FranchiseItem = styled.div`
  ${tw`flex flex-col items-stretch w-full lg:w-1/2`}
`

export const FranchiseEmpty = styled.div`
  ${tw`w-full pt-6 flex justify-center items-center`}
`
