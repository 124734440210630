import { Link } from '../../../../services/api/types'
import { SocialCardProps } from './commonTypes'
import { LazyImage } from '../../../lazy-image'
import PlaceholderImage from '../../../../static/imgs/placeholder.jpg'
import * as S from './ArticleCard.styled'

export interface ArticleCardProps extends SocialCardProps {
  title?: string
  content?: string
  action?: Link
  imgURL?: string
  isBlogCard?: boolean
}

export const ArticleCard = (props: ArticleCardProps) => {
  const { title, action, imgURL } = props

  return (
    <S.Article>
      <S.ArticleImage href={action?.url} target={action?.target}>
        <LazyImage src={imgURL || PlaceholderImage} alt={title} />
      </S.ArticleImage>
      <S.ArticleTitle>{title}</S.ArticleTitle>
      <S.ArticleLink href={action?.url} target={action?.target}>
        {action?.label}
      </S.ArticleLink>
    </S.Article>
  )
}

export default ArticleCard
