import tw from 'twin.macro'
import styled from 'styled-components'
import { size } from '../../../styles/theme'
import { SVGIcon } from '../../base/icon/svgIcon'

export const ListArticleWrapper = styled.div`
  ${tw`w-full flex flex-col my-4 m-auto bg-black__deep`}
`

export const ArticleCardWrapper = styled.div`
  ${tw`flex flex-wrap justify-center -mx-2`}
`

export const TrainerWrap = styled.div`
  ${tw`w-full flex justify-center`}
  padding-top: ${size(25)};

  @media (max-width: 768px) {
    ${tw`flex-col`}
  }
`

export const Socials = styled.div`
  ${tw`flex justify-center items-center w-full mt-8 md:mt-10`}
`

export const ButtonWrap = styled.div`
  ${tw`text-center pt-8 md:pt-10`}
`

export const SocialsLink = styled.a`
  width: 44px;
  height: 44px;
  ${tw`bg-blue__deep text-white hover:bg-blue__dark2 rounded-full mx-2.5 flex items-center justify-center transition duration-300`}
`

export const Icon = styled(SVGIcon)`
  svg {
    max-width: 17px;
  }
`
