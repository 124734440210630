import * as G from '../../../styles/global.styles'
import * as S from './MainSection.styled'
import { Colors, ImageLink } from '../../../services/api/types'
import {
  maximumParagraphInShortContent,
  YOU_FITNESS
} from '../../../services/api/constants'
import { MainSectionAPIProps } from '../MainSection'
import { Section, Container } from '../../../styles/grid.styles'
import { BlockTitle } from '../../base'
import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

export interface MainSectionProps {
  title?: string
  content?: string
  images?: ImageLink[]
  viewMoreLink?: boolean
  contentAlign?: 'left' | 'center' | 'right'
  background_colour?: Colors
}

export const MainSection = (props: MainSectionProps) => {
  const { images, title, content, contentAlign, viewMoreLink } = props

  const contentRef = useRef(null)

  const [expanded, setExpanded] = useState(false)
  const [isLessText, setIsLessText] = useState(true)

  const handleClickToggle = () => {
    if (expanded) {
      if (contentRef && contentRef.current) {
        const node = ReactDOM.findDOMNode(contentRef.current) as Element
        node.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
    setExpanded(!expanded)
  }

  useEffect(() => {
    setIsLessText(false)
    if (content && content?.length < maximumParagraphInShortContent) {
      setIsLessText(true)
    }
  }, [content])

  return (
    <Section>
      <Container>
        {title && <BlockTitle isYouFitness>{title}</BlockTitle>}
        {content && (
          <S.ContentWrap>
            <S.Content
              style={{
                textAlign: contentAlign
              }}
              dangerouslySetInnerHTML={{
                __html:
                  !viewMoreLink || isLessText || expanded
                    ? content
                    : `${content.slice(0, maximumParagraphInShortContent)}...`
              }}
            />
            {viewMoreLink && !isLessText && (
              <S.ReadMore onClick={() => handleClickToggle()}>
                {expanded ? '<< View less' : 'View more >>'}
              </S.ReadMore>
            )}
          </S.ContentWrap>
        )}
        {images &&
          !!images.length &&
          images.map((val, index) => (
            <S.GymCompMainSection key={index} custom={index % 2 ? true : false}>
              <S.GymCompMainSectionRight custom={index % 2 ? true : false}>
                <p>{val.imageAlt}</p>
                <G.BlueButtonLink target={val.imageUrlTarget} href={val.url}>
                  {YOU_FITNESS.learnMoreBtn}
                </G.BlueButtonLink>
              </S.GymCompMainSectionRight>
              <S.GymCompMainSectionLeft>
                <G.DefaultImg
                  objectFit={'cover'}
                  src={val.imageUrl}
                  alt={val.imageAlt}
                  style={{ filter: 'none' }}
                />
                <S.GymCompMainSectionLeftCustom>
                  <p>{val.imageAlt}</p>
                  <span>{YOU_FITNESS.descMainSectionMobileLeft}</span>
                  <S.GymCompMainSectionLink
                    target={val.imageUrlTarget}
                    href={val.url}
                  >
                    {YOU_FITNESS.learnMoreBtn}
                  </S.GymCompMainSectionLink>
                </S.GymCompMainSectionLeftCustom>
              </S.GymCompMainSectionLeft>
            </S.GymCompMainSection>
          ))}
      </Container>
    </Section>
  )
}

MainSection.ApiBlockToProps = (data: MainSectionAPIProps): MainSectionProps => {
  const { value, type } = data
  switch (type) {
    case 'title_and_richtext':
      return {
        content: value.content,
        title: value.headline,
        contentAlign: value.content_alignment?.content_alignment,
        viewMoreLink: value?.view_more_link
      }

    default:
      return {
        content: value.description,
        title: value.title,
        images:
          value.promo_banners &&
          value.promo_banners.map(item => {
            return {
              imageUrl: item.image,
              url:
                item.banner_link.page_link ||
                item.banner_link.external_link ||
                item.banner_link.document_link ||
                '',
              imageAlt: item.image_alt_text,
              imageUrlTarget: item.banner_link.link_target
            }
          }),
        background_colour: value.background_colour.background_colour
      }
  }
}
export default MainSection
