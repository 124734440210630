import Link from 'next/link'
import { Container } from '../../styles/grid.styles'
import { ShareBlock, ShareBlockProps } from '../blocks'
import * as S from './BlogHero.styled'

export interface BlogHeroProps extends ShareBlockProps {
  heading: string
  image?: string
  author?: string
  date?: string
  category?: string
}

const BlogHero: React.FC<BlogHeroProps> = ({
  date,
  heading,
  image,
  author,
  category,
  ...props
}) => {
  return (
    <S.BlogHero>
      <Container>
        <S.BreadCrumb>
          <Link href='/' passHref>
            <S.BreadCrumbItemLink>Home</S.BreadCrumbItemLink>
          </Link>
          <Link href='/blog' passHref>
            <S.BreadCrumbItemLink>Blog</S.BreadCrumbItemLink>
          </Link>
          {category && <S.BreadCrumbItem category>{category}</S.BreadCrumbItem>}
          <S.BreadCrumbItem>{heading}</S.BreadCrumbItem>
        </S.BreadCrumb>
        <S.Heading>{heading}</S.Heading>
        <S.GroupInfo>
          <S.Info>
            {date}
            {category ? <> in <span>{category}</span></> : ''} by {author}
          </S.Info>
          <ShareBlock template='you_fitness' {...props} />
        </S.GroupInfo>
      </Container>

      {image ? (
        <S.ImageWrap>
          <Container>
            <S.BlogImage src={image} alt={heading} />
          </Container>
        </S.ImageWrap>
      ) : (
        <div style={{ height: 1 }} />
      )}
    </S.BlogHero>
  )
}

export default BlogHero
