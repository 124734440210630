import { ArticleCard, ArticleCardProps } from './cards'
import { SubHeader } from '../header'
import { ListArticleWrapper, ArticleCardWrapper } from './WhatsHot.styles'
import { Pagination } from '../pagination'
import {
  ARTICLE_SORT_OPTIONS,
  EMPTY_ARTICLE_MESSEGE
} from '../../services/api/constants'
import * as G from '../../styles/global.styles'
import { MutableRefObject } from 'react'

export interface ArticleListProps {
  listTitle: string
  articles?: ArticleCardProps[]
  pagingPerPage: number
  totalItemsCount: number
  activePage: number
  showSortDropdown: boolean
  refElm?: MutableRefObject<HTMLDivElement>
  onPageChange?: Function
  onOrderByChange?: Function
}

export const ArticleList = (props: ArticleListProps) => {
  const {
    listTitle,
    articles,
    pagingPerPage,
    activePage,
    totalItemsCount,
    showSortDropdown,
    onPageChange,
    refElm,
    onOrderByChange
  } = props

  return (
    <ListArticleWrapper ref={refElm}>
      <SubHeader
        subHeaderText={listTitle}
        onSortOptionChange={onOrderByChange}
        dropdownOptions={ARTICLE_SORT_OPTIONS}
        showDropdown={showSortDropdown}
        showSeparator
      />
      <G.Container position='relative' contentSize='lg'>
        <ArticleCardWrapper>
          {articles && articles.length > 0 ? (
            articles.map((article, idx) => (
              <ArticleCard key={idx} {...article} isBlogCard={true} />
            ))
          ) : (
            <span>{EMPTY_ARTICLE_MESSEGE}</span>
          )}
        </ArticleCardWrapper>
      </G.Container>
      {pagingPerPage > 0 && totalItemsCount > (pagingPerPage || 5) && (
        <div className='py-8 w-full'>
          <Pagination
            onChange={onPageChange}
            totalItemsCount={totalItemsCount}
            itemsCountPerPage={pagingPerPage || 5}
            activePage={activePage}
            scrollTo={refElm && refElm.current ? refElm.current.offsetTop : 0}
          />
        </div>
      )}
    </ListArticleWrapper>
  )
}

export default ArticleList
