import styled from 'styled-components'
import tw from 'twin.macro'
import { BlueButton, BlueButtonLink } from '../../styles/global.styles'

export const Header = styled.nav<{ fixed?: boolean }>`
  ${tw`flex fixed top-0 w-full items-center bg-black__deep px-4 md:px-10 py-4.5 justify-between`}
  z-index: 99;

  @media (max-width: 767px) {
    a:last-child {
      ${tw`text-sm! px-4!`}
    }
  }
`

export const Link = styled(BlueButtonLink)`
  ${tw`uppercase font-semibold font-youfitnessHeading text-1.5xl py-1.5`}
`

export const Logo = styled.a`
  img {
    height: 44px;
  }

  @media (max-width: 767px) {
    img {
      height: 30px;
    }
  }
`

export const Button = styled(BlueButton)`
  ${tw`uppercase font-extrabold font-youfitnessHeading text-sm px-4 md:px-8 md:text-1.5xl leading-tiny`}
`
