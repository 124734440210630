import styled from 'styled-components'
import tw from 'twin.macro'
import * as G from '../../styles/global.styles'

export const AngledBannerWrapper = styled.div<{ src: string }>`
  position: relative;
  ${tw`w-full md:px-10 md:py-16 lg:px-16 lg:py-32 bg-cover bg-no-repeat bg-top flex items-center justify-center content-center`}
  ${({ src }) => {
    return `background-image: url('${src}');`
  }}
`

export const AngledBG = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  z-index: 0;
  ${tw`transform md:-skew-y-3 absolute`}
  ${({ color }) => color && G.getBgColor(color)}
`

export const AngledContent = styled.div`
  ${tw`w-full h-full p-4 p-10 md:p-16 lg:p-24 flex flex-col items-center justify-center content-center relative`}
`

export const AngledLogo = styled.img`
  color: white;
  width: 180px;
  height: auto;
  margin-bottom: 1rem;
  position: relative; 
  z-index: 1;
`

export const AngledTitle = styled.div`
  ${tw`w-full text-white flex justify-center uppercase font-bold mb-2 relative`}
  z-index: 1;
`

export const ButtonWrapper = styled.div`
  ${tw`py-0`}
`

export const Button = styled(G.ButtonLink)`
  ${tw`px-4 lg:px-8 lg:py-2`}
`
