import { PAGE_TEMPLATE } from '../../services/api/constants'
import NavigationNewGymTemplate from './YouFitnessTemplate/Navigation'
import Navigation from './Navigation'

const NavigationTemplate = (name?: string) => {
  switch (name) {
    case PAGE_TEMPLATE.YOU_FITNESS:
      return NavigationNewGymTemplate

    default:
      return Navigation
  }
}

export default NavigationTemplate
