import { useContext } from 'react'
import { SiteContext } from '../../services/context/SiteContext'
import * as S from './Header.styled'
import RegisterYourInterestModal from './RegisterYourInterestModal'

export const Header = () => {
  const { siteInfor, formBrochure } = useContext(SiteContext)

  return (
    <S.Header>
      <S.Logo href='/'>
        <img src='/static/svgs/franchise-logo.svg' alt='' />
      </S.Logo>
      {siteInfor?.isDownloadable ? (
        <S.Link
          href={
            formBrochure && formBrochure.linkUrl ? formBrochure.linkUrl : ''
          }
          target={'_blank'}
          download
        >
          {'ENQUIRE NOW'}
        </S.Link>
      ) : (
        <RegisterYourInterestModal
          button={<S.Button>ENQUIRE NOW</S.Button>}
          form={formBrochure}
          downloadFileOnSuccess={true}
          fileUrl={
            formBrochure && formBrochure.linkUrl ? formBrochure.linkUrl : ''
          }
        />
      )}
    </S.Header>
  )
}

export default Header
