import styled from 'styled-components'
import tw from 'twin.macro'
import * as G from '../../styles/global.styles'
import bg from '../../static/imgs/circle-grey.png'
import { size } from '../../styles/theme'

export const ContentWrapper = styled.div`
  ${tw`w-full flex flex-wrap items-center justify-center content-center`}
`
export const ItemWrapper = styled.div`
  ${tw`m-4`}
`

export const AvatarWrapper = styled.div`
  width: 300px;
  max-width:300px;
  background-image: url(${bg});
  ${tw`p-6 bg-contain bg-no-repeat bg-center flex items-center justify-center content-center mb-4`};
`
export const Avatar = styled.div`
  width: 240px;
  height: 240px;
  border-radius: 50%;
  overflow: hidden;
  ${tw`max-w-full max-h-full`}
`

export const Name = styled(G.Text)`
  ${tw`mb-4`}
`
export const Readmore = styled(G.Link)`
  ${tw`text-orange hover:text-blue`}
`

export const PopupWrapper = styled.div`
  ${tw`fixed w-screen h-screen z-40 flex items-center justify-center content-center p-4`}
  z-index: 100;
  padding-top: 0;
  padding-bottom: 0;
  top: 0;

  @media (max-width: 414px) {
    align-items: flex-start;
    overflow-y: auto;
  }
`

export const PopupContainer = styled.div`
  ${tw`w-full bg-white w-full lg:w-3/4 xl:w-2/3 py-12 px-4 md:px-12 relative shadow-2xl`}
`
export const Close = styled.a`
  ${tw`absolute top-0 right-0 pr-4 md:pr-8 text-4xl font-bold text-grey__medium hover:text-blue cursor-pointer`}
`
export const PopupContent = styled.div`
  ${tw`w-full flex flex-col md:flex-row`}
  overflow-y:auto;
`
export const AvatarWrapperPopup = styled.div`
  ${tw`flex flex-col`}
  @media (max-width: 414px) {
    max-width:15rem;
  }
`
export const PopupAvatar = styled.img`
  ${tw`max-w-sm flex-initial`}
`
export const PopupInfo = styled.div`
  ${tw`flex-1 pt-4 px-0 md:pr-8 md:pl-12 md:pt-0`}
`
export const PhoneWrapper = styled.div`
  ${tw`py-4`}
`

export const PhoneIconWrapper = styled.span`
  ${tw`mr-2`}
`

export const TitleWrapper = styled.div`
  ${tw`py-4 w-full text-center`}

`

export const TextDescription = styled.div`
  ${tw`text-grey__medium`}
  overflow-y:auto;
  height: ${size(313)}
  `
