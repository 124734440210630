import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import * as S from './VideoBlock.styled'
import playButtonImg from '../../static/imgs/playbutton.png'
import { WagtailBlockProps } from '../../services/api/types'
import { useInView } from 'react-intersection-observer'
import { LazyImage } from '../lazy-image'
import { VideoPlayerImgAlt } from '../../services/api/constants'

export interface VideoBlocksProps {
  thumbnailImageURL?: string
  leftThumbnailURL?: string
  leftVideoTitle?: string
  leftVideoURL?: string
  leftVideoDesc?: string
  rightThumbnailURL?: string
  rightVideoTitle?: string
  rightVideoURL?: string
  rightVideoDesc?: string
  isShowTwoVideo?: boolean
}

export interface PlayButtonProps {
  buttonImgURL?: string
  onPlayClick?: Function
  altText?: string
}

export interface VideoPlayerProps {
  thumbnailURL?: string
  videoTitle?: string
  videoURL?: string
  videoDesc?: string
  isShowTwoVideo?: boolean
}

export interface VideoAPIProps {
  video_thumbnail: string
  video_title: string
  video_description: string
  video_url: string
}

export interface VideoBlocksAPIProps extends WagtailBlockProps {
  value: {
    video_embeds: VideoAPIProps[]
  }
}

export const PlayButton = (props: PlayButtonProps) => {
  const { buttonImgURL, onPlayClick, altText } = props

  return (
    <S.PlayButtonWrap
      onClick={() => {
        onPlayClick && onPlayClick()
      }}
    >
      <LazyImage src={buttonImgURL || playButtonImg} alt={altText} />
    </S.PlayButtonWrap>
  )
}

export const VideoPlayer = (props: VideoPlayerProps) => {
  const [playing, setPlaying] = useState(false)
  const [displayEmbed, setDisplayEmbed] = useState(false)
  const playerRef = React.useRef<ReactPlayer>(null)
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  })

  const {
    thumbnailURL,
    videoTitle,
    videoURL,
    videoDesc,
    isShowTwoVideo
  } = props

  const displayPlay = () => {
    setPlaying(true)
    setDisplayEmbed(true)
  }

  return (
    <S.PlayerWrapper
      ref={ref}
      thumbURL={inView ? thumbnailURL : ''}
      isShowTwoVideo={isShowTwoVideo}
    >
      <S.VideoTitle>{videoTitle}</S.VideoTitle>
      <PlayButton onPlayClick={displayPlay} altText={VideoPlayerImgAlt} />
      <S.ReactPlayerWrapper active={displayEmbed}>
        <ReactPlayer
          className='react-player'
          ref={playerRef}
          playing={playing}
          light={false}
          height='100%'
          url={videoURL}
          onPause={() => {
            setPlaying(false)
            setDisplayEmbed(false)
          }}
          config={{
            vimeo: {
              playerOptions: {
                controls: true,
                playsinline: true
              }
            }
          }}
        />
      </S.ReactPlayerWrapper>
      <S.VideoDescription
        dangerouslySetInnerHTML={{ __html: videoDesc || '' }}
      />
    </S.PlayerWrapper>
  )
}

export const VideoBlock = (props: VideoBlocksProps) => {
  const {
    leftThumbnailURL,
    leftVideoTitle,
    leftVideoURL,
    leftVideoDesc,
    rightThumbnailURL,
    rightVideoTitle,
    rightVideoURL,
    rightVideoDesc,
    isShowTwoVideo
  } = props

  return leftVideoURL || rightVideoURL ? (
    <S.VideoWrapper isShowTwoVideo={isShowTwoVideo}>
      <VideoPlayer
        thumbnailURL={leftThumbnailURL}
        videoTitle={leftVideoTitle}
        videoURL={leftVideoURL}
        videoDesc={leftVideoDesc}
        isShowTwoVideo={isShowTwoVideo}
      />
      {isShowTwoVideo && (
        <VideoPlayer
          thumbnailURL={rightThumbnailURL}
          videoTitle={rightVideoTitle}
          videoURL={rightVideoURL}
          videoDesc={rightVideoDesc}
          isShowTwoVideo={isShowTwoVideo}
        />
      )}
    </S.VideoWrapper>
  ) : null
}

VideoBlock.ApiBlockToProps = (data: VideoBlocksAPIProps) => {
  const { value } = data
  const props: VideoBlocksProps = {
    isShowTwoVideo: false
  }

  if (value.video_embeds[0]) {
    props.leftVideoURL = value.video_embeds[0].video_url
    props.leftThumbnailURL = value.video_embeds[0].video_thumbnail
    props.leftVideoDesc = value.video_embeds[0].video_description
    props.leftVideoTitle = value.video_embeds[0].video_title
  }

  if (value.video_embeds[1]) {
    props.isShowTwoVideo = true
    props.rightThumbnailURL = value.video_embeds[1].video_thumbnail
    props.rightVideoDesc = value.video_embeds[1].video_description
    props.rightVideoTitle = value.video_embeds[1].video_title
    props.rightVideoURL = value.video_embeds[1].video_url
  }

  return props
}

export default VideoBlock
