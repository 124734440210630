import * as S from './Hero.styles'
import * as G from '../../../styles/global.styles'
import Head from 'next/head'
import {
  TextProps,
  ButtonProps,
  WagtailBlockProps,
  ButtonAPIProps,
  FirstContentBlock,
  LAYOUT_TEMPLATE
} from '../../../services/api/types'
import moment from 'moment'
import Modal from '../../modal/Modal'
import { GymSearchCard } from '../../gym-search-card'
import { DefaultAlt, PAGE_TEMPLATE } from '../../../services/api/constants'
import { IconEnums } from '../../base'
import { HTMLAttributes, useContext, useEffect } from 'react'
import { SiteContext } from '../../../services/context/SiteContext'

export interface HeroAPIProps extends WagtailBlockProps {
  value: {
    slides: SlideAPIProps[]
    enable: boolean
    top_left_text?: string
    top_right_text?: string
    show_text_on_mobile?: boolean
    is_gym?: boolean
    name?: string
    address?: string
    sub_address?: string
    is_kick_gear?: boolean
    isHomePage?: boolean
    hide_you_plus_fitness?: boolean
  }
}

export interface ImageLinkProps {
  anchor_link?: string
  document_link?: string
  external_link?: string
  link_target?: string
  page_link?: string
}

export interface SlideAPIProps {
  type: 'HeroSlide' | 'MembershipSlide' | 'ImageSlide' | 'BlogHeroSlide'
  value: {
    background_image?: {
      image: string
      mobile_image: string
    }
    headline?: string
    subtext?: string
    cta_buttons?: {
      cta_button: ButtonAPIProps[]
    }
    content_alignment: {
      content_alignment: 'center' | 'left' | 'right'
    }
    blog_post?: any
    id: string
  }
  background_image?: {
    image: string
    mobile_image: string
  }
  link?: ImageLinkProps
}

export interface HeroTextProps extends TextProps {
  spaceTop?: number
  spaceBottom?: number
}

export interface HeroSlideProps extends FirstContentBlock {
  bgImgURL?: string
  bgImgMobileURL?: string
  bgColor?:
    | 'black'
    | 'white'
    | 'blue'
    | 'blue-dark'
    | 'blue-grey'
    | 'blue-dodger'
    | 'orange'
    | 'orange-dark'
    | 'green'
    | 'grey'
    | 'grey-light'
    | 'grey-medium'
    | 'grey-dark'
    | 'grey-sand'
    | 'silver'
  contentPosition?: 'center' | 'left' | 'right'
  contentWidth?: 'full' | '1/2' | '2/3' | '3/4' | '4/5'
  texts?: HeroTextProps[]
  buttons?: ButtonProps[]
  overlay?: boolean
  isHeaderGap?: boolean
  links?: ImageLinkProps
  isGym?: boolean
  isHomePage?: boolean
  hide_you_plus_fitness?: boolean
  isHeroForm?: boolean
  top_left_text?: string
  top_right_text?: string
  show_text_on_mobile?: boolean
  isShowDetail?: boolean
}
export interface HeroProps
  extends FirstContentBlock,
    HTMLAttributes<HTMLDivElement> {
  slides: HeroSlideProps[]
  top_left_text?: string
  top_right_text?: string
  show_text_on_mobile?: boolean
  isHomePage?: boolean
  hide_you_plus_fitness?: boolean
  isHeroForm?: boolean
}
export const HeroSliderItem = (props: HeroSlideProps) => {
  const {
    bgImgURL,
    bgImgMobileURL,
    bgColor,
    contentPosition,
    texts,
    buttons,
    contentWidth,
    isHeaderGap,
    isFirstBlock,
    isGym,
    links,
    isHomePage,
    hide_you_plus_fitness,
    isHeroForm,
    top_left_text,
    top_right_text,
    show_text_on_mobile,
    isShowDetail
  } = props
  const { siteSettingPlus } = useContext(SiteContext)
  useEffect(() => {
    var word =
      siteSettingPlus &&
      siteSettingPlus.labels &&
      !!siteSettingPlus?.labels.length
        ? siteSettingPlus.labels
        : []
    var element = document.getElementById('wrap-flip')
    var wordIndex = 1

    var resetAnimation = function() {
      element && element.classList.remove('flip')
    }

    setInterval(function() {
      switch (wordIndex) {
        case 0:
          if (element) {
            element.classList.add('flip')
            element.textContent = word[wordIndex]
          }
          wordIndex = 1
          setTimeout(resetAnimation, 1000)
          break

        case 1:
          if (element) {
            element.classList.add('flip')
            element.textContent = word[wordIndex]
          }
          wordIndex = 2
          setTimeout(resetAnimation, 1000)
          break

        case 2:
          if (element) {
            element.classList.add('flip')
            element.textContent = word[wordIndex]
          }
          wordIndex = 3
          setTimeout(resetAnimation, 1000)
          break

        case 3:
          if (element) {
            element.classList.add('flip')
            element.textContent = word[wordIndex]
          }
          wordIndex = 0
          setTimeout(resetAnimation, 1000)
          break
      }
    }, 2000)
  }, [])

  return (
    <>
      <Head>
        {bgImgURL && <link rel='preload' href={bgImgURL} as='image' />}
        {bgImgMobileURL && (
          <link rel='preload' href={bgImgMobileURL} as='image' />
        )}
      </Head>
      <S.HeroWrapper isHeaderGap={isHeaderGap} isHeroForm={isHeroForm}>
        <S.HeroSection bgColor={bgColor}>
          <S.ImgWrapper isHeroForm={isHeroForm}>
            {bgImgURL && (
              <S.ImgItem src={bgImgURL} alt={DefaultAlt} isMobile={false} />
            )}
            {(bgImgMobileURL || (bgImgURL && !bgImgMobileURL)) && (
              <S.ImgItem
                src={bgImgMobileURL ? bgImgMobileURL : bgImgURL}
                alt={DefaultAlt}
                isMobile={true}
              />
            )}
          </S.ImgWrapper>

          {isHomePage && (
            <>
              <S.TextOverlayLeft>
                {show_text_on_mobile ? (
                  <>{top_left_text}</>
                ) : (
                  <S.MobileOnly>{top_left_text}</S.MobileOnly>
                )}
              </S.TextOverlayLeft>
              <S.TextOverlayRight>
                {show_text_on_mobile ? (
                  <>{top_right_text}</>
                ) : (
                  <S.MobileOnly>{top_right_text}</S.MobileOnly>
                )}
              </S.TextOverlayRight>
              {!hide_you_plus_fitness && (
                <S.TextIconsOverlayCenter>
                  <p className='text'>
                    YOU <br /> FITNESS
                  </p>
                  <S.WrapFlip>
                    <p className='text' id={'wrap-flip'}></p>
                  </S.WrapFlip>
                  <G.Icon
                    className='icon-equal'
                    name={IconEnums['icon-equal']}
                  />
                  <G.Icon
                    className='icon-plus'
                    name={IconEnums['icon-plus-3']}
                  />
                </S.TextIconsOverlayCenter>
              )}
            </>
          )}
          <S.OverlayWrapper overlay={isFirstBlock && isGym && isShowDetail}>
            <S.Container contentPosition={contentPosition}>
              <S.ContentWrapper width={contentWidth}>
                {texts &&
                  texts.map((textProp, idx) => {
                    const textData = {
                      key: idx,
                      ...textProp,
                      dangerouslySetInnerHTML: {
                        __html: textProp.text
                      }
                    }
                    if (
                      (idx == 0 && isFirstBlock) ||
                      (idx == 1 && textProp.textCase == 'upper')
                    ) {
                      return isGym ? (
                        isShowDetail && (
                          <S.HeroText {...textData} className='mb-2' />
                        )
                      ) : (
                        <S.HeroTextH2 {...textData} />
                      )
                    } else {
                      // hide address
                      return isGym && !isShowDetail ? null : (
                        <S.HeroTextWrapper>
                          <S.HeroText {...textData} color='white' />
                        </S.HeroTextWrapper>
                      )
                    }
                  })}
                {buttons && (
                  <S.ButtonGroup
                    style={{ marginTop: '1rem' }}
                    contentPosition={contentPosition}
                  >
                    {buttons &&
                      buttons.map((btn, idx) => {
                        if (btn.label) {
                          if (
                            btn.page_type &&
                            (btn.page_type === 'memberships' ||
                              btn.page_type === 'gym-membership')
                          ) {
                            if (btn.href) {
                              return (
                                <S.HeroButtonLink key={idx} {...btn}>
                                  {btn.label.toLowerCase()}
                                </S.HeroButtonLink>
                              )
                            } else {
                              return (
                                <Modal
                                  buttonText={btn.label.toLowerCase()}
                                  cancelText='Cancel'
                                  isHeroButton={true}
                                  template={
                                    PAGE_TEMPLATE.YOU_FITNESS as LAYOUT_TEMPLATE
                                  }
                                >
                                  <GymSearchCard
                                    template={
                                      PAGE_TEMPLATE.YOU_FITNESS as LAYOUT_TEMPLATE
                                    }
                                  />
                                </Modal>
                              )
                            }
                          } else {
                            return (
                              <S.HeroButtonLink key={idx} {...btn}>
                                {btn.label.toLowerCase()}
                              </S.HeroButtonLink>
                            )
                          }
                        }
                      })}
                  </S.ButtonGroup>
                )}
                {links && links.external_link && (
                  <S.AnchorWrapper
                    href={links.external_link}
                    target={links.link_target}
                    className='cursor-pointer'
                  />
                )}
              </S.ContentWrapper>
            </S.Container>
          </S.OverlayWrapper>
        </S.HeroSection>
      </S.HeroWrapper>
    </>
  )
}

export const HeroYouFitness = (props: HeroProps) => {
  const {
    slides,
    top_left_text,
    top_right_text,
    show_text_on_mobile,
    isFirstBlock,
    isHomePage,
    isHeroForm,
    style
  } = props
  const sliderSetting = {
    swipeable: true,
    showArrows: false,
    infiniteLoop: true,
    interval: 3000,
    transitionTime: 700,
    autoPlay: true,
    emulateTouch: true,
    showIndicators: true,
    showStatus: false,
    showThumbs: false
  }

  return (
    <G.FullWidthWrapper style={style}>
      {slides.length > 1 && !isHomePage ? (
        <S.Carousel {...sliderSetting}>
          {slides &&
            slides.map((slide, index) => (
              <HeroSliderItem
                key={index}
                {...slide}
                isFirstBlock={isFirstBlock && index == 0}
                isHeroForm={isHeroForm}
                top_left_text={top_left_text}
                top_right_text={top_right_text}
                show_text_on_mobile={show_text_on_mobile}
              />
            ))}
        </S.Carousel>
      ) : (
        slides[0] && (
          <HeroSliderItem
            {...slides[0]}
            isFirstBlock={isFirstBlock}
            isHeroForm={isHeroForm}
            top_left_text={top_left_text}
            top_right_text={top_right_text}
            show_text_on_mobile={show_text_on_mobile}
          />
        )
      )}
    </G.FullWidthWrapper>
  )
}

HeroYouFitness.ApiBlockToProps = (data: HeroAPIProps) => {
  //return default props for test, will implement data from API later
  if (data.value) {
    const apiData = data.value
    const props: HeroProps = {
      isFirstBlock: data.isFirstBlock,
      isHomePage: data.value.isHomePage,
      hide_you_plus_fitness: data.value.hide_you_plus_fitness || false,
      top_left_text: apiData.top_left_text,
      top_right_text: apiData.top_right_text,
      show_text_on_mobile: apiData.show_text_on_mobile,
      slides: apiData.slides.map((slide, idx) => {
        let item: HeroSlideProps = {
          bgColor: 'blue',
          isShowDetail: idx === 0 ? apiData.enable : false,
          isHomePage: data.value.isHomePage,
          hide_you_plus_fitness: data.value.hide_you_plus_fitness,
          contentPosition: apiData.is_gym
            ? 'center'
            : (slide.value &&
                slide.value.content_alignment &&
                slide.value.content_alignment.content_alignment) ||
              'left',
          bgImgURL: apiData.is_gym
            ? slide.background_image?.image
            : slide.value.background_image?.image,
          bgImgMobileURL: apiData.is_gym
            ? slide.background_image?.mobile_image
            : slide.value.background_image?.mobile_image,
          overlay: apiData.is_kick_gear ? false : true,
          isHeaderGap: apiData.is_kick_gear ? false : true,
          buttons: apiData.is_gym
            ? []
            : slide.value.cta_buttons &&
              slide.value.cta_buttons.cta_button?.map(btn => {
                return {
                  color:
                    btn.button_colour === 'secondary'
                      ? 'white'
                      : btn.button_colour === 'primary'
                      ? 'blue-deep'
                      : btn.button_colour,
                  label: btn.button_text,
                  href:
                    btn.button_link.page_link ||
                    btn.button_link.document_link ||
                    btn.button_link.external_link ||
                    btn.button_link.anchor_link ||
                    undefined,
                  target: btn.button_link.link_target,
                  onClick: btn.onClick,
                  page_type: btn.page_type
                }
              }),
          links: apiData.is_gym ? { ...slide.link } : {},
          isGym: apiData.is_gym,
          texts: []
        }

        if (slide.type) {
          switch (slide.type) {
            case 'HeroSlide': {
              if (slide.value.headline && !apiData.is_gym) {
                item.texts?.push({
                  color: 'white',
                  text: slide.value.headline,
                  fontWeight: 'bold',
                  size: '5xl',
                  textCase: 'upper'
                })
              }

              if (slide.value.subtext && !apiData.is_gym) {
                item.texts?.push({
                  color: 'blue',
                  text: slide.value.subtext,
                  fontWeight: 'bold',
                  size: '2xl',
                  spaceTop: 1
                })
              }
              return item
            }
            case 'MembershipSlide': {
              if (slide.value.headline) {
                item.texts?.push({
                  color: 'white',
                  text: slide.value.headline,
                  fontWeight: 'bold',
                  size: '6xl',
                  textCase: 'upper'
                })
              }
              return item
            }
            case 'BlogHeroSlide': {
              if (slide.value.blog_post.date) {
                item.texts?.push({
                  color: 'white',
                  text: `${moment(slide.value.blog_post.date).format(
                    'MMMM Do, YYYY'
                  )}`,
                  fontWeight: 'semibold',
                  size: '2xl',
                  textCase: 'upper',
                  spaceBottom: 0.5
                })
              }
              if (slide.value.blog_post.title) {
                item.texts?.push({
                  color: 'white',
                  text: slide.value.blog_post.title,
                  fontWeight: 'bold',
                  size: '4xl',
                  textCase: 'upper'
                })
              }
              if (slide.value.blog_post.preamble) {
                item.texts?.push({
                  color: 'white',
                  text: slide.value.blog_post.preamble,
                  spaceTop: 2,
                  spaceBottom: 3
                })
              }
              return item
            }
            default: {
              if (slide.value.headline && !apiData.is_gym) {
                item.texts?.push({
                  color: 'white',
                  text: slide.value.headline,
                  fontWeight: 'bold',
                  size: '6xl',
                  textCase: 'upper'
                })
              }
              if (slide.value.subtext && !apiData.is_gym) {
                item.texts?.push({
                  color: 'white',
                  text: slide.value.subtext,
                  fontWeight: 'bold',
                  size: '4xl'
                })
              }
              return item
            }
          }
        } else {
          if (idx === 0 && apiData.is_gym) {
            if (apiData.name !== '') {
              item.texts?.push({
                color: 'white',
                text: apiData.name,
                fontWeight: 'bold',
                size: '6xl',
                textCase: 'upper'
              })
            }
            if (apiData.address !== '') {
              item.texts?.push({
                color: 'blue',
                text: apiData.address,
                fontWeight: 'bold',
                size: '2xl'
              })
            }
            if (apiData.sub_address !== '') {
              item.texts?.push({
                color: 'blue',
                text: apiData.sub_address,
                fontWeight: 'bold',
                size: '2xl'
              })
            }
            return item
          }
          return item
        }
      })
    }
    return props
  }
}

export default HeroYouFitness
