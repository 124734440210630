import * as G from '../../styles/global.styles'
import * as S from './CountdownBlock.styled'
import { ButtonProps, ButtonAPIProps } from '../../services/api/types'
import { useState, useEffect } from 'react';
import { getPanelIconSet } from '../../services/api/helpers'

export interface CountdownBlockAPIProps {
    value: {
        heading?: string,
        content?: string,
        end_date?: Date,
        end_time?: string,
        remaining_time: number,
        cta_buttons: {
          cta_button: ButtonAPIProps[]
        }
    }
}
export interface CountdownBlockProps {
    heading?: string,
    content?: string,
    end_date?: Date,
    end_time?: string,
    remaining_time: number,
    buttons?: ButtonProps[]

}
export const CountdownBlock = (props: CountdownBlockProps) => {
  const [remainingTime, setRemainingTime] = useState<number>(props.remaining_time);
  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(prevTime => prevTime - 1);
    }, 1000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);
  // Format the time values
  const formatTime = (value: number): string => {
    return value < 10 ? `0${value}` : value.toString();
  };
  // Calculate days, hours, minutes, and seconds based on remainingTime
  const days = Math.floor(remainingTime / (24 * 60 * 60));
  const hours = Math.floor((remainingTime % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
  const seconds = Math.floor(remainingTime % 60);
  const { heading, content, remaining_time, buttons } = props

  return (
    <>
    {remaining_time && (
    <G.Section spacing={12}>
      <G.Container>
        <S.OpeningBannerWrapper>
          <S.OpeningBannerTitle color='blue'>
            {heading}
          </S.OpeningBannerTitle>
          <S.OpeningBannerTitle color='orange'>
            {content}
            <S.CountdownContainer>
          <S.CountdownBox>
            <S.CountdownNumber>{formatTime(days)}</S.CountdownNumber>
            <S.CountdownLabel>Days</S.CountdownLabel>
          </S.CountdownBox>
          <S.CountdownBox>
            <S.CountdownNumber>{formatTime(hours)}</S.CountdownNumber>
            <S.CountdownLabel>Hours</S.CountdownLabel>
          </S.CountdownBox>
          <S.CountdownBox>
            <S.CountdownNumber>{formatTime(minutes)}</S.CountdownNumber>
            <S.CountdownLabel>Minutes</S.CountdownLabel>
          </S.CountdownBox>
          <S.CountdownBox>
            <S.CountdownNumber>{formatTime(seconds)}</S.CountdownNumber>
            <S.CountdownLabel>Seconds</S.CountdownLabel>
          </S.CountdownBox>
        </S.CountdownContainer>
        {buttons && buttons.length > 0 && (
              <S.ButtonsList>
                {buttons.map((btn, idx) => (
                  <S.ButtonIcon
                    key={idx}
                    href={btn.href}
                    target={btn.target}
                  >
                    {btn.iconImgUrl && (
                      <img src={btn.iconImgUrl} alt={btn.label} />
                    )}
                    {btn.label}
                  </S.ButtonIcon>
                ))}
              </S.ButtonsList>
              )}
          </S.OpeningBannerTitle>
        </S.OpeningBannerWrapper>
      </G.Container>
    </G.Section>
    )}
    blah
    </>
  )
}
CountdownBlock.ApiBlockToProps = (
    data: CountdownBlockAPIProps
  )=> {
    const apiData = data.value
    let buttons = new Array<ButtonProps>()
    const { cta_buttons } = apiData
    if (cta_buttons?.cta_button) {
      cta_buttons.cta_button.forEach(btn => {
        let ctaButton: ButtonProps = {
          label: btn.button_text,
          color: btn.button_colour,
          href:
            btn.button_link.document_link ||
            btn.button_link.external_link ||
            btn.button_link.page_link ||
            btn.button_link.anchor_link ||
            undefined,
          target: btn.button_link.link_target,
          iconImgUrl: btn.button_icon?.icon
            ? getPanelIconSet(btn.button_icon.icon)?.icon
            : undefined
        }
  
        buttons.push(ctaButton)
      })
    }
    return {
        heading: apiData.heading,
        content: apiData.content,
        end_date: apiData.end_date,
        end_time: apiData.end_time,
        buttons: buttons,
        remaining_time: apiData.remaining_time || 0,
    }
}
export default CountdownBlock