import styled from 'styled-components'
import tw from 'twin.macro'

export const Form = styled.form`
  ${tw`mt-4`}
  z-index: 1;

  .you-fitness__control {
    min-height: 48px;
  }

  .you-fitness__single-value {
    ${tw`text-sm`}
  }
`
export const TitleWrapper = styled.div`
  ${tw`w-full lg:px-4 flex flex-col items-start justify-start lg:flex-row lg:items-center lg:justify-between`}
`

export const img = styled.div<{ src: string }>`
  width:230px;
  height:230px;
  display:inline-block;
  margin-right: 60px;
  cursor: pointer;
  margin-left:5px;
  background-size: contain;
  color:#FFFFFF;
  padding-top:80px;
  text-align:center;
  font-size:26px;
  font-weight:bold;
  font-family: 'Helvetica','Arial',sans-serif;

  ${({ src }) => {
    return `background-image: url('${src}');`
  }}

  @media (max-width: 1000px) {
    margin-right: 15px;
    width:200px;
    height:200px;
    margin-bottom:20px;
    background-size: stretch;
  }

  @media (max-width: 700px) {
    width:100%;
    max-height:100px;
    max-height:100px;
    margin-bottom:20px;
    padding-top:40px;
    background-size: cover;
    background-position: center;

  }


  
`

export const ThankYou = styled.div`
  ${tw`bg-blue__deep hover:bg-blue__dark2 text-white text-center`}
  margin: 0 auto;
  text-align:center;
`
export const ImageWrap = styled.div`
  display:inline;

  .inactive {
    opacity: 0.5;
  }
`
export const ButtonContainer = styled.div`
  max-width: 825px;

  @media (max-width: 1000px) {
    max-width:745px;
  }

  @media (max-width: 700px) {
    max-width:300px;
  }
`
export const ButtonStyled = styled.button`
// base
${tw`px-4 py-4 lg:py-2 transition-colors duration-300 uppercase`}

// blue-deep
${props =>
  props.color == 'blue-deep' && tw`bg-blue__deep hover:bg-blue__dark2 text-white`}
  ${tw`flex-1 text-center`}
`
export const ImageContainer = styled.div`
  text-align:left;
  margin:30px 10px 30px;

  `