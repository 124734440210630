import React from 'react'
import { useInView } from 'react-intersection-observer'
import { LazyImage } from '../lazy-image'
import * as S from './Advertisement.styled'
import { WagtailBlockProps, ButtonProps } from '../../services/api/types'
import * as G from '../../styles/global.styles'

export interface AdvertisementProps {
  bgImageURL: string
  imageURL: string
  title: string
  bigTitle: string
  text: string
  button?: ButtonProps
}

export interface AdvertisementAPIProps extends WagtailBlockProps {
  value: {
    image: string
    background_image: string
    overlay_colour: string
    title: string
    title_large_text: string
    subtext: string
    banner_link_text: string
    banner_link: {
      link_target: string
      external_link: string
      page_link: string
      document_link: string
    }
  }
}

export const Advertisement = (props: AdvertisementProps) => {
  const { bgImageURL, imageURL, title, bigTitle, text, button } = props
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  })

  return (
    <G.Section ref={ref} bgImgURL={inView ? bgImageURL : ''}>
      {/* the Color opacity havent support by TW yet. need to fix this when TW release version which support Color Opacity. 
      Please check the issue update here https://github.com/ben-rogerson/twin.macro/issues/45*/}
      <G.FullWidthWrapper className='bg-orange bg-opacity-75'>
        <G.FlexContainer flow='row' contentSize='lg'>
          <S.ImageWrapper>
            <LazyImage src={imageURL} alt={title} width='100%' />
          </S.ImageWrapper>
          <S.TextContent>
            <S.Title>{title}</S.Title>
            <S.BigTitle>{bigTitle}</S.BigTitle>
            <S.Text>{text}</S.Text>
            {button && <G.ButtonLink {...button}>{button.label}</G.ButtonLink>}
          </S.TextContent>
        </G.FlexContainer>
      </G.FullWidthWrapper>
    </G.Section>
  )
}

Advertisement.ApiBlockToProps = (data: AdvertisementAPIProps) => {
  const { value } = data
  if (value) {
    let props: AdvertisementProps = {
      bgImageURL: value.background_image,
      imageURL: value.image,
      title: value.title,
      bigTitle: value.title_large_text,
      text: value.subtext,
      button: value.banner_link && {
        label: value.banner_link_text,
        href:
          value.banner_link.external_link ||
          value.banner_link.page_link ||
          value.banner_link.document_link ||
          '#',
        color: 'blue',
        target: value.banner_link.link_target
      }
    }
    return props
  }
}

export default Advertisement
