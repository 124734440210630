import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { Section } from '../../styles/grid.styles'

export const SectionHero = styled(Section)<{ isHomePage?: boolean }>`
  @media (max-width: 767px) {
    ${props =>
      props.isHomePage &&
      css`
        padding-top: 140px;
        padding-bottom: 30px;
      `}
  }
`

export const Hero = styled.div<{ isVideo?: boolean; isHomePage?: boolean }>`
  ${tw`relative overflow-hidden`}
  height: calc(100vh - 80px);
  z-index: 1;

  .vp-unmute-button {
    display: none;
  }

  @media (max-width: 767px) {
    height: calc(100vh - 70px);

    ${props =>
      props.isVideo &&
      css`
        height: 0;
        padding-top: 56.25%;
      `}

    ${props =>
      props.isHomePage &&
      css`
        padding-top: 90%;
      `}
  }

  &:after {
    ${tw`absolute z-10 w-full h-full top-0 left-0`}
    content: "";
    background: linear-gradient(270deg, #2533d5 0%, rgba(0, 0, 0, 0) 31.15%),
      linear-gradient(90deg, #2533d5 0%, rgba(0, 0, 0, 0) 32.44%);
  }
`

export const FeaturedText = styled.div`
  ${tw`absolute z-50 text-center text-white font-youfitnessHeading uppercase font-bold text-3xl md:text-6xl w-full px-4 md:px-10`}
  line-height: 0.8;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  span {
    ${tw`text-blue__deep`}
  }

  @media (max-width: 767px) {
    ${tw`leading-none`}
  }

  .icon-plus {
    ${tw`inline-block bg-no-repeat relative`}
    width: 45px;
    height: 44px;
    background-image: url('/static/svgs/icon-plus-blue.svg');
    background-size: 100% 100%;
    top: 3px;

    @media (max-width: 767px) {
      width: 23px;
      height: 22px;
    }
  }
`

export const Video = styled.video`
  ${tw`absolute top-0 left-0 w-full object-cover`}
  height: calc(100vh - 80px);
`

export const Image = styled.img`
  ${tw`absolute top-0 left-0 w-full object-cover`}
  height: calc(100vh - 80px);

  @media (max-width: 767px) {
    height: calc(100vh - 70px);
  }
`

export const Logo = styled.img`
  ${tw`absolute z-50`}
  height: 250px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Plus = styled.img`
  ${tw`absolute z-50 bottom-0`}
  right: 2.5rem;

  @media (max-width: 767px) {
    right: 1rem;
  }
`

export const Text = styled.span<{ position?: 'left' | 'right' }>`
  ${tw`absolute text-white uppercase font-bold text-lg z-50 font-youfitnessHeading leading-none`}
  top: 25px;

  ${props =>
    props.position === 'left' &&
    css`
      left: 2.5rem;

      @media (max-width: 767px) {
        left: 1rem;
      }
    `}

  ${props =>
    props.position === 'right' &&
    css`
      right: 2.5rem;

      @media (max-width: 767px) {
        right: 1rem;
      }
    `}
`
