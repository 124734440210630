import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { BlueButton, WhiteButton } from '../../styles/global.styles'

export const FormWrapper = styled.div`
  ${tw`py-10`}
`

export const Form = styled.form`
  ${tw`mt-4`}
  z-index: 1;
  max-width: 675px;
  @media (max-width: 480px) {
    padding-bottom:50px;
  }

  margin:0 auto;
  .you-fitness__control {
    min-height: 48px;
  }

  .you-fitness__single-value {
    ${tw`text-sm`}
  }
`
export const Heading = styled.h2<{ center?: boolean }>`
  ${tw`text-white font-youfitnessHeading leading-tiny text-center text-2.5xl md:text-5xl mb-6 uppercase`}

  ${props =>
    props.center &&
    css`
      ${tw`text-center`}
    `}
`
export const PreFormText = styled.p`
  ${tw`mb-2 text-white text-center uppercase text-sm`}
`

export const FormTitle = styled.h1`
  ${tw`px-4 pt-8 mb-0 font-body`}
`

export const FormGroup = styled.div<{ col?: number; paddingX?: number }>`
  ${tw`flex flex-col mb-7 relative w-full text-left`}

  ${({ col }) => {
    if (col === 1) return tw`w-full`
    else if (col === 2) return tw`lg:w-2/5`
    else if (col === 3) return tw`lg:w-1/3`
    else return ''
  }}

  ${({ paddingX }) => {
    if (paddingX === 1) return tw`lg:px-1`
    else if (paddingX === 2) return tw`lg:px-2`
    else if (paddingX === 3) return tw`lg:px-3`
    else if (paddingX === 4) return tw`lg:px-4`
    else if (paddingX === 5) return tw`lg:px-5`
    else if (paddingX === 6) return tw`lg:px-6`
    else return ''
  }}

  > input,
  .react-datepicker__input-container input,
  .you-fitness-fileinput {
    ${tw`text-sm`}
    height: 48px;
  }

  .you-fitness-error,
  .error.you-fitness__control {
    ${tw`border-2 border-red`}
  }

  label span {
    ${tw`text-white text-sm`}
  }
`

export const FormLabel = styled.label<{
  error?: boolean
  hiddenMobile?: boolean
}>`
  ${tw`text-white font-medium text-sm mb-1`}

  ${props =>
    props.error &&
    css`
      ${tw`text-red`}
    `}

  ${props =>
    props.hiddenMobile &&
    css`
      ${tw`hidden md:inline-block`}
    `}
`

export const ErrorMessage = styled.span`
  ${tw`text-red italic absolute leading-sm`}
  font-size: 13px;
  top: 100%;
`

export const CloseWrapper = styled.span`
  ${tw`absolute top-0 right-0 p-4 cursor-pointer`}

  svg {
    ${tw`text-white w-5`}
  }
`

export const ButtonWrap = styled.div`
  ${tw`justify-center flex w-full mt-4 md:mt-0`}
`

export const ButtonSubmit = styled(BlueButton)`
  ${tw`font-youfitnessHeading uppercase! text-base! md:text-1.5xl! font-extrabold mx-2 leading-tiny!`}
`

export const ButtonCancel = styled(WhiteButton)`
  ${tw`font-youfitnessHeading uppercase! text-base! text-black md:text-1.5xl! font-extrabold mx-2 leading-tiny!`}
`

export const Success = styled.div`
  ${tw`text-center`}
`

export const SuccessIcon = styled.div`
    img{
      ${tw`inline-block mb-5`}
      width: 114px;
    }
`

export const SuccessTitle = styled.h4`
  ${tw`text-white font-youfitnessHeading text-5xl mb-5 leading-none`}
`

export const SuccessMsg = styled.div`
  ${tw`text-white text-sm mb-7`}
`