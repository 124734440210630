import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as G from '../../styles/global.styles'
import { Container, Section, Row, Col } from '../../styles/grid.styles'
import { useRouter } from 'next/router'
import * as R from './YourGoalsForm.styled'
import {
  CheckBox
} from '../base'
import { submitForm } from '../../services/api/cms'

export interface YourGoalsFormProps {
  value: {
    heading: string
    text: string
    subtitle: string 
    options: [{
      label: string
      name: string
    }]
    imageButtons: [
      {
        image: string
        title: string 
        options: [
          {
            label: string
            name: string
          }
        ]
      }
    ]

  }
}

export const YourGoalsForm = (props: YourGoalsFormProps) => {
  const [activeBtn, setActiveBtn] = useState(1)
  const [responseMessage, setResponseMessage] = useState('')
  const [success, setSuccess] = useState(false)
  useEffect(() => { setActiveBtn(0)}, [0] )
  const router = useRouter()
  const {token, gym} = router.query
  console.log(success)
  const formik = useFormik({
    initialValues: {
      token: token,
      gym: gym
    },

    onSubmit: async values => {
      let res = await submitForm(null, 'your-goals-form', values)
      if (res.ok) {
        setResponseMessage(res.success_message)
        setSuccess(true)
      } else {
        setResponseMessage(res.error_message)
        setSuccess(false)
        throw responseMessage
      }
    }
  })
  return (
    <Section>
      <Container>
        <R.TitleWrapper>
        {!success && 
          <G.Text size='2xl' color='blue-deep' fontWeight='bold' className={`mb-4 mt-0`}>
          {props?.value.heading}
          </G.Text> 
        }
        </R.TitleWrapper>
        <R.TitleWrapper>
        {!success && 
          <G.Text color='white' className={``}>
            {props?.value.subtitle} 
          </G.Text>
        }
        </R.TitleWrapper>
          
        <R.Form onSubmit={formik.handleSubmit}>
        {!success && 
        <Row className={`mt-3`}>
        {props?.value?.options?.map(option => {
            return (
              <Col md={2.5} className={`mb-2 ml-0`}>
                <CheckBox
                name={option.name}
                type='checkbox'
                value='true'
                label={option.label}
                color='white'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='goals-page text-white'
              />
              </Col>
            )
        })}      
        </Row>
        }
        {!success && 

        <R.TitleWrapper>
          <G.Text color='white' className={`mt-4`}>
            {props?.value.text}
          </G.Text>
        </R.TitleWrapper>
        }
          <R.ImageContainer>
          {props?.value?.imageButtons?.map((button, index) => {
            if(!success){
              return (
                  <R.ImageWrap>
                    <a onClick={() => setActiveBtn(index)}>
                      <R.img src={button.image} className={activeBtn != index ? "inactive" : '' }>
                          {button.title}
                      </R.img>
                      </a>
                  </R.ImageWrap>
              )
            }
            })}  
            </R.ImageContainer>

            {!success && 

          <Row className={`mt-3`}>
          {props?.value?.imageButtons?.map((button, index) => {
              return button.options.map(option => {
                return activeBtn === index && (
                  <Col sm={4} className={`mb-6`}>
                    <CheckBox
                    name={option.name}
                    type='checkbox'
                    value='true'
                    label={option.label}
                    color='white'
                    className='goals-page text-white'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Col>
              )
              })
            })}
            </Row>
            }
            <R.ButtonContainer>
              {!success && 
              <R.ButtonStyled type="submit" color='blue-deep' className='float-right mt-10 mb-10 w-100'>
              Let's do this!
              </R.ButtonStyled>
              }
              </R.ButtonContainer>
              {success &&
                <R.ThankYou>Thank you, your goals have been sent</R.ThankYou>
              }
            </R.Form>
      </Container>
    </Section>
  )
}
