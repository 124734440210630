import { ArticleCard, ArticleCardProps } from './cards'
import SubHeader from '../../header/YouFitnessTemplate/SubHeader'
import { ListArticleWrapper } from './WhatsHot.styles'
import { Pagination } from '../../pagination'
import {
  ARTICLE_SORT_OPTIONS,
  EMPTY_ARTICLE_MESSEGE,
  PAGE_TEMPLATE
} from '../../../services/api/constants'
import { MutableRefObject } from 'react'
import { Row, Col, Container, Section } from '../../../styles/grid.styles'
import { LAYOUT_TEMPLATE } from '../../../services/api/types'
import * as G from '../../../styles/global.styles'

export interface ArticleListProps {
  listTitle: string
  articles?: ArticleCardProps[]
  pagingPerPage: number
  totalItemsCount: number
  activePage: number
  showSortDropdown: boolean
  refElm?: MutableRefObject<HTMLDivElement>
  onPageChange?: Function
  onOrderByChange?: Function
  className?: string
  itemsPerRow?: 2 | 3
}

export const ArticleList = (props: ArticleListProps) => {
  const {
    listTitle,
    articles,
    pagingPerPage,
    activePage,
    totalItemsCount,
    showSortDropdown,
    onPageChange,
    refElm,
    onOrderByChange,
    className,
    itemsPerRow
  } = props

  return (
    <ListArticleWrapper
      ref={refElm}
      className={`${className} pt-8 article-list`}
    >
      <SubHeader
        subHeaderText={listTitle}
        onSortOptionChange={onOrderByChange}
        dropdownOptions={ARTICLE_SORT_OPTIONS}
        showDropdown={showSortDropdown}
        showSeparator
      />
      <Section>
        <Container>
          <Row gutter={8}>
            {articles && articles.length > 0 ? (
              articles.map((article, idx) => (
                <Col md={itemsPerRow === 2 ? 6 : 4} key={idx}>
                  <ArticleCard {...article} isBlogCard={true} />
                </Col>
              ))
            ) : (
              <G.Text color='white'>{EMPTY_ARTICLE_MESSEGE}</G.Text>
            )}
          </Row>
          {pagingPerPage > 0 && totalItemsCount > (pagingPerPage || 5) && (
            <div className='pt-8 w-full'>
              <Pagination
                onChange={onPageChange}
                totalItemsCount={totalItemsCount}
                itemsCountPerPage={pagingPerPage || 5}
                activePage={activePage}
                template={PAGE_TEMPLATE.YOU_FITNESS as LAYOUT_TEMPLATE}
                scrollTo={
                  refElm && refElm.current ? refElm.current.offsetTop : 0
                }
              />
            </div>
          )}
        </Container>
      </Section>
    </ListArticleWrapper>
  )
}

export default ArticleList
