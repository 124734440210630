import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { fontSize, size } from '../../../styles/theme'

export const Form = styled.form`
  ${tw`w-full lg:w-1/2`}
`

export const ErrorMessageText = styled.span`
  ${tw`text-red block text-center absolute`}
  top: 100%;
`

export const SubscribeFormWrap = styled.div`
  ${tw`flex relative justify-center`}

  .input-custom {
    min-width: 230px;
    border: 1px solid #cdcdcd;
    border-right: 0;
    color: #909090;
    ${tw`font-normal`}
    ${fontSize(14)}
    padding: 16px 24px;

    ::placeholder {
      color: #909090;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #909090;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #909090;
    }
  }

  @media (max-width: 480px) {
    .input-custom {
      width: 65%;
      min-width: unset;
    }

    button {
      width: 35%;
      padding: 0;
      justify-content: center;
    }
  }
`
export const GymBody2 = styled.div`
  ${tw`bg-black__deep`}
`

export const GymComp2 = styled.div<{ custom?: boolean }>`
  ${tw`flex w-full`}
  overflow-y: hidden;

  @media (max-width: 1024px) {
    ${tw`flex-col items-center relative`}
  }

  ${({ custom }) => {
    return custom ? `` : `flex-direction: row-reverse;`
  }}
`

export const GymComp2Left = styled.div`
  width: 55%;
  ${tw`relative`}

  @media (max-width: 1024px) {
    ${tw`hidden`}
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(
        94.67deg,
        rgba(37, 51, 213, 0.9) 1.44%,
        rgba(0, 0, 0, 0) 61.44%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    ${tw`absolute`}
  }

  img {
    height: ${size(253)};

    @media (max-width: 1024px) {
      height: 100%;
    }
  }
`

export const GymComp2Right = styled.div<{ custom?: boolean; bgImage?: string }>`
  width: 45%;
  ${tw`flex items-center justify-center flex-col font-bold relative bg-white__deep bg-cover`}
  ${fontSize(30)}
  
  p {
    ${tw`w-full mx-auto text-center font-youfitnessHeading mb-1.5`}
    max-width: ${size(300)};
  }
  
  span {
    color: #666666;
    ${tw`font-normal text-sm mb-5`}
  }

  ${ErrorMessageText} {
    ${tw`text-red`}
  }
  
  &:before {
    content: '';
    ${tw`absolute w-full h-full`}
    right: 100%;
    top: -2px;
    border-style: solid;
    border-width: 340px 120px 0 0;
    border-color: transparent #F8F8F8 transparent transparent;
    z-index: 49;
    
    @media (max-width: 1024px) {
      display: none;
    }
  }
  
  @media (max-width: 1024px) {
    ${tw`w-full`}
    min-height: ${size(280)};

    ${props =>
      props.bgImage &&
      css`
        ${tw`py-16 px-4 text-center relative text-white`}
        background-image: url(${props.bgImage});

        span, p {
          ${tw`text-white relative z-10`}
        }

        span {
          ${fontSize(13)}
        }

        p {
          ${tw`mb-1`}
        }

        form {
          ${tw`relative z-10`}
        }

        ${ErrorMessageText} {
          ${tw`absolute`}
        }
      `}

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: linear-gradient(
            94.67deg,
            rgba(37, 51, 213, 0.9) 1.44%,
            rgba(0, 0, 0, 0) 61.44%
          ),
          linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
        ${tw`absolute`}
      }
  }
`
