import * as G from '../../../styles/global.styles'
import * as S from './CTAPanels.styled'
import {
  Block,
  WagtailBlockProps,
  LAYOUT_TEMPLATE,
  Colors
} from '../../../services/api/types'
import React, { useState } from 'react'
import { Section, Container } from '../../../styles/grid.styles'
import { BlockTitle, IconEnums } from '../../base'
import { getPanelIconSet } from '../../../services/api/helpers'
import Modal from '../../modal/Modal'
import { GymSearchCard } from '../../gym-search-card/GymSearchCard'
import { PAGE_TEMPLATE } from '../../../services/api/constants'

export interface CTAPanelsProps {
  title?: string
  subText?: string
  blocks: Block[]
  background_colour?: Colors
  gym_id?: number
  gym_title?: string
}

export interface BlockAPIProps {
  link: {
    button_colour:
      | 'white'
      | 'blue'
      | 'blue-dark'
      | 'orange'
      | 'secondary'
      | 'primary'
      | string
    button_icon: {
      icon: string
    }
    icon: string
    button_text: string
    button_link: {
      link_target?: string
      external_link?: string
      page_link?: string
      document_link?: string
      anchor_link?: string
      goto_link?: string
    }
    join_now_popup?: boolean
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  }
}
export interface CTAPanelsAPIProps extends WagtailBlockProps {
  value: {
    headline?: string
    featured_text?: string
    panels: BlockAPIProps[]
    background_colour: {
      background_colour?: Colors
    }
    gym_id?: number
    gym_title?: string
  }
}

export const CTAPanels = (props: CTAPanelsProps) => {
  const { blocks, title, subText, gym_id, gym_title } = props
  const [suburbStep, setSuburbStep] = useState(true)
  const template = PAGE_TEMPLATE.YOU_FITNESS as LAYOUT_TEMPLATE

  const handleSuburbStep = () => {
    setSuburbStep(!suburbStep)
  }

  return (
    <Section>
      <Container>
        {title && (
          <BlockTitle fontSize='sm' isYouFitness>
            {title}
          </BlockTitle>
        )}
        {subText && (
          <S.Subtext>
            <span>{subText}</span>
          </S.Subtext>
        )}
        {/* Gyms Details  */}
        {blocks?.length > 0 &&
          blocks.map((block, idx) => {
            const btnProps = {
              href: !block.onClick
                ? gym_id && gym_title && block.enquireLink
                  ? `${block.enquireLink}?gymId=${gym_id}&gymTitle=${gym_title}&goto=${block.goto_link}`
                  : block.link
                : 'javascript:void(0)',
              onClick: block.onClick,
              target: block.linkTarget
            }
            

            return idx === 0 ? (
              <S.GymComp3BtnJoin key={idx}>
                <S.IconLeft name={IconEnums['icon-letdoit']} />
                {block.join_now_popup ? (
                  <>
                    <Modal
                      template={template}
                      showCloseIcon={!!suburbStep}
                      buttonText={block.text}
                      iconImageUrl={block.icon}
                      cancelText={!suburbStep ? 'Cancel' : ''}
                    >
                      <GymSearchCard
                        template={template}
                        suburbStep={suburbStep}
                        handleSuburbStep={handleSuburbStep}
                        gym={gym_id}
                      />
                    </Modal>
                  </>
                ) : (
                  <S.LinkWrapperNewLayout {...btnProps}>
                    <div dangerouslySetInnerHTML={{ __html: block.text }} />
                  </S.LinkWrapperNewLayout>
                )}
                <S.IconRight name={IconEnums['icon-ready']} />
              </S.GymComp3BtnJoin>
            ) : (
              <S.GymComp3BtnWhite key={idx}>
                {block.join_now_popup ? (
                  <>
                    <Modal
                      template={template}
                      showCloseIcon={!!suburbStep}
                      buttonText={block.text}
                      buttonColor='white'
                      iconImageUrl={block.icon}
                      cancelText={!suburbStep ? 'Cancel' : ''}
                    >
                      <GymSearchCard
                        template={template}
                        suburbStep={suburbStep}
                        handleSuburbStep={handleSuburbStep}
                        gym={gym_id}
                      />
                    </Modal>
                  </>
                ) : (
                  <S.LinkWrapperNewLayout {...btnProps}>
                    <G.Icon name={IconEnums['icon-email']} />
                    <div dangerouslySetInnerHTML={{ __html: block.text }} />
                  </S.LinkWrapperNewLayout>
                )}
              </S.GymComp3BtnWhite>
            )
          })
        }
      </Container>
    </Section>
  )
}

CTAPanels.ApiBlockToProps = (data: CTAPanelsAPIProps) => {
  const { value } = data
  if (value.panels) {
    const { panels } = value
    const props: CTAPanelsProps = {
      title: value.headline,
      subText: value.featured_text,
      blocks: panels.map((panel: BlockAPIProps) => {
        const { link } = panel
        const iconSet = getPanelIconSet(link.button_icon.icon)
        const block: Block = {
          imgURL: iconSet?.icon,
          text: link.button_text || '',
          color: link.button_colour,
          onClick: link.onClick,
          link:
            link.button_link.document_link ||
            link.button_link.external_link ||
            link.button_link.anchor_link ||
            undefined,
          join_now_popup: link.join_now_popup,
          icon: getPanelIconSet(link.button_icon.icon)?.icon || '',
          goto_link: link.button_link.goto_link || 'membership-enquiry',
          linkTarget: link.button_link.link_target || '',
          enquireLink: link.button_link.page_link || undefined,
        }
        return block
      }),
      background_colour: value.background_colour.background_colour,
      gym_id: value.gym_id,
      gym_title: value.gym_title
    }
    return props
  }
}

export default CTAPanels
