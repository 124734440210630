import { WagtailBlockProps } from '../../services/api/types'
import * as G from '../../styles/global.styles'
import * as S from './IconLinks.styles'
import { BlockTitle } from '../base'



export interface IconProps {
  image: string
  url: string
}

export interface IconLinksProps {
  heading: string
  description? : string
  icons: IconProps[]
}

export interface IconLinksAPIProps extends WagtailBlockProps {
  value: {
    heading: string
    description? : string
    icons: IconProps[]
  }
}

const ImageList = (props: { icons: IconProps[] }) => {
  const { icons } = props
  let colClass = 'w-full'

  if (icons.length === 2) {
    colClass = 'w-1/2'
  } else if (icons.length >= 3) {
    colClass = 'w-1/2 sm:w-1/3'
  }

  return (
    <S.ImageListContainer>
      {icons && icons.map((icon, index) => (
        <S.ImageContainer key={index} className={`w-full sm:${colClass} px-4 mb-4`}>
          <S.ImageLink href={icon.url}>
            <S.Image src={icon.image} alt="Image" />
          </S.ImageLink>
        </S.ImageContainer>
      ))}
    </S.ImageListContainer>
  );
};

export const IconLinks = (props: IconLinksProps) => {
  const { heading, icons, description } = props

  return (
    <G.Section spacing={8} bgColor={'black-deep'}>
    <G.CenteredContainer contentSize='lg'>
      {heading && (
        <BlockTitle
          textColor='white'
          isYouFitness
          textTransform={'uppercase'}
          underlineColor={'blue-deep'}
        >
          {heading}
        </BlockTitle>
      )}
      { description && <S.Description dangerouslySetInnerHTML={{ __html: description }} /> }
      <S.IconsWrapper>
        <ImageList icons={icons}/>
      </S.IconsWrapper>
    </G.CenteredContainer>
  </G.Section>
  )
}

IconLinks.ApiBlockToProps = (data: IconLinksAPIProps) => {
  const apiData = data.value
  let props: IconLinksProps = {
    icons: [],
    heading: '',
    description: ''
  }

  if (apiData) {
    const { icons, heading} = apiData
    props.icons = icons
    props.heading = heading
    props.description = apiData.description
  }

  return props
}
