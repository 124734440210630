import {
  forwardRef,
  useState,
  useImperativeHandle,
  ChangeEvent,
  FormEvent
} from 'react'
import { Button } from '../base'
import {
  NewsSearchWrapper,
  NewsSearchContainer,
  NewsSearchGroup,
  NewsSearchTextInput,
  LabelIcon
} from './SearchForm.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { LAYOUT_TEMPLATE } from '../../services/api/types'

export interface NewsSearchFormProps {
  searchButtonText?: string
  searchPlaceHolder?: string
  displaySearch: boolean
  onSearchClose?: Function
  onSearchClick?: Function
  template?: LAYOUT_TEMPLATE
}

export const NewsSearchForm = forwardRef((props: NewsSearchFormProps, ref) => {
  const { searchButtonText, searchPlaceHolder, onSearchClick, template } = props
  const [searchInput, setsearchInput] = useState('')

  useImperativeHandle(ref, () => ({
    resetSearch() {
      setsearchInput('')
    }
  }))

  const onSearch = (e: FormEvent) => {
    e.preventDefault()
    onSearchClick && onSearchClick(searchInput)
  }

  const handleTextInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setsearchInput(e.target.value)
  }

  return (
    <NewsSearchWrapper template={template}>
      <NewsSearchContainer onSubmit={onSearch}>
        <LabelIcon>
          <FontAwesomeIcon icon={faSearch} />
        </LabelIcon>
        <NewsSearchGroup>
          <NewsSearchTextInput
            placeholder={searchPlaceHolder || ''}
            onChange={handleTextInputChange}
            value={searchInput}
          />
          <Button type='submit' color='white'>
            {searchButtonText || 'Search'}
          </Button>
        </NewsSearchGroup>
      </NewsSearchContainer>
    </NewsSearchWrapper>
  )
})

export default NewsSearchForm
