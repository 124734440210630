import { useEffect, useState, useContext } from 'react'
import { Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import { Button, TextInput } from '../base'
import { Section } from '../../styles/global.styles'
import { SiteContext } from '../../services/context/SiteContext'

import {
  NewsletterWrapper,
  NewsletterTitle,
  NewsletterMessage,
  SubscribeFormWrap,
  NewsletterContent,
  Form,
  ErrorMessageText
} from './Newsletter.styled'
import * as G from '../../styles/global.styles'
import { submitForm } from '../../services/api/cms'
import { RECAPTCHA_KEY } from '../../services/api/constants'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'

const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export interface NewsletterProps {
  newsletterTitle: string
  newsletterMsg: string
  emailPlaceHolder?: string
  subBtnText: string
  successTitle: string
  successMessage: string
  errorTitle: string
  errorMessage: string
  formApiSlug?: string
}

const Newsletterchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter an email address!')
})

export const Newsletter = (props: NewsletterProps) => {
  const {
    newsletterTitle,
    newsletterMsg,
    emailPlaceHolder,
    subBtnText,
    formApiSlug,
    successTitle,
    successMessage,
    errorTitle,
    errorMessage
  } = props

  const [submitted, setSubmitted] = useState(false)
  const { siteSettings } = useContext(SiteContext)
  const formslug = siteSettings?.newsletter_form_slug
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState({
    title: newsletterTitle,
    message: newsletterMsg
  })

  const handleSubmit = async (values: any) => {
    if (!formslug) {
      return
    }
    setLoading(true)
    submitForm(null, formslug, values)
      .then(r => {
        sleep(1000)
        if (r.ok) {
          setText({ title: successTitle, message: successMessage })
        } else {
          setText({ title: errorTitle, message: errorMessage })
        }
        setLoading(false)
        setSubmitted(true)
      })
      .catch(() => {
        setLoading(false)
        setSubmitted(true)
        setText({ title: errorTitle, message: errorMessage })
      })
  }

  const fullwidth: boolean = loading || submitted
  const showForm: boolean = !loading && !submitted

  useEffect(() => {
    if (!window.RECAPTCHA_INITIALIZED) {
      loadReCaptcha(RECAPTCHA_KEY)
      window.RECAPTCHA_INITIALIZED = true
    }
  }, [])

  return (
    <Section>
      <G.FlexContainer contentSize='lg'>
        <NewsletterWrapper>
          {loading ? (
            <G.SpinnerWrap>
              <G.Spinner />
            </G.SpinnerWrap>
          ) : (
            <>
              <NewsletterContent full={fullwidth}>
                <NewsletterTitle center={fullwidth}>
                  {text.title}
                </NewsletterTitle>
                <NewsletterMessage center={fullwidth}>
                  {text.message}
                </NewsletterMessage>
              </NewsletterContent>
              {showForm && (
                <Formik
                  validationSchema={Newsletterchema}
                  onSubmit={handleSubmit}
                  initialValues={{ email: '', recaptcha: '' }}
                >
                  {({
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    setFieldValue
                  }: FormikValues) => (
                    <Form onSubmit={handleSubmit}>
                      <SubscribeFormWrap>
                        <TextInput
                          placeholder={
                            emailPlaceHolder
                              ? emailPlaceHolder
                              : 'Enter email address'
                          }
                          placeHolderColor='white'
                          name='email'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          color='white'
                        />
                        <ErrorMessageText>
                          {errors.email && touched.email && errors.email}
                        </ErrorMessageText>
                        <Button type='submit'>{subBtnText}</Button>
                      </SubscribeFormWrap>
                      {formApiSlug && (
                        <ReCaptcha
                          sitekey={RECAPTCHA_KEY}
                          action={formApiSlug}
                          verifyCallback={async (token: any) => {
                            if (values['recaptcha'] !== token)
                              await setFieldValue('recaptcha', token, false)
                          }}
                        />
                      )}
                    </Form>
                  )}
                </Formik>
              )}
            </>
          )}
        </NewsletterWrapper>
      </G.FlexContainer>
    </Section>
  )
}

Newsletter.defaultProps = {
  newsletterTitle: `STAY INFORMED`,
  newsletterMsg: `Register for regular updates, blogs and Plus Fitness news`,
  subBtnText: `SUBSCRIBE TO THE NEWSLETTER`,
  formApiSlug: `newsletter-signup`,
  successTitle: 'SUCCESS',
  successMessage:
    'You are now registered for regular updates, blogs and Plus Fitness',
  errorTitle: 'ERROR',
  errorMessage:
    'Sorry there has been a problem submitting your email. Please try later.'
}

export default Newsletter
