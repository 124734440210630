import * as S from './HeaderWithTitle.styled'
import {
  WagtailBlockProps,
  Colors,
  FirstContentBlock
} from '../../../services/api/types'
import { BlockTitle } from '../../base'
import { Container, Section } from '../../../styles/grid.styles'

export interface HeaderWithTitleProps extends FirstContentBlock {
  headerText: string
  featuredText?: string
  standardText?: string
  background_colour?: Colors
}

export interface HeaderWithTitleAPIProps extends WagtailBlockProps {
  value: {
    background_colour: {
      background_colour?: Colors
    }
    headline: string
    featured_text?: string
    standard_text?: string
  }
}
export const HeaderWithTitle = (props: HeaderWithTitleProps) => {
  const { headerText, featuredText, standardText, isFirstBlock } = props

  return (
    <Section>
      <Container>
        {headerText && (
          <BlockTitle isSiteTitle={isFirstBlock} isYouFitness>
            {headerText}
          </BlockTitle>
        )}
        {featuredText && <S.Content featured>{featuredText}</S.Content>}
        {standardText && <S.Content>{standardText}</S.Content>}
      </Container>
    </Section>
  )
}

HeaderWithTitle.ApiBlockToProps = (data: HeaderWithTitleAPIProps) => {
  const { value, isFirstBlock } = data
  if (value) {
    const props: HeaderWithTitleProps = {
      headerText: value.headline,
      featuredText: value.featured_text,
      standardText: value.standard_text,
      background_colour: value.background_colour.background_colour,
      isFirstBlock: isFirstBlock
    }
    return props
  }
}

export default HeaderWithTitle
