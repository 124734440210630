import * as S from './WhyOwn.styled'
import { Colors, WagtailBlockProps } from '../../../services/api/types'
import { Container, Section } from '../../../styles/grid.styles'
import { BlockTitle } from '../../base'

interface BrandLogo {
  logoImgURL: string
  link: {
    url?: string
    target?: string
  }
}
export interface WhyOwnProps {
  bgImgURL: string
  title: string
  text: string
  heading: string
  logos: BrandLogo[]
  background_colour?: Colors
}

interface LogoAPIProps {
  image: string
  logo_link: {
    link_target?: string
    external_link?: string
    page_link?: string
    document_link?: string
  }
}
export interface WhyOwnAPIProps extends WagtailBlockProps {
  value: {
    image: string
    headline: string
    featured_text: string
    text: string
    logos: LogoAPIProps[]
    background_colour: {
      background_colour?: Colors
    }
  }
}

export const WhyOwn = (props: WhyOwnProps) => {
  const { heading, title, text, logos, bgImgURL } = props
  return (
    <Section>
      <Container>
        {heading && <BlockTitle isYouFitness>{heading}</BlockTitle>}
        <S.FeaturedText>{title}</S.FeaturedText>
        <S.Content dangerouslySetInnerHTML={{ __html: text }} />
        <S.Image src={bgImgURL} alt='' />
        {logos?.length > 0 && (
          <S.LogoList>
            {logos.map((logo, i) =>
              logo.link.url ? (
                <a href={logo.link.url} target={logo.link.target}>
                  <S.Logo src={logo.logoImgURL} alt={''} key={i} />
                </a>
              ) : (
                <S.Logo src={logo.logoImgURL} alt={''} key={i} />
              )
            )}
          </S.LogoList>
        )}
      </Container>
    </Section>
  )
}
WhyOwn.ApiBlockToProps = (data: WhyOwnAPIProps) => {
  //return default props for test, will implement data from API later
  if (data.value) {
    const apiData = data.value
    let props: WhyOwnProps = {
      bgImgURL: apiData.image,
      heading: apiData.headline,
      title: apiData.featured_text,
      text: apiData.text,
      background_colour: apiData.background_colour.background_colour,
      logos: apiData.logos.map(lg => {
        return {
          logoImgURL: lg.image,
          link: {
            url:
              lg.logo_link.document_link ||
              lg.logo_link.external_link ||
              lg.logo_link.page_link ||
              undefined,
            target: lg.logo_link.link_target
          }
        }
      })
    }
    return props
  }
}
