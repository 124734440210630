import { Section, Container } from '../../styles/grid.styles'
import { PersonBlockProps } from '../blocks'
import * as S from './BlogAuthor.styled'

const BlogAuthor = ({ name, intro, avatarURL }: PersonBlockProps) => {
  return (
    <Section pb='2rem'>
      <Container>
        <S.Author>
          <S.AuthorAvatar src={avatarURL} alt={name} />
          <S.AuthorInfo>
            <S.AuthorName>{name}</S.AuthorName>
            <S.AuthorIntro>{intro}</S.AuthorIntro>
          </S.AuthorInfo>
        </S.Author>
      </Container>
    </Section>
  )
}

export default BlogAuthor
