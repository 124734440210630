import WagtailBlocksSection from '../src/components/wagtail/components'
import {
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  useContext
} from 'react'
import { Newsletter } from '../src/components/newsletter'
import NewsletterYouFitness from '../src/components/newsletter/YouFitnessTemplate/Newsletter'
import { ArticleList, ArticleListProps } from '../src/components/whats-hot'
import ArticleListYouFitness from '../src/components/whats-hot/YouFitnessTemplate/ArticleList'
import {
  WagtailPageProps,
  PageContext,
  BlogPostAPIItemProps
} from '../src/services/api/types'
import { newsletterProps } from '../src/services/mock'
import { NextPage } from 'next'
import { UrlBuilder, isNullOrEmpty } from '../src/services/utils'
import { apis, cms } from '../src/services/api'
import { apiCall } from '../src/services/api/helpers'
import {
  ITEM_PER_PAGE,
  ALL_CATEGORY_FILTER,
  PAGE_TEMPLATE
} from '../src/services/api/constants'
import { MenuContext } from '../src/services/context/MenuContext'

interface QueryParamsProps {
  type: string
  offset: number
  limit: number
  fields: string
  child_of?: number
  blog_categories?: number
  order?: string
  search?: string
}

const BlogIndexPage: NextPage<{
  pageData: WagtailPageProps
  initialData?: any
}> = (props: { pageData: WagtailPageProps; initialData?: any }) => {
  const { pageData, initialData } = props
  const [queryParams, setQueryParams] = useState<QueryParamsProps>({
    type: `blog.BlogPost`,
    child_of: pageData.id,
    offset: 0,
    limit: ITEM_PER_PAGE,
    order: '-first_published_at',
    fields: `title,slug,preamble,card_image,author,categories`
  })

  const articleElmRef = useRef<any>()
  const { navigationData, setNavigationData } = useContext(MenuContext)
  const scrollToRef = (ref: MutableRefObject<any>) =>
    window.scrollTo(0, ref && ref.current ? ref.current.offsetTop - 116 : 0)

  if (navigationData && !navigationData.startPostion) {
    const heroSlideBlocks =
      pageData &&
      pageData.flexible_content &&
      pageData.flexible_content?.filter(ctn => ctn.type === 'BlogHeroSlide')
    if (isNullOrEmpty(heroSlideBlocks)) {
      setNavigationData &&
        setNavigationData({
          ...navigationData,
          startPostion: 'relative'
        })
    }
  }

  const parsePostData = (postData: any, activePage: number) => {
    const newPostData: ArticleListProps = {
      showSortDropdown: true,
      listTitle: `NEVER STOP LEARNING...`,
      articles:
        postData && postData.items
          ? postData.items.map((item: BlogPostAPIItemProps) => {
            const authorName =
            (item.author && ` BY ${item.author.title}`) || ''
          const firstCategoryName =
            (item.categories &&
              item.categories[0] &&
              `${item.categories[0].category}`) ||
            ''
            let title = ""
            if(pageData.template === PAGE_TEMPLATE.YOU_FITNESS){
              title = item.title
            }else{
              title = firstCategoryName + authorName || 'promotion'
            }
              return {
                title:  title,
                content: item.title,
                action: {
                  label: 'Read article',
                  url: `/${pageData.meta.slug}/${item.meta.slug}`
                },
                imgURL: item.card_image && item.card_image.url,
                skewTrans: false
              }
            })
          : [],
      pagingPerPage: ITEM_PER_PAGE,
      activePage: activePage,
      totalItemsCount:
        (postData && postData.meta && postData.meta.total_count) || 0
    }
    return newPostData
  }

  const [blogPostData, setBlogPosts] = useState(parsePostData(initialData, 1))

  const handleGetPostData = async (
    newQueryParams: QueryParamsProps,
    pageNumber: number
  ) => {
    const postData = await cms.getPosts(newQueryParams)
    setBlogPosts(parsePostData(postData, pageNumber))
  }

  const onPageChange = (pageNumber: number) => {
    const newQueryParams = {
      ...queryParams,
      offset: (pageNumber - 1) * ITEM_PER_PAGE
    }
    setQueryParams(newQueryParams)
  }

  const onCategoryChange = (categoryItem: any) => {
    if (queryParams && queryParams.search) {
      delete queryParams.search
    }
    const newQueryParams = {
      ...queryParams,
      offset: 0,
      blog_categories: categoryItem.id == 0 ? null : categoryItem.id
    }
    setQueryParams(newQueryParams)
  }

  const onSearchPost = (searchKey: string) => {
    if (queryParams && queryParams.blog_categories) {
      delete queryParams.blog_categories
    }
    const newQueryParams = { ...queryParams, offset: 0, search: searchKey }
    setQueryParams(newQueryParams)
    scrollToRef(articleElmRef)
  }

  const onOrderByChange = (selectedSortBy: any) => {
    const newQueryParams = {
      ...queryParams,
      offset: 0,
      order: selectedSortBy.value
    }
    setQueryParams(newQueryParams)
  }

  useEffect(() => {
    if (queryParams && queryParams.offset > 0) {
      const activePageNumber = queryParams.offset / ITEM_PER_PAGE + 1
      handleGetPostData(queryParams, activePageNumber)
    } else {
      handleGetPostData(queryParams, 1)
    }
  }, [queryParams])

  const newFlexibleContent =
    pageData &&
    pageData.flexible_content &&
    pageData.flexible_content.map(item => {
      if (item.type === 'BlogCategoryMenu') {
        let hideMenu = false
        if (pageData.index_type === 'media') {
          hideMenu = true
        }
        const newItem = {
          ...item,
          value: {
            ...item.value,
            menuItems: [
              {
                id: ALL_CATEGORY_FILTER[0].id,
                label: ALL_CATEGORY_FILTER[0].name
              },
              ...item.value.categories.map((menu: any) => {
                return {
                  id: menu.id,
                  label: menu.name,
                  url: menu.slug,
                  target: '_blank'
                }
              })
            ],
            menuClick: onCategoryChange,
            onSearchAction: onSearchPost,
            isSearch: true,
            hideMenu: hideMenu
          }
        }
        item = newItem
      }
      if (item.type === 'BlogHeroSlide') {
        const newHeroSlide = {
          ...item,
          value: {
            slides:
              item.value.blog_post_slides &&
              item.value.blog_post_slides.map((slide: any) => {
                const newSlide = {
                  ...slide,
                  content_alignment: {
                    content_alignment: 'left'
                  }
                }
                return {
                  type: item.type,
                  value: newSlide
                }
              })
          }
        }
        item = newHeroSlide
      }
      return item
    })

  return (
    <>
      {newFlexibleContent && (
        <WagtailBlocksSection
          blocks={newFlexibleContent}
          template={pageData.template}
        />
      )}
      { pageData.template === PAGE_TEMPLATE.YOU_FITNESS ? (
        <ArticleListYouFitness
          refElm={articleElmRef}
          onPageChange={onPageChange}
          onOrderByChange={onOrderByChange}
          {...blogPostData}
        />
      ) : (
        <ArticleList
          refElm={articleElmRef}
          onPageChange={onPageChange}
          onOrderByChange={onOrderByChange}
          {...blogPostData}
        />
      )}
      {pageData.template === PAGE_TEMPLATE.YOU_FITNESS ? (
        <NewsletterYouFitness {...newsletterProps} />
      ) : (
        <Newsletter {...newsletterProps} />
      )}
    </>
  )
}

BlogIndexPage.getInitialProps = async (ctx: PageContext) => {
  const props: { pageData: WagtailPageProps; initialData?: any } = {
    pageData: ctx.pageData
  }

  const { id } = ctx.pageData

  const postData = await apiCall(
    UrlBuilder(apis.WAGTAIL_API_BASE, {
      type: `blog.BlogPost`,
      child_of: id,
      offset: 0,
      limit: ITEM_PER_PAGE,
      order: '-first_published_at',
      fields: `title,slug,preamble,card_image,author,categories`
    }),
    null,
    ctx
  )
  props.initialData = postData
  return props
}

export default BlogIndexPage
