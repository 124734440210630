import styled from 'styled-components'
import tw from 'twin.macro'

export const LogoList = styled.div`
  ${tw`text-center`}
`

export const Logo = styled.img`
  ${tw`inline-block mx-5`}
  height: 80px;

  @media (max-width: 767px) {
    ${tw`mx-2.5`}
    height: 60px;
  }
`

export const Image = styled.img`
  ${tw`mb-8`}
`
export const FeaturedText = styled.div`
  ${tw`uppercase text-center text-white__deep text-sm mb-4`}
`

export const Content = styled.div`
  ${tw`text-white__deep text-center mb-8`}

  .richtext-image {
    &.right {
      margin: 0 0 0 auto;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`text-white__deep`}
  }

  a {
    ${tw`text-blue__deep`}
  }

  .left {
    ${tw`text-left`}

    img {
      ${tw`inline-block`}
    }
  }

  .right {
    ${tw`text-right`}

    img {
      ${tw`inline-block`}
    }
  }

  .full-width img {
    ${tw`w-full`}
  }

  .full-width {
    ${tw`w-full`}
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;

    ul {
      list-style-type: circle;
    }
  }
`
