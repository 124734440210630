import styled from 'styled-components'
import tw from 'twin.macro'
import { fontSize, size } from '../../../styles/theme'

export const PopupWrapperNew = styled.div`
  ${tw`fixed w-full h-full z-40 overflow-auto sm:flex sm:items-center sm:justify-center sm:content-center p-4`}
  top: 0;
  z-index: 100;

  @media (max-width: 414px) {
    align-items: flex-start;
    overflow-y: auto;
  }
`

export const PopupContainerNewLayout = styled.div`
  ${tw`w-full bg-black__deep relative shadow-2xl`}
  max-width: ${size(808)};
  padding: ${size(20)};
  padding-bottom: ${size(70)};
  
  @media (max-width: 480px) {
    padding: ${size(20)} ${size(32)};
    padding-bottom: ${size(40)};
  }

  @media (max-width: 360px) {
    padding: ${size(15)} ${size(17)};
    padding-bottom: ${size(25)};
  }
`
export const PopupHeader = styled.div`
  ${tw`flex justify-end`}

  span {
    svg {
      width: ${size(20)};
      height: ${size(20)};
      cursor: pointer;

      @media (max-width: 414px) {
        ${tw`relative mr-0`}
        left: 1.2rem;
      }
    }
  }
`
export const PopupBody = styled.div``

export const GymCommonTitle = styled.p`
  ${tw`text-center uppercase text-white font-bold relative`}
  ${fontSize(30)}
  padding: 0 ${size(16)};
  padding-bottom: ${size(25)};
  &::before {
    content: '';
    width: 100%;
    max-width: ${size(70)};
    height: ${size(4)};
    ${tw`bg-blue__deep absolute`}
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
  }
  
  @media (max-width: 480px) {
    ${tw`text-left`}
    padding-bottom: ${size(20)};
    padding-left: 0;
    
     &::before {
      content: '';
      width: 100%;
      max-width: ${size(70)};
      height: ${size(4)};
      ${tw`bg-blue__deep absolute`}
      left: ${size(52)};
      bottom: 0;
      transform: translate(-50%, -50%);
    }
  }
`
export const PopupCompContact = styled.div`
  ${tw`flex justify-center text-white`}
  a {
    ${tw`flex`}
    margin: 0 ${size(16)};
    ${fontSize(14)}
    
    div {
      text-decoration: underline;
    }
        
    span {
      text-decoration: none;
      margin-right: ${size(5)}
    }

    @media (max-width: 480px) {
      ${tw`mx-0`}
    }
  }

  @media (max-width: 480px) {
    ${tw`hidden`}
  }
`
export const PopupLink = styled.a``

export const PopupMainBody = styled.div`
  ${tw`flex justify-center text-white`}
  padding-top: ${size(32)};
  padding-bottom: ${size(47)};
  
  @media (max-width: 480px) {
    ${tw`flex-col`}
    padding-top: ${size(33)};
    padding-bottom: ${size(27)};
  }
`

export const PopupAvatarNew = styled.div`
  width: 65%;
  max-width: ${size(313)};
  height: ${size(313)};
  padding: 0 ${size(22.5)};
  
  @media (max-width: 480px) {
    ${tw`w-full`}
    height: ${size(275)};
    padding: 0;
    margin-bottom: ${size(45)};
  }
`

export const PopupDescNew = styled.div<{
  isMobile?: boolean
}>`
  width: 35%;
  padding: 0 ${size(22.5)};

  ${props =>
    !props.isMobile &&
    `
    padding-right: 0;  
    `}

  @media (max-width: 480px) {
    ${tw`w-full`}
    max-width: none;
    padding: 0;
  }
`

export const TextDescriptionNew = styled.div`
  ${tw`text-white`}

  .rich-text,
  h1, h2, h3, h4, h5, h6,
  p, span {
    ${tw`text-white`}
  }

  a {
    ${tw`text-blue__deep`}
  }
`

export const PopupFooter = styled.div`
  ${tw`flex justify-center flex-wrap`}
  a {
    margin: 0 ${size(8)};

    &.call-now {
      display: none;

      @media (max-width: 480px) {
        display: flex;
        white-space: nowrap;
      }
    }
  }

  @media (max-width: 480px) {
    a {
      ${tw`justify-center mb-4`}
      width: calc(50% - 1rem);
    }
  }
`

export const PopupInfoContent = styled.div<{
  content?: string
}>`
  @media screen and (min-width: 480px) {
    ${({ content }) =>
      content && content.length > 400
        ? `min-height: 272px;
  max-height: 301px;
  overflow-y: auto;
  padding-right: 1.5rem;
  `
        : ``}
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2533d5;
  }
`

export const btnIcon = styled.span`
  font-size: 0;
  ${tw`mr-2`}
`
