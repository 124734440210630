import styled from 'styled-components'
import Modal from 'styled-react-modal'
import tw from 'twin.macro'

export const ButtonWrap = styled.div`
  ${tw`inline-block cursor-pointer`}
`

export const OverlayModal = Modal.styled`
  ${tw`bg-black__deep fixed items-center flex flex-col justify-between max-h-screen overflow-auto`}
  min-width: 300px;

  @media (min-width: 768px) {
    min-width: 500px;
  }

  @media (min-width: 1024px) {
    min-width: 600px;
  }

  @media (min-width: 1280px) {
    min-width: 800px;
  }
`