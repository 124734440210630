import styled from 'styled-components'
import tw from 'twin.macro'

export const ClassContentWrapper = styled.div`
  ${tw`w-full sm:max-w-screen-lg mx-auto px-2 xl:px-0 flex justify-center content-center text-center flex-col items-center py-0`}
`

export const ClassCard = styled.div`
  ${tw`w-full lg:w-1/3 p-4 flex flex-col items-stretch justify-between`}
`
export const CardContent = styled.div`
  ${tw`border-b text-left border-grey_d border-solid flex flex-col self-stretch py-4 h-full`}
`
export const Img = styled.a<{ src: string }>`
  ${tw`w-full bg-white cursor-pointer`}
  padding-bottom: 57.6923077%;
  background-size: auto 80%;
  background-repeat: no-repeat;
  background-position: center;
  ${({ src }) => {
    return `background-image: url('${src}');`
  }}
`

export const CardsWrapper = styled.div`
  ${tw`w-full flex flex-wrap mt-12 items-stretch`}
`
