import * as S from './BlocksTable.styled'
import * as G from '../../../styles/global.styles'
import { Colors, WagtailBlockProps } from '../../../services/api/types'
import { BlockTitle } from '../../base'
import { Container, Section } from '../../../styles/grid.styles'

interface Block {
  text: string
  isFull?: boolean
}
export interface BlocksTableProps {
  title: string
  subTitle: string
  blocks: Block[]
  background_colour?: Colors
}

export interface BlocksTableAPIProps extends WagtailBlockProps {
  value: {
    headline: string
    text: string
    table_items: Block[]
    background_colour: {
      background_colour?: Colors
    }
  }
}

export const BlocksTable = (props: BlocksTableProps) => {
  const { blocks, title, subTitle } = props
  return (
    <Section>
      <Container>
        {title && (
          <BlockTitle
            textColor='white'
            textTransform='uppercase'
            isYouFitness
            underlineColor='blue-deep'
          >
            {title}
          </BlockTitle>
        )}
        {subTitle && (
          <G.Text color='white' style={{ textAlign: 'center', marginBottom: 20 }}>
            {subTitle}
          </G.Text>
        )}
        <S.BlocksWrapper>
          {blocks &&
            blocks.map((block, i) => (
              <S.Block
                key={i}
                isFull={block.isFull}
                even={(i + 1) % 2 === 0}
                style={{ marginRight: i % 3 === 2 ? 0 : undefined }}
              >
                <S.BlockInner>
                  <S.Number>{i + 1}</S.Number>
                  <S.TextWrapper>
                    <S.Text>{block.text}</S.Text>
                  </S.TextWrapper>
                </S.BlockInner>
              </S.Block>
            ))}
        </S.BlocksWrapper>
      </Container>
    </Section>
  )
}

BlocksTable.ApiBlockToProps = (data: BlocksTableAPIProps) => {
  const { value } = data
  if (data.value) {
    let props: BlocksTableProps = {
      title: value.headline,
      subTitle: value.text,
      blocks: value.table_items,
      background_colour: value.background_colour.background_colour
    }
    props.blocks[props.blocks.length - 1].isFull = props.blocks.length % 3 == 1
    return props
  }
}

export default BlocksTable
