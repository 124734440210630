import styled from 'styled-components'
import tw from 'twin.macro'
import * as G from '../../styles/global.styles'

export const ContentFooter = styled(G.Container)`
  ${tw`border-t border-solid border-orange py-6`}
`

export const Img = styled.img`
  ${tw`pb-4`}
`

export const TextWrapper = styled.div`
  ${tw`pb-4`}
`
