import { useInView } from 'react-intersection-observer'
import * as S from './Classes.styled'
import * as G from '../../../styles/global.styles'
import { SubHeader } from '../../header/YouFitnessTemplate/SubHeader'
import { Section, Col, Container, Row } from '../../../styles/grid.styles'

export interface ClassProps {
  imgUrl: string
  title?: string
  description?: string
  link: string
}

export interface ClassesProps {
  title: string
  cards: ClassProps[]
  onFilterChange?: Function
  categories: {
    label: string
    value: string
  }[]
}

export const ClassCard = (props: ClassProps) => {
  const { imgUrl, title, description, link } = props
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  })

  return (
    <S.ClassCard>
      <S.CardContent>
        <S.ImgWrap href={link}>
          <S.Img ref={ref} src={inView ? imgUrl : ''} />
        </S.ImgWrap>
        <a href={link}>
          <G.Text color='blue-deep' fontWeight='bold'>
            {title}
          </G.Text>
        </a>
        <G.Text size='sm' color='white'>
          {description}
        </G.Text>
      </S.CardContent>
    </S.ClassCard>
  )
}

export const Classes = (props: ClassesProps) => {
  const { title, cards, onFilterChange, categories } = props

  return (
    <Section className='pt-8'>
      <SubHeader
        subHeaderText={title}
        dropdownOptions={categories}
        onSortOptionChange={onFilterChange}
        showDropdown={true}
        showSeparator
        dropdownPlaceholder='filter by'
      />

      <Container>
        <Row gutter={10}>
          {cards &&
            cards.map((card, idx) => (
              <Col md={4} key={idx}>
                <ClassCard {...card} />
              </Col>
            ))}
        </Row>
      </Container>
    </Section>
  )
}

export default Classes
