import styled from 'styled-components'
import tw from 'twin.macro'
import { SVGIcon } from '../base/icon/svgIcon'

export const Icon = styled(SVGIcon)`
  ${tw`hover:text-orange text-white relative`}
`

export const ContenWrapper = styled.div`
  ${tw`w-full flex flex-wrap justify-center items-center text-3xl pb-8`}
`

export const IconWrapper = styled.a<{
  color: 'facebook' | 'twitter' | 'google-plus' | 'pinterest' | 'instagram' | 'threads'
}>`
  min-width: 2.5em;
  min-height: 2.5em;
  max-width: 2.5em;
  max-height: 2.5em;
  border-radius: 50%;

  ${({ color }) => {
    switch (color) {
      case 'google-plus':
        return tw`bg-pink__googleplus`
      case 'facebook':
        return tw`bg-blue__facebook`
      case 'twitter':
        return tw`bg-blue__twitter`
      case 'pinterest':
        return tw`bg-red__pinterest`
      case 'instagram':
        return tw`bg-pink__instagram`
      case 'threads':
        return tw`bg-black__deep`
    }
  }}

  ${tw`my-2 mx-6 flex items-center justify-center content-center text-white hover:text-blue__dark cursor-pointer`}
`
