import styled from 'styled-components'
import tw from 'twin.macro'
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel'

export const Carousel = styled(ResponsiveCarousel)`
  .carousel {
    .slide {
      ${tw`bg-transparent`};
    }

    .control-dots .dot {
      ${tw`bg-blue`};
      box-shadow: none;

      &.selected {
        ${tw`bg-orange`};
      }
    }
    .thumbs-wrapper {
      display: none;
    }
  }
`
