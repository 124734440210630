import { getPanelIconSet } from '../../services/api/helpers'
import { Block, WagtailBlockProps, Colors } from '../../services/api/types'
import React, { useState } from 'react'
import * as G from '../../styles/global.styles'
import { BlockTitle } from '../base'
import * as S from './ReadyStartedBlock.styles'
import { LazyImage } from '../lazy-image'
import Modal from '../modal/Modal'
import { GymSearchCard } from '../gym-search-card/GymSearchCard'

export interface ReadyStartedBlockProps {
  title?: string
  subText?: string
  blocks: Block[]
  background_colour?: Colors
  gym_id?: number
  gym_title?: string
}

export interface BlockAPIProps {
  link: {
    button_colour:
      | 'white'
      | 'blue'
      | 'blue-dark'
      | 'orange'
      | 'secondary'
      | 'primary'
      | string
    button_icon: {
      icon: string
    }
    button_text: string
    button_link: {
      link_target?: string
      external_link?: string
      page_link?: string
      document_link?: string
      anchor_link?: string
      goto_link?: string
    }
    join_now_popup?: boolean
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  }
}
export interface ReadyStartedBlockAPIProps extends WagtailBlockProps {
  value: {
    headline?: string
    featured_text?: string
    panels: BlockAPIProps[]
    background_colour: {
      background_colour?: Colors
    }
    gym_id?: number
    gym_title?: string
  }
}

export const ReadyStartedBlock = (props: ReadyStartedBlockProps) => {
  const { blocks, title, subText, background_colour, gym_id, gym_title } = props
  const [suburbStep, setSuburbStep] = useState(true)

  const handleSuburbStep = () => {
    setSuburbStep(!suburbStep)
  }

  return (
    <G.Section spacing={8} bgColor={background_colour}>
      <G.Container contentSize='lg'>
        {title && <BlockTitle>{title}</BlockTitle>}
        {subText && (
          <S.SubtextWrapper>
            <G.Text color='blue' fontStyle='italic'>
              {subText}
            </G.Text>
          </S.SubtextWrapper>
        )}
        <S.BlocksWrapper>
          {blocks &&
            blocks.map((block, idx) => (
              <S.Block key={idx} color={block.color}>
                {block.join_now_popup ? (
                  <>
                      <Modal
                          showCloseIcon={!!suburbStep}
                          buttonText={block.text}
                          largeIconImageUrl={block.imgURL}
                          cancelText={!suburbStep ? 'Cancel' : ''}
                        >
                          <GymSearchCard
                             suburbStep={suburbStep}
                             handleSuburbStep={handleSuburbStep}
                            gym={gym_id}
                          />
                        </Modal>                    
                        </>
                ): (
                <S.LinkWrapper
                  href={
                    !block.onClick
                      ? idx === 0 && gym_id && gym_title && block.enquireLink
                        ? `${block.enquireLink}?gymId=${gym_id}&gymTitle=${gym_title}&goto=${block.goto_link}`
                        : block.link
                      : 'javascript:void(0)'
                  }
                  target={block.linkTarget}
                  onClick={block.onClick}
                >
                  <S.ImgWrapper>
                    <LazyImage src={block.imgURL} alt={block.text} />
                  </S.ImgWrapper>
                  <S.Text
                    dangerouslySetInnerHTML={{ __html: block.text }}
                  ></S.Text>
                </S.LinkWrapper>
                )}
              </S.Block>
            ))}
        </S.BlocksWrapper>
      </G.Container>
    </G.Section>
  )
}

ReadyStartedBlock.ApiBlockToProps = (data: ReadyStartedBlockAPIProps) => {
  const { value } = data
  if (value.panels) {
    const { panels } = value
    const props: ReadyStartedBlockProps = {
      title: value.headline,
      subText: value.featured_text,
      blocks: panels.map((panel: BlockAPIProps) => {
        const { link } = panel
        const iconSet = getPanelIconSet(link.button_icon.icon)
        const block: Block = {
          imgURL: iconSet?.icon,
          text: link.button_text || '',
          color: link.button_colour,
          onClick: link.onClick,
          link:
            link.button_link.document_link ||
            link.button_link.external_link ||
            link.button_link.anchor_link || 
            undefined,
          join_now_popup: link.join_now_popup,
          goto_link:
            link.button_link.goto_link || 'membership-enquiry',
          linkTarget: link.button_link.link_target || '',
          enquireLink: link.button_link.page_link || undefined
        }
        return block
      }),
      background_colour: value.background_colour.background_colour,
      gym_id: value.gym_id,
      gym_title: value.gym_title
    }
    return props
  }
}

export default ReadyStartedBlock
