import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel'
import { HeroTextProps } from './Hero'
import * as G from '../../../styles/global.styles'
import { size } from '../../../styles/theme'

export const HeroSection = styled(G.Section)<{
  bgDesktop?: string
  bgMobile?: string
}>`
  background-position: top;
  ${tw`h-full flex bg-blue__deep justify-center items-center overflow-hidden relative`}

  @media (min-width: 1024px) {
    ${({ bgDesktop }) =>
      bgDesktop && `background:url('${bgDesktop}') center/cover no-repeat;`}
  }

  @media (max-width: 1023px) {
    ${({ bgMobile }) =>
      bgMobile && `background:url('${bgMobile}') center/cover no-repeat;`}
  }
`

export const WrapFlip = styled.div<{ labels?: number }>`
  position: relative;
  overflow: hidden;
  width: 300px;
  height: 40px;

  p {
    position: absolute;
    top: ${size(-2.5)};
    margin-bottom: 0;
    text-transform: uppercase;

    &.flip {
      transform: rotateX(360deg);
      transition: transform 0.6s;
    }
  }
`
export const MobileOnly = styled.div`
  @media (max-width: 1023px) {
    ${tw`hidden`}
  }
`
export const HeroWrapper = styled.div<{
  isGym?: boolean
  isHeaderGap?: boolean
  isHeroForm?: boolean
}>`
  ${tw`w-full h-full flex flex-col items-stretch`}

  ${props => props.isGym && css``}

  ${props =>
    props.isHeroForm &&
    css`
      ${tw`md:absolute md:top-0 md:left-0`}
    `}
`

export const ExternalLink = styled.a`
  ${tw`cursor-pointer border border-white text-sm text-white py-2 px-8 inline-block hover:bg-white hover:text-blue__deep transition duration-300`};
`

export const OverlayWrapper = styled.div<{
  overlay?: boolean
}>`
  ${tw`w-full h-full absolute`}

  ${({ overlay }) =>
    overlay &&
    css`
      ${tw`bg-black__overlay`}
    `}
`

export const TextOverlayLeft = styled.div`
  ${tw`absolute text-white font-youfitnessHeading font-bold`}
  top: ${size(20)};
  z-index: 10;
  left: ${size(40)};
`

export const TextOverlayRight = styled.div`
  ${tw`absolute text-white font-youfitnessHeading font-bold`}
  top: ${size(20)};
  z-index: 10;
  right: ${size(40)};
`

export const TextIconsOverlayCenter = styled.div`
  ${tw`absolute text-white font-youfitnessHeading font-bold`}
  top: 50%;
  z-index: 5;
  left: 50%;
  transform: translate(-50%, -50%);

  .text {
    font-size: ${size(48)};
    text-align: center;
    z-index: 2;
    position: relative;
    line-height: 46px;
    margin-bottom: ${size(25)};

    @media (max-width: 767px) {
      font-size: ${size(43)};
    }
  }

  .icon-equal {
    ${tw`absolute`}
    left: ${size(-54)};
    bottom: ${size(-33)};
    width: 118px;
    height: 90px;
    margin-right: 0;
    top: unset;
    z-index: 1;

    @media (max-width: 480px) {
      left: ${size(-24)};
    }
  }

  .icon-plus {
    ${tw`absolute`}
    top: ${size(-36)};
    right: ${size(5)};
    width: 118px;
    height: 115px;
    margin-right: 0;
    z-index: 1;
  }
`

export const Container = styled.div<{
  contentPosition?: 'center' | 'left' | 'right'
}>`
  ${tw`container mx-auto flex flex-col h-full`}

  ${({ contentPosition }) => {
    switch (contentPosition) {
      case 'center':
        return tw`justify-center text-center`
      case 'left':
        return tw`justify-start text-left`
      case 'right':
        return tw`justify-end text-right`
      default:
        return tw`justify-center text-center`
    }
  }}
`

export const ButtonGroup = styled.div<{
  contentPosition?: 'center' | 'left' | 'right'
}>`
  ${tw`py-2 mt-4 flex flex-wrap flex-row items-center`}
  line-height: unset;

  ${({ contentPosition }) => {
    switch (contentPosition) {
      case 'center':
        return tw`justify-center text-center`
      case 'left':
        return tw`justify-start text-left`
      case 'right':
        return tw`justify-end text-right`
      default:
        return tw`justify-center text-center`
    }
  }}
`

export const HeroText = styled(G.Text as any)<HeroTextProps>`
  ${tw`leading-tiny font-youfitnessHeading text-center uppercase not-italic`}
  ${({ size }) => {
    switch (size) {
      case '6xl':
        return tw`leading-8`
      case '5xl':
        return tw`leading-tiny`
      case '4xl':
        return tw`leading-8`
      case '2xl':
        return tw`leading-6`
    }
  }}
  ${({ spaceTop }) => spaceTop && `margin-top:${spaceTop}em;`}
  ${({ spaceBottom }) => spaceBottom && `margin-bottom:${spaceBottom}em;`}
`

export const HeroTextNewLayout = styled(G.Text as any)<HeroTextProps>`
  font-size: 1.875rem !important;

  @media (max-width: 1100px) {
    font-size: ${size(36)} !important;
  }
`

export const HeroTextH1 = styled(G.TextH1 as any)<HeroTextProps>`
  ${tw`lg:leading-none font-youfitnessHeading`}
  ${({ size }) => {
    switch (size) {
      case '6xl':
        return tw`leading-8`
      case '5xl':
        return tw`leading-10`
      case '4xl':
        return tw`leading-8`
      case '2xl':
        return tw`leading-6`
    }
  }}
  ${({ spaceTop }) => spaceTop && `margin-top:${spaceTop}em;`}
  ${({ spaceBottom }) => spaceBottom && `margin-bottom:${spaceBottom}em;`}
`

export const HeroTextH2 = styled(G.TextH2 as any)<HeroTextProps>`
  ${tw`lg:leading-none my-0 font-youfitnessHeading`}
  ${({ size }) => {
    switch (size) {
      case '6xl':
        return tw`leading-8`
      case '5xl':
        return tw`leading-10`
      case '4xl':
        return tw`leading-8`
      case '2xl':
        return tw`leading-6`
    }
  }}
  ${({ spaceTop }) => spaceTop && `margin-top:${spaceTop}em;`}
  ${({ spaceBottom }) => spaceBottom && `margin-bottom:${spaceBottom}em;`}
`

export const HeroButtonLink = styled(G.ButtonLink as any)`
  ${tw`ml-0 my-2 py-2.5 font-medium px-8 lg:py-2.5 text-sm not-italic text-blue__deep bg-white capitalize`}
  line-height: 1.5;

  ${({ color }) => {
    switch (color) {
      case 'secondary-alt':
        return tw`ml-4 border-white text-white bg-blue__deep border-blue__deep hover:bg-blue__dark2 hover:border-blue__dark2`
    }
  }}
`
export const ContentWrapper = styled.div<{
  width?: 'full' | '1/2' | '2/3' | '3/4' | '4/5'
}>`
  ${tw`w-full px-2 lg:mt-2 flex flex-col justify-center`}
  height: 100%;
  ${({ width }) => {
    switch (width) {
      case '1/2':
        return tw`md:w-2/3 lg:w-1/2`
      case '2/3':
        return tw`lg:w-2/3`
      case '3/4':
        return tw`lg:w-3/4`
      case '4/5':
        return tw`lg:w-4/5`
      case 'full':
        return tw`lg:w-full`
      default:
        return null
    }
  }}
`

export const Carousel = styled(ResponsiveCarousel)`
  .carousel {
    .slider-wrapper {
      &.axis-horizontal {
        .slider {
          ${tw`flex flex-row items-stretch`}
          li.slide {
            ${tw`items-stretch flex flex-col`}
          }
        }
      }
    }
  }
`

export const HeroFormBlock = styled.div<{
  isHideMobile?: boolean
}>`
  ${tw`md:absolute right-0 top-0`}
  min-height: 100%;

  h6 {
    ${tw`text-white text-left px-0`}
    margin-top: 0 !important;
  }

  > div {
    width: 320px;
    ${tw`h-full flex items-center justify-center py-10`}

    @media (max-width: 767px) {
      width: 100%;
      ${tw`py-5 px-4`}

      form {
        ${tw`w-full`}
      }
    }
  }

  ${({ isHideMobile }) => {
    return isHideMobile
      ? css`
          @media (max-width: 1023px) {
            ${tw`hidden`}
          }
        `
      : null
  }}
`

export const HeroTextAddress = styled.address`
  ${tw`mb-0 not-italic`}
`

export const HeroTextWrapper = styled.p`
  ${tw`mb-0 text-center`}
`

export const ImgWrapper = styled.div<{ isHeroForm?: boolean }>`
  @media (min-width: 768px) {
    ${props =>
      props.isHeroForm &&
      css`
        max-height: 30rem;
      `}
  }

  @media (min-width: 1024px) {
    max-height: 30rem;
  }

  @media (min-width: 1280px) {
    max-height: 40rem;
  }
  ${tw`z-auto w-full min-h-full min-w-full flex-shrink-0`}
`

export const ImgItem = styled.img<{
  isMobile?: boolean
}>`
  ${({ isMobile }) => {
    return isMobile
      ? `display: block !important;
    @media (min-width: 1024px) {
      display: none !important;
    }`
      : `display: none !important;
    @media (min-width: 1024px) {
      display: block !important;
    }`
  }}
  object-fit: cover;
  object-position: center;
  ${tw`w-full h-full`}
`
export const AnchorWrapper = styled.a`
  ${tw`absolute z-10 top-0 left-0 w-full h-full no-underline`}
`
export const Banner = styled.div`
  position: relative;
`

export const BannerImage = styled.img<{
  isMobile?: boolean
}>`
  ${({ isMobile }) => {
    return isMobile
      ? `display: block !important;
    @media (min-width: 1024px) {
      display: none !important;
    }`
      : `display: none !important;
    @media (min-width: 1024px) {
      display: block !important;
    }`
  }}
  object-fit: cover;
  filter: contrast(150%) saturate(50%);
  ${tw`w-full h-full`}
`

export const GroupTextBanner = styled.div`
  ${tw`flex justify-center items-center flex-col`}
  width: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25);

  p,
  a {
    ${tw`text-white`}
  }

  p {
    ${tw`font-bold uppercase text-3xl mb-2`}
  }

  a {
    ${tw`border border-white`}
    padding: 4px 17px;

    &:hover {
      ${tw`text-black bg-gray border-gray`}
    }
  }
`
