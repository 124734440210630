import * as S from './MembershipBenefits.styles'
import * as G from '../../styles/global.styles'
import {
  Colors,
  LAYOUT_TEMPLATE,
  WagtailBlockProps
} from '../../services/api/types'
import checkBoxIcon from '../../../static/svgs/icon-check.svg'
import checkBoxIconBlue from '../../../static/svgs/icon-check-blue.svg'
import { BlockTitle } from '../base'
import { PAGE_TEMPLATE } from '../../services/api/constants'

export interface BenefitProps {
  name?: string
  icon?: string
  description?: string
}
export interface MembershipBenefitsProps {
  title: string
  text: string
  template?: LAYOUT_TEMPLATE
  benefits: BenefitProps[]
}

export interface MembershipBenefitsAPIProps extends WagtailBlockProps {
  value: {
    headline: string
    subtext: string
    membership_benefits: BenefitProps[]
  }
}

export const MembershipBenefits = (props: MembershipBenefitsProps) => {
  const { title, benefits, template } = props

  const isYouFitnessTemplate = template === PAGE_TEMPLATE.YOU_FITNESS

  return (
    <G.Section
      bgColor={isYouFitnessTemplate ? 'black-deep' : 'blue-dark'}
      spacing={8}
    >
      <S.Container>
        {title && (
          <S.TitleWrapper>
            <BlockTitle
              underlineColor={
                isYouFitnessTemplate ? 'blue-deep' : ('blue' as Colors)
              }
              textColor={isYouFitnessTemplate ? 'white' : ('orange' as Colors)}
              isYouFitness={isYouFitnessTemplate}
              fontSize={isYouFitnessTemplate ? 'lg' : 'md'}
            >
              {title}
            </BlockTitle>
          </S.TitleWrapper>
        )}
        <S.BenefitsContainer>
          <S.BenefitsWrapper>
            {benefits &&
              benefits.map((item, idx) => (
                <S.Benefit key={idx} template={template}>
                  <S.Img
                    src={isYouFitnessTemplate ? checkBoxIconBlue : checkBoxIcon}
                  />
                  <S.BenefitText>{item.name}</S.BenefitText>
                </S.Benefit>
              ))}
          </S.BenefitsWrapper>
        </S.BenefitsContainer>
      </S.Container>
    </G.Section>
  )
}
MembershipBenefits.ApiBlockToProps = (data: MembershipBenefitsAPIProps) => {
  //return default props for test, will implement data from API later
  if (data.value) {
    let props: MembershipBenefitsProps = {
      text: data.value.subtext,
      title: data.value.headline,
      benefits: data.value.membership_benefits,
      template: data.template
    }
    return props
  }
}

export default MembershipBenefits
