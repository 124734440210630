import React, { useEffect, useState } from 'react'
import { WagtailBlockProps, Colors } from '../../services/api/types'
import * as G from '../../styles/global.styles'
import * as S from './PersonalTrainers.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { BlockTitle } from '../base'

export interface PersonalTrainer {
  fullname?: string
  avatar?: string
  description?: string
  phone?: string
  email?: string
  bios?: string
  excert?: string
  instagram_url?: string
  video?: string
  idx?: number
  toggleModal?: () => void
  setSelectedIndex?: () => void
  viewMore?: () => void
}
export interface PersonalTrainersProps {
  title?: string
  subtext?: string
  trainers: PersonalTrainer[]
  background_colour?: Colors
}

export interface TrainerAPIProps {
  full_name: string
  phone?: string
  description?: string
  image: string
  email?: string
  instagram_url?: string
}

export interface PersonalTrainersAPIProps extends WagtailBlockProps {
  value: {
    headline?: string
    text?: string
    personal_trainers: TrainerAPIProps[]
    background_colour: {
      background_colour?: Colors
    }
  }
}

export const PersonalTrainers = (props: PersonalTrainersProps) => {
  const [popupData, setPopupData] = useState<PersonalTrainer | null>()
  const { trainers, title, subtext, background_colour } = props
  const openPopup = (trainer: PersonalTrainer) => {
    setPopupData(trainer)
  }

  const closePopup = () => {
    setPopupData(null)
  }

  useEffect(() => {
    document.body.style.overflow = !!popupData ? 'hidden' : ''
  }, [popupData])

  return (
    <G.Section spacing={16} bgColor={background_colour}>
      {popupData && (
        <S.PopupWrapper className='bg-black bg-opacity-75'>
          <S.PopupContainer>
            <S.Close aria-label='Close' onClick={closePopup}>
              ×
            </S.Close>
            <S.PopupContent>
              <S.AvatarWrapperPopup>
                <S.PopupAvatar
                  src={popupData.avatar}
                  alt={popupData.fullname}
                ></S.PopupAvatar>
              </S.AvatarWrapperPopup>
              <S.PopupInfo>
                <S.Name color='orange' textCase='upper' fontWeight='bold'>
                  {popupData.fullname}
                </S.Name>
                <S.TextDescription
                  dangerouslySetInnerHTML={{
                    __html: popupData.description ? popupData.description : ''
                  }}
                />
                <S.PhoneWrapper>
                  <G.TextPara
                    href={`tel:${popupData.phone}`}
                    color='grey-medium'
                  >
                    <S.PhoneIconWrapper>
                      <FontAwesomeIcon icon={faMobileAlt} />
                    </S.PhoneIconWrapper>
                    {popupData.phone}
                  </G.TextPara>
                  {popupData.email && (
                    <G.TextPara
                      href={`mailto:${popupData.email}`}
                      color='grey-medium'
                    >
                      <S.PhoneIconWrapper>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </S.PhoneIconWrapper>
                      {popupData.email}
                    </G.TextPara>
                  )}
                </S.PhoneWrapper>
              </S.PopupInfo>
            </S.PopupContent>
          </S.PopupContainer>
        </S.PopupWrapper>
      )}

      <G.Container>
        {title && <BlockTitle textTransform='uppercase'>{title}</BlockTitle>}
        {subtext && (
          <S.TitleWrapper>
            <G.TextPara size='xl' color='blue' fontStyle='italic'>
              {subtext}
            </G.TextPara>
          </S.TitleWrapper>
        )}
        <S.ContentWrapper>
          {trainers &&
            !!trainers.length &&
            trainers.map((trainer, idx) => (
              <S.ItemWrapper key={idx}>
                <S.AvatarWrapper>
                  <S.Avatar>
                    <img src={trainer.avatar} alt={trainer.fullname} />
                  </S.Avatar>
                </S.AvatarWrapper>
                <G.TextH3 color='orange' textCase='upper' fontWeight='bold'>
                  {trainer.fullname}
                </G.TextH3>
                <S.Readmore color='orange' onClick={() => openPopup(trainer)}>
                  [Read more]
                </S.Readmore>
              </S.ItemWrapper>
            ))}
        </S.ContentWrapper>
      </G.Container>
    </G.Section>
  )
}

PersonalTrainers.ApiBlockToProps = (
  data: PersonalTrainersAPIProps
): PersonalTrainersProps => {
  const { value } = data
  return {
    title: value.headline,
    subtext: value.text,
    trainers: value.personal_trainers.map((pt: TrainerAPIProps) => {
      return {
        avatar: pt.image,
        fullname: pt.full_name,
        phone: pt.phone,
        description: pt.description,
        email: pt.email
      }
    }),
    background_colour: value.background_colour.background_colour
  }
}
