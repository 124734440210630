import { useRef } from 'react'
import { WagtailBlock } from '../src/components/wagtail/components'
import {
  WagtailBlockProps,
  WagtailPageProps,
  MenuBlockItem,
  PageContext
} from '../src/services/api/types'
import { MenuBlocksProps } from '../src/components/menu/Menu'
import { performancePageSubMenuItemBuild } from '../src/services/api/helpers'
import { getFormCMSContent } from '../src/services/api/cms'
import { isNullOrEmpty } from '../src/services/utils'

const PlusPerformancePage = (props: {
  pageData: WagtailPageProps
  hideNav?: boolean
}) => {
  const { pageData, hideNav } = props

  const scrollToRef = (ref: any) =>
    window.scrollTo(0, ref && ref.current ? ref.current.offsetTop - 116 : 0)

  const menuClick = (item: MenuBlockItem) => {
    switch (item.label) {
      default: {
        scrollToRef(item.blockRef)
        return
      }
    }
  }

  let menus: MenuBlocksProps = {
    //default menu join link (open in new page)
    menuItems: [],
    menuClick: menuClick,
    bgCorlor: 'blue',
    hideNav: hideNav
  }

  // check and build menu base on returned block from CMS
  pageData.flexible_content?.map(block => {
    if (block.type) {
      let menu = performancePageSubMenuItemBuild(block.type)
      const existed = menus.menuItems.find(
        (s: MenuBlockItem) => s.blockName == block.type
      )
      if (menu && !existed) {
        menu.blockId = block.id
        if (!menu.blockRef) menu.blockRef = useRef(null)
        menus.menuItems.push(menu)
      }
    }
  })

  // rebuild block data for some specific blocks
  let blocksData: WagtailBlockProps[] = []
  if (pageData.flexible_content) {
    blocksData = pageData.flexible_content.map((block: WagtailBlockProps) => {
      switch (block.type) {
        case 'page_submenu': {
          block.value = menus
          return block
        }
        default:
          return block
      }
    })
  }

  // make list of blocks components which use to render and check linked with menu
  let blocks =
    blocksData && blocksData.length
      ? blocksData?.map((block, key) => {
          return {
            block: block.type && block.value && (
              <WagtailBlock type={block.type} key={key} data={block} />
            ),
            type: block.type,
            id: block.id
          }
        })
      : null

  return (
    <>
      {blocks &&
        blocks?.map(block => {
          let blockrender = <div className='w-full'>{block.block}</div>
          menus.menuItems = menus.menuItems?.map((mn, idx) => {
            if (mn.blockName == block.type && mn.blockId == block.id) {
              blockrender = (
                <div key={idx} className='w-full' ref={mn.blockRef}>
                  {block.block}
                </div>
              )
            }
            return mn
          })

          return blockrender
        })}
    </>
  )
}

PlusPerformancePage.getInitialProps = async (ctx: PageContext) => {
  let props: {
    pageData: WagtailPageProps
  } = {
    pageData: ctx.pageData
  }

  const forms =
    props.pageData.flexible_content?.filter(
      ctn => ctn.type === 'form' || ctn.type === 'HeroWithForm'
    ) || []
  if (!isNullOrEmpty(forms)) {
    for (let i = 0; i < forms.length; i++) {
      const formData = await getFormCMSContent(ctx, {
        slug:
          forms[i].type === 'HeroWithForm'
            ? forms[i].value.form.slug
            : forms[i].value.slug
      })
      forms[i].value = {
        ...forms[i].value,
        ...formData
      }
    }
  }

  return props
}

export default PlusPerformancePage
