import { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import { YOU_FITNESS } from '../../../services/api/constants'
import { WagtailBlockProps } from '../../../services/api/types'
import { Col, Container, Row, Section } from '../../../styles/grid.styles'
import { BlockTitle } from '../../base'
import * as S from './ImageGallery.styled'

export interface ImageGalleryItem {
  image: string
  caption?: string
}

export interface ImageGalleryProps {
  value: ImageGalleryItem[]
}

export interface ImageGalleryAPIProps extends WagtailBlockProps {
  value: ImageGalleryItem[]
}

export const ImageGallery = (props: ImageGalleryProps) => {
  const { value } = props
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenLightBox = (index: number) => {
    setPhotoIndex(index)
    setIsOpen(true)
  }

  const imgsLength = value.length

  return (
    <>
      {value && !!value.length && (
        <Section>
          <Container fullWidthMobile>
            {YOU_FITNESS.titleImageGallery && (
              <BlockTitle isYouFitness>
                {YOU_FITNESS.titleImageGallery}
              </BlockTitle>
            )}
            <Row mobileGutter={8} gutter={8}>
              {value.map((it, idx) => (
                <Col
                  xs={idx === imgsLength - 1 && imgsLength % 2 !== 0 ? 12 : 6}
                  sm={idx === imgsLength - 1 && imgsLength % 2 !== 0 ? 12 : 6}
                  md={idx === imgsLength - 1 && imgsLength % 2 !== 0 ? 12 : 6}
                  lg={
                    imgsLength === 2 || imgsLength === 4
                      ? 6
                      : idx % 5 < 3
                      ? 4
                      : 6
                  }
                  key={idx}
                  onClick={() => handleOpenLightBox(idx)}
                >
                  <S.ImageWrap>
                    <S.Image src={it.image} alt={it.caption} />
                  </S.ImageWrap>
                </Col>
              ))}
            </Row>
          </Container>
          {isOpen && (
            <Lightbox
              mainSrc={value[photoIndex].image}
              nextSrc={value[(photoIndex + 1) % value.length].image}
              prevSrc={
                value[(photoIndex + value.length - 1) % value.length].image
              }
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + value.length - 1) % value.length)
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % value.length)
              }
            />
          )}
        </Section>
      )}
    </>
  )
}

ImageGallery.ApiBlockToProps = (
  data: ImageGalleryAPIProps
): ImageGalleryProps => {
  const { value } = data
  return { value }
}

export default ImageGallery
