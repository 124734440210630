export { default as GenericPageTemplate } from './GenericPageTemplate'
import GenericPageTemplate from './GenericPageTemplate'
import HomePage from './HomePage'
import Error from './ErrorPage'
import BlogIndexPage from './BlogIndexPage'
import BlogPost from './BlogPost'
import GymIndexPage from './GymIndexPage'
import Gym from './Gym'
import FranchiseeBenefitsIndex from './FranchiseeBenefitsIndex'
import PlusPerformancePage from './PlusPerformancePage'
import ClassIndex from './ClassIndex'
import ClassPage from './ClassPage'
import FranchiseeBenefitPage from './FranchiseeBenefitPage'
import CorporatePartnerPage from './CorporatePartnerPage'
import GymStudio from './GymStudio'
import OwnAGymPage from './OwnAGymPage'
import YourGoals from './YourGoals'
import CorporatePartnerIndexPage from './CorporatePartnerIndexPage'
import GymOpportunityIndex from './GymOpportunityIndex'
import FranchisePage from './FranchisePage'

const layout = (name: string): any => {
  switch (name) {
    case 'HomePage':
      return HomePage
    case 'BlogIndex':
      return BlogIndexPage
    case 'BlogPost':
      return BlogPost
    case 'FranchiseeBenefitsIndex':
      return FranchiseeBenefitsIndex
    case 'FranchiseeBenefitPage':
      return FranchiseeBenefitPage
    case 'GymIndex':
      return GymIndexPage
    case 'GymOpportunityIndex':
      return GymOpportunityIndex
    case 'Gym':
    case 'GymOpportunity':
      return Gym
    case 'Error':
      return Error
    case 'PlusPerformancePage':
      return PlusPerformancePage
    case 'ClassIndex':
      return ClassIndex
    case 'ClassPage':
      return ClassPage
    case 'GymStudio':
      return GymStudio
    case 'OwnAGymPage':
      return OwnAGymPage
    case 'YourGoals':
      return YourGoals
    case 'CorporatePartnerIndexPage':
      return CorporatePartnerIndexPage
    case 'CorporatePartnerPage':
      return CorporatePartnerPage
    case 'FranchisePage':
      return FranchisePage
    default:
      // memberhips go here
      return GenericPageTemplate
  }
}

export default layout
