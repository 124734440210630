import React, { CSSProperties } from 'react'
import { Link, WagtailBlockProps } from '../../../services/api/types'
import { ButtonLink } from '../../base'
import { LazyImage } from '../../lazy-image'
import { Container } from '../../../styles/grid.styles'
import * as S from './Banner.styled'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

export interface BannerProps {
  imageURL: string
  title: string
  content: string
  action: Link
  styles?: CSSProperties
}

export interface BannerAPIProps extends WagtailBlockProps {
  value: {
    image: {
      image: string
    }
    title: string
    title_large_text: string
    banner_link_text: string
    banner_link: {
      link_target: string
      external_link: string
      page_link: string
      document_link: string
      anchor_link?: string
    }
  }
}
export const Banner = (props: BannerProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [triangleHeight, setTriangleHeight] = useState(300)

  const {
    imageURL,
    title,
    content,
    action: { url, label, target }
  } = props

  useEffect(() => {
    ref.current?.clientHeight &&
      setTriangleHeight(ref.current?.clientHeight + 5)
  }, [ref])

  return (
    <S.Section>
      <Container fullWidthMobile>
        <S.Banner bgImage={imageURL}>
          <S.BannerImage bgImage={imageURL}>
            <LazyImage src={imageURL} alt={title} width='100%' />
          </S.BannerImage>
          <S.BannerInfo ref={ref} triangleHeight={triangleHeight}>
            <S.BannerTitle>{title}</S.BannerTitle>
            <S.Content>{content}</S.Content>
            {url && (
              <S.ButtonWrapper>
                <ButtonLink href={url} color='blue-deep' target={target}>
                  {label}
                </ButtonLink>
              </S.ButtonWrapper>
            )}
          </S.BannerInfo>
        </S.Banner>
      </Container>
    </S.Section>
  )
}

Banner.ApiBlockToProps = (data: BannerAPIProps) => {
  const { value } = data
  if (data.value) {
    let props: BannerProps = {
      imageURL: value.image.image,
      title: value.title,
      content: value.title_large_text,
      action: {
        target: value.banner_link.link_target,
        label: value.banner_link_text,
        url:
          value.banner_link.page_link ||
          value.banner_link.document_link ||
          value.banner_link.external_link ||
          value.banner_link.anchor_link
      }
    }
    return props
  }
}

export default Banner
