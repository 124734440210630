import * as S from './ImageLink.styles'
import * as G from '../../styles/global.styles'
import { WagtailBlockProps } from '../../services/api/types'
import { BlockTitle } from '../base'

export interface ImagelinkProps {
  title?: string
  imgUrl: string
  link?: string
  target?: string
  align?: 'left' | 'center' | 'right'
}

export interface ImagelinkAPIProps extends WagtailBlockProps {
  value: {
    headline: string
    image: string
    image_link?: {
      link_target?: string
      external_link?: string
      page_link?: string
      document_link?: string
    }
    content_alignment?: {
      content_alignment: 'left' | 'center' | 'right'
    }
  }
}

export const ImageLink = (props: ImagelinkProps) => {
  const { imgUrl, link, target, title, align } = props
  return (
    <G.Section>
      {title && (
        <G.CenteredContainer>
          <S.TitleWrapper>
            <BlockTitle>{title}</BlockTitle>
          </S.TitleWrapper>
        </G.CenteredContainer>
      )}

      <G.FlexContainer>
        <S.ContentWrapper contentAlign={align}>
          {link ? (
            <S.Link href={link} target={target}>
              <S.IMG src={imgUrl} alt={title} />
            </S.Link>
          ) : (
            <S.IMG src={imgUrl} alt={title} />
          )}
        </S.ContentWrapper>
      </G.FlexContainer>
    </G.Section>
  )
}

ImageLink.ApiBlockToProps = (data: ImagelinkAPIProps): ImagelinkProps => {
  const { value } = data
  return {
    imgUrl: value.image,
    link:
      value.image_link?.external_link ||
      value.image_link?.page_link ||
      value.image_link?.document_link,
    target: value.image_link?.link_target,
    align: value.content_alignment?.content_alignment,
    title: value.headline
  }
}
