import * as G from '../../../styles/global.styles'
import * as S from './KeyMessage.styles'
import Head from 'next/head'
import {
  ButtonProps,
  WagtailBlockProps,
  ButtonAPIProps,
  Colors
} from '../../../services/api/types'
import { Container, Section } from '../../../styles/grid.styles'
export interface KeyMessageBlock {
  Title: string
  LargeText?: string
  Msg?: string
  ImageURL: string
  buttons: ButtonProps[]
}

export interface KeyMessageProps {
  blocks: [KeyMessageBlock, KeyMessageBlock]
  centralButtons: ButtonProps[]
  overlay?: string
  background_colour?: Colors
  isHomePage?: boolean
}

export interface KeyMessageAPIProps extends WagtailBlockProps {
  value: {
    overlay: string
    left_panel: {
      background: {
        image: string
        mobile_image: string
      }
      headline: string
      large_text: string
      subtext: string
      button: ButtonAPIProps[]
    }
    right_panel: {
      background: {
        image: string
        mobile_image: string
      }
      headline: string
      large_text: string
      subtext: string
      button: ButtonAPIProps[]
    }
    centered_call_to_action_button: ButtonAPIProps[]
    background_colour: {
      background_colour?: Colors
    }
    isHomePage?: boolean
  }
}

export const KeyMessage = (props: KeyMessageProps) => {
  const { blocks, isHomePage } = props

  return (
    <>
      <Head>
        {blocks.length > 0 && blocks[0].ImageURL && (
          <link rel='preload' href={blocks[0].ImageURL} as='image' />
        )}
      </Head>
      <Section pb={isHomePage ? '0px' : ''}>
        <Container>
          <S.KeyMessageWrap isHomePage={isHomePage}>
            {blocks.map((block, i) => {
              return (
                <S.KeyMessage key={i} src={block.ImageURL}>
                  {block.Title && (
                    <S.KeyMessageTitle>{block.Title}</S.KeyMessageTitle>
                  )}
                  {block.LargeText && (
                    <S.KeyMessageLargeText>
                      {block.LargeText}
                    </S.KeyMessageLargeText>
                  )}
                  {block.Msg && (
                    <S.KeyMessageDesc>{block.Msg}</S.KeyMessageDesc>
                  )}

                  {block.buttons && block.buttons.length > 0 && (
                    <S.ButtonsList>
                      {block.buttons.map((btn, idx) => (
                        <G.WhiteButton key={idx} href={btn.href}>
                          {btn.label?.toLowerCase()}
                        </G.WhiteButton>
                      ))}
                    </S.ButtonsList>
                  )}
                </S.KeyMessage>
              )
            })}
          </S.KeyMessageWrap>
        </Container>
      </Section>
    </>
  )
}

KeyMessage.ApiBlockToProps = (data: KeyMessageAPIProps) => {
  const { value } = data
  const props: KeyMessageProps = {
    blocks: [
      {
        Title: value.left_panel.headline,
        LargeText: value.left_panel.large_text,
        Msg: value.left_panel.subtext,
        ImageURL: value.left_panel.background.image,
        buttons: value.left_panel.button.map(btn => {
          return {
            label: btn.button_text,
            href:
              btn.button_link.external_link ||
              btn.button_link.page_link ||
              btn.button_link.document_link ||
              btn.button_link.anchor_link,
            color: btn.button_colour,
            target: btn.button_link.link_target
          }
        })
      },
      {
        Title: value.right_panel.headline,
        LargeText: value.right_panel.large_text,
        Msg: value.right_panel.subtext,
        ImageURL: value.right_panel.background.image,
        buttons: value.right_panel.button.map(btn => {
          return {
            label: btn.button_text,
            href:
              btn.button_link.external_link ||
              btn.button_link.page_link ||
              btn.button_link.document_link ||
              btn.button_link.anchor_link,
            color: btn.button_colour,
            target: btn.button_link.link_target
          }
        })
      }
    ],
    centralButtons: value.centered_call_to_action_button.map(btn => {
      return {
        label: btn.button_text,
        href:
          btn.button_link.external_link ||
          btn.button_link.page_link ||
          btn.button_link.document_link ||
          btn.button_link.anchor_link,
        color: btn.button_colour,
        target: btn.button_link.link_target,
        onClick: btn.onClick
      }
    }),
    overlay: value.overlay,
    background_colour: value.background_colour.background_colour,
    isHomePage: value.isHomePage
  }
  return props
}

export default KeyMessage
