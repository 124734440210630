import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const BlogHero = styled.div`
  ${tw`text-white pt-6 bg-black mb-5`}
`

export const BreadCrumb = styled.div`
  ${tw`flex mb-3`}

  a {
    ${tw`underline`}
  }
`

const breadCrumbStyle = css<{ category?: boolean }>`
  ${tw`italic text-sm mr-4 pr-2 relative`}
  color: #909090;

  &:after {
    content: '/';
    ${tw`absolute `}
    right: -0.5rem;
  }

  &:last-child:after {
    ${tw`hidden`}
  }

  ${({ category }) =>
    category &&
    css`
      ${tw`capitalize`}
    `}
`

export const BreadCrumbItemLink = styled.a<{ category?: boolean }>`
  ${breadCrumbStyle};
`

export const BreadCrumbItem = styled.span<{ category?: boolean }>`
  ${breadCrumbStyle};
`

export const Heading = styled.h1`
  ${tw`text-white uppercase text-3xl font-youfitnessHeading`}
`

export const GroupInfo = styled.div`
  ${tw`flex flex-col md:flex-row justify-between mb-7 md:items-center`}
`

export const Info = styled.div`
  ${tw`font-bold text-sm mb-4 md:mb-0`}
  color: #909090;

  span {
    ${tw`capitalize`}
  }
`

export const ImageWrap = styled.div`
  ${tw`relative`}

  &:before {
    height: 50%;
    content: '';
    ${tw`absolute bottom-0 left-0 w-full bg-black__deep`}
  }
`

export const BlogImage = styled.img`
  ${tw`relative z-10 w-full`}
`
