import { useState, useEffect } from 'react'
import WagtailBlocksSection from '../src/components/wagtail/components'
import { WagtailPageProps, PageContext } from '../src/services/api/types'
import { NextPage } from 'next'
import { UrlBuilder } from '../src/services/utils'
import { apis, cms } from '../src/services/api'
import { apiCall } from '../src/services/api/helpers'
import {
  FranchiseBenefits,
  FranchiseBenefitsProps
} from '../src/components/franchise-benefits'

interface QueryParamsProps {
  type: string
  fields: string
  search?: string
}

interface CorporatePartnerAPIItemProps {
  id: number
  meta: {
    type: string
    detail_url: string
    html_url: string
    slug: string
    first_published_at: string
  }
  title: string
  details: string
  contact_details: string
  logo: string
  logo_thumb: string
  notes: string
}

const CorporatePartnerIndexPage: NextPage<{
  pageData: WagtailPageProps
  initialData?: any
}> = (props: { pageData: WagtailPageProps; initialData?: any }) => {
  const { pageData, initialData } = props
  const [queryParams, setQueryParams] = useState<QueryParamsProps>({
    type: `corporate_partners.CorporatePartnerPage`,
    fields: `title,details,notes,contact_details,logo_thumb,logo`
  })

  const parseCorporateData = (corporateData: any) => {
    const data: FranchiseBenefitsProps = {
      title: pageData.title,
      franchise:
        corporateData && corporateData.items
          ? corporateData.items.map((item: CorporatePartnerAPIItemProps) => {
              return {
                imgURL: item.logo,
                description: item.details,
                name: item.title,
                action: { label: 'VIEW BENEFITS', url: item.meta.html_url }
              }
            })
          : []
    }
    return data
  }

  const [corporateData, setCorporateData] = useState(
    parseCorporateData(initialData)
  )

  const handleGetCorporatePartnerData = async (
    newQueryParams: QueryParamsProps
  ) => {
    const corporateData = await cms.getCorporate(newQueryParams)
    setCorporateData(parseCorporateData(corporateData))
  }

  const onSearch = (searchKey: string) => {
    const newQueryParams = { ...queryParams, search: searchKey }
    setQueryParams(newQueryParams)
  }

  useEffect(() => {
    handleGetCorporatePartnerData(queryParams)
  }, [queryParams])

  return (
    <>
      <div className='w-full'>
        <FranchiseBenefits {...corporateData} handleSearch={onSearch} />
      </div>
      <WagtailBlocksSection blocks={pageData.flexible_content || []} />
    </>
  )
}

CorporatePartnerIndexPage.getInitialProps = async (ctx: PageContext) => {
  let props: { pageData: WagtailPageProps; initialData?: any } = {
    pageData: ctx.pageData
  }

  const data = await apiCall(
    UrlBuilder(apis.WAGTAIL_API_BASE, {
      type: `corporate_partners.CorporatePartnerPage`,
      fields: `title,details,notes,contact_details,logo_thumb,logo`
    }),
    null,
    ctx
  )

  props.initialData = data
  return props
}

export default CorporatePartnerIndexPage
