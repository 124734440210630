import { useRef } from 'react'
import { WagtailBlock } from '../src/components/wagtail/components'
import {
  GymPageProps,
  WagtailBlockProps,
  PageContext,
  LinkAPI,
  MenuBlockItem
} from '../src/services/api/types'
import { MenuBlocksProps } from '../src/components/menu/Menu'
import { NextPage } from 'next'
import { MAP_API_KEY } from '../src/services/api/constants'
import {
  gymMediaPageSubMenuItemBuild,
  apiCall
} from '../src/services/api/helpers'
import { apis } from '../src/services/api'

const GymStudio: NextPage<{
  pageData: GymPageProps
  hideNav?: boolean
}> = (props: {
  pageData: GymPageProps
  hideNav?: boolean
  initialData?: GymPageProps
}) => {
  const { pageData, hideNav, initialData } = props
  const scrollToRef = (ref: any) =>
    window.scrollTo(0, ref && ref.current ? ref.current.offsetTop - 116 : 0)

  const menuClick = (item: MenuBlockItem) => {
    switch (item.label) {
      default: {
        if (item.blockRef && item.blockRef.current) {
          scrollToRef(item.blockRef)
        } else {
          window.open(item.url, item.target)
        }
        return
      }
    }
  }

  const menus: MenuBlocksProps = {
    //default menu join link (open in new page)
    menuItems: initialData?.menuItems || [],
    menuClick: menuClick,
    hideNav: hideNav
  }

  menus.menuItems.forEach(item => {
    if (item.blockName && !item.blockRef) {
      item.blockRef = useRef(null)
    }
    return item
  })

  // rebuild block data for some specific blocks
  let blocksData: WagtailBlockProps[] = []
  if (pageData.flexible_content) {
    blocksData = pageData.flexible_content.map((block: WagtailBlockProps) => {
      switch (block.type) {
        case 'page_submenu': {
          block.value = menus
          return block
        }
        case 'gym_location': {
          if (initialData) {
            block.value = {
              ...block.value,
              apiKey: MAP_API_KEY,
              name: initialData.title,
              address: initialData.address_street || '',
              suburb: `${initialData.address_suburb} ${initialData.address_state_code}`,
              postcode: initialData.address_postcode || '',
              phone: initialData.phone_number || block.value.phone || '',
              email: initialData.email || block.value.email || '',
              geo: initialData.geo_point?.coordinates
            }
            return block
          }
          return block
        }
        case 'CTAPanels': {
          block.value = {
            ...block.value,
            gym_id: pageData.id,
            gym_title: pageData.title
          }
          return block
        }
        case 'Hero': {
          block.value = {
            ...block.value,
            is_gym: true,
            name: pageData?.title,
            address: pageData?.address_street ? pageData?.address_street : null,
            sub_address: `${
              pageData?.address_suburb ? `${pageData?.address_suburb} ` : ''
            }${
              pageData?.address_state_code
                ? `${pageData?.address_state_code} `
                : ''
            }${
              pageData?.address_postcode ? `${pageData?.address_postcode}` : ''
            }`
          }
          return block
        }
        default:
          return block
      }
    })
  }

  return (
    <>
      {blocksData &&
        blocksData.map((block: WagtailBlockProps, key: number) => {
          let blockrender = (
            <div className='w-full'>
              <WagtailBlock type={block.type} key={key} data={block} />
            </div>
          )
          menus.menuItems = menus.menuItems.map(mn => {
            if (mn.blockName == block.type) {
              blockrender = (
                <div className='w-full' ref={mn.blockRef}>
                  <WagtailBlock type={block.type} key={key} data={block} />
                </div>
              )
            }
            return mn
          })

          return blockrender
        })}
    </>
  )
}

GymStudio.getInitialProps = async (ctx: PageContext) => {
  let props: { pageData: GymPageProps; initialData?: GymPageProps } = {
    pageData: ctx.pageData
  }

  const { pageData } = ctx

  const data = await apiCall(
    `${apis.WAGTAIL_API_BASE}${ctx.pageData.meta.parent?.id}`,
    null,
    ctx
  )

  const menuItems: MenuBlockItem[] = []
  // check and build menu base on returned block from CMS
  pageData.flexible_content?.map(block => {
    if (block.type) {
      let menu = gymMediaPageSubMenuItemBuild(block.type)
      if (menu && !menuItems.find(s => s.blockName == block.type)) {
        menuItems.push(menu)
      }
    }
  })

  const sub = pageData.flexible_content?.find(s => s.type == 'page_submenu')
  if (sub) {
    sub.value?.link?.forEach((link: LinkAPI) => {
      menuItems.push({
        label: link.link_text,
        url:
          link.link.external_link ||
          link.link.page_link ||
          link.link.document_link,
        target: link.link.link_target
      })
    })
  }

  props.initialData = { ...data, menuItems }

  return props
}

export default GymStudio
