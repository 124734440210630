import { useInView } from 'react-intersection-observer'
import * as S from './Classes.styles'
import * as G from '../../styles/global.styles'
import { SubHeader } from '../header'

export interface ClassProps {
  imgUrl: string
  title?: string
  description?: string
  link: string
}

export interface ClassesProps {
  title: string
  cards: ClassProps[]
  onFilterChange?: Function
  categories: {
    label: string
    value: string
  }[]
}

export const ClassCard = (props: ClassProps) => {
  const { imgUrl, title, description, link } = props
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  })

  return (
    <S.ClassCard>
      <S.CardContent>
        <S.Img ref={ref} src={inView ? imgUrl : ''} href={link} />
        <a href={link}>
          <G.Text color='orange' fontWeight='bold'>
            {title}
          </G.Text>
        </a>
        <G.Text color='black'>{description}</G.Text>
      </S.CardContent>
    </S.ClassCard>
  )
}

export const Classes = (props: ClassesProps) => {
  const { title, cards, onFilterChange, categories } = props

  return (
    <G.Section spacing={8} bgColor='grey-sand'>
      <S.ClassContentWrapper>
        <SubHeader
          subHeaderText={title}
          dropdownOptions={categories}
          onSortOptionChange={onFilterChange}
          showDropdown={true}
          showSeparator
          dropdownPlaceholder='filter by'
        />
        <S.CardsWrapper>
          {cards && cards.map((card, idx) => <ClassCard key={idx} {...card} />)}
        </S.CardsWrapper>
      </S.ClassContentWrapper>
    </G.Section>
  )
}
