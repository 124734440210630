import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import * as S from './VideoBlock.styled'
import { WagtailBlockProps } from '../../../services/api/types'
import { useInView } from 'react-intersection-observer'
import { LazyImage } from '../../lazy-image'
import { VideoPlayerImgAlt } from '../../../services/api/constants'
import * as G from '../../../styles/global.styles'
import { BlockTitle, IconEnums } from '../../base'
import { Col, Container, Row, Section } from '../../../styles/grid.styles'
import { useEffect } from 'react'

export interface VideoBlocksProps {
  thumbnailImageURL?: string
  leftThumbnailURL?: string
  leftVideoTitle?: string
  leftVideoURL?: string
  leftVideoDesc?: string
  rightThumbnailURL?: string
  rightVideoTitle?: string
  rightVideoURL?: string
  rightVideoDesc?: string
  isShowTwoVideo?: boolean
  isHomePage?: boolean
  isShowPlusIcon?: boolean
}

export interface PlayButtonProps {
  buttonImgURL?: string
  onPlayClick?: Function
  altText?: string
}

export interface VideoPlayerProps {
  thumbnailURL?: string
  videoTitle?: string
  videoURL?: string
  videoDesc?: string
  isShowTwoVideo?: boolean
  isHomePage?: boolean
  isShowPlusIcon?: boolean
  autoPlay?: boolean
  pauseVideo?: boolean
  loop?: boolean
  isVideoBackground?: boolean
}

export interface VideoAPIProps {
  video_thumbnail: string
  video_title: string
  video_description: string
  video_url: string
}

export interface VideoBlocksAPIProps extends WagtailBlockProps {
  value: {
    video_embeds: VideoAPIProps[]
    isHomePage?: boolean
  }
}

export const PlayButton = (props: PlayButtonProps) => {
  const { buttonImgURL, onPlayClick, altText } = props

  return (
    <S.PlayButtonWrap
      onClick={() => {
        onPlayClick && onPlayClick()
      }}
    >
      <LazyImage
        src={buttonImgURL || '/static/imgs/icon-play.png'}
        alt={altText}
      />
    </S.PlayButtonWrap>
  )
}

export const VideoPlayer = (props: VideoPlayerProps) => {
  const {
    thumbnailURL,
    videoURL,
    isShowTwoVideo,
    isHomePage,
    isShowPlusIcon = false,
    autoPlay,
    loop,
    isVideoBackground
  } = props

  const [playing, setPlaying] = useState(false)
  const [displayEmbed, setDisplayEmbed] = useState(false)
  const playerRef = React.useRef<ReactPlayer>(null)
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  })

  const displayPlay = () => {
    setPlaying(true)
    setDisplayEmbed(true)
  }

  useEffect(() => {
    if (autoPlay && playerRef.current) {
      setTimeout(() => {
        displayPlay()
      }, 100)
    } else {
      setPlaying(false)
      setDisplayEmbed(false)
    }
  }, [autoPlay, playerRef])

  return (
    <S.PlayerWrapper
      ref={ref}
      isShowTwoVideo={isShowTwoVideo}
      playing={playing}
      isHomePage={isHomePage}
      isVideoBackground={isVideoBackground}
    >
      {!playing && <S.VideoThumb thumbURL={inView ? thumbnailURL : ''} />}
      <S.ReactPlayerWrapper active={displayEmbed}>
        <ReactPlayer
          className='react-player'
          ref={playerRef}
          playing={playing && inView}
          light={false}
          height='100%'
          url={videoURL}
          loop={loop}
          playsinline={true}
          controls={isVideoBackground ? false : true}
          muted={isVideoBackground ? true : false}
          volume={isVideoBackground ? 0 : 1}
          onPause={() => {
            setPlaying(false)
            setDisplayEmbed(false)
          }}
          config={{
            vimeo: {
              playerOptions: {
                controls: true,
                playsinline: true,
                background: isVideoBackground ? true : false
              }
            }
          }}
        />
      </S.ReactPlayerWrapper>
      {!playing && !isVideoBackground && (
        <PlayButton onPlayClick={displayPlay} altText={VideoPlayerImgAlt} />
      )}
      {isShowPlusIcon && !isShowTwoVideo && !isVideoBackground && (
        <G.Icon className='icon-plus' name={IconEnums['icon-plus-4']} />
      )}
    </S.PlayerWrapper>
  )
}

export const VideoBlockNewLayout = (props: VideoBlocksProps) => {
  const {
    leftThumbnailURL,
    leftVideoTitle,
    leftVideoURL,
    leftVideoDesc,
    rightThumbnailURL,
    rightVideoTitle,
    rightVideoURL,
    rightVideoDesc,
    isShowTwoVideo,
    isShowPlusIcon,
    isHomePage
  } = props

  return leftVideoURL ? (
    <Section pb='1rem'>
      <Container fullWidthMobile>
        <Row gutter={8} mobileGutter={0}>
          {(leftVideoTitle || leftThumbnailURL) && (
            <Col md={isShowTwoVideo ? 6 : 12}>
              <S.VideoBlock>
                {leftVideoTitle && (
                  <BlockTitle
                    isYouFitness
                    textColor='white'
                    fontSize='sm'
                    underlineColor='blue-deep'
                  >
                    {leftVideoTitle}
                  </BlockTitle>
                )}
                <VideoPlayer
                  thumbnailURL={leftThumbnailURL}
                  videoTitle={leftVideoTitle}
                  videoURL={leftVideoURL}
                  isShowTwoVideo={isShowTwoVideo}
                  isHomePage={isHomePage}
                  isShowPlusIcon={isShowPlusIcon}
                />
                <S.VideoDescription
                  dangerouslySetInnerHTML={{ __html: leftVideoDesc || '' }}
                />
              </S.VideoBlock>
            </Col>
          )}
          {(rightVideoTitle || rightThumbnailURL) && (
            <Col md={isShowTwoVideo ? 6 : 12}>
              <S.VideoBlock>
                {rightVideoTitle && (
                  <BlockTitle
                    isYouFitness
                    textColor='white'
                    fontSize='sm'
                    underlineColor='blue-deep'
                  >
                    {rightVideoTitle}
                  </BlockTitle>
                )}
                <VideoPlayer
                  thumbnailURL={rightThumbnailURL}
                  videoTitle={rightVideoTitle}
                  videoURL={rightVideoURL}
                  videoDesc={isShowTwoVideo ? '' : rightVideoDesc}
                  isShowTwoVideo={isShowTwoVideo}
                  isHomePage={isHomePage}
                  isShowPlusIcon={isShowPlusIcon}
                />
                <S.VideoDescription
                  dangerouslySetInnerHTML={{ __html: rightVideoDesc || '' }}
                />
              </S.VideoBlock>
            </Col>
          )}
        </Row>
      </Container>
    </Section>
  ) : null
}

VideoBlockNewLayout.ApiBlockToProps = (data: VideoBlocksAPIProps) => {
  const { value } = data
  const props: VideoBlocksProps = {
    isShowTwoVideo: false
  }

  if (value.video_embeds[0]) {
    props.leftVideoURL = value.video_embeds[0].video_url
    props.leftThumbnailURL = value.video_embeds[0].video_thumbnail
    props.leftVideoDesc = value.video_embeds[0].video_description
    props.leftVideoTitle = value.video_embeds[0].video_title
  }

  if (value.video_embeds[1]) {
    props.isShowTwoVideo = true
    props.rightThumbnailURL = value.video_embeds[1].video_thumbnail
    props.rightVideoDesc = value.video_embeds[1].video_description
    props.rightVideoTitle = value.video_embeds[1].video_title
    props.rightVideoURL = value.video_embeds[1].video_url
  }

  props.isHomePage = value.isHomePage

  return props
}

export default VideoBlockNewLayout
