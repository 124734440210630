import styled from 'styled-components'
import tw from 'twin.macro'
import { Section } from '../../../styles/grid.styles'
import { size } from '../../../styles/theme'

export const ImageWrapper = styled.div`
  ${tw`flex self-end absolute`}
  height: ${size(508)};
  top: ${size(-85)};
  left: ${size(40)};
  width: ${size(266)};
  overflow: hidden;
  border-radius: 40px;

  @media (max-width: 1024px) {
    ${tw`relative self-center left-0`}
    top: ${size(-254)};
    margin-bottom: ${size(-200)};
  }
  
  img {
    ${tw`w-full h-full object-cover`}

    &.main-img {
      ${tw`relative z-20 p-2.5`}
      border-radius: 30px;
    }
    
    &.frame {
      ${tw`absolute z-30 object-contain`}
    }

    &.logo-plus {
      ${tw`absolute object-contain z-30`}
      height: 65%;
      width: 65%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  
`

export const Details = styled.div`
  ${tw`w-full p-2 py-12 lg:py-16 px-4 text-white`}
  padding-left: ${size(400)};

  @media (max-width: 1024px) {
    ${tw`pl-0 pt-0`}
  }

  @media (max-width: 768px) {
    height: auto;
    padding-left: 0;
    padding-top: 0;
    position: relative;
  }
`

export const Title = styled.div`
  ${tw`w-full font-youfitnessHeading text-xs uppercase font-bold`}
`

export const BigTitle = styled.h2`
  ${tw`w-full relative font-youfitnessHeading leading-tiny text-4xl mb-4 text-3xl pb-4 lg:pb-7 lg:mb-7 lg:pr-5 font-bold uppercase text-white`}
  
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    ${tw`bg-white`}
    width: ${size(50)};
    height: ${size(4)};
    left: 0;
  }

  @media (max-width: 1024px) {
    &::after {
      left: 50%;
      margin-left: ${size(-25)};
    }
  }
`

export const Content = styled.p`
  ${tw`w-full mb-6 text-sm`}
`

export const Advertisement = styled.div`
  ${tw`flex text-center lg:text-left flex-col lg:flex-row items-center relative`}
`

export const AdvertisementWrap = styled(Section)`
  ${tw`mt-12 relative`}

  &:before {
    content: "";
    ${tw`left-0 w-full h-12 absolute bg-black__deep block`}
    top: -3rem;
  }

  @media (min-width: 1024px) {
    &:after {
      height: 7rem;
    }
  }

  @media (max-width: 1024px) {
    margin-top: ${size(254)};

    &:before {
      content: "";
      height: ${size(254)};
      top: ${size(-254)};
    }
  }
`

export const Button = styled.a`
  ${tw`bg-white text-black text-center font-medium text-blue__deep text-sm transition-colors duration-300 inline-block`}
  line-height: 1.5;
  padding: 0.625rem 2rem;

  &:hover {
    background-color: #cccccc;
  }
`

export const ButtonGroup = styled.div`
  ${tw`flex flex-col gap-4 sm:flex-row items-center justify-center lg:justify-start mt-6 sm:mt-0`}
`