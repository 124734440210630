import { Section } from '../../styles/grid.styles'
import styled from 'styled-components'
import tw from 'twin.macro'

export const OurValues = styled(Section)`
  background: linear-gradient(270deg, #2533d5 0%, rgba(0, 0, 0, 0) 31.15%),
    linear-gradient(90deg, #2533d5 0%, rgba(0, 0, 0, 0) 32.44%);

  ${tw`pt-20 bg-black__deep`}
`

export const Content = styled.div`
  ${tw`text-white text-lg md:text-1.5xl`}

  h1, h2, h3, h4, h5, h6 {
    ${tw`text-white font-youfitnessHeading mb-0 text-2xl md:text-2.5xl leading-tiny`}
  }

  p {
    ${tw`mb-6`}
  }
`

export const IconList = styled.div`
  ${tw`-mb-16 pt-10 lg:pt-0`}
`

export const Icon = styled.div`
  ${tw`text-center text-white mb-16`}
`

export const IconImg = styled.img`
  ${tw`mx-auto mb-6`}
`

export const IconTitle = styled.h3`
  ${tw`font-youfitnessHeading font-extrabold text-white mb-0 leading-none`}
  font-size: 2.5rem;
`

export const IconSubTitle = styled.div`
  ${tw`font-youfitnessHeading font-extrabold text-white text-1.5xl leading-none`}
`
