import React, { useRef } from 'react'

import {
  Link,
  WagtailBlockProps,
  ButtonProps,
  Colors,
  GymLocation,
  LAYOUT_TEMPLATE
} from '../../services/api/types'
import {
  GoogleMapDirectionLink,
  GoogleMapPointLink
} from '../../services/api/helpers'
import { PAGE_TEMPLATE } from '../../services/api/constants'
import * as G from '../../styles/global.styles'
import * as S from './ClubLocation.styles'
import { ButtonLink } from '../base'
import { ButtonWithIcon } from '../base/ButtonWithIcon'
import DriectionImg from '../../static/imgs/get-directions-arrow.png'
import Modal from '../modal/Modal'
import VirtualImg from '../../static/imgs/icon-vtour.png'
import dynamic from 'next/dynamic'
import ReactGA from 'react-ga';

interface OpenTime {
  day: string
  times: string
}
interface TimeGroup {
  title: string
  times: OpenTime[]
}

const LocationMap = dynamic(() => import('../maps/LocationMap'), {
  ssr: false
})

export interface ClubLocationProps {
  prefix?: string
  name: string
  address: string
  suburb: string
  postcode: string
  phone?: string
  email?: string
  otherOptions?: string[]
  geo?: [number, number]
  OpenTimes: TimeGroup[]
  isHaveTime?: boolean
  directionAction?: Link
  distanceAction?: Link
  virtualTourAction?: Link
  CTAButtons?: ButtonProps[]
  background_colour?: Colors
  mapPins?: Array<GymLocation>
  template?: LAYOUT_TEMPLATE
  description: string
  franchisee_email?: string
  show_franchisee_email?: boolean
}

export interface ClubLocationAPIProps extends WagtailBlockProps {
  value: {
    prefix?: string
    name: string
    address: string
    suburb: string
    postcode: string
    phone?: string
    email?: string
    geo: [number, number]
    pre_text: string
    virtual_tour_url: ''
    staffed_hours: OpenTime[]
    creche_hours: OpenTime[]
    additional_address_text: string
    parking_comments: string
    show_map: true
    CTAButtons?: ButtonProps[]
    background_colour: {
      background_colour?: Colors
    }
    mapPins?: Array<GymLocation>
    template?: LAYOUT_TEMPLATE
    description: string
    franchisee_email?: string
    show_franchisee_email?: boolean
  }
}

export const ClubLocation = (props: ClubLocationProps) => {
  const {
    prefix,
    name,
    address,
    suburb,
    postcode,
    phone,
    email,
    otherOptions,
    geo,
    OpenTimes,
    directionAction,
    virtualTourAction,
    CTAButtons,
    isHaveTime,
    background_colour,
    mapPins,
    franchisee_email,
    show_franchisee_email
  } = props

  const list_franchisee_email = franchisee_email
    ? franchisee_email?.split(',')
    : []

  const clubMapRef = useRef<any>()

  const googleMapDirection = directionAction
    ? {
        ...directionAction,
        label: 'Open in Maps'
      }
    : null
  const handlePhoneLinkClick = () => {
      ReactGA.event({
        category: 'Club location',
        action: 'Click to call',
        label: phone,
        nonInteraction: true
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'click_to_call',
      });
  };
  return (
    <G.Section bgColor={background_colour || 'grey-sand'}>
      <S.Container>
        <S.ContentWrapper size={2}>
          <S.InfoWrapper>
            <S.TextBlockInline>
              {prefix && <S.TextPrefix>{prefix}</S.TextPrefix>}
              <S.TextName title={name}>{name}</S.TextName>
            </S.TextBlockInline>
            <G.TextH3
              color='orange'
              text='xl'
              fontWeight='bold'
              textCase='upper'
              className='mb-2'
            >
              Address
            </G.TextH3>
            <S.AddressWrapper>
              <S.TextBlock isHover={true}>
                <G.TextParaCustom
                  href={directionAction?.url}
                  target={directionAction?.target}
                >
                  {address} <br />{' '}
                  <span>
                    {suburb} {postcode}
                  </span>{' '}
                </G.TextParaCustom>
              </S.TextBlock>
              <S.TextBlock>
                {phone && (
                  <G.TextPara
                    isHover={true}
                    href={`tel:${phone}`}
                    onClick={handlePhoneLinkClick}
                    color='grey-dark'
                  >
                    P: <span>{phone}</span>
                  </G.TextPara>
                )}
                {email && (
                  <G.TextPara
                    isUnderline={true}
                    isHover={true}
                    href={`mailto:${email}`}
                    color='grey-dark'
                  >
                    E: <span>{email}</span>
                  </G.TextPara>
                )}
                {!!list_franchisee_email?.length &&
                  show_franchisee_email &&
                  list_franchisee_email.map(email => (
                    <G.TextPara
                      isUnderline={true}
                      isHover={true}
                      href={`mailto:${email}`}
                      color='grey-dark'
                    >
                      Franchisee: <span>{email}</span>
                    </G.TextPara>
                  ))}
                {otherOptions &&
                  otherOptions.map((op, idx) => (
                    <G.Text key={idx} color='grey-dark' textCase='upper'>
                      {op}
                    </G.Text>
                  ))}
              </S.TextBlock>
            </S.AddressWrapper>

            {isHaveTime && (
              <S.OpenTimesWrapper>
                {OpenTimes &&
                  OpenTimes.map(
                    (ot, idx) =>
                      ot.title &&
                      ot.times.length > 0 && (
                        <div className='mb-4'>
                          <G.TextH3
                            key={idx}
                            color='orange'
                            text='xl'
                            fontWeight='bold'
                            textCase='upper'
                            className='mb-2'
                          >
                            {ot.title}
                          </G.TextH3>
                          {ot.times &&
                            ot.times.map((t, i) => (
                              <S.TextWrapperInline key={i}>
                                <S.WeekDayText>{t.day}</S.WeekDayText>
                                <S.WeekDayTime>{t.times}</S.WeekDayTime>
                              </S.TextWrapperInline>
                            ))}
                        </div>
                      )
                  )}
                {CTAButtons && (
                  <S.ButtonsGroup>
                    {CTAButtons.map(btn => (
                      <S.ButtonWrapper>
                        <ButtonLink {...btn}>{btn.label}</ButtonLink>
                      </S.ButtonWrapper>
                    ))}
                  </S.ButtonsGroup>
                )}
              </S.OpenTimesWrapper>
            )}
            <S.ButtonActionWrapper>
              {directionAction && (
                <S.ButtonAction isFirst={true}>
                  <ButtonWithIcon
                    color='blue'
                    iconImgUrl={DriectionImg}
                    label={directionAction.label}
                    href={directionAction.url}
                    target={directionAction.target}
                    size='lg'
                  ></ButtonWithIcon>
                </S.ButtonAction>
              )}
              {virtualTourAction && virtualTourAction.url && (
                <S.ButtonAction>
                  <Modal
                    buttonText={virtualTourAction.label}
                    cancelText='Close'
                    iconImageUrl={VirtualImg}
                  >
                    <S.IframeWrapper src={virtualTourAction.url} />
                  </Modal>
                </S.ButtonAction>
              )}
            </S.ButtonActionWrapper>
          </S.InfoWrapper>
        </S.ContentWrapper>
        <S.ContentWrapper size={2}>
          <LocationMap
            mapZoom={[14]}
            centerCoord={geo}
            mapData={mapPins}
            refElm={clubMapRef}
            googleMapDirection={googleMapDirection}
          ></LocationMap>
        </S.ContentWrapper>
      </S.Container>
    </G.Section>
  )
}

ClubLocation.ApiBlockToProps = (
  data: ClubLocationAPIProps
): ClubLocationProps => {
  const { value } = data
  return {
    prefix: value.pre_text,
    name: value.name,
    address: value.address,
    suburb: value.suburb,
    postcode: value.postcode,
    phone: value.phone,
    email: value.email,
    otherOptions: [value.parking_comments],
    geo: value.geo,
    OpenTimes: [
      {
        title: 'STAFFED HOURS',
        times: value.staffed_hours
      },
      {
        title: 'CRECHE HOURS',
        times: value.creche_hours
      }
    ],
    isHaveTime: value.creche_hours.length > 0 || value.staffed_hours.length > 0,
    directionAction: {
      url: GoogleMapPointLink(value.geo),
      label:
        value.template == PAGE_TEMPLATE.YOU_FITNESS
          ? 'Larger Map'
          : 'Get directions',
      target: '_blank'
    },
    distanceAction: {
      url: GoogleMapDirectionLink(value.geo),
      label: 'Get directions',
      target: '_blank'
    },
    virtualTourAction: { url: value.virtual_tour_url, label: 'Virtual tour' },
    CTAButtons: value.CTAButtons,
    background_colour: value.background_colour.background_colour,
    mapPins: value.mapPins,
    template: value.template,
    description: value.description,
    franchisee_email: value.franchisee_email,
    show_franchisee_email: value.show_franchisee_email
  }
}

export default ClubLocation
