import { Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import { Form, FormGroup, FormLabel, ErrorMessage } from './Form.styled'
import { ContactFormWrapper } from './ContactForm.styled'
import { EnquiryFormRow } from './MemberEnquiryForm.styled'
import { Button } from '../base/Button'
import { TextInput, TextAreaInput } from '../base'
import { BlockTitle } from '../base/Title'
import ReactGA from 'react-ga';

export interface ContactFormProps {
  submitText?: string
  formHeader?: string
  onSubmit?: Function
}

const ContactSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  phone: Yup.string().required('Phone is required'),
  message: Yup.string().required('Message is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required')
})

export const ContactForm = (props: ContactFormProps) => {
  const { submitText, formHeader, onSubmit } = props

  const submit = (values: any) => {
    // We add our react conversion
    ReactGA.event({
      category: 'Contact submit',
      action: 'form contact submit',
      nonInteraction: true
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'contact_form_submit',
    });
    onSubmit && onSubmit(values)
  }

  return (
    <Formik
      validationSchema={ContactSchema}
      onSubmit={submit}
      initialValues={{}}
    >
      {({
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }: FormikValues) => (
        <ContactFormWrapper>
          <BlockTitle>{formHeader || 'Contact us'}</BlockTitle>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>Name</FormLabel>
              <TextInput
                name='name'
                color='black'
                borderColor='white'
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage>
                {errors.name && touched.name && errors.name}
              </ErrorMessage>
            </FormGroup>
            <FormGroup>
              <FormLabel>Email Address</FormLabel>
              <TextInput
                name='email'
                color='black'
                borderColor='white'
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage>
                {errors.email && touched.email && errors.email}
              </ErrorMessage>
            </FormGroup>
            <FormGroup>
              <FormLabel>Phone Number</FormLabel>
              <TextInput
                type='tel'
                name='phone'
                color='black'
                borderColor='white'
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage>
                {errors.phone && touched.phone && errors.phone}
              </ErrorMessage>
            </FormGroup>
            <FormGroup>
              <FormLabel>Message</FormLabel>
              <TextAreaInput
                rows={5}
                name='message'
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage>
                {errors.message && touched.message && errors.message}
              </ErrorMessage>
            </FormGroup>
            <EnquiryFormRow justify='end'>
              <Button type='submit' disabled={isSubmitting}>
                {submitText || 'SEND'}
              </Button>
            </EnquiryFormRow>
          </Form>
        </ContactFormWrapper>
      )}
    </Formik>
  )
}

export default ContactForm
