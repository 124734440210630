import { FormsAPIProps, FormsProps } from '../../../services/api/types'
import { getFormInitValues, dynamicValidation } from '../../../services/utils'
import { submitForm, submitFormWithFiles } from '../../../services/api/cms'
import { useState } from 'react'
import { useFormik } from 'formik'
import dynamic from 'next/dynamic'
import { Container, Section } from '../../../styles/grid.styles'
import * as G from '../../../styles/global.styles'
import { getCookieFromBrowser } from '../../../services/api/cookies'

// Dynamic import due to SSR issue with react-select as per
// https://github.com/JedWatson/react-select/issues/3590
const StandardForm = dynamic(() => import('./StandarForm'), {
  ssr: false
})
const MemberEnquiryForm = dynamic(() => import('./MemberEnquiryForm'), {
  ssr: false
})

export const Forms = (props: FormsProps) => {
  const { slug, ctaButtons, fields, postRedirectPage } = props

  const [responseMessage, setResponseMessage] = useState('')
  const [success, setSuccess] = useState<boolean | null>(null)
  let fileFieldText: Array<string> = []
  fields &&
    fields.length > 0 &&
    fields.map(field => {
      if (field.type === 'file') {
        fileFieldText.push(field.name)
      }
    })
  const hasFile = fileFieldText && fileFieldText.length > 0

  const formik = useFormik({
    initialValues: getFormInitValues(props.fields),
    validationSchema: dynamicValidation(props.fields),
    onSubmit: async values => {
      let fileData: any
      let fieldData: any
      if (hasFile) {
        fileData = Object.keys(values)
          .filter(key => fileFieldText.includes(key))
          .reduce((obj: any, key: string) => {
            obj[key] = values[key]
            return obj
          }, {})
        fieldData = Object.keys(values)
          .filter((key: string) => !fileFieldText.includes(key))
          .reduce((obj: any, key: string) => {
            obj[key] = values[key]
            return obj
          }, {})
      }
      if (!slug) {
        return
      }

      const utmSourceCookie = getCookieFromBrowser('utm_source')
      const utmCampaignCookie = getCookieFromBrowser('utm_campaign')
      if (utmSourceCookie && utmCampaignCookie) {
        values['utm_source'] = utmSourceCookie
        values['utm_campaign'] = utmCampaignCookie
      }

      let res = hasFile
        ? await submitFormWithFiles(null, slug, fieldData, fileData)
        : await submitForm(null, slug, values)
      if (res.ok) {
        setResponseMessage(res.success_message)
        setSuccess(true)
      } else {
        setResponseMessage(res.error_message)
        setSuccess(false)
        throw responseMessage
      }
    }
  })

  if (success) {
    {
      postRedirectPage && window.location.assign(postRedirectPage)
    }
    return (
      <Section>
        <Container style={{ textAlign: 'center' }}>
          <G.Text color='white' fontWeight='bold'>
            {responseMessage || 'Your enquiry was successful.'}
          </G.Text>
          <G.Space />
          {ctaButtons?.map(cta => (
            <G.BlueButtonLink href={cta.href}>{cta.label}</G.BlueButtonLink>
          ))}
        </Container>
      </Section>
    )
  } else {
    if (slug.includes('memberships-enquiry')) {
      return <MemberEnquiryForm formik={formik} {...props} />
    } else if (slug.includes('performance-enquire')) {
      return <MemberEnquiryForm formik={formik} formColums={2} {...props} />
    } else return <StandardForm formik={formik} {...props} />
  }
}

Forms.ApiBlockToProps = (data: FormsAPIProps): FormsProps => {
  const apiData = data.value
  const {
    form,
    slug,
    form_action,
    title,
    preamble,
    pre_form_text,
    reference_id,
    submit_button_text,
    success_message,
    error_message,
    post_redirect_page,
    fields,
    gym_id,
    gym_title,
    pop_up_form
  } = apiData

  return {
    slug,
    title,
    fields: form.fields ? form.fields : fields,
    preamble,
    reference_id,
    id: form.form ? form.form : form,
    formAction: form_action,
    preFormText: pre_form_text,
    submitText: submit_button_text,
    successMessage: success_message,
    errorMessage: error_message,
    postRedirectPage: post_redirect_page,
    ctaButtons: [
      {
        color: 'blue-deep',
        href: '/',
        label: 'Back To Homepage'
      }
    ],
    gymId: gym_id,
    gymTitle: gym_title,
    pop_up_form: form?.pop_up_form || pop_up_form
  }
}

export default Forms
