import styled from 'styled-components'
import tw from 'twin.macro'

export const ClassCard = styled.div`
  ${tw`w-full flex flex-col mb-7 items-stretch justify-between overflow-hidden`}
`

export const CardContent = styled.div`
  ${tw`border-b text-sm text-left border-grey_d border-solid flex flex-col self-stretch py-4 h-full`}
`

export const ImgWrap = styled.a`
  ${tw`mb-4 overflow-hidden`}
`

export const Img = styled.div<{ src: string }>`
  ${tw`w-full cursor-pointer bg-cover`}
  padding-bottom: 57.6923077%;
  transform: scale(1.05);

  ${({ src }) => {
    return `background-image: url('${src}');`
  }}
`
