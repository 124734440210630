import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const BlocksWrapper = styled.div`
  ${tw`w-full flex flex-wrap items-stretch justify-center mx-auto`}
  max-width: 700px;

  @media (max-width: 767px) {
    ${tw`block`}
  }
`
export const Block = styled.div<{
  even?: boolean
  isFull?: boolean
}>`
  ${tw`inline-flex items-end text-white mb-10 pt-5 mx-8`}
  width: 180px;
  ${({ isFull }) => (isFull ? tw`flex uppercase w-full` : tw``)}

  ${({ even }) =>
    even &&
    css`
      ${Number} {
        ${tw`text-blue__deep`}
      }
    `}

  @media (max-width: 767px) {
    ${tw`w-full flex mx-0 px-4`}
  
    ${({ even }) =>
      even &&
      css`
        ${tw`justify-end`}
      `}
  }
`

export const BlockInner = styled.div`
  ${tw`flex items-end`}
`

export const Number = styled.div`
  font-size: 144px;
  line-height: 0.55;
  ${tw`flex-initial font-youfitnessHeading font-bold flex items-center`}
`

export const TextWrapper = styled.div`
  ${tw`flex-1 flex items-center pl-2`}
`

export const Text = styled.div`
  width: 85px;
  ${tw`text-sm`}
`
