import { ButtonLink } from '../src/styles/global.styles'
import { Link } from '../src/services/api/types'
import { NextPage } from 'next'

interface ErrorPageProps {
  pageData: {
    status: number
    statusText: string
  }
  title: string
  button: Link
}

const ErrorPage: NextPage<ErrorPageProps> = (props: ErrorPageProps) => {
  const {
    pageData: { status, statusText },
    title,
    button
  } = props
  return (
    <div className='w-full'>
      <div className='container mx-auto'>
        <div className='w-full mt-24 text-center'>
          <h1 className='text-6xl font-extrabold text-grey__dark'>{title}</h1>
        </div>
        <div className='w-full my-12'>
          <h1 className='text-2xl font-bold'>
            {status} Error - {statusText}
          </h1>
        </div>
        <div className='w-full text-center mt-12'>
          <ButtonLink
            color='blue'
            size='2xl'
            fontWeight='bold'
            href={button.url}
          >
            {button.label}
          </ButtonLink>
        </div>
      </div>
    </div>
  )
}
ErrorPage.defaultProps = {
  title: 'Something went wrong!',
  pageData: {
    status: 404,
    statusText:
      'the page you are looking for might not exist or could be an expired link.'
  },
  button: {
    label: '-go to homepage',
    url: '/'
  }
}

export default ErrorPage
