import WagtailBlocksSection from '../src/components/wagtail/components'
import { WagtailPageProps, PageContext } from '../src/services/api/types'
import { NextPage } from 'next'
import { UrlBuilder } from '../src/services/utils'
import { apis } from '../src/services/api'
import { apiCall } from '../src/services/api/helpers'
import {
  FranchiseBenefits,
  FranchiseBenefitsProps
} from '../src/components/franchise-benefits'

interface FranchiseeBenefitsAPIItemProps {
  id: number
  meta: {
    type: string
    detail_url: string
    html_url: string
    slug: string
    first_published_at: string
  }
  title: string
  results_text: string
  image: string
  url: string
}

const FranchiseeBenefitsIndex: NextPage<{
  pageData: WagtailPageProps
  initialData?: any
}> = (props: { pageData: WagtailPageProps; initialData?: any }) => {
  const { pageData, initialData } = props
  const data: FranchiseBenefitsProps = {
    title: pageData.title,
    franchise:
      initialData && initialData.items
        ? initialData.items.map((item: FranchiseeBenefitsAPIItemProps) => {
            return {
              imgURL: item.image,
              description: item.results_text,
              name: item.title,
              action: { label: 'VIEW BENEFITS', url: item.url }
            }
          })
        : []
  }

  return (
    <>
      <div className='w-full'>
        <FranchiseBenefits {...data} />
      </div>
      {pageData && pageData.flexible_content && (
        <WagtailBlocksSection blocks={pageData.flexible_content} />
      )}
    </>
  )
}

FranchiseeBenefitsIndex.getInitialProps = async (ctx: PageContext) => {
  let props: { pageData: WagtailPageProps; initialData?: any } = {
    pageData: ctx.pageData
  }

  const data = await apiCall(
    UrlBuilder(apis.WAGTAIL_API_BASE, {
      type: `franchisee_benefits.FranchiseeBenefitPage`,
      fields: `url,results_text,title,image`
    }),
    null,
    ctx
  )
  props.initialData = data
  return props
}

export default FranchiseeBenefitsIndex
