import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { PAGE_TEMPLATE } from '../../services/api/constants'
import { LAYOUT_TEMPLATE } from '../../services/api/types'
import { size } from '../../styles/theme'

export const Container = styled.div`
  ${tw`container mx-auto py-10 flex flex-col justify-center text-center`}
`
export const Img = styled.img`
  width: 2.25rem;
`

export const TitleWrapper = styled.div`
  ${tw`w-full text-center flex flex-col justify-center items-center`}
`

export const TextWrapper = styled.div`
  ${tw`w-full text-center px-2 pt-8`}
`

export const BenefitsContainer = styled.div`
  ${tw`w-full block overflow-x-auto mt-4 flex flex-col items-center`}
`

export const BenefitsWrapper = styled.div`
  ${tw`w-full md:w-full lg:w-8/12 flex flex-wrap items-center content-center justify-center pt-2`}
`

export const BenefitText = styled.span`
  ${tw`text-white text-left`}
  font-size: ${size(16)}
`

export const Benefit = styled.div<{ template?: LAYOUT_TEMPLATE }>`
  ${tw`w-full sm:w-1/2 flex items-center pl-6 text-white mb-1`};

  ${({ template }) =>
    template === PAGE_TEMPLATE.YOU_FITNESS &&
    css`
      ${tw`mb-2.5`}

      ${BenefitText} {
        ${tw`ml-4 text-sm`}
      }

      ${Img} {
        width: 30px;
      }
    `}
`
