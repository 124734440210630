import {
  ArticleCard,
  InstagramCard,
  TwitterCard,
  SocialIconCard,
  SocialCardProps,
  SocialIconCardProps
} from './cards'
import * as S from './WhatsHot.styles'
import * as G from '../../styles/global.styles'
import { BlockTitle, ButtonLink, IconEnums } from '../base'
import {
  Link,
  WagtailBlockProps,
  ButtonAPIProps
} from '../../services/api/types'

export interface BlockProps {
  type: 'twitter' | 'article' | 'instagram' | 'social-icons'
  block: SocialCardProps
}

interface BlockRow {
  blocks: [BlockProps, BlockProps, BlockProps]
}

export interface WhatsHotProps {
  title?: string
  blocksRow?: BlockRow[]
  action: Link
}

export interface BlogAPIProps {
  title: string
  author: {
    name: string
    image: string
    biography: string
  }
  preamble: string
  date: string
  header_image?: string
  full_url: string
}
export interface WhatsHotAPIProps extends WagtailBlockProps {
  value: {
    cta_button: ButtonAPIProps
    latest_posts: BlogAPIProps[]
    social_media: {
      twitter: string
      facebook: string
      linkedin: string
      instagram: string
      youtube: string
      app: string
    }
  }
}

export const WhatsHot = (props: WhatsHotProps) => {
  const {
    title,
    blocksRow,
    action: { label, url }
  } = props

  return (
    <G.Section bgColor='grey-light' spacing={16}>
      <G.Container contentSize='lg'>
        <S.TitleWrapper>
          {title && <BlockTitle textTransform='uppercase'>{title}</BlockTitle>}
        </S.TitleWrapper>
        <S.BlocksGroup>
          {blocksRow &&
            blocksRow.map((row, i) => (
              <S.Blocks key={i}>
                {row &&
                  row.blocks.map((block, j) => {
                    switch (block.type) {
                      case 'article':
                        return (
                          <ArticleCard
                            key={j}
                            {...block.block}
                            isReskew={true}
                          />
                        )
                      case 'instagram':
                        return (
                          <InstagramCard
                            key={j}
                            {...block.block}
                            isReskew={true}
                          />
                        )
                      case 'twitter':
                        return (
                          <TwitterCard
                            key={j}
                            {...block.block}
                            isReskew={true}
                          />
                        )
                      case 'social-icons':
                        return (
                          <SocialIconCard
                            key={j}
                            {...block.block}
                            isReskew={true}
                          />
                        )
                    }
                  })}
              </S.Blocks>
            ))}
        </S.BlocksGroup>
        <S.CenteredButton>
          <ButtonLink href={url} color='blue' className='py-4'>
            {label}
          </ButtonLink>
        </S.CenteredButton>
      </G.Container>
    </G.Section>
  )
}

WhatsHot.ApiBlockToProps = (data: WhatsHotAPIProps) => {
  const { value } = data
  const props: WhatsHotProps = {
    title: `What's hot`,
    action: {
      label: value.cta_button.button_text,
      url:
        value.cta_button.button_link?.page_link ||
        value.cta_button.button_link?.external_link ||
        '#'
    },
    blocksRow: []
  }
  let blocks: BlockProps[] = []
  if (value.latest_posts && value.latest_posts.length > 0) {
    let i = 0
    for (i = 0; i < 2; i++) {
      if (value.latest_posts[i]) {
        let block: BlockProps = {
          type: 'article',
          block: {
            title: value.latest_posts[i].title,
            content: value.latest_posts[i].preamble,
            action: {
              label: 'Read article',
              url: value.latest_posts[i].full_url
            },
            imgURL: value.latest_posts[i].header_image
          }
        }
        blocks.push(block)
      }
    }
  }
  if (value.social_media) {
    let socialBlock: SocialIconCardProps = {
      socials: []
    }
    if (!!value.social_media.facebook) {
      socialBlock.socials?.push({
        icon: IconEnums['facebook-c'],
        url: value.social_media.facebook
      })
    }
    if (!!value.social_media.twitter) {
      socialBlock.socials?.push({
        icon: IconEnums['twitter-c'],
        url: value.social_media.twitter
      })
    }
    if (!!value.social_media.instagram) {
      socialBlock.socials?.push({
        icon: IconEnums['instagram-c'],
        url: value.social_media.instagram
      })
    }
    if (!!value.social_media.youtube) {
      socialBlock.socials?.push({
        icon: IconEnums['youtube-c'],
        url: value.social_media.youtube
      })
    }
    if (!!value.social_media.app) {
      socialBlock.socials?.push({
        icon: IconEnums['plus-app'],
        url: value.social_media.app
      })
    }
    blocks.push({
      type: 'social-icons',
      block: socialBlock
    })
  }
  props.blocksRow = [{ blocks: [blocks[0], blocks[1], blocks[2]] }]
  return props
}

export default WhatsHot
