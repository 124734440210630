import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { colors, size } from '../../../styles/theme'
import * as G from '../../../styles/global.styles'

export const ContentWrap = styled.div<{ isContactPage?: boolean }>``

export const Content = styled.div`
  ${tw`text-black__deep`}
  text-transform: uppercase;
  .richtext-image {
    &.right {
      margin: 0 0 0 auto;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`text-white`}
  }

  a {
    ${tw`text-blue__deep`}
  }

  ul {
    ${tw`pl-5`}
    list-style: disc;
  }

  ol {
    ${tw`pl-5`}
    list-style: number;
  }

  p,
  b {
    word-break: break-word;
  }

  .full-width {
    ${tw`w-full`}
  }
`

export const ReadMore = styled.span`
  ${tw`pt-6 pb-2 inline-block font-medium cursor-pointer text-blue__deep hover:underline`}
`

export const BannerTitle = styled(G.TextH2)`
  ${tw`mb-1 text-white font-youfitnessHeading text-sm uppercase font-normal lg:font-bold`}
  @media (min-width: 1024px) {
    ${tw`text-blue__deep text-xs`}
  }
`

export const BannerContent = styled.h3<{ custom?: boolean }>`
  ${tw`text-white text-center text-3xl font-youfitnessHeading leading-tiny px-4 font-bold uppercase mb-2 leading-10 lg:leading-none`}
  @media (min-width: 1024px) {
    ${({ custom }) => {
      return !custom ? tw`text-black mb-6` : tw`text-white mb-6`
    }}
  }
`

export const GymBodyMainSection = styled.div`
  padding-top: ${size(56)};
  padding-bottom: ${size(62)};
`

export const Banner = styled.div<{ bgImage?: string; custom?: boolean }>`
  ${tw`bg-black__deep flex relative overflow-hidden bg-cover bg-no-repeat`}

  ${(props) => props.custom && tw`flex-row-reverse`}

  ${(props) =>
    props.bgImage &&
    css`
      background-image: url(${props.bgImage});
    `}

  @media (max-width: 1023px) {
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
    }
  }
`

export const BannerImage = styled.div<{ bgImage?: string }>`
  flex: 0 0 55%;
  img {
    ${tw`h-full object-cover opacity-0 h-0`}
  }

  ${(props) =>
    props.bgImage &&
    css`
      ${tw`bg-cover`}
      background-image: url(${props.bgImage});
    `}

  @media (max-width: 1023px) {
    ${tw`hidden`}
  }
`

export const BannerInfo = styled.div<{
  triangleHeight?: number
  custom?: boolean
}>`
  ${tw`bg-white__deep text-center py-7 lg:py-12.5 flex-1 relative px-4 lg:pr-5 lg:pl-0 flex items-center flex-col justify-center`}

  ${({ custom }) => {
    return custom
      ? css`
          background: rgb(0, 0, 0);
        `
      : tw`bg-white__deep`
  }}

  @media (max-width: 1023px) {
    ${tw`w-full bg-transparent`}

    ${Content},
    ${BannerTitle} {
      ${tw`text-white`}
    }
  }

  ${({ custom, triangleHeight }) => {
    return custom
      ? `
        &:before {
          content: '';
          position: absolute;
          left: 107%;
          transform: translateX(-50%);
          top: 0;
          border-style: solid;
          border-bottom: 300px solid transparent;
          border-left: 120px solid rgb(0, 0, 0);
          z-index: 5;
        }
      `
      : `
        &:before {
          content: '';
          position: absolute;
          right: 100%;
          top: -2px;
          border-style: solid;
          border-width: ${triangleHeight}px 120px 0px 0px;
          border-color: transparent ${colors.white__deep} transparent transparent;
          z-index: 5;
        }
        `
  }}
`

export const ButtonWrapper = styled.div`
  a {
    ${tw`normal-case text-sm not-italic py-2.5 px-8`}

    @media (max-width: 1023px) {
      ${tw`bg-transparent border border-white hover:bg-white hover:text-blue__deep`}
    }
  }
`

export const Section = styled.div`
  ${tw`bg-black__deep pb-20 w-full`}
`
