import styled from 'styled-components'
import tw from 'twin.macro'

export const Container = styled.div`
  ${tw`container mx-auto py-10 flex flex-col justify-center text-center`}
`
export const Img = styled.img`
  width: auto;
  max-width: 150px;
`

export const ImgIcon = styled.img`
  width: 80px;
  ${tw`mx-auto mb-4`}
`

export const FeatureContentWrapper = styled.div<{ active?: boolean }>`
  top: 100%;
  ${tw`w-full px-1 pt-4 flex flex-col lg:absolute bottom-0`}
  ${({ active }) => (active ? tw`flex` : tw`hidden`)};
`
export const FeatureContentList = styled.ul`
  ${tw`my-0`}
`
export const FeatureContentItem = styled.li`
  ${tw`text-white text-center`}
`

export const BenefitsContainer = styled.div`
  ${tw`w-full block overflow-x-auto mt-4`}
`

export const BenefitsWrapper = styled.div`
  ${tw`flex flex-wrap items-center content-center justify-center md:px-24 lg:px-4`}
`
export const Benefit = styled.div`
  max-width: 150px;
  ${tw`text-center flex flex-col items-start px-4 self-stretch mt-8`};
`

export const ImgWrapper = styled.div`
  ${tw`flex items-start w-full content-center justify-center`}
`

export const BenefitText = styled.span`
  ${tw`uppercase font-bold max-w-full text-white mt-4`}
`

export const FeatureWraper = styled.div`
  ${tw`w-full flex justify-center content-center overflow-x-hidden`}
`

export const FeatureItemText = styled.h4`
  ${tw`mb-0 text-base`}
`

export const TitleWrapper = styled.div`
  ${tw`w-full text-center flex flex-col justify-center items-center`}
`

export const TextWrapper = styled.div`
  ${tw`w-full text-center px-2 pt-8`}
`
export const FeatureWrapper = styled.div`
  ${tw`w-full px-1 pt-5 pb-2 flex flex-col items-center`}
`
export const FeatureItemsWrapper = styled.div`
  ${tw`w-full flex flex-wrap lg:flex-row justify-center content-center`}
`
export const FeatureItemWrapper = styled.div`
  min-width: 170px;
  ${tw`flex flex-col justify-start text-center relative content-start w-full sm:w-1/5 lg:w-auto`}
`
export const FeatureItem = styled.div<{
  isActive?: boolean
}>`
  ${tw`py-1 px-3 font-semibold hover:text-orange hover:border-orange cursor-pointer border-b-4 border-solid border-blue whitespace-no-wrap flex flex-col text-center content-center`}
  ${({ isActive }) =>
    isActive ? tw`text-orange border-orange` : tw`text-white`};
`

export const FeatureItemHide = styled(FeatureItem)`
  ${tw`opacity-0 hidden lg:flex`}
`
