import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const KeyMessage = styled.div<{ src: string }>`
  width: 50%;
  ${tw`flex flex-col justify-center items-center bg-blue py-8 px-16`}
  ${({ src }) => {
    return `background: url('${src}'); background-size:cover; box-shadow: inset 2000px 0 0 0 rgba(37, 51, 213, 0.90);`
  }}

  @media (max-width: 1024px) {
    ${tw`w-full mb-6`}
  }
`

export const KeyMessageWrap = styled.div<{ isHomePage?: boolean }>`
  ${tw`flex relative`}

  ${({ isHomePage }) =>
    isHomePage &&
    css`
      transform: translateY(-50%);

      @media (max-width: 1024px) {
        ${tw`pb-12`}
        transform: translateY(0);
      }
    `}

  @media (max-width: 1024px) {
    ${tw`flex-col`}
  }
`

export const KeyMessageLargeText = styled.div`
  ${tw`text-white font-bold font-youfitnessHeading leading-none text-center uppercase text-3xl mb-1`}
`

export const KeyMessageTitle = styled.div`
  ${tw`text-white text-xs font-youfitnessHeading font-bold text-center uppercase mb-1`}
`

export const KeyMessageDesc = styled.div`
  ${tw`text-white text-sm`}
`

export const ButtonsList = styled.div`
  ${tw`text-center`}

  a {
    ${tw`mt-4`}
  }
`
