import WagtailBlocksSection from '../src/components/wagtail/components'
import { PageContext, WagtailPageProps } from '../src/services/api/types'

const YourGoalsPage = (props: { pageData: WagtailPageProps }) => {
  const { pageData } = props

  const blocks = pageData.flexible_content ||  []
  
  return (
    <>
      {pageData && pageData.flexible_content ? (
        <WagtailBlocksSection
          blocks={blocks}
          template='you_fitness'
        />
      ) : null}
    </>
  )
}

YourGoalsPage.getInitialProps = async (ctx: PageContext) => {
  let props: {
    pageData: WagtailPageProps
  } = {
    pageData: ctx.pageData
  }

  return props
}
export default YourGoalsPage
