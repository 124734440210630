import { AuthorSection } from './AuthorBlock.styled'
import { PersonBlock, PersonBlockProps } from './PersonBlock'
import circleFrame from '../../static/imgs/circle.png'

export const AuthorBlock = (props: PersonBlockProps) => {
  return (
    <AuthorSection>
      <PersonBlock {...props} avatarFrame={circleFrame} />
    </AuthorSection>
  )
}
export default AuthorBlock
