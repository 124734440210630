import { ButtonLink } from '../../base'
import { Link } from '../../../services/api/types'
import { isNullOrEmpty } from '../../../services/utils'
import { SocialCardProps } from './commonTypes'
import { useInView } from 'react-intersection-observer'
import * as S from './Cards.styles'
import * as G from '../../../styles/global.styles'

export interface ArticleCardProps extends SocialCardProps {
  title?: string
  content?: string
  action?: Link
  imgURL?: string
  isBlogCard?: boolean
  isReskew?: boolean
}

export const ArticleCard = (props: ArticleCardProps) => {
  const { title, content, action, imgURL, isBlogCard, isReskew } = props
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  })

  return (
    <S.Wrapper isBlogCard={isBlogCard}>
      <S.Container
        style={{ backgroundImage: inView ? `url('${imgURL}')` : '' }}
        className={`${imgURL && 'bg-black'}`}
        bg='dark-blue'
        ref={ref}
      >
        <G.OverlayWrapper
          color='grey'
          className='absolute top-0 opacity-75'
          overlay={!isNullOrEmpty(imgURL)}
        />
        <S.CardContent className='z-10'>
          <S.Title
            skewTrans={isReskew}
            color={imgURL ? 'white' : 'blue'}
            shadow
          >
            {title}
          </S.Title>
          <S.Title
            skewTrans={isReskew}
            color={imgURL ? 'white' : 'dark-blue'}
            size='2xl'
            shadow
          >
            {content}
          </S.Title>
        </S.CardContent>
        <S.CardBottom className='z-10' skewTrans={isReskew}>
          <ButtonLink href={action && action.url} color='orange'>
            {action && action.label}
          </ButtonLink>
        </S.CardBottom>
      </S.Container>
    </S.Wrapper>
  )
}

export default ArticleCard
