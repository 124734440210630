import WagtailBlocksSection from '../src/components/wagtail/components'
import { FranchiseeBenefitPageProps } from '../src/services/api/types'
import { NextPage } from 'next'
import { FranchiseeBenefitDetail } from '../src/components/franchisee-benefit-detail'

const FranchiseeBenefitPage: NextPage<{
  pageData: FranchiseeBenefitPageProps
}> = (props: { pageData: FranchiseeBenefitPageProps }) => {
  const {
    pageData: {
      flexible_content,
      image,
      title,
      results_text,
      description,
      link
    }
  } = props

  return (
    <>
      <FranchiseeBenefitDetail
        image={image}
        title={title}
        subText={results_text}
        content={description}
        action={{ label: 'FIND OUT MORE', url: link, target: '_blank' }}
      />
      <WagtailBlocksSection blocks={flexible_content || []} />
    </>
  )
}

export default FranchiseeBenefitPage
